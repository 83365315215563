import { Box, Hidden, Typography } from '@material-ui/core';
import React from 'react';
import { CcpFilter } from '../CcpTransactionsTable/CcpFilter';

export default function CcpInvoicesTableHeader() {
    return (
        <Box display="flex"
            justifyContent="space-between" alignItems="center"
        >
            <Hidden smDown>
                <Box display="flex">
                    <Typography variant="h4">
                        Invoiced Reconciled
                    </Typography>
                </Box>
                <Box marginBottom={'15px'}>
                    <CcpFilter />
                </Box>
            </Hidden>
        </Box>);
}
