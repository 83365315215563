import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHomeTitle, useUserDashboardData } from 'modules/home/dashboard/utils';
import { setHomeDashboardFilter } from 'modules/home/store/actions';
import Dashboard from 'shared/components/dashboard/Dashboard';
import { selectIsManager } from 'store/components/auth/selectors';
import { selectIsUserStatsLoading } from 'modules/home/store/selectors';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';

export const ClientDashboard = () => {
    const dispatch = useDispatch();
    const clientId = useSelector(selectCurrentClientId) || undefined;
    useEffect(() => {
        dispatch(setHomeDashboardFilter({ clientId }));
    }, [dispatch, clientId]);

    const isLoading = useSelector(selectIsUserStatsLoading);
    const isManager = useSelector(selectIsManager);
    const data = useUserDashboardData(isManager);
    useHomeTitle();

    return (
        <Dashboard data={data} isLoading={isLoading} />
    );
};
