import React from 'react';
import { Avatar, Hidden, IconButton } from '@material-ui/core';
import { useHeaderStyles } from 'shared/styles/header';
import HeaderUserMenu from 'shared/components/header/HeaderUserMenu';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/components/auth/selectors';
import { selectUserAvatar } from 'modules/profile/store/profileSelector';

export const UserProfileMenu = () => {
    const headerClasses = useHeaderStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleUserIconClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleUserMenuClose = () => {
        setAnchorEl(null);
    };
    const currentUser = useSelector(selectCurrentUser);
    const avatar = useSelector(selectUserAvatar);
    return (
        <>
            <Hidden smDown>
                <IconButton
                    color="inherit"
                    aria-haspopup="true"
                    className={headerClasses.Avatar}
                    onClick={handleUserIconClicked}
                >
                    <Avatar className={headerClasses.avatarColor} src={avatar.url}>
                        {currentUser?.firstName.substring(0, 1)}{currentUser?.lastName.substring(0, 1)}
                    </Avatar>
                </IconButton>
            </Hidden>
            <HeaderUserMenu anchorEl={anchorEl} handleClose={handleUserMenuClose} />
        </>
    );
};
