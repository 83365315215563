import React, { useCallback } from 'react';
import { useStyles } from 'modules/profile/styles';
import { Typography, Avatar, Box } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { IAvatarUpload } from '../store/profileModel';
import { readAvatarContent } from './readUserAvatar';

export interface IUserAvatar {
    initials: string;
    userId: string;
    avatarUrl: string;
    previewUrl: string;
    setNewAvatar(newAvatar: IAvatarUpload): void;
}

export default function UserAvatarField({ initials, userId, avatarUrl, setNewAvatar, previewUrl }: IUserAvatar) {
    const classes = useStyles();

    const onDrop = useCallback((uploadedFiles: File[]) => {
        uploadedFiles.forEach(file => {
            readAvatarContent(file).then(async function (data){
                const avatarToUpload: IAvatarUpload = {
                    previewUrl: data.previewUrl,
                    body: data.body.split(';base64,')[1],
                    filename: data.filename,
                    mimetype: data.mimetype,
                    entry_id: userId,
                };
                setNewAvatar(avatarToUpload);
            });
        });
    }, [userId, setNewAvatar]);

    const {
        getRootProps, getInputProps,
    } = useDropzone({
        accept: 'image/*',
        onDrop,
        noDrag: true,
        multiple: false,
    });
    return (
        <Box className={classes.avatarBlock}>
            <div {...getRootProps()} className={classes.uploadBox}>
                <Avatar className={classes.avatar}
                    src={previewUrl ?? avatarUrl}
                    alt={initials}>
                    {initials}              
                </Avatar>
                <input {...getInputProps() } />
                <Typography className={classes.avatarTitle}>Add photo</Typography>
            </div>
        </Box>
    );
}
