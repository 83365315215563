import { Box } from '@material-ui/core';
import { usePayRanges } from 'modules/offerLetter/components/OfferLetterForm/hooks/usePayRanges';
import { useOfferLetterDetailCells } from 'modules/offerLetter/components/OfferLetterTableDetail/cells';
import { IDetailTableRow } from 'modules/offerLetter/components/OfferLetterTableDetail/model';
import { useDetailTableStyles } from 'modules/offerLetter/components/OfferLetterTableDetail/styles';
import { IOfferLetter } from 'modules/offerLetter/store/model';
import React from 'react';
import { useSelector } from 'react-redux';
import GridTable from 'shared/components/table/GridTable/GridTable';
import { formatShortDate } from 'shared/utils/formatters/dateFormatter';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { formatPayRate } from 'shared/utils/formatters/payRate';
import { isPayRateInPayRange } from 'store/entities/configuration/helpers/payRanges';
import { getFieldValueName } from 'store/entities/customFields/helpers';
import { selectCustomFieldValuesByIds } from 'store/entities/customFields/selectors';

interface IOfferLetterTableDetailProps {
    offerLetter: IOfferLetter;
}

export const OfferLetterTableDetail = ({ offerLetter }: IOfferLetterTableDetailProps) => {
    const classes = useDetailTableStyles();
    const cells = useOfferLetterDetailCells();
    const customFieldValuesByIds = useSelector(selectCustomFieldValuesByIds);
    const { payRange } = usePayRanges(null);
    // @ts-ignore
    const rows: IDetailTableRow[] = [
        {
            title: 'Job Order',
            value: getFieldValueName(offerLetter.custom_field_value_ids
                && customFieldValuesByIds[offerLetter.custom_field_value_ids[0]]),
        },
        {
            title: 'Pay Range',
            value: payRange ? `${formatDollars(payRange.min_value)} - ${formatDollars(payRange.max_value)}` : null,
        },
        {
            title: 'Pay Rate',
            value: formatPayRate(offerLetter, ''),
            customClassName: payRange && !isPayRateInPayRange(offerLetter.pay_rate_value, payRange)
                ? classes.red : null,
        },
        {
            title: 'Department',
            value: '',
        },
        {
            title: 'Employee Type',
            value: offerLetter.employment_type,
        },
        {
            title: 'Start Date',
            value: formatShortDate(offerLetter.start_date),
        },
    ].filter(row => row.value);
    return (
        <Box className={classes.container}>
            <Box className={classes.header}>
                Offer letter details
            </Box>
            <GridTable
                getKey={row => row.title}
                rowData={rows}
                cells={cells}
                hideHeader={true}
                className={classes.table}
                bodyCellClassName={classes.cell}
            />
        </Box>
    );
};
