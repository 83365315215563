import React, { memo, useCallback, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import { Box, Hidden } from '@material-ui/core';
import {
    getPayPeriodWorkingSheets,
    setCurrentPayPeriod,
} from 'modules/timeAndExpense/components/WorkingPage/store/actions';
import {
    selectCurrentPayPeriod,
    selectIsPayPeriodSheetsLoading,
    selectLimitWorkingSheets,
    selectPayPeriodSheetStatusesByType,
    selectPayPeriodWorkingEntries,
    selectWorkingEmptyText,
    selectWorkingEntryTypeFilter,
    selectWorkingFilteredEntries,
} from 'modules/timeAndExpense/components/WorkingPage/store/selectors';
import AddNewEntryDialog
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/AddEntryControlsMobile/AddNewEntryDialog';
import EntriesGrid from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/EntriesGrid/EntriesGrid';
import FilterAndActionControls
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/FilterAndActionControls';
import FiltersChips
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/FiltersChips';
import Footer from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/Footer';
import useSheetsInProgressStyles
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/SheetsInProgressStyles';
import { WeeklySheetsStatus } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/WeeklySheetsStatus';
import TimeEntryTabs from 'modules/clients/header/TimeEntryTabs';
import { setHeaderContent } from 'modules/home/header/Header';
import { SelectorsPanel } from 'modules/timeAndExpense/components/AddEntry/components/SelectorsPanel/SelectorsPanel';
import { useSheetCalculationUpdate } from 'modules/timeAndExpense/components/utils/updateSheetCalculationUtils';
import { useDispatch, useSelector } from 'react-redux';
import { usePayPeriod } from 'shared/models/Dates';
import { EntryType, QuantityType } from 'shared/models/sheet/Sheet';
import { useTotalTimeEntriesByDay } from 'shared/models/validationSchemes/utils/totalTimeEntriesByDay';
import { IgnoredAreasByType } from 'shared/utils/context/ignoredAreas';
import { TotalMinutesByDay } from 'shared/utils/context/totalMinutesByDay';
import { getOwnConfiguration } from 'store/components/addEntry/addEntryActions';
import { selectCurrentUser, selectIsUserHasPermission } from 'store/components/auth/selectors';
import { setSheetsGridFilters } from 'store/components/workingSheets/workingSheetsActions';
import { selectWorkingSheetsFilters } from 'store/components/workingSheets/workingSheetsSelectors';
import { selectCurrentClientId, selectFieldConfiguration } from 'store/entities/clients/selectors/clientsSelectors';
import { removeLastAddedEntryAction } from 'store/entities/timesheet/actions/sheetState';
import { submitSheets } from 'store/entities/timesheet/actions/statuses';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import AddNewEntry from './AddEntryControls/AddNewEntry';
import { TotalInfo } from './components/TotalInfo/TotalInfo';
import { useEntriesByDayArray } from './utils/entriesByDay';
import { SyncMessage } from 'shared/components/toolbar/SyncMessage';
import { SyncingModels } from 'store/entities/appConfig/syncing/models';
import { clearSyncing } from 'store/entities/appConfig/actions';
import { selectOrderedPayPeriods } from 'store/entities/timesheet/selectors';
import { CaliforniaBreakBanner } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/CaBreakBanner';
import { getHasEmptyEntries } from './utils/emptyEntries';
import { useDisallowPastDayEntry } from '../../../../../store/entities/clients/hooks';
import { moment } from '../../../../../utils/momentExtensions';
import { Permission } from '../../../../../store/components/auth/authModels';

// eslint-disable-next-line react/display-name
const SheetsWorkingPage = memo(() => {
    const classes = useSheetsInProgressStyles();

    const dispatch = useDispatch();
    const user = useSelector(selectCurrentUser);
    const clientId = useSelector(selectCurrentClientId);
    const configuration = useSelector(selectFieldConfiguration(clientId || ''));
    const initialPayPeriod = usePayPeriod();
    const payPeriod = useSelector(selectCurrentPayPeriod);
    const payPeriodsRaw = useSelector(selectOrderedPayPeriods);
    const editSheetAsApprover = useSelector(selectIsUserHasPermission(Permission.EditSheetAsApprover));
    const superAdmin = useSelector(selectIsUserHasPermission(Permission.ManageEverything));
    const disallowPastEntries = useDisallowPastDayEntry();
    const payPeriods = useMemo(() => {
        const date = moment().toDate();
        return (disallowPastEntries && !(editSheetAsApprover || superAdmin))
            ? payPeriodsRaw.filter(x => x && (x?.period_end ?? date) >= date)
            : (payPeriodsRaw ?? []);
    }, [payPeriodsRaw, disallowPastEntries, superAdmin, editSheetAsApprover]);
    const setPayPeriod = useCallback((newPayPeriod: IPayPeriod) => {
        dispatch(setCurrentPayPeriod(newPayPeriod));
    }, [dispatch]);
    useEffect(() => {
        if (initialPayPeriod) {
            setPayPeriod(initialPayPeriod);
        }
    }, [initialPayPeriod, setPayPeriod]);

    useSheetCalculationUpdate(payPeriod);

    const isLoading = useSelector(selectIsPayPeriodSheetsLoading);
    const weeklyWorkingEntries = useSelector(selectPayPeriodWorkingEntries);
    const payPeriodSheetStatusesByType = useSelector(selectPayPeriodSheetStatusesByType);
    const entryTypeFilter = useSelector(selectWorkingEntryTypeFilter);
    const customEmptyText = useSelector(selectWorkingEmptyText);

    const onSubmitSheets = useCallback((entryTypes: Array<EntryType>) => {
        if (payPeriod) {
            dispatch(submitSheets({
                payPeriod,
                entryTypes,
            }));
        }
    }, [dispatch, payPeriod]);

    const recalledAreaIdsByType = useSelector(selectLimitWorkingSheets);
    const filteredEntries = useSelector(selectWorkingFilteredEntries);

    const entriesByDay = useEntriesByDayArray(filteredEntries);
    const totalEntryMinutesByDay = useTotalTimeEntriesByDay(filteredEntries);

    useEffect(() => {
        if (payPeriod && clientId) {
            dispatch(getPayPeriodWorkingSheets.init(payPeriod));
        }

        return () => {
            dispatch(removeLastAddedEntryAction());
            dispatch(clearSyncing(SyncingModels.EditableEmployeeSheet));
        };
    }, [payPeriod, clientId, dispatch]);

    useEffect(() => {
        dispatch(getOwnConfiguration.init());
    }, [dispatch, configuration]);

    const hasEntries = weeklyWorkingEntries.length > 0;
    const hasEmptyEntries = useMemo(() => getHasEmptyEntries(weeklyWorkingEntries), [weeklyWorkingEntries]);
    const hasEntriesForSubmit = hasEntries && !hasEmptyEntries;
    const noFilteredEntries = filteredEntries.length === 0;

    useEffect(() => {
        setHeaderContent(
            <Box className={classes.headerContentWrapper}>
                <WeeklySheetsStatus />
                <TimeEntryTabs />
            </Box>,
        );

        return () => {
            setHeaderContent(null);
        };
    }, [classes]);

    const entryTypeAllowed: EntryType[] = [EntryType.TIME, EntryType.EXPENSE]
        .filter(entryType => {
            // Allow adding new entry if exist working sheets or no sheet of this type
            const entryTypeStatusNames: StatusNames[] = payPeriodSheetStatusesByType[entryType];
            return isEmpty(entryTypeStatusNames) || entryTypeStatusNames.includes(StatusNames.WORKING);
        });
    const isFileEntriesView = !!weeklyWorkingEntries.find(entry => entry.data.entry_type === QuantityType.FILE);

    const gridEntryType = ((entryTypeFilter || entryTypeAllowed.length === 1) && entryTypeAllowed[0]) || undefined;

    return (
        <IgnoredAreasByType.Provider value={recalledAreaIdsByType}>
            <TotalMinutesByDay.Provider value={totalEntryMinutesByDay}>
                <Box className={classes.mainContainer}>
                    <CaliforniaBreakBanner />

                    <Box className={classes.topContainer}>
                        <Hidden smDown>
                            <SelectorsPanel />
                        </Hidden>
                        <FilterAndActionControls
                            hasEntries={hasEntries}
                            entryTypeAllowed={entryTypeAllowed}
                            payPeriod={payPeriod}
                            payPeriods={payPeriods}
                        />
                    </Box>

                    <Hidden smDown>
                        <AddNewEntry
                            customClasses={{ paper: classes.addControlsPaper }}
                            entryTypeAllowed={entryTypeAllowed}
                            userId={user?.id}
                            payPeriod={payPeriod}
                            entryTypeFilter={entryTypeFilter}
                            hasEntries={hasEntries}
                            statusName={StatusNames.WORKING}
                        />
                    </Hidden>
                    {entryTypeAllowed.length > 0 && (
                        <Hidden mdUp>
                            {/*
                            // @ts-ignore */}
                            <AddNewEntryDialog
                                entries={filteredEntries}
                                entryTypeAllowed={entryTypeAllowed}
                                userId={user?.id}
                                payPeriod={payPeriod}
                                statusName={StatusNames.WORKING}
                            />
                        </Hidden>
                    )}
                    <Box className={classes.aboveTable}>
                        <FiltersChips
                            // @ts-ignore
                            action={setSheetsGridFilters}
                            selector={selectWorkingSheetsFilters}
                            className={clsx(classes.headerTables, classes.filters, { [classes.hidden]: !hasEntries })}
                        />
                        <SyncMessage syncingKey={SyncingModels.EditableEmployeeSheet} />
                    </Box>
                    <EntriesGrid
                        isLoaded={!isLoading}
                        entryFilter={gridEntryType}
                        hasEntries={hasEntries}
                        noFilteredEntries={noFilteredEntries}
                        filteredEntriesByDay={entriesByDay}
                        customEmptyText={customEmptyText}
                        isFileEntriesView={isFileEntriesView}
                        userId={user?.id}
                        payPeriod={payPeriod}
                        statusName={StatusNames.WORKING}
                    />
                    <Hidden xsDown>
                        <Box>
                            <TotalInfo entries={filteredEntries} />
                        </Box>
                    </Hidden>
                    <Footer hasEntries={hasEntriesForSubmit} submitAction={onSubmitSheets} />
                </Box>
            </TotalMinutesByDay.Provider>
        </IgnoredAreasByType.Provider>
    );
});

export default SheetsWorkingPage;
