import React, { useCallback, useMemo, useState } from 'react';
import { OfferLetterStatusSlug } from 'modules/offerLetter/store/model';
import { useLatestEmployeeOffersByStatus } from 'modules/settings/submodules/employees/components/EditEmployee/hooks';
import { SelectClientWithConfirmation } from 'modules/settings/submodules/employees/components/EditEmployee/SelectClientWithConfirmation';
import { overrideOfferLetterStatus } from 'modules/settings/submodules/employees/store/actions';
import { selectIsStatusUpdating } from 'modules/settings/submodules/employees/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { getUserName } from 'shared/utils/converters/user';
import { selectUserById } from 'store/entities/users/selectors';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';

interface IResetOfferButtonProps {
    userId: string;
}

const confirmationText = `
This will retract their offer letter Pending I-9 status and return it back to outstanding status in Headway Connect.
To continue, select the client of the offer letter you want to retract.
`;

export const ResetOfferButton = ({ userId }: IResetOfferButtonProps) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const user = useSelector(selectUserById(userId));
    const isLoading = useSelector(selectIsStatusUpdating);

    const handleOpen = useCallback(() => setOpen(true), []);
    const handleClose = useCallback(() => setOpen(false), []);
    const offerLetters = useLatestEmployeeOffersByStatus(
        user?.email,
        [OfferLetterStatusSlug.PendingManagerApproval],
    );
    const clientIds = useMemo(() => {
        return offerLetters.map(offer => offer.client_id);
    }, [offerLetters]);
    const handleSubmit = useCallback(clientId => {
        const offerLetterId = offerLetters.find(offer => offer.client_id === clientId)?.id;
        dispatch(overrideOfferLetterStatus.init({
            // @ts-ignore
            id: offerLetterId,
            status: OfferLetterStatusSlug.Outstanding,
        }));
        handleClose();
    }, [dispatch, handleClose, offerLetters]);

    const confirmationTitle = `Are you sure you want to retract ${getUserName(user)}'s Pending I-9 status?
    Employee will not be able to re-accept this offer letter. 
    Admin will need to rescind and send new offer letter or edit and resend this offer letter.`;

    return (
        <>
            <ButtonWithLoader
                variant="contained"
                color="secondary"
                onClick={handleOpen}
                disabled={clientIds.length === 0}
                isLoading={isLoading}
            >
                Reset
            </ButtonWithLoader>
            <SelectClientWithConfirmation
                open={open}
                onClose={handleClose}
                onSubmit={handleSubmit}
                modalTitle="Reset Status to Outstanding in Headway Connect"
                confirmationTitle={confirmationTitle}
                confirmationText={confirmationText}
                allowedClientIds={clientIds}
            />
        </>
    );
};
