import React from 'react';
import { useSelector } from 'react-redux';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import DayPickerField from 'shared/components/formFields/DayPickerField';
import MoneyField from 'shared/components/formFields/MoneyField';
import { shortDateFormat } from 'shared/models/Dates';
import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import { FormikProvider, useFormik } from 'formik';
import { some } from 'lodash-es';
import { selectOrderedCustomFieldAssignmentNodes } from 'store/entities/customFields/selectors';
import { useFormWithSectionsStyles } from 'modules/settings/common/components/FormWithSections/styles';
import { CreateSubassignmentCustomFields } from 'modules/subassignmentManagement/components/CreateSubAssignment/CreateSubAssignmentForm/CreateSubassignmentCustomFields';
import { useSubAssignmentBulkCreateEditFormStyles } from 'modules/subassignmentManagement/components/CreateSubAssignment/CreateSubAssignmentForm/styles';
import { SubassignmentApprovers } from 'modules/subassignmentManagement/components/CreateSubAssignment/CreateSubAssignmentForm/SubassignmentApprovers';
import { IBulkCreateSubAssignmentForm } from 'modules/subassignmentManagement/components/CreateSubAssignment/CreateSubAssignmentForm/types';
import { useCreateSubAssignmentValidationSchema } from 'modules/subassignmentManagement/components/CreateSubAssignment/CreateSubAssignmentForm/validationsSchema';
import { selectIsLoadingPreInitialize } from 'modules/subassignmentManagement/components/CreateSubAssignment/store/selectors';
import { prepareDefaultInitialFormValues } from 'modules/subassignmentManagement/components/EditSubAssignment/hooks/useEditCustomFieldsInitialFormValues';
import { useSubassignmentApproversInitialState } from 'modules/subassignmentManagement/hooks/useSubassignmentApproversInitialState';

interface ICreateSubAssignmentFormV2Props {
    numberOfApprovers: number;
    onSubmit: (values: IBulkCreateSubAssignmentForm) => void;
    submitButtonTitle?: string;
    onBack?: () => void;
}

export function CreateSubAssignmentForm({
    onSubmit,
    numberOfApprovers,
    submitButtonTitle = 'Next',
    onBack,
}: ICreateSubAssignmentFormV2Props) {
    const classes = useSubAssignmentBulkCreateEditFormStyles();
    const formClasses = useFormWithSectionsStyles();
    const hierarchy = useSelector(selectOrderedCustomFieldAssignmentNodes);
    const isLoading = useSelector(selectIsLoadingPreInitialize);
    const validationSchema = useCreateSubAssignmentValidationSchema(numberOfApprovers);

    const approversInitial = useSubassignmentApproversInitialState(numberOfApprovers);
    const defaultInitialFormValues = prepareDefaultInitialFormValues(hierarchy);

    const formikData = useFormik<IBulkCreateSubAssignmentForm>({
        // @ts-ignore
        initialValues: {
            startDate: '',
            endDate: '',
            overrideRate: null,
            ...defaultInitialFormValues,
            ...approversInitial,
        },
        validationSchema,
        onSubmit,
        validateOnBlur: false,
    });
    const { handleSubmit, values } = formikData;
    const isDisabled = !some(Object.values(values));

    return (
        <FormikProvider value={formikData}>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item sm={4}>
                        <DayPickerField
                            name="startDate"
                            format={shortDateFormat}
                            placeholder={shortDateFormat}
                            outerLabel="Start Date"
                            className={classes.input}
                            withKeyboard
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <DayPickerField
                            name="endDate"
                            format={shortDateFormat}
                            placeholder={shortDateFormat}
                            outerLabel="End Date"
                            className={classes.input}
                            withKeyboard
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <MoneyField
                            name="overrideRate"
                            outerLabel="Override Rate"
                            className={classes.input}
                            withDollar
                        />
                    </Grid>
                </Grid>

                <SubassignmentApprovers
                    inputClassName={classes.input}
                    numberOfApprovers={numberOfApprovers}
                />

                <Box mt={3} />
                <Divider light />

                <Box mt={2} mb={2}>
                    <Typography variant="subtitle2" className={formClasses.formBlockTitle}>
                        Custom Fields
                    </Typography>
                </Box>

                <Grid
                    container
                    spacing={2}
                >
                    {hierarchy.map(node => (
                        <CreateSubassignmentCustomFields
                            key={node.custom_field_id}
                            node={node}
                            disabled={isLoading}
                            classes={classes}
                            hierarchy={hierarchy}
                            values={values}
                        />
                    ))}
                </Grid>

                <Box mt={7}>
                    <ButtonWithLoader
                        type="submit"
                        variant="contained"
                        color="secondary"
                        disabled={isDisabled}
                        isLoading={isLoading}
                        className={classes.button}
                    >
                        {submitButtonTitle}
                    </ButtonWithLoader>
                    {onBack && (
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.button}
                            onClick={onBack}
                        >
                            Back
                        </Button>
                    )}
                </Box>
            </form>
        </FormikProvider>
    );
}
