export enum FeatureSwitches {
    enableEditingEmployeeNameOnOfferLetterCreation = 'enableeditingemployeenameonofferlettercreation', // disabled
    enableArBucketSettingsModule = 'enablearbucketsettingsmodule', // disabled
    enabledCollapsingDayEntriesInSheet = 'enabledcollapsingdayentriesinsheet', // disabled
    displayActivityShowZipCode = 'displayactivityshowzipcode', // disabled
    enablePPHFilterByManager='enablepphfilterbymanager', // disabled
    enableMaintainceWorkMessage='enablemaintainceworkmessage',
    displayTimeInOutInPPH='displaytimeinoutinpph', // disabled
    enableFrontendRefresher='enablefrontendrefresher', // feature vars for configuration
    enableZohoAnalyticsReportsEmbedding = 'enable_zoho_analytics_reports_embedding',
    enableCompensationChange = 'enable_compensation_change',
    enablePromotionDemotion = 'enable_promotion_demotion',
    enableAssignmentStartDateChanging = 'enable_assignment_start_date_changing',
    enableCreditCardPortalLink = 'enable_credit_card_portal_link',
    enableCreditCardPortal = 'enable_ccp',
    enableEndAssignmentReason = 'enable_end_assignment_reason',
    enableTerminationEaf = 'enable_termination_eaf',
    enableTerminationRequests = 'enable_termination_requests',
    enableEafSettings = 'enable_eaf_settings',
    enableEafSettingsHideSubassignment = 'enable_eaf_settings_hide_subassignment',
    enableEafRetro = 'enable_eaf_retro',
    enablePromotionDemotionForAssignment = 'enable_promotion_demotion_for_assignment',
    enableEditEmployee = 'enable_edit_employee',
    enableAcceptOfferLetterInSettings = 'enable_accept_offer_letter_in_settings',
    usePtoMaxUseAvailable = 'use_pto_max_use_available',
    enableResetOfferLetterInSettings = 'enable_reset_offer_letter_in_settings',
    enableScopeActions = 'enable_scope_actions',
    enableDeleteEmployee = 'enable_delete_employee',
    enableResetPasswordButton = 'enable_reset_password_button',
    enableEditCustomFieldValueChildren = 'enable_edit_custom_field_value_children',
    displayEmployeePrismIdInSettings = 'display_employee_prism_id_in_settings',
    enableEditEmployeePrismId = 'enable_edit_employee_prism_id',
    enableSyncEmployeeProfileButton = 'enable_sync_employee_profile_button',
    enableFilterOfferLetterByApprover = 'enable_filter_offer_letter_by_approver',
    displaySheetApprovalTimestamp = 'display_sheet_approval_timestamp',
    enableDeleteClient = 'enable_delete_client',
    enableSecureAttachmentUrl = 'enable_secure_attachment_url',
    enableSearchJobNumbers = 'enable_search_job_numbers',
    hideTimeAndExpenseIfNoActivities = 'hide_time_and_expense_if_no_activities',
    enableSeparatePtoAndPsl = 'enable_separate_pto_and_psl',
    enableClientRoleUserAssociationV2 = 'enable_client_role_user_association_v2',
    enableClientRoleAndPermissionPage = 'enable_client_role_and_permission_page',
    enableClientRoleManagement = 'enable_client_role_management',
    displayClientRoleNotManagedPermission = 'display_client_role_not_managed_permission',
    enableClientRoleFilter = 'enable_client_role_filter',
    enableClientRoleDelete = 'enable_client_role_delete',
    enableFillLookupDataIntoOfferLetterForm = 'enable_fill_lookup_data_into_offer_letter_form',
    enableEmployeePortalMenuItem = 'enable_employee_portal_menu_item',
    enableCustomFieldValueStatus = 'enable_custom_field_value_status',
    enableRescindOfferFromPending = 'enable_rescind_offer_from_pending',
    enableOfferLetterStartDateRangeFilter = 'enable_offer_letter_start_date_range_filter',
    enableOfferLetterTableSorting = 'enable_offer_letter_table_sorting',
    enableOfferLetterTemplateEditing = 'enable_offer_letter_template_editing',
    enableClientSearch = 'enable_client_search',
    enableTerminationEafAttachments = 'enable_termination_eaf_attachments',
    enableAssignmentHistory = 'enable_assignment_history',
    displaySubmittedTimeForSheet = 'display_submitted_time_for_sheet',
    enableExtendEntriesCellsByActivities = 'enable_extend_entries_cells_by_activities',
    enableCaliforniaBreakBanner = 'enable_california_break_banner',
    enableCaliforniaBreakSubmissionConfirmation = 'enable_california_submission_confirmation',
    allowEditSubmittedSheets = 'allow_edit_submitted_sheets',
    enableVirtualizationForPphTable = 'enable_virtualization_for_pph_table',
    disableOfferLetterPendingI9Status = 'disable_offer_letter_pending_i9_status',
    enableGwEcubReport = 'enable_gw_ecub_report',
    enableApprovalCsvExport = 'enable_approval_csv',
    enablePayrollFailsCsvReport = 'enable_payroll_fails_csv_report',
    enableExpandAllAssignmentsButton = 'enable_expand_all_assignments_button',
    enableVirtualizationForSubassignmentsTable = 'enable_virtualization_for_subassignments_table',
    enableExplicitPositionsSync = 'enable_explicit_positions_prism_sync',
    hideTimeExpenseApprovalsByActivities = 'hide_time_and_expense_approvals_if_no_activities',
    enableUpdatedByPph = 'enable_updated_by_pph',
    enablePphDepartmentFilter = 'enable_pph_department_filter',
    enableCustomFieldsExport = 'enable_custom_fields_export',
    enableRegionalDirectorField = 'credit_card_regional_director_field',
    enableErrorPage = 'enable_error_page',
}
