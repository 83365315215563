import React, { useEffect } from 'react';
import { HierarchyPageSortable } from 'modules/settings/submodules/components/HierarchyPage/HierarchyPageSortable';
import { setClientId } from 'store/entities/clients/clientsAction';
import { useDispatch } from 'react-redux';
import { getCustomFieldsHierarchyNodes } from 'store/entities/customFields/actions';

export const DefaultHierarchyPage = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setClientId(null));
        dispatch(getCustomFieldsHierarchyNodes.init());
    }, [dispatch]);
    return (
        <HierarchyPageSortable />
    );
};
