import React from 'react';
import PlainText from 'shared/components/table/Cells/PlainText';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

const useStyles = makeHighPriorityStyles(() => ({
    employeeHeaderCell: {
        transform: 'translateX(-14px)',
    },
}));

export const EmployeeHeaderCell: React.FC = () => {
    const classes = useStyles();
    return (
        <PlainText value="Employee" className={classes.employeeHeaderCell} />
    );
};
