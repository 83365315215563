import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { settingsRoutes } from 'modules/settings/routes';
import { ScopeTypeDisplayNames } from 'store/entities/scopes/models';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { SubmodulesDashboard } from 'modules/settings/common/components/SubmodulesDashboard';

export const ScopesDashboard = () => {
    const clientId = useSelector(selectCurrentClientId);
    const items = useMemo(() => {
        if (!clientId) {
            return [];
        }
        return Object.entries(ScopeTypeDisplayNames).map(([type, displayName]) => {
            return {
                title: displayName,
                link: generatePath(
                    settingsRoutes.CLIENTS.ASSIGNMENT_SETUP.SCOPES.TYPE,
                    { clientId, type },
                ),
            };
        });
    }, [clientId]);
    return (
        <SubmodulesDashboard items={items} />
    );
};
