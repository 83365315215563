import React, { useMemo } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { FormikProvider, useFormik } from 'formik';
import { Box, Button } from '@material-ui/core';
import { useFeature } from '@optimizely/react-sdk';
import { useEafFormStyles } from 'modules/subassignmentManagement/components/EafFormShared/useEafFormStyles';
import { WarningAlertWithIcon } from 'shared/components/alerts/WarningAlertWithIcon';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import { AttachmentInput } from 'shared/components/formFields/AttachmentInput/AttachmentInput';
import DayPickerField from 'shared/components/formFields/DayPickerField';
import EnumFormSelect from 'shared/components/formFields/EnumFormSelect';
import FormField from 'shared/components/formFields/FormField';
import FormRadioButtonGroup from 'shared/components/selects/FormRadioButtonGroup';
import { shortDateFormat } from 'shared/models/Dates';
import { Status } from 'shared/models/Status';
import { IAssignment, ISubassignment } from 'store/entities/configuration/configurationModel';
import ReasonSelect from 'modules/employeeActionsForm/components/EndAssignment/ReasonSelect';
import { sidebarFormStyles } from 'modules/employeeActionsForm/components/EndAssignment/styles';
import { useEndAssignmentValidationSchema } from 'modules/employeeActionsForm/components/EndAssignment/validationSchema';
import { EafType, eafTypeDisplayValues } from 'modules/employeeActionsForm/store/models';
import { FeatureSwitches } from 'utils/featureSwitches';
import { maxMomentDate } from 'utils/momentExtensions';
import CheckboxField from 'shared/components/formFields/CheckboxField';

export interface IEndAssignmentFormProps {
    isLoading: boolean,
    onCancel: () => void,
    relatedSubassignments?: ISubassignment[];
    assignmentMaxEntryDate?: string;
    assignment?: IAssignment;
    initialValues: IEmployeeActionFormDataModel,
    onSubmit: (values: IEmployeeActionFormDataModel) => void,
    isTermination?: boolean;
}

export interface IEmployeeActionFormDataModel {
    employee_action_form_type: EafType | null,
    employee_action_form_reason_text?: string,
    employee_action_form_reason?: string | null,
    effective_date: string | null;
    eligibleForRehire: Status | null,
    attachments: IAssignment[],
    do_not_send_employee_email?: boolean;
}

export const EndAssignmentForm = ({
    isLoading,
    onCancel,
    relatedSubassignments,
    assignmentMaxEntryDate,
    assignment,
    initialValues,
    onSubmit,
    isTermination = false,
}: IEndAssignmentFormProps) => {
    const classes = useEafFormStyles();
    const formClasses = sidebarFormStyles();

    const [enableEndAssignmentReason] = useFeature(FeatureSwitches.enableEndAssignmentReason);

    const validationSchema = useEndAssignmentValidationSchema(enableEndAssignmentReason, isTermination);
    const formikData = useFormik<IEmployeeActionFormDataModel>({
        initialValues,
        validationSchema,
        validateOnBlur: false,
        onSubmit,
    });
    const { handleSubmit, values } = formikData;

    const minEffectiveDate = useMemo(() => {
        // Return next day after latest assignment entry or assignment hire date
        return assignmentMaxEntryDate ? moment(assignmentMaxEntryDate).add(1, 'days')
            : assignment?.hire_date ? moment(assignment?.hire_date) : undefined;
    }, [assignmentMaxEntryDate, assignment?.hire_date]);

    const countSubassignmentToClose = useMemo(() => {
        const assignmentEndDate = moment(values.effective_date || undefined);
        return relatedSubassignments?.filter(subasssignment => {
            const subassignmentEndDate = moment(subasssignment.end_date || maxMomentDate);
            return subassignmentEndDate.isAfter(assignmentEndDate);
        }).length || 0;
    }, [relatedSubassignments, values.effective_date]);

    const [enableTerminationEafAttachments] = useFeature(FeatureSwitches.enableTerminationEafAttachments);

    return (
        <FormikProvider value={formikData}>
            <form
                onSubmit={handleSubmit}
                className={formClasses.form}
            >
                {countSubassignmentToClose !== 0 && !isTermination && (
                    <Box mb={2}>
                        <WarningAlertWithIcon>
                            This will end {countSubassignmentToClose} sub-assignments
                            associated with this main assignment.
                        </WarningAlertWithIcon>
                    </Box>
                )}

                {isTermination && (
                    <Box mb={2}>
                        <WarningAlertWithIcon>
                            This will end all sub-assignments and send a termination request to HR.
                        </WarningAlertWithIcon>
                    </Box>
                )}

                {!isTermination && (
                    <Box className={classes.row}>
                        <label htmlFor="employee_action_form_reason_text" className={formClasses.inlineInputLabel}>Type</label>
                        <Box width="250px">
                            <EnumFormSelect
                                name="employee_action_form_type"
                                values={EafType}
                                displayValues={eafTypeDisplayValues}
                                allowedValues={[EafType.AssignmentEnded, EafType.Resignation]}
                                className={formClasses.input}
                            />
                        </Box>
                    </Box>
                )}

                {(enableEndAssignmentReason || isTermination) && (
                    <>
                        <Box className={classes.row}>
                            <label htmlFor="employee_action_form_reason_text" className={formClasses.inlineInputLabel}>Reason</label>
                            <Box width="250px">
                                {isTermination ? (
                                    <ReasonSelect
                                        id="employee_action_form_reason"
                                        name="employee_action_form_reason"
                                        type={EafType.Termination}
                                        label="--"
                                        useIdValue
                                        className={formClasses.input}
                                    />
                                ) : (
                                    <FormField
                                        id="employee_action_form_reason_text"
                                        name="employee_action_form_reason_text"
                                        className={formClasses.input}
                                    />
                                )}
                            </Box>
                        </Box>

                        <Box className={classes.row}>
                            <label htmlFor="eligibleForRehire" className={formClasses.inlineInputLabel}>Eligible for rehire?</label>
                            <EnumFormSelect
                                id="eligibleForRehire"
                                name="eligibleForRehire"
                                values={Status}
                                className={clsx(formClasses.input, classes.inlineRadio)}
                                displayValues={{
                                    [Status.active]: 'Yes',
                                    [Status.inactive]: 'No',
                                }}
                                InputComponent={FormRadioButtonGroup}
                            />
                        </Box>
                    </>
                )}

                <Box className={classes.row}>
                    <label htmlFor="effective_date" className={formClasses.inlineInputLabel}>Effective Date</label>
                    <Box width="160px">
                        <DayPickerField
                            id="effective_date"
                            name="effective_date"
                            format={shortDateFormat}
                            className={formClasses.input}
                            disableToolbar
                            withKeyboard
                            minDate={minEffectiveDate}
                            maxDate={assignment?.end_date || undefined}
                        />
                    </Box>
                </Box>

                {!isTermination && (
                    <Box className={classes.row}>
                        <label htmlFor="do_not_send_employee_email" className={formClasses.inlineInputLabel}>Do not send email</label>
                        <Box>
                            <CheckboxField name="do_not_send_employee_email">
                            </CheckboxField>
                        </Box>
                    </Box>
                )}

                {isTermination && enableTerminationEafAttachments && (
                    <Box className={clsx(classes.row, classes.fileInputWrapper)}>
                        <AttachmentInput
                            name="attachments"
                            relatedEntityType="eaf"
                            text="Uploading supporting docs"
                            note="a file up to 15MB"
                            className={classes.fileInput}
                            multiple
                            maxSizeMb={15}
                            rejectMessage={null}
                        />
                    </Box>
                )}

                <Box
                    display="flex"
                    alignItems="baseline"
                >
                    <ButtonWithLoader
                        type="submit"
                        variant="contained"
                        color="secondary"
                        className={formClasses.button}
                        isLoading={isLoading}
                        data-testid="submit_button"
                    >
                        Submit
                    </ButtonWithLoader>
                    <Button
                        disabled={isLoading}
                        onClick={onCancel}
                        variant="outlined"
                        color="primary"
                    >
                        Cancel
                    </Button>
                </Box>
            </form>
        </FormikProvider>
    );
};
