import { Box } from '@material-ui/core';
import { PaySettingsFormWrapper } from 'modules/settings/submodules/clients/paySettings/PaySettingsFormWrapper';
import React from 'react';
import { WarningAlertWithIcon } from 'shared/components/alerts/WarningAlertWithIcon';

export const PaySettingsPage = () => {
    return (    
        <>
            <Box
                mb={2}
                display="flex"
                justifyContent="space-between"
            >
                <WarningAlertWithIcon>
                    Pay setting changes will apply to future timesheets only.
                </WarningAlertWithIcon>
            </Box>
            <PaySettingsFormWrapper />
        </>
    );
};
