import React from 'react';
import { Box, Hidden } from '@material-ui/core';
import {
    PayrollSheetBatchActions,
} from 'modules/payrollProcessorHub/components/PayrollSheetsTablePage/PayrollSheetBatchActions';
import SheetSummary from 'modules/payrollProcessorHub/components/SheetSummary/SheetSummary';
import { SheetTabTitle } from 'modules/payrollProcessorHub/components/SheetTitle/SheetTabTitle';

export const PayrollSheetsPageHeading = () => (
    <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        minHeight="42px"
    >
        <SheetTabTitle />

        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
        >
            <PayrollSheetBatchActions />

            <Hidden xsDown>
                <Box ml={2}>
                    <SheetSummary />
                </Box>
            </Hidden>
        </Box>
    </Box>
);
