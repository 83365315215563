import GetAppIcon from '@material-ui/icons/GetApp';
import React, { useCallback, useState } from 'react';
import { CircularProgress, IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { SvgIconComponent } from '@material-ui/icons';
import { AnyAction } from 'redux';
import ConfirmationDialog from 'shared/components/modals/ConfirmationDialog';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';

export interface IDeleteItemButtonProps {
    confirmation?: string;
    confirmationTitle?: string;
    action: (payload: any) => AnyAction;
    payload: any;
    disabled?: boolean;
    permission?: Permission;
    Icon?: SvgIconComponent;
    title?: string;
    confirmText?: string;
    isLoading?: boolean;
}

export const IconActionButton = ({
    confirmation,
    title,
    action,
    payload,
    disabled,
    permission,
    confirmationTitle,
    Icon = GetAppIcon,
    confirmText = 'Confirm',
    isLoading,
}: IDeleteItemButtonProps) => {
    const dispatch = useDispatch();
    const [isOpenConfirmation, setOpenConfirmation] = useState(false);
    const userHasPermission = useSelector(selectIsUserHasPermission(permission));

    const onCancel = useCallback(() => {
        setOpenConfirmation(false);
    }, [setOpenConfirmation]);
    const onConfirm = useCallback(() => {
        dispatch(action(payload));
        onCancel();
    }, [dispatch, action, payload, onCancel]);
    const onButtonClick = useCallback(() => {
        if (confirmation) {
            setOpenConfirmation(true);
        } else {
            onConfirm();
        }
    }, [onConfirm, confirmation]);

    if (permission && !userHasPermission) {
        return null;
    }

    return (
        <>
            <IconButton
                onClick={onButtonClick}
                color="secondary"
                data-testid="delete-button"
                disabled={disabled}
                title={title}
            >
                {isLoading ? (
                    <CircularProgress size="0.8em" />
                ) : (
                    <Icon fontSize="small" />
                )}
            </IconButton>
            <ConfirmationDialog
                open={isOpenConfirmation}
                onConfirm={onConfirm}
                onCancel={onCancel}
                dialogTitle={confirmationTitle}
                confirmText={confirmText}
            >
                {confirmation}
            </ConfirmationDialog>
        </>
    );
};
