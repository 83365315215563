import React from 'react';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import { PublishOutlined } from '@material-ui/icons';

interface IRunPayrollProps {
    isLoading: boolean;
    onClick: () => void;
}

export const RunPayrollButton = ({ isLoading, onClick }: IRunPayrollProps) => {

    return (
        <>
            <ButtonWithLoader
                onClick={onClick}
                isLoading={isLoading}
                variant="contained"
                color="primary"
                type="submit"
                startIcon={<PublishOutlined fontSize="small" />}
            >
                <span>Initialize payroll</span>
            </ButtonWithLoader>
        </>
    );
};
