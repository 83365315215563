import React from 'react';
import { useSelector } from 'react-redux';
import { DeleteOutlined } from '@material-ui/icons';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import IconButtonWithLoader from 'shared/components/buttons/IconButtonWithLoader';
import { useEntryEditStyles } from 'shared/components/sidebars/EntryEdit/styles';
import { isFileReading, isReceiptUploading } from 'store/entities/timesheet/selectors';
import { ISubmitBlockProps } from './models';
import { selectIsEntryRemoving, selectIsEntryUpdating } from 'modules/timeAndExpense/components/EditEntry/store/selectors';

export default function EditEntrySave({ onSubmit, onDelete }: ISubmitBlockProps) {
    const classes = useEntryEditStyles();
    const isUploading = useSelector(isReceiptUploading);
    const isLoading = useSelector(selectIsEntryUpdating);
    const isRemoving = useSelector(selectIsEntryRemoving);
    const isDisabled = useSelector(isFileReading);

    return (
        <>
            <ButtonWithLoader
                color="primary"
                variant="contained"
                onClick={onSubmit}
                classes={{ root: classes.button, label: classes.buttonLabel }}
                disabled={isDisabled || isUploading || isLoading}
                isLoading={isLoading || isUploading}
            >
                Save
            </ButtonWithLoader>
            {onDelete && (
                <IconButtonWithLoader
                    title="Remove"
                    onClick={onDelete}
                    isLoading={isRemoving}
                >
                    <DeleteOutlined />
                </IconButtonWithLoader>
            )}
        </>
    );
}
