import { useSelector } from 'react-redux';
import { PayRateType } from 'shared/models/PaySettings';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { selectClientPaySettings } from 'store/entities/clients/selectors/timeAndPaySelectors';
import { IAssignment, ISubassignment } from 'store/entities/configuration/configurationModel';
import { zeroWidthSpace } from 'utils/constants';

const payRateTypeDisplayValue: Record<PayRateType, string> = {
    [PayRateType.PER_HOUR]: 'hr',
    [PayRateType.DAILY]: 'daily',
    [PayRateType.PER_WEEK]: 'weekly',
    [PayRateType.BI_WEEKLY]: 'bi-weekly',
    [PayRateType.MONTHLY]: 'monthly',
    [PayRateType.SEMI_MONTHLY]: 'semi-monthly',
};

export function formatPayRate(
    { pay_rate_type, pay_rate_value }: { pay_rate_type: PayRateType, pay_rate_value: number | string },
    paySettingPeriodDescription: string | undefined,
) {
    const payRateType = pay_rate_type === PayRateType.PER_HOUR
        ? payRateTypeDisplayValue[pay_rate_type]
        : paySettingPeriodDescription?.toLocaleLowerCase() || payRateTypeDisplayValue[pay_rate_type];
    return pay_rate_value ? `${formatDollars(pay_rate_value)}${zeroWidthSpace}/${payRateType}` : '';
}

export function formatSubassignmentPayRate(
    assignment: IAssignment,
    subassignment: ISubassignment,
    paySettingPeriodDescription: string | undefined,
): string {
    const data = {
        pay_rate_type: assignment.pay_rate_type,
        pay_rate_value: subassignment.override_rate_value,
    };
    return formatPayRate(data, paySettingPeriodDescription);
}

export const usePaySettingsPayPeriodType = () => {
    const paySettings = useSelector(selectClientPaySettings);
    return paySettings?.pay_period_type;
};

export function useFormattedPayRate(
    payDescription?: { pay_rate_type: PayRateType, pay_rate_value: number | string },
) {
    const paySettingPeriodDescription = usePaySettingsPayPeriodType();
    if (!payDescription) {
        return '';
    }
    return formatPayRate(payDescription, paySettingPeriodDescription);
}

export const usePayRateType = (payPateType: PayRateType) => {
    const paySettingPeriodDescription = usePaySettingsPayPeriodType();
    return payPateType === PayRateType.PER_HOUR ? payPateType : paySettingPeriodDescription || '';
};
