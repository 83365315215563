import React, { FC, useCallback } from 'react';
import { Button } from '@material-ui/core';
import FilterSVG from 'shared/components/icons/FilterSVG';
import { useModal } from 'shared/utils/hooks/useModal';
import PopOver from 'shared/components/popover/PopOver';
import Sidebar from 'shared/components/sidebars/Sidebar/Sidebar';
import { useIsMobile } from 'shared/utils/hooks/media';
import clsx from 'clsx';
import useActionButtonStyles
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/buttons/ActionButtonStyles';
import { useFiltersSidebarStyles } from 'shared/components/filters/FilterButtonAndForm/FilterButtonAndFormStyles';
import { DefaultFiltersContent } from 'shared/components/filters/FilterButtonAndForm/DefaultFiltersContent';
import { IFilterStoreProps } from 'shared/models/Filters';
import {
    FiltersTestIds,
    IFiltersContentProps,
} from 'shared/components/filters/FilterButtonAndForm/FilterButtonAndFormModel';

interface IFilterButtonProps extends IFilterStoreProps {
    FiltersContent?: FC<IFiltersContentProps>;
    className?: string;
    overrideClassName?: string;
    title?: string;
}

export default function SheetFilterButton({
    FiltersContent = DefaultFiltersContent,
    title = 'FILTER',
    className = '',
    overrideClassName = '',
    ...contentProps
}: IFilterButtonProps) {
    const classes = useFiltersSidebarStyles();
    const buttonClasses = useActionButtonStyles();
    const buttonRootClassName = overrideClassName ? overrideClassName : buttonClasses.default;

    const isMobile = useIsMobile();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { isModalOpened: isOpened, onModalOpen, onModalClose: onClose } = useModal();

    const onFilterClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        onModalOpen();
    }, [onModalOpen]);

    return (
        <>
            <Button classes={{ root: clsx(buttonRootClassName, className) }} onClick={onFilterClick}
                data-testid={FiltersTestIds.Button}
            >
                <FilterSVG />
            </Button>

            <PopOver isOpened={isOpened && !isMobile} anchorEl={anchorEl}
                onClose={onClose} data-testid={FiltersTestIds.Wrapper}
            >
                <FiltersContent onClose={onClose} {...contentProps} />
            </PopOver>

            <Sidebar title={title} onClose={onClose}
                titleClasses={classes} anchor={'bottom'}
                isOpened={isMobile && isOpened} data-testid={FiltersTestIds.Wrapper}
            >
                <FiltersContent onClose={onClose} {...contentProps} />
            </Sidebar>
        </>
    );
}
