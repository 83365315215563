import { selectIsClientDeleting } from 'modules/settings/submodules/clients/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { useFeature } from '@optimizely/react-sdk';
import { deleteClient } from 'modules/settings/submodules/clients/store/actions';
import {
    DeleteButtonWithConfirmation,
} from 'modules/settings/submodules/components/Modals/DeleteButtonWithConfirmation';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';
import { selectClientById } from 'store/entities/clients/selectors/clientsSelectors';
import { FeatureSwitches } from 'utils/featureSwitches';

interface IDeleteClientButtonProps {
    clientId: string;
    className?: string;
}

export const DeleteClientButton = ({ clientId, className }: IDeleteClientButtonProps) => {
    const client = useSelector(selectClientById(clientId));
    const [enable] = useFeature(FeatureSwitches.enableDeleteClient);
    const isUserHasPermission = useSelector(selectIsUserHasPermission(Permission.ManageEverything));

    if (!enable || !isUserHasPermission) {
        return null;
    }

    return (
        <DeleteButtonWithConfirmation
            buttonText="Delete client"
            modalTitle="Delete client"
            modalButtonTitle="I understand the consequences, delete this client"
            isLoadingSelector={selectIsClientDeleting}
            action={deleteClient.init}
            actionPayload={clientId}
            className={className}
        >
            <Typography variant="subtitle2" gutterBottom>
                Are you sure you want to permanently delete the {client?.name} client from Headway Connect?
            </Typography>
            <Typography gutterBottom>
                This action cannot be undone.
                It will permanently delete the client and all of it{`'`}s data along with it.
            </Typography>
        </DeleteButtonWithConfirmation>
    );
};
