import React from 'react';
import { Box, Container, CssBaseline } from '@material-ui/core';
import { useIsMobile } from 'shared/utils/hooks/media';
import { useStyles } from './AuthStyles';

export interface IAuthWrapper {
    children: NonNullable<React.ReactNode>;
}

const AuthWrapper = ({ children }: IAuthWrapper) => {
    const classes = useStyles();
    const isMobile = useIsMobile();

    return (
        <Box className={classes.authWrapper}>
            <CssBaseline />
            <Container
                className={classes.contentWrapper}
                disableGutters={isMobile}
            >
                {children}
            </Container>
        </Box>
    );
};

export default AuthWrapper;
