import { Box } from '@material-ui/core';
import { FormikProps } from 'formik';
import { FormFieldWithSeparateLabel } from 'modules/settings/common/components/FormFieldWithSeparateLabel';
import { IClientPaySettingsData } from 'modules/settings/submodules/clients/paySettings/store/models';
import { useInlineFormStyles } from 'modules/settings/submodules/components/sharedStyles/inlineFormStyles';
import React, { useMemo } from 'react';
import DayPickerField from 'shared/components/formFields/DayPickerField';
import FormSelect from 'shared/components/selects/FormSelect';
import { PayPeriodType, WeekDayEnum } from 'shared/models/PaySettings';
import { FormWithSections } from '../../../common/components/FormWithSections/FormWithSections';

export interface IPaySettingsFormProps extends FormikProps<IClientPaySettingsData> {
    isUpdating?: boolean;
}

export function PaySettingsForm({ isUpdating, ..._ }: IPaySettingsFormProps) {
    const classes = useInlineFormStyles();
    const payPeriodSelectOptions = useMemo(() => buildOptions(PayPeriodType), []);
    const weekDaySelectOptions = useMemo(() => buildOptions(WeekDayEnum), []);
    const getKey = (option: Record<string, string>) => option.value;
    const getText = (option: Record<string, string>) => option.label;

    const sections = useMemo(() => ([
        {
            name: 'Pay Group',
            component: (
                <>
                    <Box width="35%">
                        <FormFieldWithSeparateLabel
                            name="pay_period_type"
                            outerLabel="Pay Period Type"
                            className={classes.shortInput}  
                            justifyContent="space-between"
                            FieldComponent={FormSelect}  
                            options={payPeriodSelectOptions}
                            getKey={getKey}
                            getText={getText}
                            showErrorText = {false}
                            useIdValue
                        />
                    </Box>
                </>
            ),
        },
        {
            name: 'Start and End Days',
            component: (
                <>
                    <Box width="35%">                            
                        <FormFieldWithSeparateLabel
                            name="pay_period_start_day"
                            outerLabel="Period Start Day"
                            className={classes.shortInput}  
                            justifyContent="space-between"
                            FieldComponent={FormSelect}  
                            options={weekDaySelectOptions}
                            getKey={getKey}
                            getText={getText}
                            showErrorText = {false}
                            useIdValue
                        />
                    </Box>
                    <Box width="35%">
                        <FormFieldWithSeparateLabel
                            name="pay_period_end_day"
                            outerLabel="Period End Day"
                            className={classes.shortInput}  
                            justifyContent="space-between"
                            FieldComponent={FormSelect}  
                            options={weekDaySelectOptions}
                            getKey={getKey}
                            getText={getText}
                            showErrorText = {false}
                            useIdValue
                        />
                    </Box>
                    <Box width="35%">
                        <FormFieldWithSeparateLabel                                                  
                            name="pay_period_start_date"
                            outerLabel="Period Start Date"
                            className={classes.shortInput}
                            justifyContent="space-between"
                            FieldComponent={DayPickerField}  
                            showErrorText = {false}
                            withKeyboard
                            
                        />
                    </Box>                 
                </>
            ),
        },
        {
            name: 'Pay Day',
            component: (
                <Box width="35%">
                    <FormFieldWithSeparateLabel
                        name="pay_day"
                        outerLabel="Should be paid on"
                        className={classes.shortInput}  
                        justifyContent="space-between"
                        FieldComponent={FormSelect}  
                        options={weekDaySelectOptions}
                        getKey={getKey}
                        getText={getText}
                        showErrorText = {false}
                        useIdValue
                    />
                </Box>
            ),
        },
    ]), [classes.shortInput, payPeriodSelectOptions, weekDaySelectOptions]);

    return (
        <FormWithSections
            sections={sections}
            submitTitle="Update"
            onSubmit={_.handleSubmit}
            isLoading={isUpdating}
            isDisabled={!_.isValid || isUpdating}
        />
    );
}

const buildOptions = (values: Record<string, string>) => {
    return Object.entries(values).map(([key, value]) => ({
        value: value, 
        label: key, 
    })); 
};
