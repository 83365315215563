import { useFeature } from '@optimizely/react-sdk';
import { usePayrollGroupedCalculationTableCells } from 'modules/payrollProcessorHub/components/PayrollSheetTable/hooks/usePayrollGroupedCalculationTableCells';
import { IPayrollSheetTableComponentProps } from 'modules/payrollProcessorHub/components/PayrollSheetTable/PayrollSheetTable';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IPayrollSheetGroupedRow } from 'modules/payrollProcessorHub/components/PayrollSheetTable/model';
import {
    groupedCalculationsInfinityTableSelectors,
    selectGroupedCalculationsRows,
} from 'modules/payrollProcessorHub/store/selectors';
import {
    getMoreGroupedSheetCalculationBatches,
} from 'modules/payrollProcessorHub/store/actions';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { IPayrollSheetTableProps, PayrollSheetTablePure } from 'modules/payrollProcessorHub/components/PayrollSheetTable/PayrollSheetTablePure';
import { FeatureSwitches } from 'utils/featureSwitches';

export const PayrollGroupedCalculationsTable = separateLogicDecorator<
IPayrollSheetTableComponentProps,
Partial<IPayrollSheetTableProps<IPayrollSheetGroupedRow>>
>(props => {
    const dispatch = useDispatch();
    const hasMore = useSelector(groupedCalculationsInfinityTableSelectors.selectHasMore);
    const isLoading = useSelector(groupedCalculationsInfinityTableSelectors.isLoading);
    const totalCount = useSelector(groupedCalculationsInfinityTableSelectors.selectTotalItems);
    const cells = usePayrollGroupedCalculationTableCells();
    const rowData = useSelector(
        selectGroupedCalculationsRows,
        (left, right) => {
            return JSON.stringify(left) === JSON.stringify(right);
        },
    );
    const [enableVirtualizationForPphTable] = useFeature(FeatureSwitches.enableVirtualizationForPphTable);

    const onLoadMore = useCallback(() => {
        if (!isLoading && hasMore) {
            dispatch(getMoreGroupedSheetCalculationBatches.init());
        }
    }, [dispatch, hasMore, isLoading]);

    return {
        ...props,
        cells,
        rowData,
        className: props.tableClassName,
        onLoadMore,
        hasMore,
        isLoading: enableVirtualizationForPphTable ? isLoading : undefined,
        totalCount,
    };
    // @ts-ignore
})(PayrollSheetTablePure);
