import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setCreateCustomFieldModalState } from 'modules/settings/submodules/customFields/store/actions';
import { CreateCustomFieldModal } from 'modules/settings/submodules/customFields/components/CreateCustomField/CreateCustomFieldModal';

export const CreateCustomFieldButton = () => {
    const dispatch = useDispatch();
    const openModal = useCallback(() => {
        dispatch(setCreateCustomFieldModalState(true));
    }, [dispatch]);

    return (
        <>
            <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={openModal}>
                + Add custom field
            </Button>
            <CreateCustomFieldModal />
        </>
    );
};
