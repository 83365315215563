import React from 'react';
import { Box, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, FormikProps } from 'formik';
import { StatusSelect } from 'modules/settings/common/components/StatusSelect/StatusSelect';
import { GlobalRoleSelect } from 'modules/settings/submodules/platformUsers/components/PlatformUserForm/GlobalRoleSelect';
import { IClientUserAssociationFilter, IPlatformUsersFilter } from 'modules/settings/submodules/platformUsers/store/models';
import { setPlatformUsersFilter } from 'modules/settings/submodules/platformUsers/store/actions';
import { selectPlatformUserFilter } from 'modules/settings/submodules/platformUsers/store/selectors';
import { useFilterStyles } from 'modules/settings/submodules/components/sharedStyles/filterStyles';
import FilterSVG from 'shared/components/icons/FilterSVG';
import ClientSelect from 'shared/components/selects/ClientSelect';
import { useModal } from 'shared/utils/hooks/useModal';
import PopOver from 'shared/components/popover/PopOver';
import useActionButtonStyles
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/buttons/ActionButtonStyles';
import {
    FiltersTestIds,
} from 'shared/components/filters/FilterButtonAndForm/FilterButtonAndFormModel';

export default function PlatformUserFilter() {
    const buttonClasses = useActionButtonStyles();
    const classes = useFilterStyles();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { isModalOpened: isOpened, onModalOpen, onModalClose: onClose } = useModal();
    const filter = useSelector(selectPlatformUserFilter);

    const onFilterClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        onModalOpen();
    };

    const onApplyFilter = (
        values: IPlatformUsersFilter,
    ) => {
        dispatch(setPlatformUsersFilter(values));
        onClose();
    };

    return (
        <Box
            display="flex"
            justifyContent="flex-end"
            ml={2}
            mt={0.5}
        >
            <Button
                onClick={onFilterClick}
                classes={{ root: buttonClasses.default }}
                data-testid={FiltersTestIds.Button}
            >
                <FilterSVG />
            </Button>

            <PopOver
                isOpened={isOpened}
                anchorEl={anchorEl}
                onClose={onClose}
                data-testid={FiltersTestIds.Wrapper}
            >
                <Formik
                    initialValues={filter}
                    onSubmit={onApplyFilter}
                >
                    {(props: FormikProps<IClientUserAssociationFilter>) => (
                        <form
                            onSubmit={props.handleSubmit}
                            className={classes.form}
                        >
                            <GlobalRoleSelect
                                name="global_role"
                                label="Role"
                                className={classes.input}
                            />
                            <ClientSelect
                                name="client"
                                label="Client"
                                className={classes.input}
                            />
                            <StatusSelect
                                name="status"
                                label="Status"
                                className={classes.input}
                            />
                            <Box>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    className={classes.button}
                                    data-testid="submit_button"
                                >
                                    Apply
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </PopOver>
        </Box>
    );
}
