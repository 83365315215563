import React, { useCallback, useEffect, useState } from 'react';
import * as qs from 'query-string';
import { Paper, Box, Typography, CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import AuthWrapper from 'shared/components/auth/AuthWrapper';
import { useStyles } from './AuthStyles';
import { newPasswordAfterResetting, newPasswordCodeChecking } from 'store/components/resetPassword/actions';
import {
    selectResettingPasswordCodeValidating,
    selectResettingPasswordCodeValidatingIsLoading, selectResettingPasswordNewPassword,
    selectResettingPasswordNewPasswordIsLoading,
} from 'store/components/resetPassword/selectors';
import { EnterNewPasswordForm } from './forms/EnterNewPasswordForm';
import { INewPasswordFormValues } from './forms/models';

import { routes } from '../../routes';
import { Nullable } from '../../../types/types';
import { ISystemState } from '../../models/Global';
import { AuthFooter } from './components/AuthFooter';
import { useLocation } from 'react-router-dom';
import { Logo } from './components/Logo';
import { Maintaince } from '../alerts/Maintaince';

export default function EnterPassword() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const { secret, email } = qs.parse(location.search);

    const isCodeValidatingLoading = useSelector(selectResettingPasswordCodeValidatingIsLoading);
    const codeValidatingResult = useSelector(selectResettingPasswordCodeValidating);

    useEffect(() => {
        dispatch(newPasswordCodeChecking.init({
            secret_code: `${secret}`,
            email: `${email}`,
        }));
    }, [secret, email, dispatch]);

    const isSubmitting = useSelector(selectResettingPasswordNewPasswordIsLoading);
    const [formHelp, setFormHelp] = useState<Nullable<ISystemState>>(null);
    const userResult = useSelector(selectResettingPasswordNewPassword);

    useEffect(() => {
        setFormHelp(userResult);
    }, [userResult]);

    const onSubmit = useCallback((values: INewPasswordFormValues) => {
        dispatch(newPasswordAfterResetting.init({
            password: values.password,
            secret_code: `${secret}`,
            email: `${email}`,
        }));
    }, [secret, email, dispatch]);

    const onChange = useCallback(() => {
        setFormHelp(null);
    }, [setFormHelp]);

    return (
        <AuthWrapper>
            <Maintaince />
            <Paper className={classes.block}>
                <Box className={classes.logoContainer}>
                    <Logo />
                </Box>
                {isCodeValidatingLoading
                    ? (
                        <Box
                            ml={1}
                            mb={-0.5}
                            textAlign="center"
                        >
                            <CircularProgress color="inherit" />
                        </Box>
                    )
                    : (
                        <>
                            {codeValidatingResult && !codeValidatingResult.isError && (
                                <>
                                    <Box mt={3}>
                                        <Typography>Reset Password</Typography>
                                    </Box>
                                    <EnterNewPasswordForm
                                        isSubmitting={isSubmitting}
                                        helpText={formHelp ? formHelp.message : ''}
                                        hasError={Boolean(formHelp && formHelp.isError)}
                                        onSubmit={onSubmit}
                                        onChange={onChange}
                                    />
                                </>
                            )}
                        </>
                    )
                }
            </Paper>
            <AuthFooter label="Try to login again or register new account" route={routes.AUTH.NEW_PASSWORD} />
        </AuthWrapper>
    );
}
