import { BulkCreateCcpTransactionsSteps, SetBulkCcpStep, setOpenBulkCreateCcpUploadModal } from './store/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectCcpBulkCreationStep,
    selectIsCcpBulkLoadingPreInitialize,
    selectIsOpenCcpUploadCreationModal,
} from './store/selectors';
import React, { useCallback, useState } from 'react';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { Box } from '@material-ui/core';
import { BulkUploadCcpTransactionsForm } from './BulkUploadCcpTransactionsForm';
import {
    bulkUploadCcpTransactionAction,
    bulkUploadCreateCcpTransactionPreInitializeAction,
} from '../../store/actions';
import { PreinitializeCcpCreateReport } from './PreinitializeCcpCreationReport';
import { CcpPostCreateReport } from './CcpCreationPostImportReport';

const stepTitleMapping: Record<BulkCreateCcpTransactionsSteps, string> = {
    [BulkCreateCcpTransactionsSteps.Form]: '1. Import Credit Card Data',
    [BulkCreateCcpTransactionsSteps.Verify]: '2. Verify',
    [BulkCreateCcpTransactionsSteps.PostReport]: '3. Report',
};

export const BulkCreateCcpUploadFile = () => {
    const dispatch = useDispatch();
    const isOpened = useSelector(selectIsOpenCcpUploadCreationModal);
    const step = useSelector(selectCcpBulkCreationStep);
    const isLoading = useSelector(selectIsCcpBulkLoadingPreInitialize);
    const [creationPayload, setCreationPayload] = useState<File | null>(null);

    const onClose = useCallback(() => {
        dispatch(setOpenBulkCreateCcpUploadModal(false));
    }, [dispatch]);
    const onBack = useCallback(() => {
        dispatch(SetBulkCcpStep(BulkCreateCcpTransactionsSteps.Form));
    }, [dispatch]);
    const handleSubmitPreInitializeReport = useCallback((file: File) => {
        setCreationPayload(file);
        dispatch(bulkUploadCreateCcpTransactionPreInitializeAction.init(file));
    }, [dispatch]);
    const handleImport = useCallback(() => {
        // @ts-ignore
        dispatch(bulkUploadCcpTransactionAction.init(creationPayload));
    }, [dispatch, creationPayload]);

    return (
        <ModalDialog
            title={stepTitleMapping[step]}
            isOpened={isOpened}
            onClose={onClose}
            colored
            modalProps={{
                showCloseIcon: true,
            }}
        >
            <Box>
                {
                    step === BulkCreateCcpTransactionsSteps.Form && (
                        <BulkUploadCcpTransactionsForm
                            onSubmit={handleSubmitPreInitializeReport}
                            isLoading={isLoading}
                        />
                    )}
                {
                    step === BulkCreateCcpTransactionsSteps.Verify && (
                        <PreinitializeCcpCreateReport
                            onBack={onBack}
                            onNextClick={handleImport}
                        />
                    )
                }
                {
                    step === BulkCreateCcpTransactionsSteps.PostReport && (
                        <CcpPostCreateReport onNextClick={onClose} />
                    )
                }
            </Box>
        </ModalDialog>
    );
};
