import {
    CircularProgress, Fade,
    FormControl,
    FormHelperText,
    InputLabel,
    OutlinedInput,
    Popper,
    Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useScaZoneStyles } from 'shared/components/formSpecialFields/scaZone/scaZoneStyles';
import { IOptionsConfig } from 'shared/components/formSpecialFields/scaZone/model';
import { INamedEntity } from 'shared/models/Entity';
import { useFormHelperTextStyles } from 'shared/styles/formHelperText';
import { inputParams } from '../../../styles/constants';

interface IScaZoneInputProps {
    value: string;
    onChange: (newValue: string) => void;
    onBlur: () => void;
    startAdornment?: React.ReactNode;
    label: string;
    customClasses?: string;
    options?: IOptionsConfig;
    resetPreviousValue?: (value: null) => void;
    disabled?: boolean;
    loading?: boolean;
    error?: string;
}

export default function ScaZoneInput({
    value, onChange, onBlur, label, resetPreviousValue, disabled,
    customClasses, startAdornment, loading, options, error,
}: IScaZoneInputProps) {
    const classes = useScaZoneStyles();
    const formHelperTextClasses = useFormHelperTextStyles();
    const listWrapper = useRef<HTMLUListElement>(null);
    const [highlighted, setHighlighted] = useState(0);
    const [inputElement, setInputElement] = useState<HTMLInputElement | null>(null);
    const [selectOpened, setSelectOpened] = useState(true);

    const values = options?.values;

    useEffect(() => {
        setHighlighted(0);
    }, [setHighlighted, values]);

    const onSelectOpen = useCallback(() => {
        setSelectOpened(true);
    }, [setSelectOpened]);

    const handleBlur = useCallback(() => {
        setSelectOpened(false);
        onBlur();
    }, [setSelectOpened, onBlur]);

    const onInputChange = useCallback(({ target }: React.ChangeEvent<HTMLInputElement>) => {
        onChange(target.value);
    }, [onChange]);

    const popperPreventFocusLose = useCallback((event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
    }, []);

    const onKeyDown = useCallback((event: React.KeyboardEvent) => {
        const key: string = event.key.toLowerCase();
        if (key === 'backspace' && value === '' && resetPreviousValue) {
            resetPreviousValue(null);
            return;
        }

        if (key === 'enter') {
            event.preventDefault();

            if (options && options.values.length > 0) {
                options.setValue(options.values[highlighted].id);
                return;
            }
        }

        if (['arrowup', 'arrowdown'].includes(key) && options) {
            let newHighlighted = highlighted + (key === 'arrowdown' ? 1 : -1);

            if (newHighlighted === options.values.length) {
                newHighlighted = 0;
            } else if (newHighlighted < 0) {
                newHighlighted = options.values.length - 1;
            }
            setHighlighted(newHighlighted);

            const childElement = listWrapper.current ? listWrapper.current.children[newHighlighted] : null;

            if (childElement) {
                //TODO Add scrollIntoViewIfNeeded to Element prototype in typescript
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                childElement.scrollIntoViewIfNeeded(false);
            }

            return;
        }
    }, [value, highlighted, setHighlighted, options, resetPreviousValue, listWrapper]);

    return (
        <>
            <FormControl variant="outlined" error={!!error}
                classes={{ root: customClasses }}
            >
                <InputLabel>
                    {label}
                </InputLabel>
                <OutlinedInput
                    autoFocus
                    disabled={disabled}
                    label={label} value={value}
                    onChange={onInputChange} ref={setInputElement}
                    onFocus={onSelectOpen} onBlur={handleBlur}
                    onKeyDown={onKeyDown} startAdornment={startAdornment}
                    inputProps={{
                        ...inputParams,
                    }}
                />
                {error && (
                    <FormHelperText classes={formHelperTextClasses}>
                        {error}
                    </FormHelperText>
                )}
            </FormControl>
            {inputElement && options && (
                <Popper anchorEl={inputElement}
                    className={classes.popperElement}
                    placement="bottom-start"
                    open={selectOpened}
                    onMouseDown={popperPreventFocusLose}
                >
                    <div className={classes.popoverWrapper}>
                        <Typography className={classes.popoverTitle} variant="caption"
                            color="primary"
                        >
                            {options.title}
                        </Typography>
                        <ul className={classes.optionsWrapper} ref={listWrapper}>
                            {options.values.map((option: INamedEntity, index: number) => (
                                <li onClick={options.setValue.bind(null, option.id)}
                                    className={clsx(classes.optionElement, highlighted === index ? classes.active : '')}
                                    onMouseEnter={setHighlighted.bind(null, index)}
                                    key={option.id}
                                >
                                    {option.name}
                                </li>
                            ))}
                        </ul>
                        {loading ? (
                            <CircularProgress size={32} color="primary" />
                        ) : (
                            <>
                                {options.values.length === 0 && (
                                    <Fade in>
                                        <Typography variant="caption">No items found</Typography>
                                    </Fade>
                                )}
                            </>
                        )}
                    </div>
                </Popper>
            )}
        </>
    );
}
