import { Formik } from 'formik';
import { EmployeeProfileForm } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/form/EmployeeProfileForm';
import { employeeProfileValidationsSchema } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/form/EmployeeProfileValidationsSchema';
import {
    defaultEmployeeProfileValues,
    IEmployeeProfileFormValues,
} from 'modules/offerLetter/components/OfferLetterEmployeeDetail/form/model';
import { onboardMyEmployeeProfile } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/store/actions';
import {
    selectMyEmployeeProfileOnboarding,
} from 'modules/offerLetter/components/OfferLetterEmployeeDetail/store/selectors';
import { selectMyEmployeeProfileByClientId } from 'modules/offerLetter/store/selectors';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationDialog from 'shared/components/modals/ConfirmationDialog';
import { selectCurrentUser } from 'store/components/auth/selectors';

export interface IEmployeeProfileFormWrapperProps {
    clientId: string;
    offerLetterId: string;
}

const EmployeeProfileFormWrapper = ({ clientId, offerLetterId }: IEmployeeProfileFormWrapperProps) => {
    const dispatch = useDispatch();
    const isOnboarding = useSelector(selectMyEmployeeProfileOnboarding);
    const currentUser = useSelector(selectCurrentUser);
    const employeeProfile = useSelector(selectMyEmployeeProfileByClientId(clientId));
    const initialValues = {
        ...defaultEmployeeProfileValues,
        first_name: currentUser?.firstName || defaultEmployeeProfileValues.first_name,
        last_name: currentUser?.lastName || defaultEmployeeProfileValues.last_name,
        birthday: employeeProfile?.date_of_birth || defaultEmployeeProfileValues.birthday,
        address: employeeProfile?.address || defaultEmployeeProfileValues.address,
        address2: employeeProfile?.address2 || defaultEmployeeProfileValues.address2,
        county: employeeProfile?.county || defaultEmployeeProfileValues.county,
        state: employeeProfile?.state || defaultEmployeeProfileValues.state,
        city: employeeProfile?.city || defaultEmployeeProfileValues.city,
        zip_code: employeeProfile?.zip_code || defaultEmployeeProfileValues.zip_code,
    };
    const isDisabled = !!employeeProfile?.headway_onboarding_completed;

    const [valuesToSubmit, setValuesToSubmit] = useState<IEmployeeProfileFormValues|null>(null);

    const onCancel = useCallback(() => {
        setValuesToSubmit(null);
    }, [setValuesToSubmit]);
    const onConfirm = useCallback(() => {
        if (valuesToSubmit && clientId && offerLetterId) {
            dispatch(onboardMyEmployeeProfile.init({
                clientId: clientId,
                onboardingData: {
                    offer_letter_id: offerLetterId,
                    first_name: valuesToSubmit.first_name,
                    last_name: valuesToSubmit.last_name,
                    date_of_birth: valuesToSubmit.birthday,
                    tax_id: valuesToSubmit.ssn,
                    county: valuesToSubmit.county,
                    state: valuesToSubmit.state,
                    city: valuesToSubmit.city,
                    address1: valuesToSubmit.address,
                    address2: valuesToSubmit.address2,
                    zip_code: valuesToSubmit.zip_code,
                },
            }));
        }
        onCancel();
    }, [dispatch, clientId, offerLetterId, valuesToSubmit, onCancel]);

    const onSubmitCreateForm = (values: IEmployeeProfileFormValues) => {
        setValuesToSubmit(values);
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={employeeProfileValidationsSchema}
                onSubmit={onSubmitCreateForm}
                validateOnBlur={false}
            >
                {props => (
                    <EmployeeProfileForm
                        {...props}
                        isLoading={isOnboarding}
                        isDisabled={isDisabled}
                    />
                )}
            </Formik>
            <ConfirmationDialog
                open={Boolean(valuesToSubmit)}
                onConfirm={onConfirm}
                onCancel={onCancel}
            >
                Please confirm that you used your legal first and last name in the previous form.
            </ConfirmationDialog>
        </>
    );
};

export default EmployeeProfileFormWrapper;
