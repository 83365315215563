import { selectIsEmployeeOnbordingWithAvionte } from 'modules/settings/submodules/employees/store/selectors';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import ConfirmationDialog from 'shared/components/modals/ConfirmationDialog';
import { getUserName } from 'shared/utils/converters/user';
import { selectUserById } from 'store/entities/users/selectors';
import { onboardWithAvionte } from '../../store/actions';

interface ICompleteAvionteOnboardingButton {
    userId: string;
}

export const CompleteAvionteOnboardingButton = ({ userId }: ICompleteAvionteOnboardingButton) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const user = useSelector(selectUserById(userId));
    const isOnboarding = useSelector(selectIsEmployeeOnbordingWithAvionte);

    const handleOpen = useCallback(() => setOpen(true), []);
    const handleClose = useCallback(() => setOpen(false), []);
    const handleSubmit = useCallback(() => {
        dispatch(onboardWithAvionte.init(userId));
        handleClose();
    }, [dispatch, handleClose, userId]);

    const isHeadwayOnboardingCompleted = !!user?.employee_profile?.headway_onboarding_completed;
    const isAvionteOnboardingCompleted = !!user?.employee_profile?.avionte_onboarding_completed;

    const isDisabled = !isHeadwayOnboardingCompleted || isAvionteOnboardingCompleted;
    const buttonText = isAvionteOnboardingCompleted ? 'Onboarded' : 'Set';

    const confirmationText = `
        Are you sure you want to set ${getUserName(user)}'s Avionte onboarding status to Completed?
        This will set all of their Pending-I9 offer letters to Accepted, create Assignments with Sub Assignments in Connect, 
        and Placement in Avionte, and give them access to their timesheet.
        `;

    return (
        <>
            <ButtonWithLoader
                variant="contained"
                color="secondary"
                onClick={handleOpen}
                disabled={isDisabled}
                isLoading={isOnboarding}
            >
                {buttonText}
            </ButtonWithLoader>
            <ConfirmationDialog
                open={open}
                onConfirm={handleSubmit}
                onCancel={handleClose}
            >
                {confirmationText}
            </ConfirmationDialog>
        </>
    );
};
