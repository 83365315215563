import { ValidationMessages } from 'shared/models/Validation';
import { getPayPeriodForDate } from 'shared/utils/helpers/paySettingsHelpers';
import { IClientPaySettings } from 'store/entities/clients/clientsModel';
import { moment } from 'utils/momentExtensions';
import * as yup from 'yup';

export const getEffectiveDateValidationSchema = (paySettings: IClientPaySettings, additionalDate?: string) => {
    return yup.string().required(ValidationMessages.REQUIRED)
        .test({
            name: 'effectiveDateRetro',
            message: 'Please select the effective date in the future',
            test: function (value) {
                const isRetro = this.parent.isRetro;
                return isRetro || moment(value).isSameOrAfter(moment(), 'day');
            },
            exclusive: true,
        })
        .test({
            name: 'effectiveDatePayPeriodStart',
            message: 'The effective date should be at the start of a pay period',
            test: value => {
                const payPeriod = getPayPeriodForDate(paySettings, value);
                if (additionalDate && moment(value).isSame(moment(additionalDate), 'day')) {
                    return true;
                }
                return moment(value).isSame(moment(payPeriod.period_start), 'day');
            },
            exclusive: true,
        });
};
