import React from 'react';
import { IconButton } from '@material-ui/core';
import useAddControlsStyles from '../AddControlsStyles';

interface IEntryTypeButton {
    onClick: () => void;
    disabled: boolean;
    active: boolean;
    IconComponent: React.ElementType;
}

function EntryTypeButton({ onClick, disabled, active, IconComponent }: IEntryTypeButton) {
    const classes = useAddControlsStyles();

    return (
        <IconButton
            onClick={onClick}
            disabled={disabled}
            data-testid="entry-type-switcher"
        >
            <IconComponent
                className={!disabled && active ? classes.activeFilterControl : ''}
                fontSize="small" />
        </IconButton>
    );
}

export default EntryTypeButton;
