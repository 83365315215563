import { useFeature } from '@optimizely/react-sdk';
import React, { useEffect, useMemo } from 'react';
import { FormikProps } from 'formik';
import { isEqual } from 'lodash-es';
import { useSelector } from 'react-redux';
import FormField from 'shared/components/formFields/FormField';
import { Permission } from 'store/components/auth/authModels';
import { UserAutocompleteWithSearch } from 'shared/components/autocomplete/UserAutocompleteWithSearch/UserAutocompleteWithSearch';
import {
    IJobNumberFilter,
    jobNumberFilterDefaultValues,
} from 'modules/settings/submodules/clients/jobNumber/store/models';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { useInlineFormStyles } from 'modules/settings/submodules/components/sharedStyles/inlineFormStyles';
import { DealAutocompleteWithSearch } from 'shared/components/autocomplete/deals/DealAutocompleteWithSearch';
import { debounce } from 'ts-debounce';
import { textInputDebounceTimeout } from 'utils/constants';
import { FeatureSwitches } from 'utils/featureSwitches';

export interface IJobNumberFilterFormProps extends FormikProps<IJobNumberFilter> {
    onChange: (value: Partial<IJobNumberFilter>) => void;
    actualFilterValues: IJobNumberFilter;
}

export const JobNumberFilterForm = ({
    actualFilterValues,
    handleSubmit,
    onChange,
    values,
}: IJobNumberFilterFormProps) => {
    const classes = useInlineFormStyles();
    const clientId = useSelector(selectCurrentClientId);
    const [enableSearchJobNumbers] = useFeature(FeatureSwitches.enableSearchJobNumbers);

    const onChangeDebounced = useMemo(() => {
        return debounce(onChange, textInputDebounceTimeout);
    }, [onChange]);

    useEffect(() => {
        if (!isEqual(values, actualFilterValues)) {
            onChangeDebounced({
                userId: values.userId || jobNumberFilterDefaultValues.userId,
                dealId: values.dealId || jobNumberFilterDefaultValues.dealId,
                jobNumberSearch: values.jobNumberSearch || jobNumberFilterDefaultValues.jobNumberSearch,
            });
        }
    }, [actualFilterValues, values, onChangeDebounced]);

    return (
        <form className={classes.form} onSubmit={handleSubmit}>
            {enableSearchJobNumbers && (
                <FormField
                    name="jobNumberSearch"
                    type="number"
                    label="Job #"
                    className={classes.input}
                />
            )}
            <UserAutocompleteWithSearch
                additionalFilter={{
                    purpose: Permission.SubmitSheets,
                    client_id: clientId || undefined,
                }}
                name="userId"
                label="Employee"
                useIdValue
                className={classes.input}
            />
            <DealAutocompleteWithSearch
                name="dealId"
                label="Deal"
                useIdValue
                className={classes.input}
            />
        </form>
    );
};
