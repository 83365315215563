import React, { useCallback } from 'react';
import { TableSortLabel } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Order, SortingOrder } from 'shared/models/Order';
import { colors } from 'shared/styles/constants';
import { IStore } from 'store/configureStore';
import { ActionCreatorKnownArgs } from 'store/utils';
import { Sort } from '@material-ui/icons';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export interface ISortableTableHeadProps {
    children: React.ReactNode;
    setOrderAction: ActionCreatorKnownArgs<SortingOrder, { type: string; payload: SortingOrder }>;
    tableSortingSelector: (state: IStore) => SortingOrder;
    id: string;
}

const useStyles = makeHighPriorityStyles(theme => ({
    container: {
        '&:hover $icon': {
            opacity: 0,
        },
    },
    icon: {
        position: 'absolute',
        zIndex: 0,
        transition: 'opacity 0.2s ease',
        fontSize: theme.spacing(2),
        right: theme.spacing(0.5),
        color: colors.gray,
    },
}));

export const SortableTableHead = ({
    children,
    tableSortingSelector,
    setOrderAction,
    id,
}: ISortableTableHeadProps) => {
    const tableSorting = useSelector(tableSortingSelector);
    const direction = tableSorting[id];
    const isActiveOrdering = Boolean(direction);
    const classes = useStyles();

    const dispatch = useDispatch();
    const handleClick = useCallback(() => {
        dispatch(setOrderAction({ [id]: direction === Order.asc ? Order.desc : Order.asc }));
    }, [dispatch, id, direction, setOrderAction]);

    return (
        <TableSortLabel
            active={isActiveOrdering}
            direction={direction || Order.asc}
            onClick={handleClick}
            className={classes.container}
        >
            {children}
            {!isActiveOrdering && (
                <Sort className={classes.icon} />
            )}
        </TableSortLabel>
    );
};
