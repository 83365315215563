import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import MainContent from 'shared/components/common/MainContent';
import { loadClientRoles, loadGlobalRoles } from 'store/entities/users/actions';
import { getClientUsersAssociation } from 'modules/settings/submodules/platformUsers/store/actions';
import CreateUserClientAssociation from 'modules/settings/submodules/platformUsers/components/CreateUserClientAssociation/CreateUserClientAssociation';
import { UserClientAssociationTable } from 'modules/settings/submodules/platformUsers/components/ClientUserAssociationTable/UserClientAssociationTable';
import { FeatureSwitches } from 'utils/featureSwitches';
import { useFeature } from '@optimizely/react-sdk';
import ClientUserAssociationFilter from 'modules/settings/submodules/platformUsers/components/ClientUserAssociationFilter/ClientUserAssociationFilter';
import ClientUserAssociationFilterChips from 'modules/settings/submodules/platformUsers/components/ClientUserAssociationFilterChips/ClientUserAssociationFilterChips';
import {
    CreateUserClientAssociationButton,
} from 'modules/settings/submodules/platformUsers/components/CreateUserClientAssociation/CreateUserClientAssociationButton';
import {
    ClientUserAssociationFilterInline,
} from 'modules/settings/submodules/platformUsers/components/ClientUserAssociationFilter/ClientUserAssociationFilterInline';

export const UserRolesPage = () => {
    const dispatch = useDispatch();
    const [enableClientRoleUserAssociationV2] = useFeature(FeatureSwitches.enableClientRoleUserAssociationV2);

    useEffect(() => {
        dispatch(loadClientRoles.init());
        dispatch(loadGlobalRoles.init());
        dispatch(getClientUsersAssociation.init());
    }, [dispatch]);

    return (
        <MainContent whiteBackground>
            {enableClientRoleUserAssociationV2 ? (
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                >
                    <CreateUserClientAssociationButton />
                    <ClientUserAssociationFilterInline />
                </Box>
            ) : (
                <>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        mb={2}
                    >
                        <CreateUserClientAssociation />
                        <ClientUserAssociationFilter />
                    </Box>
                    <ClientUserAssociationFilterChips />
                </>
            )}
            <UserClientAssociationTable />
        </MainContent>
    );
};
