import { useFeature } from '@optimizely/react-sdk';
import { setEditLogoClientId } from 'modules/settings/submodules/clients/components/EditClientLogo/store/actions';
import { IDashboardMenuItem } from 'modules/settings/submodules/components/DashboardLink/model';
import { ISideMenuItem } from 'modules/settings/submodules/components/SideMenu/model';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import {
    selectCurrentClientId,
} from 'store/entities/clients/selectors/clientsSelectors';
import { selectCurrentClientHasJobNumberConfiguration } from 'store/entities/clients/selectors/timeAndPaySelectors';
import { settingsRoutes } from '../../../routes';
import { FeatureSwitches } from 'utils/featureSwitches';
import { useClientAssignmentSetupSubModules } from 'modules/settings/submodules/clients/clientAssignmentSetup/useClientAssignmentSetupSubModules';

export interface IClientMenuItem extends ISideMenuItem {
    description?: string;
}

export const useClientSubModulesDashboardMenu = (): IDashboardMenuItem[] => {
    const clientId = useSelector(selectCurrentClientId);
    const assignmentSetupSubmodules = useClientAssignmentSetupSubModules();
    const dispatch = useDispatch();
    const openEditLogo = useCallback(() => {
        dispatch(setEditLogoClientId(clientId));
    }, [dispatch, clientId]);
    const hasGenworthSpecificModules = useSelector(selectCurrentClientHasJobNumberConfiguration);
    const [enableCreditCardPortalLink] = useFeature(FeatureSwitches.enableCreditCardPortalLink);
    const [enableGwEcubReport] = useFeature(FeatureSwitches.enableGwEcubReport);

    return clientId ? [
        {
            title: 'General Settings',
            subheader: true,
        },
        {
            title: 'General',
            description: 'General account settings',
            link: generatePath(settingsRoutes.CLIENTS.GENERAL, { clientId }),
        },
        {
            title: 'Application configuration',
            description: 'Manage basic application configuration',
            link: generatePath(settingsRoutes.CLIENTS.APP_CONFIGURATION.ROOT, { clientId }),
            children: enableCreditCardPortalLink ? [
                {
                    title: 'Links',
                    link: generatePath(settingsRoutes.CLIENTS.APP_CONFIGURATION.LINKS, { clientId }),
                    feature: FeatureSwitches.enableCreditCardPortalLink,
                },
            ] : undefined,
        },
        {
            title: 'Logo',
            description: 'Edit client logo',
            onClick: openEditLogo,
        },
        {
            title: 'Employment',
            subheader: true,
        },
        {
            title: 'Assignment Setup',
            description: 'Manage fields hierarchy, locations, positions, departments',
            link: generatePath(settingsRoutes.CLIENTS.ASSIGNMENT_SETUP.ROOT, { clientId }),
            children: assignmentSetupSubmodules,
        },
        {
            title: 'Activities',
            description: 'List of activities',
            link: generatePath(settingsRoutes.CLIENTS.ACTIVITIES, { clientId }),
        },
        ...(hasGenworthSpecificModules ? [
            {
                title: 'Controlling Org',
                description: 'Create and edit controlling orgs',
                link: generatePath(settingsRoutes.CLIENTS.CONTROLLING_ORG, { clientId }),
            },
            {
                title: 'Submitting Org',
                description: 'Create and edit Submitting orgs',
                link: generatePath(settingsRoutes.CLIENTS.SUBMITTING_ORG, { clientId }),
            },
            {
                title: 'Cost Centers',
                description: 'Create and edit cost centers',
                link: generatePath(settingsRoutes.CLIENTS.COST_CENTERS, { clientId }),
            },
            {
                title: 'Deal type',
                description: 'Manage deal types',
                link: generatePath(settingsRoutes.CLIENTS.DEAL_TYPE, { clientId }),
            },
            {
                title: 'Deals',
                description: 'Create and edit cost deals',
                link: generatePath(settingsRoutes.CLIENTS.DEALS, { clientId }),
            },
            {
                title: 'Job number',
                description: 'Create and edit job numbers',
                link: generatePath(settingsRoutes.CLIENTS.JOB_NUMBER, { clientId }),
            },
            {
                title: 'Assignment Metadata',
                description: 'Manage assignment uw numbers and SSO_ID',
                link: generatePath(settingsRoutes.CLIENTS.ASSIGNMENTS_REPORT_DATA, { clientId }),
            },
        ] : []),
        {
            title: 'Payment',
            subheader: true,
        },
        {
            title: 'Pay Codes',
            description: 'List of pay codes',
            link: generatePath(settingsRoutes.CLIENTS.PAYCODES, { clientId }),
        },
        ...(hasGenworthSpecificModules ? [
            {
                title: 'Pay Codes AR_Bucket Association',
                description: 'Manage association between pay codes, deal types and ar_buckets',
                link: generatePath(settingsRoutes.CLIENTS.PAYCODE_DEAL_TYPE_AR_BUCKET, { clientId }),
                feature: FeatureSwitches.enableArBucketSettingsModule,
            },
        ] : []),
        {
            title: 'Pay Settings',
            description: 'Manage time and pay day settings',
            link: generatePath(settingsRoutes.CLIENTS.PAY_SETTINGS, { clientId }),
        },
        {
            title: 'Offer Letter Templates',
            subheader: true,
        },
        {
            title: 'Physical Demand Templates',
            description: 'Manage and add templates',
            link: generatePath(settingsRoutes.CLIENTS.PHYSICAL_DEMANDS, { clientId }),
        },
        {
            title: 'Working Conditions Templates',
            description: 'Manage and add templates',
            link: generatePath(settingsRoutes.CLIENTS.WORKING_CONDITIONS, { clientId }),
        },
        {
            title: 'Background Checks Templates',
            description: 'Manage and add templates',
            link: generatePath(settingsRoutes.CLIENTS.BACKGROUND_CHECKS, { clientId }),
        },
        {
            title: 'Reports',
            subheaderForChildren: true,
            feature: FeatureSwitches.enableZohoAnalyticsReportsEmbedding,
            link: generatePath(settingsRoutes.CLIENTS.REPORTS.ROOT, { clientId }),
            children: [
                {
                    title: 'Zoho Analytics',
                    link: generatePath(settingsRoutes.CLIENTS.REPORTS.ZOHO, { clientId }),
                    description: 'Add permalink to embed a Zoho Analytics dashboard',
                },
                {
                    title: 'Latest Pay Rates',
                    link: generatePath(settingsRoutes.CLIENTS.REPORTS.LATEST_PAY_RATES, { clientId }),
                },
                ...((hasGenworthSpecificModules && enableGwEcubReport) ? [
                    {
                        title: 'eCub',
                        link: generatePath(settingsRoutes.CLIENTS.REPORTS.ECUB, { clientId }),
                    },
                ] : []),
            ],
        },
    ] as IDashboardMenuItem[] : [];
};

export const useClientSubModulesMenu = (): IClientMenuItem[] => {
    const items = useClientSubModulesDashboardMenu();

    return items.filter(item => item.link && !item.subheader) as IClientMenuItem[];
};
