/* eslint-disable */
import DayRangePickerField from 'shared/components/formFields/DayRangePickerField/DayRangePickerField';
import { Nullable } from 'types/types';
import React, { useCallback, useRef, useState } from 'react';
import { Formik } from 'formik';
import moment from 'moment';
import {
    Box, Button, ClickAwayListener, Popper,
} from '@material-ui/core';
import { UserAutocompleteWithSearch } from 'shared/components/autocomplete/UserAutocompleteWithSearch/UserAutocompleteWithSearch';
import FilterSVG from 'shared/components/icons/FilterSVG';
import { useOfferLetterHeaderStyles } from 'modules/offerLetter/components/OfferLetterHeader/OfferLetterHeaderStyles';
import { CustomFieldValuesSelect } from 'shared/components/selects/CustomFieldValuesSelect/CustomFieldValuesSelect';
import FormSelect from 'shared/components/selects/FormSelect';
import { shortDateFormat } from 'shared/models/Dates';
import { useModal } from 'shared/utils/hooks/useModal';
import { useDispatch, useSelector } from 'react-redux';
import { IMenuItemType } from 'shared/models/MenuItemType';
import { selectOfferLetterFormFieldsMapping, selectOfferLetterFilters, selectStartDates } from 'modules/offerLetter/store/selectors';
import { setOfferLetterFilters } from 'modules/offerLetter/store/actions';
import { IOfferLetterFilters } from 'modules/offerLetter/store/model';
import { selectOfferLetterHierarchy } from 'store/entities/customFields/selectors';
import { UserType } from 'store/entities/users/model';
import { useFeature } from "@optimizely/react-sdk";
import { FeatureSwitches } from "utils/featureSwitches";
import DayPickerField from '../../../../shared/components/formFields/DayPickerField';
import { useOfferLetterYearFilterValues } from '../../hooks/useOfferLetterYearFilterValues';

export const offerLetterFilterTestId = {
    openFilterButton: 'open-offer-letter-filter-button',
    applyFilterButton: 'apply-offer-letter-filter-button',
};

export default function OfferLetterFilters() {
    const dispatch = useDispatch();

    const actualFilter = useSelector(selectOfferLetterFilters);
    const startDates = useSelector(selectStartDates);
    // @ts-ignore
    const startDateOptions: Array<IMenuItemType<string>> = Object.values(startDates).map(startDate => ({
        // @ts-ignore
        text: moment(startDate).format(shortDateFormat),
        value: startDate,
    }));
    const yearOptions: Array<IMenuItemType<string>> = useOfferLetterYearFilterValues();

    const hierarchy = useSelector(selectOfferLetterHierarchy);
    const customFieldMapping = useSelector(selectOfferLetterFormFieldsMapping);

    const classes = useOfferLetterHeaderStyles();
    const { isModalOpened, onModalClose, onModalOpen } = useModal();
    const buttonRef = useRef(null);
    const [enableFilterOfferLetterByApprover] = useFeature(FeatureSwitches.enableFilterOfferLetterByApprover);
    const [enableOfferLetterStartDateRangeFilter] = useFeature(FeatureSwitches.enableOfferLetterStartDateRangeFilter);

    const applyFilter = useCallback((values: IOfferLetterFilters) => {
        dispatch(setOfferLetterFilters(values));
        onModalClose();
    }, [dispatch, onModalClose]);

    const [formRef, setFormRef] = useState<Nullable<HTMLFormElement>>(null);
    const handleClickAway = useCallback((event: React.MouseEvent<Document, MouseEvent>) => {
        /**
         * Hack: Click away listener watches on outer DOM element click, while selects use React portal to
         * render dropdowns - they are part of outer DOM element - so modal closes.
         * To fix this we check actual pointer position on screen and if it's really outside of modal -
         * we close it.
         */
        if (!formRef || !event) {
            return;
        }
        const { left, top, width, height } = formRef.getBoundingClientRect();
        const { clientX, clientY } = event;
        if (
            clientX < left
            || clientX > left + width
            || clientY < top
            || clientY > top + height
        ) {
            onModalClose();
        }
    }, [formRef, onModalClose]);

    return (
        <>
            <Button
                className={classes.filterButton}
                ref={buttonRef}
                onClick={onModalOpen}
                data-testid={offerLetterFilterTestId.openFilterButton}
            >
                <FilterSVG/>
            </Button>
            <Popper
                open={isModalOpened}
                anchorEl={buttonRef.current}
                placement="bottom-end"
            >
                <Formik
                    initialValues={actualFilter}
                    onSubmit={applyFilter}
                    validateOnBlur={false}
                >
                    {props => (
                        <ClickAwayListener onClickAway={handleClickAway}>
                            <form ref={setFormRef} onSubmit={props.handleSubmit}>
                                <Box
                                    className={classes.filterRoot}
                                    display="flex"
                                    flexDirection="column"
                                >
                                    {hierarchy.map(node => (
                                        <CustomFieldValuesSelect
                                            key={node.custom_field_id}
                                            customFieldId={node.custom_field_id}
                                            name={customFieldMapping[node.custom_field_id]}
                                            useIdValue
                                            useLabel
                                        />
                                    ))}
                                    <FormSelect
                                        name="startDate"
                                        label="Start Date"
                                        useIdValue
                                        getKey={(option: IMenuItemType<string>) => option.value}
                                        getText={(option: IMenuItemType<string>) => option.text}
                                        options={startDateOptions}
                                    />
                                    <FormSelect
                                        name="created_since"
                                        label="Offer Date Year"
                                        useIdValue
                                        getKey={(option: IMenuItemType<string>) => option.value}
                                        getText={(option: IMenuItemType<string>) => option.text}
                                        options={yearOptions}
                                    />
                                    {enableOfferLetterStartDateRangeFilter && (
                                        <DayRangePickerField
                                            style={{
                                                maxWidth: "400px",
                                            }}
                                            startDateName="startDateGte"
                                            endDateName="startDateLte"
                                            outerLabel="Start Date Range"
                                        />
                                    )}
                                    <UserAutocompleteWithSearch
                                        additionalFilter={{
                                            user_type: UserType.Manager,
                                        }}
                                        name="creatorId"
                                        label="Created by"
                                        useIdValue
                                    />
                                    {enableFilterOfferLetterByApprover && (
                                        <UserAutocompleteWithSearch
                                            additionalFilter={{
                                                user_type: UserType.Manager,
                                            }}
                                            name="approverId"
                                            label="Approver"
                                            useIdValue
                                        />
                                    )}
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className={classes.filterApplyButton}
                                        type="submit"
                                        data-testid={offerLetterFilterTestId.applyFilterButton}
                                    >
                                        Apply
                                    </Button>
                                </Box>
                            </form>
                        </ClickAwayListener>
                    )}
                </Formik>
            </Popper>
        </>
    );
}
