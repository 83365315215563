import React from 'react';
import { Box } from '@material-ui/core';

import { FilterSection } from 'modules/payrollProcessorHub/components/PayrollSheetsTablePage/components/FilterSection';
import { MobilePayrollFooter } from 'modules/payrollProcessorHub/components/PayrollSheetsTablePage/MobilePayrollFooter';
import SheetSummary from 'modules/payrollProcessorHub/components/SheetSummary/SheetSummary';
import { SheetTabTitle } from 'modules/payrollProcessorHub/components/SheetTitle/SheetTabTitle';
import { PayrollSheetTable } from 'modules/payrollProcessorHub/components/PayrollSheetTable/PayrollSheetTable';
import { FeatureSwitches } from 'utils/featureSwitches';
import { useFeature } from '@optimizely/react-sdk';
import usePayrollSheetsTableStyles
    from 'modules/payrollProcessorHub/components/PayrollSheetsTablePage/styles/usePayrollSheetsTableStyles';

export const PayrollOverdueSheetsTablePage = () => {
    const classes = usePayrollSheetsTableStyles();
    const [enableVirtualizationForPphTable] = useFeature(FeatureSwitches.enableVirtualizationForPphTable);

    return (
        <>
            <Box p={enableVirtualizationForPphTable ? 2 : 0}>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    mb={2}
                >
                    <SheetTabTitle />
                    <SheetSummary />
                </Box>

                <FilterSection mb={2} />
            </Box>

            <Box
                className={enableVirtualizationForPphTable ? classes.flexTableWrapper : undefined}
            >
                <PayrollSheetTable />
            </Box>

            <MobilePayrollFooter />
        </>
    );
};
