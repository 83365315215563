import React from 'react';
import { Box, Fab } from '@material-ui/core';
import { useOfferLetterStyles } from 'modules/offerLetter/OfferLetterStyles';
import { useDispatch, useSelector } from 'react-redux';
import LookupMobile from 'modules/offerLetter/components/lookup/LookupMobile';
import { useIsMobile } from 'shared/utils/hooks/media';
import OfferLetterHeader from 'modules/offerLetter/components/OfferLetterHeader/OfferLetterHeader';
import OfferLetterInfinityTable from 'modules/offerLetter/components/OfferLetterTable/OfferLetterInfinityTable';
import AddIcon from '@material-ui/icons/Add';
import MainContent from 'shared/components/common/MainContent';
import { CreateOfferListener } from 'modules/offerLetter/components/CreateOffer/CreateOfferListener';
import { setLookupSidebarOpen } from 'modules/offerLetter/store/actions';
import { offerLettersTableStateSelectors } from 'modules/offerLetter/store/selectors';

interface IOfferLetterCommon {
    children?: React.ReactNode;
}

export default function OfferLetters({ children }: IOfferLetterCommon) {
    const classes = useOfferLetterStyles();
    const isMobile = useIsMobile();
    const dispatch = useDispatch();
    const total = useSelector(offerLettersTableStateSelectors.selectTotalItems);

    const openLookup = () => dispatch(setLookupSidebarOpen(true));

    return (
        <MainContent>
            <Box className={classes.wrapper}>
                <OfferLetterHeader amount={total} />
                <OfferLetterInfinityTable />
                {isMobile && (
                    <>
                        <Fab
                            color="primary"
                            aria-label="add"
                            onClick={openLookup}
                            className={classes.mobileLookupButton}
                        >
                            <AddIcon />
                        </Fab>
                        <LookupMobile />
                        <CreateOfferListener />
                    </>
                )}
                {children}
            </Box>
        </MainContent>
    );
}
