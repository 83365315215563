import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { useStyles } from 'modules/offerLetter/components/RejectOfferLetter/styles';
import { updateOfferLetterStatus } from 'modules/offerLetter/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import FormSelect from 'shared/components/selects/FormSelect';
import { OfferLetterStatusSlug, rejectReasons } from 'modules/offerLetter/store/model';
import { ValidationMessages } from 'shared/models/Validation';
import { useIsMobile } from 'shared/utils/hooks/media';
import { selectRejectOfferIsLoading } from 'modules/offerLetter/components/RejectOfferLetter/store/selectors';

export interface IRejectOfferLetterModalProps {
    offerLetterId: string;
    open: boolean;
    onClose: () => void;
}

export interface IRejectOfferLetterFormValues {
    reason: string;
}

export const rejectOfferLetterValidationSchema = yup.object().shape({
    reason: yup.string().required(ValidationMessages.REQUIRED),
});

export const RejectOfferLetterModal = ({ offerLetterId, open, onClose }: IRejectOfferLetterModalProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const isLoading = useSelector(selectRejectOfferIsLoading);

    const onReject = (values: IRejectOfferLetterFormValues) => {
        dispatch(updateOfferLetterStatus.init({
            id: offerLetterId,
            status: OfferLetterStatusSlug.Declined,
            rejectionReason: values.reason,
        }));
    };

    const defaultRejectReasonValue = rejectReasons[0].value;

    return (
        <ModalDialog
            title="2. Reason for rejecting this offer letter"
            isOpened={open}
            onClose={onClose}
            modalProps={{
                showCloseIcon: true,
                customClasses: {
                    title: classes.modalTitle,
                    paper: classes.modalPaper,
                },
            }}
            sidebarProps={{
                titleClasses: {
                    root: classes.sidebarTitle,
                },
                anchor: 'bottom',
            }}
        >
            <Box p={4}>
                <Formik
                    initialValues={{
                        reason: defaultRejectReasonValue,
                    }}
                    validationSchema={rejectOfferLetterValidationSchema}
                    onSubmit={onReject}
                    validateOnBlur={false}
                >
                    {formProps => (
                        <form onSubmit={formProps.handleSubmit} className={classes.form}>
                            <FormSelect
                                name="reason"
                                className={classes.input}
                                getKey={reason => reason.value}
                                getText={reason => reason.label}
                                options={rejectReasons}
                                useIdValue
                            />
                            <Box mt={14} mb={1}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    className={classes.button}
                                    disabled={isLoading || !formProps.isValid}
                                >
                                    Reject
                                    {isLoading && (
                                        <Box ml={1} mb={-0.5}>
                                            <CircularProgress color="inherit" size={16} />
                                        </Box>
                                    )}
                                </Button>
                                {!isMobile && (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.button}
                                        onClick={onClose}
                                        disabled={isLoading}
                                    >
                                        Go back
                                    </Button>
                                )}
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>
        </ModalDialog>
    );
};
