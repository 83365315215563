/* eslint-disable react/display-name */
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Radio, Box, Typography } from '@material-ui/core';
import PlainText from 'shared/components/table/Cells/PlainText';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import GridTable from 'shared/components/table/GridTable/GridTable';
import { IUserInfo } from 'shared/models/User';
import { getUserName } from 'shared/utils/converters/user';
import {
    selectIsSearchingJobNumbersUsers,
    selectJobNumberUserSearchResult,
} from 'modules/settings/submodules/clients/jobNumber/components/AssignUser/store/selectors';

interface IUserResultSearchRow extends IUserInfo {
    className?: string;
}

interface IUserSearchResultsProps {
    selectedUserId: string | null;
    onSelectUser: (id: string) => void;
}

export const UserSearchResults = ({
    selectedUserId,
    onSelectUser,
}: IUserSearchResultsProps) => {
    const tableClasses = useDefaultTableStyles();
    const searchResult = useSelector(selectJobNumberUserSearchResult);
    const isSearching = useSelector(selectIsSearchingJobNumbersUsers);

    const cells = useMemo(() => [
        {
            key: 'action',
            width: '54px',
            render: ({ className, id }: IUserResultSearchRow) => (
                <div className={clsx(className, tableClasses.iconCell, tableClasses.checkbox)}>
                    <Radio
                        checked={selectedUserId === id}
                        onChange={() => onSelectUser(id)}
                    />
                </div>
            ),
        },
        {
            key: 'employee',
            title: 'employee',
            width: '1fr',
            render: ({ className, ...employee }: IUserResultSearchRow) => (
                <PlainText className={className} value={getUserName(employee)} />
            ),
        },
        {
            key: 'username',
            title: 'username',
            width: '2fr',
            render: ({ className, username }: IUserResultSearchRow) => (
                <PlainText className={className} value={username} />
            ),
        },
    ], [onSelectUser, selectedUserId, tableClasses]);

    return (
        <Box width="100%">
            {(isSearching || searchResult.length > 0) ? (
                <GridTable
                    isLoading={isSearching}
                    rowData={searchResult}
                    headerCellClassName={tableClasses.headCell}
                    bodyCellClassName={tableClasses.bodyCell}
                    className={tableClasses.tableBorder}
                    getKey={(row: IUserResultSearchRow) => row.id}
                    cells={cells}
                />
            ) : (
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    mt={2}
                >
                    <Typography variant="caption">No results</Typography>
                </Box>
            )}
        </Box>
    );
};
