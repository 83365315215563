import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import {
    selectEditStep,
    selectIsOpenModal,
} from 'modules/subassignmentManagement/components/EditSubAssignment/store/selectors';
import {
    setOpenBulkEditSubassignmentModal,
} from 'modules/subassignmentManagement/components/EditSubAssignment/store/actions';
import { BulkEditAssignmentFormV2 } from 'modules/subassignmentManagement/components/EditSubAssignment/EditSubAssignmentForm/BulkEditAssignmentFormV2';
import { BulkEditSubassignmentSteps } from 'modules/subassignmentManagement/components/EditSubAssignment/store/model';
import { useEditBulkSubassignmentModalTitle } from 'modules/subassignmentManagement/components/EditSubAssignment/hooks';
import { BulkEditPostInitializeV2Report } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/BulkReportV2/BulkEditPostInitializeV2Report';
import { BulkEditPreInitializeV2Report } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/BulkReportV2/BulkEditPreInitializeV2Report';

export const BulkEditAssignmentModal = () => {
    const isOpened = useSelector(selectIsOpenModal);
    const step = useSelector(selectEditStep);
    const dispatch = useDispatch();

    const onClose = useCallback(() => {
        dispatch(setOpenBulkEditSubassignmentModal(false));
    }, [dispatch]);

    const { title, setAdditionalSteps } = useEditBulkSubassignmentModalTitle(isOpened);

    return (
        <ModalDialog
            title={title}
            isOpened={isOpened}
            onClose={onClose}
            colored
            modalProps={{
                showCloseIcon: true,
            }}
        >
            <>
                {step === BulkEditSubassignmentSteps.Form && (
                    <BulkEditAssignmentFormV2 setAdditionalSteps={setAdditionalSteps} />
                )}
                {step === BulkEditSubassignmentSteps.EditVerify && <BulkEditPreInitializeV2Report />}
                {step === BulkEditSubassignmentSteps.EditPostReport && (
                    <BulkEditPostInitializeV2Report onClose={onClose} />
                )}
            </>
        </ModalDialog>
    );
};
