import { IClientPaySettings } from 'store/entities/clients/clientsModel';
import { createRequestActions, RequestType } from 'store/utils';
import { IUpsertClientPaySettingsRequest } from './models';

export const upsertClientPaySettings = createRequestActions<{
    clientId: string,
    data: IUpsertClientPaySettingsRequest}, IClientPaySettings>(
    RequestType.Upsert,
    'ClientPaySettings',
    'settings/',
);
