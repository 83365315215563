import { useIsEmployeeSheetGroupingEnabled } from 'modules/timeAndExpense/store/hooks';
import React, { useState } from 'react';
import { IAvailableActionsProps } from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/sheetsData';
import { Box } from '@material-ui/core';
import { IEntity } from 'shared/models/Entity';
import { EntryType } from 'shared/models/sheet/Sheet';
import { useSheetsSubmittedViewStyles } from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/SheetsSubmittedView/SheetsSubmittedPageStyles';
import { useSubmittedTableStyles } from 'shared/components/sheetsSubmitted/sheetsSubmittedStyles';
import GridTable from 'shared/components/table/GridTable/GridTable';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import EntryTypeSelect from 'shared/components/selects/EntryTypeSelect';
import MobileFiltersAndAmount from 'shared/components/sheetsSubmitted/filterAndActionControls/MobileFiltersAndAmount';
import FiltersChips
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/FiltersChips';
import { setEmployeeSubmittedSheetsFilters } from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsActions';
import { selectEmployeeSubmittedSheetsFilters } from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsSelectors';

interface ISheetsSubmittedMobileViewProps<Row> {
    timeCells: Array<ICellInfo<Row>>;
    expenseCells: Array<ICellInfo<Row>>;
    sheetsEntryTypes?: Record<string, EntryType>;
    timeRows: Array<Row>;
    expenseRows: Array<Row>;
    isLoading: boolean;
    showFilters?: boolean;
    availableActions?: (props: IAvailableActionsProps) => JSX.Element;
}

export default function SheetsSubmittedMobileView<Row extends IEntity>({
    timeCells, expenseCells, timeRows, expenseRows, sheetsEntryTypes,
    isLoading, showFilters, availableActions: AvailableActions,
}: ISheetsSubmittedMobileViewProps<Row>) {
    const [entryType, setEntryType] = useState(EntryType.TIME);
    const classes = useSheetsSubmittedViewStyles();
    const tableClasses = useSubmittedTableStyles();
    const enableGrouping = useIsEmployeeSheetGroupingEnabled();

    const sheetsRows = entryType === EntryType.TIME ? timeRows : expenseRows;
    const sheets = enableGrouping
        // @ts-ignore
        ? sheetsRows.map(({ group }) => group.sheets).flat()
        // @ts-ignore
        : sheetsRows.map(({ sheet }) => sheet);

    return (
        <>
            <Box className={classes.mainContainer}>
                {(showFilters ?? true) && (
                    <FiltersChips
                        // @ts-ignore
                        action={setEmployeeSubmittedSheetsFilters}
                        className={classes.filtersWrapper}
                        selector={selectEmployeeSubmittedSheetsFilters}
                    />
                )}
                {AvailableActions && sheetsEntryTypes && Object.keys(sheetsEntryTypes).length !== 0 ? (
                    <AvailableActions typedSheets={sheetsEntryTypes} variant="contained" />
                ) : (
                    <EntryTypeSelect className={classes.entryTypeSwitcher}
                        value={entryType} onChange={setEntryType}
                    />
                )}

                <Box className={tableClasses.tableMain}>
                    <GridTable rowData={entryType === EntryType.TIME ? timeRows : expenseRows}
                        headerCellClassName={tableClasses.headCell}
                        bodyCellClassName={tableClasses.bodyCell}
                        getKey={row => row.id}
                        cells={entryType === EntryType.TIME ? timeCells : expenseCells}
                        isLoading={isLoading}
                        hideHeader={true}
                    />
                </Box>
                <MobileFiltersAndAmount sheets={sheets} sheetType={entryType} />
            </Box>
        </>
    );
}
