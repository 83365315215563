import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditAndResubmitButton from 'modules/clients/content/TimeAndExpensePage/RejectedSheets/EditAndResubmitButton';
import { SheetGroupDetailSidebar } from 'modules/sheetApproval/components/SheetGroupDetailSidebar/SheetGroupDetailSidebar';
import { selectEmployeeSidebarSheetGroup } from 'modules/timeAndExpense/components/EmployeeSheetDetail/selectors';
import { useSheetGroupDetail, useSheetGroupDetailAdditionalData } from 'modules/sheetApproval/components/SheetGroupDetailSidebar/hooks';
import { setSheetGroupedSidebarId } from 'modules/sheetApproval/store/actions';
import RecallButton from 'shared/components/sheetsSubmitted/filterAndActionControls/RecallButton';
import { EntryType } from 'shared/models/sheet/Sheet';
import { AvailableDetailConfiguration } from 'store/entities/clients/clientsModel';
import { selectDetailConfiguration } from 'store/entities/clients/selectors/fieldSelectors';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { useDisallowPastDayEntry } from '../../../../store/entities/clients/hooks';

export const EmployeeSheetGroupDetailSidebar = () => {
    const dispatch = useDispatch();

    const group = useSelector(selectEmployeeSidebarSheetGroup);

    const onClose = useCallback(() => dispatch(setSheetGroupedSidebarId(null)), [dispatch]);
    const configuration = useSelector(selectDetailConfiguration(
        group?.type === EntryType.TIME
            ? AvailableDetailConfiguration.EmployeeTimeSheet : AvailableDetailConfiguration.EmployeeExpenseSheet,
    )).info;
    const disallowPastDayEntry = useDisallowPastDayEntry();
    useSheetGroupDetailAdditionalData(group);
    // @ts-ignore
    const detailInfo = useSheetGroupDetail(group, group?.type, configuration, true);

    const availableActions = useMemo(() => {
        if (group && group.status.name === StatusNames.SUBMITTED && !disallowPastDayEntry) {
            const sheetTypes = group.sheets.reduce((mem, sheet) => {
                return {
                    ...mem,
                    [sheet.id]: sheet.entry_type,
                };
            }, {});
            return (
                <RecallButton sheetsEntryTypes={sheetTypes} onAction={onClose} />
            );
        }
        if (group && group.status.name === StatusNames.REJECTED && !disallowPastDayEntry) {
            const sheetIds = group.sheets.map(sheet => sheet.id);
            return (
                <EditAndResubmitButton
                    sheetIds={sheetIds}
                    entryType={group.type}
                    onClose={onClose}
                />
            );
        }
        return null;
    }, [group, onClose, disallowPastDayEntry]);

    if (group?.status?.name === StatusNames.RECALLED && !disallowPastDayEntry) {
        return null;
    }

    return (
        <SheetGroupDetailSidebar
            group={group}
            detailInfo={detailInfo}
            // @ts-ignore
            sheetType={group?.type}
            availableActions={availableActions}
            onClose={onClose}
        />
    );
};
