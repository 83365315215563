import React, { useEffect, useState } from 'react';
import { Badge, Hidden, IconButton } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationSidebar } from 'modules/notificationCenter/components/notificationsSidebar/NotificationSidebar';
import { getNotifications } from 'modules/notificationCenter/store/actions';
import { selectHasNewNotifications } from 'modules/notificationCenter/store/selectors';
import { useStyles } from './styles';

export const NotificationCenter = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const noUnreadNotifications = !useSelector(selectHasNewNotifications);

    useEffect(() => {
        dispatch(getNotifications.init());

        const pollingTimeout = 200_000;
        const polling = setInterval(() => {
            dispatch(getNotifications.init());
        }, pollingTimeout);

        return () => {
            clearInterval(polling);
        };
    }, [dispatch]);

    const openNotificationSidebar = () => {
        setSidebarOpen(true);
    };

    const handleUserMenuClose = () => {
        setSidebarOpen(false);
    };

    return (
        <>
            <Hidden smDown>
                <Badge
                    color="error"
                    overlap="circle"
                    badgeContent=" "
                    invisible={noUnreadNotifications}
                    classes={{
                        badge: classes.notificationBadge,
                    }}
                >
                    <IconButton
                        color="inherit"
                        aria-haspopup="true"
                        onClick={openNotificationSidebar}
                        className={classes.notificationIcon}
                    >
                        <NotificationsIcon />
                    </IconButton>
                </Badge>
            </Hidden>
            <NotificationSidebar
                isOpen={sidebarOpen}
                onClose={handleUserMenuClose}
            />
        </>
    );
};
