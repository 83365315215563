import { Status } from 'shared/models/Status';
import { CustomFieldType, CustomFieldValueData } from 'store/entities/customFields/model';

import { OverrideWorkGeoCode } from 'modules/settings/submodules/clients/store/models';

export interface IBaseLocationValues {
    name: string;
    address: string;
    address2: string;
    city: string;
    state_code: string;
    county: string;
    zip_code: string;
    override_work_geo_code: OverrideWorkGeoCode;
    geo_code: string | undefined;
}

export interface IClientWorkLocationFormValues extends IBaseLocationValues {
    client_id: string;
    client_name: string;
    location_id: string;
}

export const defaultBaseLocationValues: IBaseLocationValues = {
    name: '',
    address: '',
    address2: '',
    city: '',
    state_code: '',
    county: '',
    zip_code: '',
    override_work_geo_code: OverrideWorkGeoCode.OverrideWithResidentGeoCode,
    geo_code: undefined,
};

export const defaultClientWorkLocationValues: IClientWorkLocationFormValues = {
    client_id: '',
    client_name: '',
    location_id: '',
    ...defaultBaseLocationValues,
};

export interface ICustomFieldValueFormValues {
    assignmentSiblings: Record<string, string[]>;
    assignmentSiblingsAllFields: Record<string, boolean>;
    assignmentParentIds: string[];
    assignmentParentAll: boolean;
    assignmentChildren: Record<string, string[]>;
    assignmentChildrenAllFields: Record<string, boolean>;
    offerLetterSiblings: Record<string, string[]>;
    offerLetterSiblingsAllFields: Record<string, boolean>;
    offerLetterParentIds: string[];
    offerLetterParentAll: boolean;
    offerLetterChildren: Record<string, string[]>;
    offerLetterChildrenAllFields: Record<string, boolean>;
    data: CustomFieldValueData;
    status: Status;
}

export const useDefaultValueByType = (headway_connect_field: CustomFieldType): ICustomFieldValueFormValues => {
    const commonFields = {
        assignmentSiblings: {},
        assignmentSiblingsAllFields: {},
        assignmentChildren: {},
        assignmentChildrenAllFields: {},
        assignmentParentIds: [],
        assignmentParentAll: false,
        offerLetterSiblings: {},
        offerLetterSiblingsAllFields: {},
        offerLetterChildren: {},
        offerLetterChildrenAllFields: {},
        offerLetterParentIds: [],
        offerLetterParentAll: false,
        status: Status.active,
    };
    switch (headway_connect_field) {
        case CustomFieldType.Location:
            return {
                ...commonFields,
                data: {
                    headway_connect_field,
                    work_comp_state_code: '',
                    ...defaultBaseLocationValues,
                },
            };
        case CustomFieldType.NameCode:
            return {
                ...commonFields,
                data: {
                    headway_connect_field,
                    name: '',
                    code: '',
                },
            };
        case CustomFieldType.NameCodeDescription:
            return {
                ...commonFields,
                data: {
                    headway_connect_field,
                    name: '',
                    code: '',
                    description: '',
                },
            };
        case CustomFieldType.NameCodeDescriptionProjectClass:
            return {
                ...commonFields,
                data: {
                    headway_connect_field,
                    name: '',
                    code: '',
                    description: '',
                    project_class: null,
                },
            };
        case CustomFieldType.ShiftCode:
            return {
                ...commonFields,
                data: {
                    headway_connect_field,
                    name: '',
                    code: '',
                    description: '',
                    differential_method: null,
                },
            };
        case CustomFieldType.SCA: {
            return {
                ...commonFields,
                data: {
                    headway_connect_field,
                    name: '',
                    pay_rate: null,
                    health_and_welfare_rate: null,
                    counties: [],
                    positions: [],
                    state: '',
                    wage_determination_number: '',
                },
            };
        }
        case CustomFieldType.AvionteJobOrder: {
            return {
                ...commonFields,
                data: {
                    headway_connect_field,
                    name: '',
                    job_id: '',
                    company_id: '',
                    company_name: '',
                    title: '',
                    worksite_address: {
                        address1: '',
                        address2: '',
                        city: '',
                        state: '',
                        zip_code: '',
                        county: '',
                        geo_code: '',
                    },
                },
            };
        }
        case CustomFieldType.NameValue:
        default:
            return {
                ...commonFields,
                data: {
                    headway_connect_field,
                    name: '',
                    value: '',
                },
            };
    }
};
