import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { getPayrollErrorsCsv } from 'modules/payrollProcessorHub/store/actions';
import { selectPostPayrollReport } from 'modules/payrollProcessorHub/store/selectors';
import { withFeature } from 'shared/hoc/withFeature';
import { FeatureSwitches } from 'utils/featureSwitches';

export const DownloadPayrollErrorsButton = withFeature(
    FeatureSwitches.enablePayrollFailsCsvReport,
    () => {
        const dispatch = useDispatch();
        const handleClick = () => {
            dispatch(getPayrollErrorsCsv.init());
        };
        const report = useSelector(selectPostPayrollReport);
        const hasErrors = report.sheetsForPayroll.some(sheet => sheet.failedReason);
        return (
            <Button
                variant="outlined"
                color="secondary"
                onClick={handleClick}
                disabled={!hasErrors}
                startIcon={<GetApp />}
            >
                Download fails to new file
            </Button>
        );
    },
);
