import { pick } from 'lodash-es';
import { selectEditSheetIdsForSubmit } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/selectors';
import { setSheetGroupedDetailId } from 'modules/sheetApproval/store/actions';
import { useIsEmployeeSheetGroupingEnabled } from 'modules/timeAndExpense/store/hooks';
import React, { useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import { Box } from '@material-ui/core';
import EditSheetAfterSubmit from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/EditSheetAfterSubmit';
import { setHeaderContent } from 'modules/home/header/Header';
import useSheetsInProgressStyles from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/SheetsInProgressStyles';
import TimeEntryTabs from 'modules/clients/header/TimeEntryTabs';
import { useDispatch, useSelector } from 'react-redux';
import SubmitStatus from 'shared/components/submitStatus/SubmitStatus';
import { DATE_FORMAT } from 'shared/models/Dates';
import { EntryType } from 'shared/models/sheet/Sheet';
import { useStoredSelector } from 'shared/utils/hooks/useStoredSelector';
import { useStoredValue } from 'shared/utils/hooks/useStoredValue';
import { setResubmitSheet } from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsActions';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import {
    selectSheet,
} from 'store/entities/timesheet/selectors';
import { SheetGroup } from 'modules/timeAndExpense/store/model';

interface ISheetsRejectedViewProps {
    sheetIds: string[];
    entryType: EntryType;
    sheetGroup?: SheetGroup;
}

export default function SheetRejectedView({ sheetIds: propSheetIds, entryType, sheetGroup }: ISheetsRejectedViewProps) {
    const classes = useSheetsInProgressStyles();
    const dispatch = useDispatch();
    const enableGrouping = useIsEmployeeSheetGroupingEnabled();

    const sheet = useSelector(selectSheet(propSheetIds[0]));
    const editPayPeriod = useStoredValue(
        sheetGroup?.payPeriod || pick(sheet, ['period_start', 'period_end']),
    );
    const groupSheetIdsToSubmit = useStoredSelector(
        // @ts-ignore
        selectEditSheetIdsForSubmit(entryType, StatusNames.REJECTED, editPayPeriod),
    );
    const sheetIds = useMemo(() => {
        if (!enableGrouping) {
            return propSheetIds;
        }
        return groupSheetIdsToSubmit;
    }, [enableGrouping, propSheetIds, groupSheetIdsToSubmit]);

    const submitStatusDue = moment(sheet?.period_end).clone().add(1, 'day')
        .format(DATE_FORMAT.MM_DD_YYYY);

    useEffect(() => {
        setHeaderContent(
            <Box className={classes.headerContentWrapper}>
                <SubmitStatus
                    customClasses={classes.statusWrapper}
                    labelClass={classes.recalledSubmit}
                    dueDate={submitStatusDue}
                    status={StatusNames.REJECTED}
                />
                <TimeEntryTabs />
            </Box>,
        );

        return () => {
            setHeaderContent(null);
        };
    }, [submitStatusDue, classes]);

    const onClose = useCallback(() => {
        dispatch(setResubmitSheet(null));
        dispatch(setSheetGroupedDetailId(null));
    }, [dispatch]);

    return (
        <EditSheetAfterSubmit
            // @ts-ignore
            sheetIds={sheetIds}
            entryType={entryType}
            onClose={onClose}
            // @ts-ignore
            showAllEntriesForPeriod={enableGrouping ? editPayPeriod : undefined}
            statusName={StatusNames.REJECTED}
        />
    );
}
