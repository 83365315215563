import React from 'react';
import { HeadwayLoader } from 'shared/components/loader/HeadwayLoader';
import { useSelector } from 'react-redux';
import { selectIsLoadingClientCustomFields } from '../../store/selectors';
import { useCustomFieldValueModules } from './useCustomFieldValueModules';
import { SubmodulesDashboard } from 'modules/settings/common/components/SubmodulesDashboard';

export const ClientCustomFieldsListPage = () => {
    const isLoading = useSelector(selectIsLoadingClientCustomFields);
    const items = useCustomFieldValueModules();

    if (isLoading) {
        return (
            <HeadwayLoader />
        );
    }
    return (
        <SubmodulesDashboard items={items} />
    );
};
