import React, { useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import { OfferLetterOfferForm } from './OfferLetterOfferForm';
import { FormikProps } from 'formik';
import { IOfferLetterFormValues } from '../model';
import FormField from 'shared/components/formFields/FormField';
import { IOfferLetterSeletedEmployee } from '../OfferLetterMultiStepForm';
import { formatPhoneNumber } from 'shared/utils/formatters/phoneNumberFormatter';
import { MultiStepFormValues } from 'shared/components/forms/multiStep/MultiStepForm';
import clsx from 'clsx';
import PhoneField from '../../../../../shared/components/formSpecialFields/phoneField/PhoneField';

export const OFFER_LETTER_FORM_STEP_EMPLOYEE = 'OFFER_LETTER_FORM_STEP_EMPLOYEE';

interface IOfferLetterFormStepEmployee {
    employee: IOfferLetterSeletedEmployee; // TODO: it needs to be IEmployeeProfile
    canEditEmployee?: boolean;
}

export function OfferLetterFormStepEmployee({
    canEditEmployee,
    employee,
    ...formProps
}: FormikProps<MultiStepFormValues<IOfferLetterFormValues>> & IOfferLetterFormStepEmployee) {
    const classes = useStyles();
    const canEditPhone = useMemo(() => {
        return employee?.cell_phone ?? '' === '';
    }, [employee]);

    return (
        <Box className={classes.form}>
            <Box display="flex" mt={2}>
                <Box className={classes.employeeFieldBlock}>
                    <FormField
                        name="first_name"
                        label="First Name"
                        className={clsx(classes.field, classes.firstInputBlock)}
                        disabled={!canEditEmployee}
                    />
                    <FormField
                        name="last_name"
                        label="Last Name"
                        className={clsx(classes.field, classes.firstInputBlock)}
                        disabled={!canEditEmployee}
                    />
                    <PhoneField
                        name="phone"
                        label="Cell Phone Number"
                        className={classes.field}
                        disabled={!canEditPhone}
                    />
                </Box>
                <Box className={clsx(classes.employeeFieldBlock, classes.secondInputBlock)} mt={3}>
                    <Typography variant="body2"><b>Email:</b> {employee.email}</Typography>
                    {employee.cell_phone && (
                        <Typography variant="body2"><b>Cell Number:</b> {formatPhoneNumber(employee.cell_phone)}</Typography>
                    )}
                    <Typography variant="body2"><b>Avionte Talent ID:</b> {employee.avionte_talent_id}</Typography>
                    {employee.identity_id && (
                        <Typography variant="body2"><b>ID:</b> {employee.identity_id}</Typography>
                    )}
                    {employee.state && (
                        <Typography variant="body2"><b>State:</b> {employee.state}</Typography>
                    )}
                    {employee.zip_code && (
                        <Typography variant="body2"><b>Zip:</b> {employee.zip_code}</Typography>
                    )}
                </Box>
            </Box>
            <OfferLetterOfferForm {...formProps} />
        </Box>
    );
}
