/* eslint-disable react/display-name,react/prop-types */

import { LoginAsUserButton } from 'modules/settings/common/components/LoginAsUserButton';
import React, { useMemo } from 'react';
import clsx from 'clsx';
import { DeleteItemButton } from 'modules/settings/common/components/DeleteItemButton/DeleteItemButton';
import { removeClientUserAssociation } from 'modules/settings/submodules/platformUsers/store/actions';
import { IUserClientAssociationRow } from 'modules/users/managers/components/UserRolesTable/model';
import PlainText from 'shared/components/table/Cells/PlainText';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { getLastFirstName } from 'shared/utils/converters/user';
import { Permission } from 'store/components/auth/authModels';

enum CellTypes {
    Id,
    User,
    GlobalRole,
    Client,
    Role,
    Actions,
    Impersonate,
    Email,
}

const getCellsDictionary = (tableClasses: ReturnType<typeof useDefaultTableStyles>) => ({
    [CellTypes.Id]: {
        key: 'id',
        title: 'id',
        render: ({ className, id }: IUserClientAssociationRow) => (
            <PlainText className={className} value={id} />
        ),
    },
    [CellTypes.User]: {
        key: 'user',
        title: 'name',
        render: ({ className, user }: IUserClientAssociationRow) => (
            <PlainText className={className} value={getLastFirstName(user)} />
        ),
    },
    [CellTypes.Email]: {
        key: `${CellTypes.Email}`,
        title: 'email',
        render: ({ className, user }: IUserClientAssociationRow) => (
            <PlainText className={className} value={user?.email} />
        ),
    },
    [CellTypes.GlobalRole]: {
        key: 'global_role',
        title: 'Global Role',
        render: function RoleCell({ user, className }: IUserClientAssociationRow) {
            return (
                <PlainText className={className} value={`${user?.global_roles?.map(r => r.name).join(', ')}`} />
            );
        },
    },
    [CellTypes.Client]: {
        key: 'client',
        title: 'client',
        render: function Client({ className, client }: IUserClientAssociationRow) {
            return (
                <PlainText className={className} value={client?.name} />
            );
        },
    },
    [CellTypes.Role]: {
        key: 'role',
        title: 'role',
        render: ({ className, role }: IUserClientAssociationRow) => (
            <PlainText className={className} value={role?.name} />
        ),
    },
    [CellTypes.Actions]: {
        key: 'actions',
        title: '',
        width: '54px',
        render: ({ className, id }: IUserClientAssociationRow) => (
            <div className={clsx(className, tableClasses.iconCell)}>
                <DeleteItemButton
                    confirmation="Are you sure you want to delete client role from user?"
                    id={id}
                    deleteAction={removeClientUserAssociation.init}
                    permission={Permission.deleteClientSettingsEntities}
                />
            </div>
        ),
    },
    [CellTypes.Impersonate]: {
        key: 'actions',
        title: '',
        width: '54px',
        render: ({ className, user_id, client_id }: IUserClientAssociationRow) => (
            <div className={clsx(className, tableClasses.iconCell, tableClasses.detailsIcon)}>
                <LoginAsUserButton userId={user_id} clientId={client_id} />
            </div>
        ),
    },
});

export const useClientUsersAssociationCells = (): ICellInfo<IUserClientAssociationRow>[] => {
    const cellsDictionary = getCellsDictionary(useDefaultTableStyles());
    return useMemo(() => {
        return [
            cellsDictionary[CellTypes.Id],
            cellsDictionary[CellTypes.User],
            cellsDictionary[CellTypes.Email],
            cellsDictionary[CellTypes.GlobalRole],
            cellsDictionary[CellTypes.Client],
            cellsDictionary[CellTypes.Role],
            cellsDictionary[CellTypes.Actions],
        ];
    }, [cellsDictionary]);
};

export const useManagersCells = (): ICellInfo<IUserClientAssociationRow>[] => {
    const cellsDictionary = getCellsDictionary(useDefaultTableStyles());

    return [
        cellsDictionary[CellTypes.User],
        cellsDictionary[CellTypes.Email],
        cellsDictionary[CellTypes.Role],
        cellsDictionary[CellTypes.Impersonate],
    ];
};
