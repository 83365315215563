import React, { useCallback } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { useIsMediumDisplay } from 'shared/utils/hooks/media';
import { useSidebarState } from 'store/entities/appConfig/utils';
import { ChevronRight } from '@material-ui/icons';
import { Hidden } from '@material-ui/core';
import { useHeaderStyles } from 'shared/styles/header';

function HideSidebarButton() {
    const headerClasses = useHeaderStyles();
    const upMd = useIsMediumDisplay();
    const { isSidebarOpened, setSidebarOpened } = useSidebarState();

    const onSidebarOpen = useCallback(() => {
        setSidebarOpened(true);
    }, [setSidebarOpened]);

    return isSidebarOpened && upMd ? null : (
        <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={onSidebarOpen}
            className={headerClasses.hideSidebar}
        >
            <Hidden lgUp>
                <MenuIcon />
            </Hidden>
            <Hidden mdDown={true}>
                <ChevronRight />
            </Hidden>
        </IconButton>
    );
}

export default HideSidebarButton;
