import {
    tryAddPtoItem,
    tryAddPtoItemBasedMaxUseAvailable,
} from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/helpers/tryAddPtoItem';
import { IGroupedSheetCalculation } from 'modules/payrollProcessorHub/store/model';
import { ISheetInfoItemData } from 'shared/components/sidebars/SheetDetail/SheetInfoItem';
import { ILocation } from 'shared/models/Location';
import { EntryType } from 'shared/models/sheet/Sheet';
import { IPaidTimeOffResponse } from 'shared/models/User';
import { IClient } from 'store/entities/clients/clientsModel';
import { SheetStatusSlug } from 'store/entities/timesheet/models/Status';
import {
    resolveClient,
    resolveDealNumber,
    resolveDepartment,
    resolveExpenseSheetId,
    resolveJobNumber,
    resolveLocation,
    resolvePayPeriod,
    resolvePayType,
    resolvePosition,
    resolvePrismBatchNumber,
    resolveTimeSheetId,
} from '../helpers/detailItemValueResolvers';
import { onlyNotEmptyItems } from '../helpers/filters';
import { SheetInfoItemTitle } from '../model/SheetInfoItemTitle';
import { useCustomFieldsItems } from './useCustomFieldsItems';
import { useSheetGroupAdditionalData } from './useSheetGroupAdditionalData';
import { useFeature } from '@optimizely/react-sdk';
import { FeatureSwitches } from '../../../../../utils/featureSwitches';

/**
 * Resolves Prism batch item depending on entry type
 */
function getPrismBatchItem(type: EntryType, sheetGroup: IGroupedSheetCalculation) {
    return {
        title: SheetInfoItemTitle.PrismBatchId,
        value: resolvePrismBatchNumber(type, sheetGroup),
    };
}

/**
 * Retrieves items specific to Time Entry
 */
function getTimeEntryItems(client: IClient, sheetGroup: IGroupedSheetCalculation) {
    return [
        {
            title: SheetInfoItemTitle.Client,
            value: resolveClient(client),
        },
        (
            sheetGroup.time_status_slug === SheetStatusSlug.APPROVED_CLOSED
                ? getPrismBatchItem(EntryType.TIME, sheetGroup)
                : {
                    title: SheetInfoItemTitle.TimesheetId,
                    value: resolveTimeSheetId(sheetGroup),
                }
        ),
        {
            title: SheetInfoItemTitle.PayType,
            value: resolvePayType(sheetGroup),
        },
    ];
}

/**
 * Retrieves items specific to Expense Entry
 */
function getExpenseEntryItems(location: ILocation, sheetGroup: IGroupedSheetCalculation) {
    return [
        {
            title: SheetInfoItemTitle.Location,
            value: resolveLocation(location),
        },
        (
            sheetGroup.expense_status_slug === SheetStatusSlug.APPROVED_CLOSED
                ? getPrismBatchItem(EntryType.EXPENSE, sheetGroup)
                : {
                    title: SheetInfoItemTitle.ExpenseSheetId,
                    value: resolveExpenseSheetId(sheetGroup),
                }
        ),
    ];
}

/**
 * Resolves items depending on entry type
 */
const resolveItemsByEntryType = (
    type: EntryType,
    client: IClient,
    sheetGroup: IGroupedSheetCalculation,
    location: ILocation,
) => (
    type === EntryType.TIME
        ? getTimeEntryItems(client, sheetGroup)
        : getExpenseEntryItems(location, sheetGroup)
);

export function useSheetGroupItems(sheetGroup: IGroupedSheetCalculation, type: EntryType) {
    const { position, location, department, client, pto } = useSheetGroupAdditionalData(sheetGroup);
    const typeRelatedInfo = resolveItemsByEntryType(type, client, sheetGroup, location);
    const [usePtoMaxUseAvailable] = useFeature(FeatureSwitches.usePtoMaxUseAvailable);

    const sheetDetailsList: ISheetInfoItemData[] = [
        {
            title: SheetInfoItemTitle.PayPeriod,
            value: resolvePayPeriod(sheetGroup),
        },
        ...typeRelatedInfo,
        {
            title: SheetInfoItemTitle.DealNumber,
            value: resolveDealNumber(sheetGroup),
        },
        {
            title: SheetInfoItemTitle.JobNumber,
            value: resolveJobNumber(sheetGroup),
        },
        {
            title: SheetInfoItemTitle.Position,
            value: resolvePosition(sheetGroup, position),
        },
        {
            title: SheetInfoItemTitle.Department,
            value: resolveDepartment(department),
        },
    ].filter(onlyNotEmptyItems);

    const updatedSheetDetailsList = useCustomFieldsItems(
        type,
        sheetGroup,
        sheetDetailsList,
    );

    if (usePtoMaxUseAvailable) {
        tryAddPtoItemBasedMaxUseAvailable(
            pto as IPaidTimeOffResponse | null,
            type,
            updatedSheetDetailsList,
        );
    } else {
        tryAddPtoItem(
            pto as IPaidTimeOffResponse | null,
            type,
            updatedSheetDetailsList,
        );
    }

    return updatedSheetDetailsList;
}
