import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'ts-debounce';
import { setEmployeesFilter } from 'modules/users/employees/store/actions';
import { selectEmployeeFilter } from 'modules/users/employees/store/selectors';
import { TextFilter } from 'shared/components/filters/TextFilter';
import { textInputDebounceTimeout } from 'utils/constants';

export const EmployeeNameFilter = () => {
    const dispatch = useDispatch();
    const filter = useSelector(selectEmployeeFilter);
    const changeFilterName = useMemo(() =>
        debounce(
            (search: string) => {
                dispatch(setEmployeesFilter({
                    ...filter,
                    search,
                }));
            },
            textInputDebounceTimeout),
    [dispatch, filter],
    );
    return (
        <TextFilter onFilterValueChanged={changeFilterName} />
    );
};
