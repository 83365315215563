import { autoHideDefaultDuration, IModalSeverity } from 'shared/components/toasts/modal';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { getClientPaySettings } from 'store/entities/clients/clientsAction';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { call, put, takeLatest } from 'typed-redux-saga';
import { upsertClientPaySettings } from './actions';
import { api } from './api';

function* upsertClientPaySettingsSaga({ payload }: ReturnType<typeof upsertClientPaySettings.init>) {
    const { clientId, data } = payload;
    const clientPaySettings = yield* call(api.upsertClientPaySettings, clientId, data);
    yield put(setGlobalToast({
        severity: IModalSeverity.Success,
        title: 'Client pay settings successfully updated',
        autoHideDuration: autoHideDefaultDuration,
    }));
    yield put(upsertClientPaySettings.success(clientPaySettings));
    yield put(getClientPaySettings.success(clientPaySettings));
}

function* upsertClientPaySettingsSagaWatcher() {
    yield* takeLatest(
        upsertClientPaySettings.initType,
        withBackendErrorHandler(
            upsertClientPaySettingsSaga,
            upsertClientPaySettings.error,
            'Unable to update client pay settings',
        ),
    );
}

export const paySettingsSagas = [
    upsertClientPaySettingsSagaWatcher,
];
