import MomentUtils from '@date-io/moment';
import { StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { OptimizelyProvider } from '@optimizely/react-sdk';
import { PayrollSheetTabs } from 'modules/payrollProcessorHub/store/model';
import { UserProfileTab } from 'modules/profile/store/profileModel';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { TMenuState } from 'shared/models/Global';
import { MenuContext } from 'shared/models/menuContext';
import Analytics from 'shared/services/Analytics';
import Appcues from 'shared/services/Appcues/Appcues';
import { browserHistory } from 'shared/utils/browserHistory';
import 'shared/utils/scrollIntoViewIfNeeded';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import 'typeface-roboto';
import { optimizely } from 'utils/optimizely';
import App from './App';
import baseTheme from './baseTheme';
import './index.sass';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configureStore';

const store = configureStore();

if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.__store__ = store;
}

const Main: React.FC<{}> = () => {
    Analytics.listen();
    Appcues.init();

    const [menuState, setMenuState] = useState<TMenuState>({
        currentPage: '/dashboard',
        currentHeader: 'Dashboard',
        currentMobileHeader: 'Dashboard',
        tabs: {
            timeEntryPage: StatusNames.WORKING,
            payroll: PayrollSheetTabs.APPROVED,
            userProfile: UserProfileTab.Profile,
        },
    });
    return (
        <OptimizelyProvider
            optimizely={optimizely}
            user={{
                id: 'empty',
            }}
        >
            <Provider store={store}>
                <Router history={browserHistory}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <MenuContext.Provider
                            value={{
                                menuState,
                                setMenuState,
                            }}
                        >
                            <StylesProvider injectFirst>
                                <ThemeProvider theme={baseTheme}>
                                    <div className="App" data-testid="app">
                                        <App />
                                    </div>
                                </ThemeProvider>
                            </StylesProvider>
                        </MenuContext.Provider>
                    </MuiPickersUtilsProvider>
                </Router>
            </Provider>
        </OptimizelyProvider>
    );
};

ReactDOM.render(<Main />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
