import { useSelector } from 'react-redux';
import { INamedEntitySelectProps, NamedEntitySelect } from 'shared/components/selects/NamedEntitySelect';
import { IJobNumberUserType } from 'shared/models/JobNumber';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import {
    selectIsJobNumbersUserTypesLoading,
    selectJobNumbersUserTypesWithAvionteData,
} from 'store/entities/configuration/configurationSelectors';

interface IUserTypeSelectProps extends Omit<INamedEntitySelectProps<IJobNumberUserType>, 'options'> {}
interface IUserTypeSelectStoreProps {
    options: IJobNumberUserType[];
    isLoading: boolean;
}

export const UserTypeSelect = separateLogicDecorator<IUserTypeSelectProps, IUserTypeSelectStoreProps>(
    () => ({
        options: useSelector(selectJobNumbersUserTypesWithAvionteData),
        isLoading: useSelector(selectIsJobNumbersUserTypesLoading),
    }),
)(NamedEntitySelect);
