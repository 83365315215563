import {
    Box,
    Checkbox,
    CircularProgress,
    FormControl,
    FormHelperText,
    InputLabel,
    ListSubheader,
    MenuItem,
    MenuProps,
    Select,
    Tooltip,
} from '@material-ui/core';
import { useField } from 'formik';
import React, { ChangeEvent, Ref, useCallback, useEffect, useMemo } from 'react';
import { useFormHelperTextStyles } from 'shared/styles/formHelperText';
import { useIsMobile } from 'shared/utils/hooks/media';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { IFormFieldProps } from '../formFields/models';
import { IFormSelect } from './model';

export interface IOptionGroup<T> {
    isOptionGroup: true;
    label: string;
    options: Array<T>;
}

export type SelectOption<T> = T | IOptionGroup<T>

function LoadingIconComponent() {
    return (
        <Box position="absolute" right={8}>
            <CircularProgress size={24} />
        </Box>
    );
}

const useStyles = makeHighPriorityStyles({
    nonNativeInput: {
        display: 'flex',
        alignItems: 'center',
    },
});

const menuProps: Partial<MenuProps> = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
    getContentAnchorEl: null,
};

export interface IFormSelectProps<T> extends IFormFieldProps, IFormSelect {
    options: Array<SelectOption<T>>;
    getKey: (item: T) => string | number;
    getText: (item: T) => string;
    filter?: (item: T) => boolean;
    onChanged?: (item: T | null | '') => void;
    getOptionTitle?: (item: T) => string;
    onConfirmSelection?: () => void;
    inputRef?: Ref<HTMLDivElement>;
    isLoading?: boolean;
    native?: boolean;
    multiple?: boolean;
    inputLabel?: string;
    autoSelectSingleVariant?: boolean;
    showErrorText?: boolean;
}

export default function FormSelect<T>({
    name,
    label,
    inputLabel,
    outerLabel,
    options,
    disabled = false,
    className,
    getKey,
    getText,
    onChanged,
    filter,
    getOptionTitle,
    onConfirmSelection,
    useIdValue = false,
    isNullable = true,
    multiple = false,
    resetIfValueNotAvailable = false,
    title = '',
    id = name,
    isLoading = false,
    native: nativeProps = false,
    autoSelectSingleVariant = false,
    showErrorText = true,
}: IFormSelectProps<T>,
) {
    const formHelperTextClasses = useFormHelperTextStyles();
    const [field, meta, helpers] = useField(name);
    const hasError = Boolean(meta.error && meta.touched);
    const defaultValue = useMemo(() => multiple ? [] : isNullable ? null : '', [isNullable, multiple]);
    const selectedKeyValue = useMemo(() => {
        if (multiple) {
            return (Array.isArray(field.value) && field.value?.map(i => useIdValue ? i : getKey(i))) || [];
        }
        return (!useIdValue && field.value ? getKey(field.value) : field.value) || '';
    }, [field.value, getKey, multiple, useIdValue]);
    const isMobile = useIsMobile();
    const classes = useStyles();
    const native = nativeProps || isMobile;

    const filteredOptions = filter ? (options as T[]).filter(filter) : options;

    const flatOptions: Array<T> = useMemo(() => {
        return filteredOptions.reduce((mem: Array<T>, item): Array<T> => {
            // @ts-ignore
            if (item.isOptionGroup) {
                return mem.concat((item as IOptionGroup<T>).options);
            }
            mem.push(item as T);
            return mem;
        }, []);
    }, [filteredOptions]);

    const onChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
        const { value: changedValue = multiple ? [] : '' } = event.target;
        const newKeys = multiple ? changedValue : [changedValue];
        // @ts-ignore
        const objectValues = flatOptions.filter(option => newKeys.includes(getKey(option))) || defaultValue;
        const newValue = useIdValue ? changedValue || defaultValue : multiple ? objectValues : objectValues[0];
        helpers.setValue(newValue);
        helpers.setTouched(true);
        if (onChanged) {
            // @ts-ignore
            onChanged(multiple ? objectValues : objectValues[0] );
        }
    };

    useEffect(() => {
        if (multiple) {
            return;
        }
        const selected = flatOptions.find(option => getKey(option) === selectedKeyValue);
        if (resetIfValueNotAvailable && !selected && selectedKeyValue) {
            helpers.setValue(defaultValue);
        }
    }, [resetIfValueNotAvailable, defaultValue, useIdValue, getKey, flatOptions, helpers, selectedKeyValue, multiple]);

    const handleConfirmSelection = useCallback(() => {
        onConfirmSelection && onConfirmSelection();
    }, [onConfirmSelection]);

    const handleBlur = useCallback(() => {
        helpers.setTouched(false);
        handleConfirmSelection();
    }, [helpers, handleConfirmSelection]);

    useEffect(() => {
        if (autoSelectSingleVariant && !selectedKeyValue && flatOptions.length === 1) {
            const newValueItem = flatOptions[0];
            const newFieldValue = useIdValue ? getKey(newValueItem) : newValueItem;
            helpers.setValue(newFieldValue);
            handleConfirmSelection();
        }
    }, [autoSelectSingleVariant, flatOptions, getKey, handleConfirmSelection, helpers, selectedKeyValue, useIdValue]);

    const renderOptions = (optionList: any[]) => {
        if (native) {
            return (
                optionList.map(option => (
                    <React.Fragment key={option.label || getKey(option)}>
                        {option?.isOptionGroup ? (
                            <optgroup label={option.label}>
                                {renderOptions(option.options)}
                            </optgroup>
                        ) : (
                            <option
                                title={getOptionTitle && getOptionTitle(option)}
                                value={getKey(option)}
                                key={getKey(option)}
                            >
                                {getText(option)}
                            </option>
                        )}
                    </React.Fragment>
                ))
            );
        }
        return optionList.map(option => {
            if (option?.isOptionGroup) {
                return [
                    (
                        <ListSubheader key={option.label}>
                            {option.label}
                        </ListSubheader>
                    ),
                    ...renderOptions(option.options),
                ];
            }
            return (
                <MenuItem
                    title={getOptionTitle && getOptionTitle(option)}
                    value={getKey(option)}
                    key={getKey(option)}
                >
                    {multiple && (
                        <Checkbox
                            style={{ marginRight: 8 }}
                            checked={selectedKeyValue.indexOf(getKey(option)) > -1}
                        />
                    )}
                    {getText(option)}
                </MenuItem>
            );
        });
    };

    const renderValue = useCallback(() => {
        const keys = multiple ? selectedKeyValue : [selectedKeyValue];
        const objectValues = flatOptions.filter(option => keys.includes(getKey(option)));
        if (objectValues.length === 0) {
            return label;
        }
        return objectValues.map(value => getText(value)).join(', ');
    }, [flatOptions, getKey, getText, label, multiple, selectedKeyValue]);

    return (
        <FormControl
            variant="outlined"
            classes={{ root: className }}
            error={hasError}
        >
            <Tooltip title={title}>
                <>
                    {inputLabel && (
                        <InputLabel>{inputLabel}</InputLabel>
                    )}
                    {outerLabel && (
                        <label
                            htmlFor={id}
                            className={formHelperTextClasses.outerLabel}
                        >
                            {outerLabel}
                        </label>
                    )}
                    <Select
                        {...field}
                        value={selectedKeyValue}
                        disabled={disabled}
                        id={id}
                        label={inputLabel}
                        onChange={onChange}
                        IconComponent={isLoading ? LoadingIconComponent : undefined}
                        native={native}
                        multiple={multiple}
                        displayEmpty
                        renderValue={renderValue}
                        onBlur={handleBlur}
                        onClose={handleConfirmSelection}
                        classes={{
                            select: native ? undefined : classes.nonNativeInput,
                        }}
                        MenuProps={menuProps} // for non-native drop-down menus
                    >
                        {label && native && (
                            <option value="">
                                {label}
                            </option>
                        )}
                        {label && !native && (
                            <MenuItem value="">
                                {label}
                            </MenuItem>
                        )}
                        {renderOptions(filteredOptions)}
                    </Select>
                </>
            </Tooltip>
            {hasError && showErrorText && (
                <FormHelperText classes={formHelperTextClasses}>
                    {meta.error}
                </FormHelperText>
            )}
        </FormControl>
    );
}
