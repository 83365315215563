import {
    FormControl,
    FormHelperText,
    InputLabel,
    OutlinedInput,
} from '@material-ui/core';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput/OutlinedInput';
import { useField } from 'formik';
import React from 'react';
import { inputParams } from 'shared/styles/constants';
import { useFormHelperTextStyles } from 'shared/styles/formHelperText';
import { IFormFieldProps as IBasicFormFieldProps } from './models';

const FormField = ({
    name,
    label,
    outerLabel,
    id = String(name),
    className,
    disabled = false,
    defaultInputProps = true,
    showErrorText = true,
    ...props
}: IFormFieldProps) => {
    const [field, meta, helper] = useField(name);
    const inputProps = defaultInputProps ? inputParams : {};
    const formHelperTextClasses = useFormHelperTextStyles();

    const hasError = Boolean(meta.error && meta.touched);
    const formattedValue = props.valueFormatter ? props.valueFormatter(field.value) : field.value;
    return (
        <FormControl
            error={hasError}
            className={className}
            variant="outlined"
            key={id}
        >
            {outerLabel && <label htmlFor={id} className={formHelperTextClasses.outerLabel}>{outerLabel}</label>}
            {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
            <OutlinedInput
                {...props}
                inputProps={{
                    ...inputProps,
                    ...props.inputProps,
                }}
                {...field}
                onBlur={() => helper.setTouched(true)}
                disabled={disabled}
                id={id}
                name={name}
                label={label}
                autoComplete="off"
                value={formattedValue}
            />
            {hasError && showErrorText && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
    );
};

export default FormField;

export interface IFormFieldProps extends IBasicFormFieldProps, Partial<OutlinedInputProps> {
    name: string;
    label?: string;
    defaultInputProps?: boolean;
    valueFormatter?: (value: any) => string;
}
