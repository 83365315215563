import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { PhysicalDemandsTable } from 'modules/settings/submodules/clients/physicalDemands/PhysicalDemandsTable/PhysicalDemandsTable';
import { CreatePhysicalDemandsButton } from 'modules/settings/submodules/clients/physicalDemands/CreatePhysicalDemands/CreatePhysicalDemandsButton';
import { useDispatch, useSelector } from 'react-redux';
import { getPhysicalDemands } from 'store/entities/configuration/configurationAction';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
export const PhysicalDemandsPage = () => {

    const clientId = useSelector(selectCurrentClientId);

    const dispatch = useDispatch();
    useEffect(() => {
        if (clientId) {
            dispatch(getPhysicalDemands.init());
        }
    }, [dispatch, clientId]);

    return (
        <>
            <Box mb={2}>
                <CreatePhysicalDemandsButton />
            </Box>
            <PhysicalDemandsTable />
        </>
    );
};
