import { useFeature } from '@optimizely/react-sdk';
import React from 'react';
import AttachmentsPreviewLegacy from 'shared/components/attachments/AttachmentsPreviewLegacy';
import { AttachmentsPreviewSecure } from 'shared/components/attachments/AttachmentsPreviewSecure';
import { useLoadSecureSheetAttachments } from 'shared/components/attachments/hooks';
import { IAttachment } from 'shared/models/Attachments';
import { FeatureSwitches } from 'utils/featureSwitches';

export interface IAttachmentsPreviewProps {
    open: boolean;
    onClose: () => void;
    onDelete?: (file: IAttachment) => void;
    files: IAttachment[];
}

export default function EntryAttachmentsPreview(props: IAttachmentsPreviewProps) {
    const [enableSecureAttachments] = useFeature(FeatureSwitches.enableSecureAttachmentUrl);
    const files = enableSecureAttachments ? props.files : [];
    useLoadSecureSheetAttachments(files);
    if (enableSecureAttachments) {
        return (<AttachmentsPreviewSecure {...props} />);
    }
    return (<AttachmentsPreviewLegacy {...props} />);
}
