import React, { useMemo } from 'react';
import { ICalculationGroupActionsProps } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/components/calculationGroup/batch/ICalculationGroupActionsProps';
import { getSheetCalculationBatchExpenseSheetIds, getSheetCalculationBatchTimeSheetIds } from 'modules/payrollProcessorHub/helpers/sheetCalculationBatchHelpers';
import { ISheetGroupIdWithClient } from 'modules/payrollProcessorHub/store/model';
import { useSelector } from 'react-redux';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';
import { AviontePayrollDownloadButton } from './AviontePayrollDownloadButton';

export const AviontePayrollDownloadBatchButton = ({ calculationGroups }: ICalculationGroupActionsProps) => {
    const groupIds = useMemo(() => {
        let groupIdsInner: ISheetGroupIdWithClient[] = [];
        calculationGroups.forEach(calculationGroup => {
            const clientId = calculationGroup.client_id;
            const timeSheetIds = getSheetCalculationBatchTimeSheetIds(calculationGroup);
            const expenseSheetIds = getSheetCalculationBatchExpenseSheetIds(calculationGroup);
            groupIdsInner = [
                ...groupIdsInner,
                ...timeSheetIds.map(timeSheetId => ({
                    timeSheetId,
                    expenseSheetId: null,
                    clientId: clientId,
                })),
                ...expenseSheetIds.map(expenseSheetId => ({
                    expenseSheetId,
                    timeSheetId: null,
                    clientId: clientId,
                })),
            ];
        });
        return groupIdsInner;
    }, [calculationGroups]);
    const userHasPayrollPermission = useSelector(selectIsUserHasPermission(Permission.payrollProcessing));
    if (!userHasPayrollPermission) {
        return null;
    }

    return (
        <AviontePayrollDownloadButton groupIds={groupIds} />
    );
};
