import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
    selectCcpSplitTableItems,
    selectIsOpenCcpSplitSidebar,
    selectSplitOpenedTransactionId,
} from './store/selectors';
import { ICcpTransactionRow, selectCcpTransactionsById } from '../CcpTransactionsTable/store/selectors';
import { ICcpTransaction } from '../../models/CcpTransaction';
import { useCcpMainStyles, useCcpTableStyles } from '../../styles';
import Sidebar from 'shared/components/sidebars/Sidebar/Sidebar';
import React, {
    ChangeEvent,
    useCallback, useEffect,
    useMemo,
    useState,
} from 'react';
import { changeSplitAmountTotal, setOpenSplitCcpSidebar } from './store/actions';
import {
    Box,
    FormControl,
    OutlinedInput,
    Select,
    Toolbar,
    Typography,
} from '@material-ui/core';
import GridTable from 'shared/components/table/GridTable/GridTable';
import { selectCustomFieldValuesByFieldId } from '../../../../store/entities/customFields/selectors';
import { Form, Formik } from 'formik';
import { selectClientCreditCardPortalPageCustomFieldId } from '../../../../store/entities/clients/selectors/configurationSelectors';
import {
    selectCustomFieldScopeRestrictionsByAction,
    selectUserHasScopeRestrictionByType,
} from '../../../../store/entities/scopes/selectors';
import { ScopeAction } from '../../../../store/entities/scopes/models';
import { useCellSelectStyles } from '../../../settings/submodules/components/sharedStyles/tableCellStyles';
import { selectTransactionCodes } from '../CcpCreate/store/selectors';
import { Close } from '@material-ui/icons';
import { TransactionPropertyList } from './TransactionPropertyList';
import clsx from 'clsx';
import { formatDollars } from '../../../../shared/utils/formatters/dollarFormatter';
import TotalNum from '../../../../shared/components/toolbar/totalNum/totalNum';
import ActionButton from '../../../../shared/components/buttons/ActionButton';
import CcpFilesUploader from '../CcpEdit/CcpFilesUploader';
import { ICcpSplitItem, ITransactionCode } from '../../models/ccpModel';
import DollarQuantity from '../../../../shared/components/table/addEntryControls/quantity/DollarQuantity';
import { moneyModifiers } from '../../../../shared/models/Money';
import PlainText from '../../../../shared/components/table/Cells/PlainText';
import { splitCcpTransaction } from '../../store/actions';
import { CcpTotalsBlockWrapper } from './CcpTotalsBlockWrapper';
import { SplitSaveButtonWrapper } from './SplitSaveButtonWrapper';
import moment from 'moment/moment';

export const CcpSplitSidebar = () => {
    const scopeActionFilter = ScopeAction.Entry;

    const dispatch = useDispatch();
    const isOpened = useSelector(selectIsOpenCcpSplitSidebar, shallowEqual);
    const transactions = useSelector(selectCcpTransactionsById, shallowEqual);
    const customFieldId = useSelector(selectClientCreditCardPortalPageCustomFieldId, shallowEqual);
    const openedId = useSelector(selectSplitOpenedTransactionId, shallowEqual);
    const transactionItem = useMemo(() => {
        return transactions[openedId] as ICcpTransaction;
    }, [transactions, openedId]);
    const ccpAllTransactionCodes = useSelector(selectTransactionCodes, shallowEqual);

    const ccpTransactionCodes = useMemo(() => {
        return Object.values(ccpAllTransactionCodes) as ITransactionCode[];
    }, [ccpAllTransactionCodes]);
    const currentTableItemsRaw = useSelector(selectCcpSplitTableItems);

    const [currentTableItems, setCurrentTableItems] = useState<ICcpSplitItem[]>(currentTableItemsRaw ?? []);

    const userHasScopesByAction = useSelector(selectUserHasScopeRestrictionByType(scopeActionFilter), shallowEqual);
    const scopeAllowedCustomFieldValueIdsByAction = useSelector(
        selectCustomFieldScopeRestrictionsByAction(scopeActionFilter, customFieldId ? customFieldId : ''),
        shallowEqual,
    );
    const customFieldValuesRaw = useSelector(selectCustomFieldValuesByFieldId(customFieldId ? customFieldId : ''),
        shallowEqual);
    const customFieldValues = useMemo(() => {
        return (userHasScopesByAction && scopeAllowedCustomFieldValueIdsByAction.length > 0)
            ? customFieldValuesRaw.filter(t => scopeAllowedCustomFieldValueIdsByAction.includes(t.id))
            : customFieldValuesRaw;
    }, [userHasScopesByAction, scopeAllowedCustomFieldValueIdsByAction, customFieldValuesRaw]);

    const additionalClasses = useCcpMainStyles();
    const tableClasses = useCcpTableStyles();
    const selectClasses = useCellSelectStyles();

    const handleClose = () => {
        dispatch(setOpenSplitCcpSidebar(''));
    };

    const handleSubmit = () => {
        dispatch(splitCcpTransaction.init(currentTableItems));
    };

    const handleRecalculateAmount = useCallback(() => {
        const amount = currentTableItems?.length > 0 ? currentTableItems?.map(x =>
            +(x.amount)).reduce((prev, current) => prev + current) : 0;
        const balance = +(transactionItem?.amount) - amount;
        dispatch(changeSplitAmountTotal(balance));
    }, [currentTableItems, transactionItem?.amount, dispatch]);

    useEffect(() => {
        setCurrentTableItems(JSON.parse(JSON.stringify(currentTableItemsRaw ?? [])));
    }, [currentTableItemsRaw]);

    const onAddSplitRow = useCallback(() => {
        const newItem = { id: String(moment().valueOf()), amount: '0.00' } as ICcpSplitItem;
        const extendedItems = currentTableItems.concat([newItem]);
        setCurrentTableItems(extendedItems);
        handleRecalculateAmount();
    }, [currentTableItems, handleRecalculateAmount]);

    const cells = [
        {
            key: 'removal',
            title: '',
            width: '25px',
            render: function amountCell({ id }: ICcpTransactionRow) {
                const onRowRemove = () => {
                    const filteredItems = currentTableItems.filter(x => x.id !== id);
                    setCurrentTableItems(filteredItems);
                    handleRecalculateAmount();
                };
                if (currentTableItems?.length <= 2) {
                    return (
                        <PlainText value={''} />
                    );
                }
                return (
                    <Box className={tableClasses.enlargedActionsIconCellSecondary}
                        onClick={onRowRemove}>
                        <Close />
                    </Box>
                );
            },
        },
        {
            key: 'transaction_code',
            title: 'TRANSACTION CODE',
            render: function TransactionCodeCell({ id,
                transaction_code_id,
                className }: ICcpTransactionRow) {
                const onTransactionCodeChange = (event: ChangeEvent<HTMLSelectElement>) => {
                    const { options } = event.target as HTMLSelectElement;
                    const selectedId = [...options].find(option => option.selected)?.value;
                    const current = currentTableItems.find((x: ICcpSplitItem) => x.id === id);
                    if (current) {
                        current.transaction_code_id = selectedId ?? '';
                    }
                };
                return (
                    <div className={className}>
                        <FormControl
                            variant="outlined"
                            size="small"
                            className={tableClasses.statusSelect}
                        >
                            <Select
                                disabled={false}
                                native
                                inputProps={{}}
                                classes={selectClasses}
                                value={transaction_code_id}
                                onChange={onTransactionCodeChange}
                            >
                                <option value="">
                                    {'Select Code'}
                                </option>
                                {ccpTransactionCodes?.map((item, index) => (
                                    <option
                                        value={item.id}
                                        key={index}
                                    >
                                        {item.name}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                );
            },
        },
        {
            key: 'department_name',
            title: 'DEPARTMENT',
            render: function DepartmentCell({ id, department_id, className }: ICcpTransactionRow) {
                const onDepartmentChange = (event: ChangeEvent<HTMLSelectElement>) => {
                    const { options } = event.target as HTMLSelectElement;
                    const selectedId = [...options].find(option => option.selected)?.value;
                    const current = currentTableItems.find((x: ICcpSplitItem) => x.id === id);
                    if (current) {
                        current.department_id = selectedId ?? '';
                    }
                };
                return (
                    <div className={className}>
                        <FormControl
                            variant="outlined"
                            size="small"
                            className={tableClasses.statusSelect}
                        >
                            <Select
                                disabled={false}
                                native
                                inputProps={{}}
                                classes={selectClasses}
                                value={department_id}
                                onChange={onDepartmentChange}
                            >
                                <option value="">
                                    {'Select Department'}
                                </option>
                                {customFieldValues?.filter(cfv => cfv.is_active)?.map((item, index) => (
                                    <option
                                        value={item.id}
                                        key={index}
                                    >
                                        {item.data.name}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                );
            },
        },
        {
            key: 'notes',
            title: 'NOTES',
            render: function ActionsCell({ id, note, className }: ICcpTransactionRow) {
                const [editableNoteState, setEditableNoteState] = useState(note);
                const onNoteChangeText = (event: ChangeEvent<HTMLInputElement>) => {
                    const value = event.target.value;
                    setEditableNoteState(value);
                };
                const onNoteBlur = () => {
                    const currentElement = currentTableItems.find(x => x.id === id);
                    if (currentElement) {
                        currentElement.note = editableNoteState;
                    }
                };
                return (
                    <Box className={className}>
                        <Box maxHeight={'23px'}>
                            <OutlinedInput
                                name={`note_${id}`}
                                className={additionalClasses.input}
                                classes={{ input: additionalClasses.inputInline }}
                                value={editableNoteState}
                                autoComplete="off"
                                onChange={onNoteChangeText}
                                onBlur={onNoteBlur}
                            />
                        </Box>
                    </Box>
                );
            },
        },
        {
            key: 'amount',
            title: 'AMOUNT',
            width: '90px',
            render: function AmountCell({ id, amount, className }: ICcpTransactionRow) {
                const [focused, setFocused] = useState(false);
                const [editableAmountState, setEditableAmountState] = useState(amount);
                const onFocus = useCallback((isFocused: boolean) => {
                    setFocused(isFocused);
                }, [setFocused]);
                const onAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
                    const amountValue = event.target.value;
                    setEditableAmountState(amountValue);
                    const currentElement = currentTableItems.find(x => x.id === id);
                    if (currentElement) {
                        currentElement.amount = amountValue;
                    }
                    handleRecalculateAmount();
                };
                const clearedAmount = editableAmountState ? editableAmountState : '0.00';
                return (
                    <Box className={className} width={'95%'}
                        justifyContent={'right'}>
                        <Box className={
                            (focused ? tableClasses.focusedAmount : tableClasses.outlinedCellContent)
                        }>
                            <DollarQuantity
                                value={clearedAmount}
                                onFocus={onFocus}
                                onBlur={onAmountChange}
                                customClasses={{
                                    input: additionalClasses.input,
                                    inputRoot: clsx(additionalClasses.inputRoot, tableClasses.amountBodyCell),
                                }}
                                modifiers={moneyModifiers}
                                disabled={false}
                            />
                        </Box>
                    </Box>
                );
            },
        },
    ];

    return (
        <Sidebar
            title="SPLIT TRANSACTION"
            isOpened={Boolean(isOpened)}
            onClose={handleClose}
            whiteTitle
        >
            <Box
                component="section"
                p={2}
                display="flex"
                flexDirection="column"
                flexGrow={1}
            >
                <Box display="flex" flexDirection="row">
                    <TransactionPropertyList
                        purchase_date={transactionItem?.purchase_date}
                        merchant_name={transactionItem?.merchant_name}
                        merchant_desc={transactionItem?.merchant_desc}
                        transaction_type_name={transactionItem?.transaction_type_name}
                    />
                    <CcpTotalsBlockWrapper />
                </Box>
                <Box
                    marginLeft={'-15px'}
                    marginRight={'-15px'}
                    display="flex"
                    flexDirection="column"
                    flexGrow={1}
                >
                    <Toolbar classes={{ root: tableClasses.toolbarLine }}>
                        <Typography variant="body2" classes={{ root: clsx(additionalClasses.label, additionalClasses.ccpLabel) }}>
                            TOTAL ORIGINAL AMOUNT
                        </Typography>
                        <TotalNum value={formatDollars(transactionItem?.amount)} />
                    </Toolbar>
                    <Formik
                        initialValues={currentTableItems}
                        onSubmit={handleSubmit}
                    >
                        <Form className={additionalClasses.sidebarForm}>
                            <Box
                                id={'ccp_splitted_transaction_items'}
                                className={additionalClasses.sidebarTableContainer}
                            >
                                <GridTable rowData={currentTableItems}
                                    getKey={row => row.id}
                                    isLoading={false}
                                    hideHeader={false}
                                    headerCellClassName={tableClasses.headCellWhite}
                                    bodyCellClassName={tableClasses.bodyCell}
                                    cells={cells}
                                />
                                <Box marginTop="5px" marginLeft={'15px'}>
                                    <ActionButton
                                        variant="contained"
                                        color="primary"
                                        customType="success"
                                        type="button"
                                        isLoading={false}
                                        disabled={false}
                                        onClick={onAddSplitRow}
                                    >
                                        Add
                                    </ActionButton>
                                </Box>
                            </Box>
                            <Box className={additionalClasses.sidebarBottomUploadContainer}>
                                <CcpFilesUploader
                                    entry={transactionItem}
                                />
                            </Box>
                            <Box className={additionalClasses.sidebarBottomActionsContainer} marginLeft={'15px'}>
                                <SplitSaveButtonWrapper />
                            </Box>
                        </Form>
                    </Formik>
                </Box>
            </Box>
        </Sidebar>
    );
};
