export enum MenuItemEnum {
    Dashboard = 'Dashboard',
    OfferLetter = 'Offer Letter',
    TimeApproval = 'Time Approval',
    ExpenseApproval = 'Expense Approval',
    TravelExpenses = 'Travel Expenses',
    TimeExpenses = 'Time & Expenses',
    MyOfferLetter = 'My Offer Letter',
    PayrollDashboard = 'Payroll Dashboard',
    TimeExpensesManagement = 'T&E sheets',
    EmploymentManagement = 'Employment Management',
    SubassignmentManagement = 'Sub-assignment Management',
    AssignmentManagement = 'Assignment Management',
    EmployeeActionForm = 'Employee Action Forms',
    EAFPendingApproval = 'Employee Action Forms',
    Impersonation = 'Impersonation',
    Employees = 'Employees',
    Managers = 'Managers',
    Reports = 'Reports',
    CreditCardPortal = 'Credit Card Portal',
    TerminationRequests = 'Termination Requests',
}
