import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import {
    Tabs, Tab, Hidden, IconButton, ButtonBase, Box, TabProps,
} from '@material-ui/core';
import useHeaderTabsStyles from './TabStyles';
import MoreVert from '@material-ui/icons/MoreVert';
import Modal from 'shared/components/modals/Modal';
import { useModal } from 'shared/utils/hooks/useModal';

export interface IHeaderTab extends Partial<TabProps> {
    value: any;
    label: string;
}

export interface IHeaderTabsProps {
    tabs: Array<IHeaderTab>;
    mobileHeader: string;
    value: any;
    onChange: (value: string) => void;
}

export const HeaderTabs = ({
    tabs,
    mobileHeader,
    value,
    onChange,
}: IHeaderTabsProps) => {
    const classes = useHeaderTabsStyles();
    const { isModalOpened, onModalClose, onModalOpen } = useModal();

    const onChangeTab = (event: React.ChangeEvent<{}>, newValue: string) => {
        onChange(newValue);
    };

    const [tabsPermanent, tabsHiddenForMobile] = useMemo(() => {
        return [
            tabs.slice(0, 3),
            tabs.slice(3),
        ];
    }, [tabs]);

    const changeTabAndCloseMore = useCallback((newValue: string) => {
        onChange(newValue);
        onModalClose();
    }, [onChange, onModalClose]);

    return (
        <div className={classes.secondaryBar}>
            <Tabs
                classes={{
                    root: classes.tabs,
                    flexContainer: classes.tabsFlexContainer,
                    indicator: classes.tabsIndicator,
                }}
                value={value}
                onChange={onChangeTab}
                textColor="inherit"
            >
                {tabsPermanent.map(({ label, value: tabValue, classes: tabClasses = {}, ...tabProps }) => (
                    <Tab
                        key={label}
                        classes={{
                            root: classes.tab,
                            selected: classes.tabActive,
                            ...tabClasses,
                        }}
                        label={label}
                        value={tabValue}
                        {...tabProps}
                    />
                ))}
                {tabsHiddenForMobile.map(({ label, value: tabValue, classes: tabClasses = {}, ...tabProps }) => (
                    <Tab
                        key={label}
                        classes={{
                            root: clsx(classes.tab, classes.tabMobileHidden),
                            selected: classes.tabActive,
                            ...tabClasses,
                        }}
                        label={label}
                        value={tabValue}
                        {...tabProps}
                    />
                ))}
                {tabsHiddenForMobile.length > 0 && (
                    <Hidden mdUp>
                        <IconButton className={classes.moreVert} onClick={onModalOpen}>
                            <MoreVert />
                        </IconButton>
                        <Modal
                            title={mobileHeader}
                            isOpened={isModalOpened}
                            onClose={onModalClose}
                            showCloseIcon={true}
                            customClasses={{
                                title: classes.modalTitle,
                                paper: classes.modalPaper,
                                content: classes.modalContent,
                            }}
                        >
                            <Box display="flex" flexDirection="column">
                                {tabs.map(tab => (
                                    <ButtonBase
                                        key={tab.value}
                                        classes={{ root: classes.mobileTab }}
                                        onClick={() => changeTabAndCloseMore(tab.value)}
                                    >
                                        {tab.label}
                                    </ButtonBase>
                                ))}
                            </Box>
                        </Modal>
                    </Hidden>
                )}
            </Tabs>
        </div>
    );
};
