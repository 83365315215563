import { Box, Grid, Typography } from '@material-ui/core';
import { FormikProvider, useFormik } from 'formik';
import { useHandleAddressSelect } from 'modules/settings/submodules/components/AddressFormBlock/useHandleAddressSelect';
import { useSettingsFormGridStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import { useEmployeeValidationSchema } from 'modules/settings/submodules/employees/components/EditEmployee/validationSchema';
import { IEmployeeEditFormValues } from 'modules/settings/submodules/employees/store/model';
import React from 'react';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import FormField from 'shared/components/formFields/FormField';
import AddressAutocompleteField from 'shared/components/formSpecialFields/addressAutocompleteField/AddressAutocompleteField';
import PhoneField from 'shared/components/formSpecialFields/phoneField/PhoneField';

interface IEditEmployeeFormProps {
    onSubmit: (values: IEmployeeEditFormValues) => void;
    isLoading: boolean;
    initialValues: IEmployeeEditFormValues;
    initialEmail: string;
    children?: React.ReactChildren | React.ReactChild;
    additionalButton?: React.ReactChildren | React.ReactChild;
}

export const EditEmployeeForm = ({
    onSubmit,
    isLoading,
    initialValues,
    initialEmail,
    children,
    additionalButton,
}: IEditEmployeeFormProps) => {
    const classes = useSettingsFormGridStyles();
    const validationSchema = useEmployeeValidationSchema(initialEmail);

    const formikData = useFormik<IEmployeeEditFormValues>({
        initialValues,
        validationSchema,
        onSubmit,
        enableReinitialize: true,
    });

    const handleAddressSelect = useHandleAddressSelect(formikData.setFieldValue);

    return (
        <FormikProvider value={formikData}>
            <form onSubmit={formikData.handleSubmit}>
                <Typography variant="subtitle2" className={classes.formBlockTitle}>Profile</Typography>

                <Grid container spacing={2}>
                    <Grid item sm={4}>
                        <FormField
                            name="first_name"
                            label="First name"
                            className={classes.input}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <FormField
                            name="last_name"
                            label="Last name"
                            className={classes.input}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <PhoneField
                            name="phone"
                            label="Phone Number"
                            className={classes.input}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item sm={8}>
                        <FormField
                            name="email"
                            type="email"
                            label="Email Address"
                            className={classes.input}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        {formikData.values.email !== initialEmail && (
                            <FormField
                                name="emailConfirmation"
                                type="email"
                                label="Confirm Email Address"
                                className={classes.input}
                            />
                        )}
                    </Grid>
                </Grid>
               
                <Grid container spacing={2}>
                    <Grid item sm={4}>
                        <FormField
                            name="prism_employee_id"
                            label="Prism ID"
                            className={classes.input}
                            disabled
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <FormField
                            name="avionte_talent_id"
                            label="Avionte ID"
                            className={classes.input}
                            disabled
                        />
                    </Grid>
                </Grid>

                {children}

                <Box className={classes.formBlock}>
                    <Typography variant="subtitle2" className={classes.formBlockTitle}>Address</Typography>
                </Box>

                <Grid container spacing={2}>
                    <Grid item sm={8}>
                        <AddressAutocompleteField
                            name="address"
                            label="Address"
                            className={classes.input}
                            onAddressSelect={handleAddressSelect}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <FormField
                            name="address2"
                            label="Address 2"
                            className={classes.input}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item sm={8}>
                        <FormField
                            name="city"
                            label="City"
                            className={ classes.input }
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <FormField
                            name="state"
                            label="State/Region"
                            className={ classes.input }
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item sm={8}>
                        <FormField
                            name="county"
                            label="County"
                            className={ classes.input }
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <FormField
                            name="zip_code"
                            label="Zip code"
                            className={ classes.input }
                        />
                    </Grid>
                </Grid>

                <Box>
                    <ButtonWithLoader
                        type="submit"
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        disabled={isLoading}
                        isLoading={isLoading}
                        data-testid="submit_update_button"
                    >
                        Update
                    </ButtonWithLoader>
                    {additionalButton}
                </Box>
            </form>
        </FormikProvider>
    );
};
