import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setCreateCustomFieldValueModalState } from 'modules/settings/submodules/clients/customFieldValues/store/actions';
import { CreateCustomFieldValueModal } from 'modules/settings/submodules/clients/customFieldValues/components/CreateCustomFieldValue/CreateCustomFieldValueModal';

export const CreateCustomFieldValueButton = () => {
    const dispatch = useDispatch();
    const openModal = useCallback(() => {
        dispatch(setCreateCustomFieldValueModalState(true));
    }, [dispatch]);

    return (
        <>
            <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={openModal}>
                + Add new value
            </Button>
            <CreateCustomFieldValueModal />
        </>
    );
};
