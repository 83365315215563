import baseApi from 'shared/utils/baseApi';
import { IClient } from 'store/entities/clients/clientsModel';

export const employeesApi = {
    async getEmployeeClients(userId: string): Promise<IClient[]> {
        const { data } = await baseApi.get<{ clients: IClient[] }>(
            `/client-users/employee/${userId}/clients`,
        );
        return data.clients;
    },
    
    async onboardWithAvionte(userId: string): Promise<{has_offer_letter_errors: boolean}> {
        const { data } = await baseApi.post<undefined, {has_offer_letter_errors: boolean}>(
            `/client-users/users/${userId}/complete_avionte_onboarding`);
        return data;
    },
};
