import { Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { PayRateType, PayType } from 'shared/models/PaySettings';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Nullable } from '../../../../types/types';
import { selectClientPaySettings } from 'store/entities/clients/selectors/timeAndPaySelectors';

export interface IShowPayRateType {
    type?: PayRateType;
    payType?: PayType;
}

export const useStyles = makeHighPriorityStyles(() => ({
    type: {
        textTransform: 'lowercase',
    },
}));

const ShowPayRateType = ({ type, payType }: IShowPayRateType) => {
    const classes = useStyles();
    const paySettings = useSelector(selectClientPaySettings);

    let value: Nullable<string> = '';
    if (type && payType) {
        value = payType === PayType.Salaried ? paySettings?.pay_period_type : type;
    }

    return (
        <Typography variant="body2" className={classes.type}>{value}</Typography>
    );
};

export default ShowPayRateType;
