import PhoneField from 'shared/components/formSpecialFields/phoneField/PhoneField';
import { useIsMobile } from 'shared/utils/hooks/media';
import { IUserLookupRequest } from 'store/entities/users/model';
import React from 'react';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { FormikProps } from 'formik';
import FormField from 'shared/components/formFields/FormField';

export const defaultLookupValues: IUserLookupRequest = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
};

export interface ILookupFormClasses {
    form?: string;
    input?: string;
    button?: string;
}

export interface ILookupFormProps extends FormikProps<IUserLookupRequest> {
    classes?: ILookupFormClasses;
    isLoading?: boolean;
}

export function LookupForm({
    handleSubmit,
    values,
    classes = {},
    isLoading = false,
}: ILookupFormProps) {
    const isMobile = useIsMobile();
    const { firstName, lastName, email, phone } = values;
    const isValid = Boolean(firstName?.trim() || lastName?.trim() || email?.trim() || phone?.trim());
    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <FormField
                name="firstName"
                label="First Name"
                className={classes.input}
            />

            <FormField
                name="lastName"
                label="Last Name"
                className={classes.input}
            />

            <FormField
                name="email"
                label="Email Address"
                className={classes.input}
            />

            <PhoneField
                name="phone"
                label="Cell Phone Number"
                className={classes.input}
            />

            <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={!isValid}
            >
                {isMobile ? 'Look up' : 'Lookup'}
                {isLoading && (
                    <Box ml={1} mb={-0.5}>
                        <CircularProgress color="inherit" size={16} />
                    </Box>
                )}
            </Button>
        </form>
    );
}
