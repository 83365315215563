import { Box, CircularProgress, Fade, Typography } from '@material-ui/core';
import { ErrorOutlineOutlined } from '@material-ui/icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { colors } from 'shared/styles/constants';
import useTitleUpdate from 'shared/utils/hooks/useTitleUpdate';
import { logger } from 'shared/utils/logging/logger';
import { selectZohoAnalyticsPermalink } from 'store/entities/clients/selectors/configurationSelectors';
import { HeadwayError } from 'utils/errors';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

const IN_TIMEOUT_MS = 300;
const EXIT_TIMEOUT_MS = 900;
const TIMEOUT_ERROR_MS = 300;

const useReportsPageStyles = makeHighPriorityStyles(() => ({
    wrapper: {
        height: '100%',
        width: '100%',
        position: 'relative',
    },
    iframe: {
        border: 'none',
        width: '100%',
        height: '100%',
    },
    container: {
        background: colors.whiteSecondary,
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerInner: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loadingLabel: {
        marginRight: '15px',
    },
    failedLabel: {
        marginLeft: '10px',
    },
}));

export const ReportsPage: React.FC = () => {
    const zohoPermalink = useSelector(selectZohoAnalyticsPermalink);
    useTitleUpdate('Reports');
    const classes = useReportsPageStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        setIsError(false);
    }, [zohoPermalink]);

    const handleLoaded = useCallback(() => {
        setIsLoading(false);
    }, []);

    const handleError = useCallback(() => {
        logger.error(new HeadwayError('Failed to load Zoho reports'));
        setIsLoading(false);
        setIsError(true);
    }, []);

    const iframe = useMemo(() => (
        <iframe
            className={classes.iframe}
            onLoad={handleLoaded}
            onError={handleError}
            // @ts-ignore
            src={zohoPermalink}
        />
    ), [zohoPermalink, handleError, handleLoaded, classes.iframe]);

    let pageFadeTimeout = IN_TIMEOUT_MS;
    if (!isLoading) {
        pageFadeTimeout = isError ? TIMEOUT_ERROR_MS : EXIT_TIMEOUT_MS;
    }

    return (
        <Box className={classes.wrapper}>
            {!isError && iframe}

            <Fade
                in={isLoading}
                exit={!isLoading}
                timeout={pageFadeTimeout}
            >
                <Box className={classes.container}>
                    <Box className={classes.containerInner}>
                        <Typography
                            variant="h5"
                            className={classes.loadingLabel}
                        >
                            Loading Reports
                        </Typography>
                        <CircularProgress />
                    </Box>
                </Box>
            </Fade>

            <Fade
                in={isError}
                exit={!isError}
                timeout={TIMEOUT_ERROR_MS}
            >
                <Box className={classes.container}>
                    <ErrorOutlineOutlined />
                    <Typography
                        className={classes.failedLabel}
                        variant="h5"
                    >
                        Failed to load reports
                    </Typography>
                </Box>
            </Fade>
        </Box>
    );
};
