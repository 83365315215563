import React from 'react';

export const ServiceDisabledPage = () => {
    return (
        <>
            <link rel="stylesheet" href="/css/extended_error.css" />
            <div className="logo-container">
                <img src="/assets/hwfs-s360-logo.png" alt="Headway" />
            </div>
            <div className="error-message" role="alert">
                <div className="error-message-title">We&apos;ll be back shortly.</div>
                <div className="error-message-description">
                    We are experiencing some technical difficulties.<br />
                    Here are some cute puppies to distract you.<br />
                    Thank you for your patience!<br />
                    <br />
                    For updates, please check the <a href="https://headwayconnect.statuspage.io/">status page</a>.
                </div>
            </div>
            <div id="bg"></div>
            <div id="bg-copyright">Labrador psd created by rawpixel.com</div>
        </>
    );
};
