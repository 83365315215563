import React from 'react';
import { Warning } from '@material-ui/icons';
import { Alert, AlertProps } from '@material-ui/lab';
import { colors } from 'shared/styles/constants';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

const useStyles = makeHighPriorityStyles(theme => ({
    root: {
        borderRadius: theme.spacing(0.75),
        border: `1px solid ${colors.warningBorder}`,
        color: colors.black,
    },
    icon: {
        padding: theme.spacing(0.5, 0),
    },
}));

type WarningAlertWithIconProps = Omit<AlertProps, 'icon' | 'severity' | 'variant' | 'iconMapping'>

export const WarningAlertWithIcon = ({
    children,
    ...rest
}: WarningAlertWithIconProps) => {
    const classes = useStyles();

    return (
        <Alert
            icon={<Warning />}
            severity="warning"
            variant="standard"
            classes={classes}
            {...rest}
        >
            {children}
        </Alert>
    );
};
