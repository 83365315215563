import { getOfferLetter } from 'modules/offerLetter/store/actions';
import React, { useCallback, useEffect } from 'react';
import { RouteComponentProps, generatePath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectOfferLetter } from './store/selectors';
import SidebarCommon from 'shared/components/sidebars/Sidebar/SidebarCommon';
import { useSidebarStyles } from './components/lookup/useSidebarStyles';
import { browserHistory } from 'shared/utils/browserHistory';
import { IOfferLetter } from './store/model';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { getOfferLabel } from './store/utils';
import { routes } from 'shared/routes';
import { Box, CircularProgress } from '@material-ui/core';
import { useTabUpdateByOffer } from 'modules/offerLetter/hooks/useTabUpdateByOffer';

interface IMatchCardParams {
    id: string;
}

export interface IOfferLetterPanelRoute extends RouteComponentProps<IMatchCardParams> {
}

interface IRenderSidebarProps {
    offerLetter: IOfferLetter;
    onClose: () => void;
    clientId: string | null;
}

interface IOfferLetterPanelProps {
    getTitle: (name: string) => string;
    renderSidebar: (params: IRenderSidebarProps) => React.ReactNode;
    id: string;
}

const OfferLetterPanel: React.FC<IOfferLetterPanelProps> = ({
    getTitle,
    renderSidebar,
    id,
}: IOfferLetterPanelProps) => {
    const offerLetter = useSelector(selectOfferLetter(id));
    const clientId = useSelector(selectCurrentClientId);
    const title = getTitle(offerLetter ? getOfferLabel(offerLetter) : '');

    const dispatch = useDispatch();
    useEffect(() => {
        if (!offerLetter) {
            dispatch(getOfferLetter.init(id));
        }
    }, [dispatch, id, offerLetter]);
    useTabUpdateByOffer(offerLetter);

    const sidebarClasses = useSidebarStyles();
    const onCloseSidebar = useCallback(() => {
        clientId && browserHistory.push(generatePath(routes.CLIENT.OFFER_LETTER.ROOT, { client_id: clientId }));
    }, [clientId]);

    return (
        <SidebarCommon
            open
            title={title}
            onBack={onCloseSidebar}
            onClose={onCloseSidebar}
            titleClasses={{
                root: sidebarClasses.sidebarTitle,
            }}
        >
            {offerLetter
                ? renderSidebar({ onClose: onCloseSidebar, offerLetter, clientId })
                : (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                        height="100%"
                    >
                        <CircularProgress />
                    </Box>
                )
            }
        </SidebarCommon>
    );
};

export default OfferLetterPanel;
