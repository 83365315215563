import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MainContent from 'shared/components/common/MainContent';
import { Box } from '@material-ui/core';
import { EmployeeTable } from 'modules/users/employees/components/UserListPage/EmployeeTable/EmployeeTable';
import { EmployeeNameFilter } from 'modules/users/employees/components/UserListPage/Filter/EmployeeNameFilter';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { Status } from 'shared/models/Status';
import useTitleUpdate from 'shared/utils/hooks/useTitleUpdate';
import { selectCurrentClient } from 'store/entities/clients/selectors/clientsSelectors';
import { IUserRow, useUserCellDictionary } from 'modules/settings/submodules/components/SharedTableRows/UsersRows';
import { setEmployeesFilter } from 'modules/users/employees/store/actions';

export const EmployeeListPage = () => {
    useTitleUpdate('Employees');
    const userCellsDictionary = useUserCellDictionary(true);
    const userCells: ICellInfo<IUserRow>[] = useMemo(() => [
        userCellsDictionary.name,
        userCellsDictionary.email,
        userCellsDictionary.state,
        userCellsDictionary.loginAsUser,
    ], [userCellsDictionary]);

    const client = useSelector(selectCurrentClient);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setEmployeesFilter({
            status: Status.active,
            client,
        }));
    }, [dispatch, client]);

    return (
        <MainContent>
            <Box
                display="flex"
                justifyContent="flex-end"
                mb={2}
            >
                <EmployeeNameFilter />
            </Box>
            <EmployeeTable cells={userCells} hideTotal />
        </MainContent>
    );
};
