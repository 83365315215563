import React from 'react';
import { useFeature } from '@optimizely/react-sdk';
import { FeatureSwitches } from 'utils/featureSwitches';

export function withFeature<TProps = {}>(
    feature: FeatureSwitches,
    WrappedComponent: React.ComponentType<TProps>,
): React.ComponentType<TProps> {
    const FeatureDependedComponent = (props: TProps) => {
        const [enabled] = useFeature(feature);

        if (enabled) {
            return <WrappedComponent {...props} />;
        }

        return null;
    };

    return FeatureDependedComponent;
}
