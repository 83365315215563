import { Grid } from '@material-ui/core';
import { selectPositionCustomFieldValueId } from 'modules/subassignmentManagement/components/PromotionDemotion/store/selectors';
import React from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import MoneyField from 'shared/components/formFields/MoneyField';
import { CustomFieldValuesSelect } from 'shared/components/selects/CustomFieldValuesSelect/CustomFieldValuesSelect';
import { useSubAssignmentBulkCreateEditFormStyles } from '../../CreateSubAssignment/CreateSubAssignmentForm/styles';
import { FooterSection } from './components/editFormSections/FooterSection';
import { DatesSection } from './components/editFormSections/DatesSection';
import { SubassignmentApprovers } from 'modules/subassignmentManagement/components/CreateSubAssignment/CreateSubAssignmentForm/SubassignmentApprovers';
import { DeleteSubassignmentsButton } from 'modules/subassignmentManagement/components/EditSubAssignment/EditSubAssignmentForm/DeleteSubassignmentsButton';
import { IBulkEditSubAssignmentFormValuesV2 } from './types';
import { useEditSubAssignmentValidationSchemaV2 } from './validationsSchema';

export interface IBulkEditSubassignmentsFormStep1Props {
    onSubmit: (values: IBulkEditSubAssignmentFormValuesV2) => void;
    numberOfApprovers: number;
    isLoading: boolean;
    initialValuesOverride?: Partial<IBulkEditSubAssignmentFormValuesV2>;
    assignmentsOnly?: boolean;
}

export function BulkEditSubassignmentsFormStep1({
    onSubmit,
    numberOfApprovers,
    isLoading,
    initialValuesOverride,
    assignmentsOnly = false,
}: IBulkEditSubassignmentsFormStep1Props) {
    const classes = useSubAssignmentBulkCreateEditFormStyles();
    const validationSchema = useEditSubAssignmentValidationSchemaV2(
        numberOfApprovers,
    );
    const positionCustomFieldId = useSelector(selectPositionCustomFieldValueId);

    const formikData = useFormik({
        initialValues: {
            startDate: '',
            endDate: '',
            overrideRate: null,
            approvers: null,
            ...initialValuesOverride,
        },
        validationSchema,
        // @ts-ignore
        onSubmit,
        validateOnBlur: true,
        enableReinitialize: true,
    });

    const additionalButtons = assignmentsOnly ? null : (
        <DeleteSubassignmentsButton />
    );

    return (
        <FormikProvider value={formikData}>
            <Form onSubmit={formikData.handleSubmit}>
                <DatesSection
                    classes={classes}
                    isLoading={isLoading}
                    hideEndDate={assignmentsOnly}
                />

                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={12}
                        md={6}
                    >
                        <MoneyField
                            name="newPayRate"
                            outerLabel="Pay Rate"
                            className={classes.input}
                            withDollar
                            disabled={isLoading}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                    >
                        <CustomFieldValuesSelect
                            name="newPosition"
                            customFieldId={positionCustomFieldId}
                            className={classes.input}
                            useIdValue
                        />
                    </Grid>
                </Grid>

                {!assignmentsOnly && (
                    <SubassignmentApprovers
                        numberOfApprovers={numberOfApprovers}
                        inputClassName={classes.input}
                        isLoading={isLoading}
                    />
                )}

                <FooterSection
                    classes={classes}
                    loading={isLoading}
                    submitButtonTitle="Next"
                    // @ts-ignore
                    additionalButtons={additionalButtons}
                />
            </Form>
        </FormikProvider>
    );
}
