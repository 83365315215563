export enum OfferLetterCellKey {
    AcceptedDate = 'acceptedDate',
    Action = 'action',
    CheckBoxPayRangeApproval = 'checkBoxPayRangeApproval',
    Client = 'client',
    DeclinedDate = 'declinedDate',
    Department = 'department',
    Email = 'email',
    Employee = 'employee',
    FullInfo = 'fullInfo',
    Location = 'location',
    OfferDate = 'offerDate',
    OfferEdit = 'offerEdit',
    OfferLetterPDF = 'offerLetterPDF',
    PayRange = 'payRange',
    PayRate = 'payRate',
    Position = 'position',
    JobOrder = 'job_order',
    Worksite = 'worksite',
    Reason = 'reason',
    StartDate = 'startDate',
    Status = 'status',
    StatusPayRate = 'statusPayRate',
}
