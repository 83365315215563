import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFeature } from '@optimizely/react-sdk';
import { Box } from '@material-ui/core';
import { setEndAssignmentId } from 'modules/employeeActionsForm/components/EndAssignment/store/action';
import {
    selectAssignmentMaxEntryDate,
    selectEndAssignment,
    selectEndAssignmentId,
    selectRelatedAssignments,
    selectRelatedSubassignments,
} from 'modules/employeeActionsForm/components/EndAssignment/store/selectors';
import { EndAssignmentForm, IEmployeeActionFormDataModel } from 'modules/employeeActionsForm/components/EndAssignment/EndAssignmentForm';
import { selectIsEafCreating } from 'modules/employeeActionsForm/store/selectors';
import { EafType, IEafAssignmentEndData, IEafEndDateChangeData } from 'modules/employeeActionsForm/store/models';
import { Status } from 'shared/models/Status';
import { getSubassignments } from 'store/entities/configuration/configurationAction';
import { createEmployeeActionForm, getAssignmentMaxEntryDate } from 'modules/employeeActionsForm/store/actions';
import { WorkspaceSidebar } from 'shared/components/workspaces/WorkspaceSidebar/WorkspaceSidebar';
import { FeatureSwitches } from 'utils/featureSwitches';
import { AssignmentEditDateForm } from '../AssignmentEditEndDate/AssignmentEditEndDateForm';
import { ReopenAssignmentForm } from '../AssignmentEditEndDate/ReopenAssignmentForm';

export enum EndOfAssignmentSidebarMode {
    END_OF_ASSIGNMENT = 'END_OF_ASSIGNMENT',
    EDIT_CLOSED_ASSIGNMENT_END_DATE = 'EDIT_CLOSED_ASSIGNMENT_END_DATE',
    REOPEN_ASSIGNMENT = 'REOPEN_ASSIGNMENT',
}

export interface IEndAssignmentSidebarProps {
    sideBarTitle: string,
    mode?: EndOfAssignmentSidebarMode,
}

export const EndAssignmentSidebar = ({
    sideBarTitle,
    mode = EndOfAssignmentSidebarMode.END_OF_ASSIGNMENT,
}: IEndAssignmentSidebarProps) => {
    const dispatch = useDispatch();
    const assignmentId = useSelector(selectEndAssignmentId);
    const assignment = useSelector(selectEndAssignment);
    const relatedSubassignments = useSelector(selectRelatedSubassignments);
    const isLoading = useSelector(selectIsEafCreating);
    const assignmentMaxEntryDate = useSelector(selectAssignmentMaxEntryDate);
    const relatedAssignments = useSelector(selectRelatedAssignments);

    useEffect(() => {
        if (assignmentId) {
            dispatch(getSubassignments.init({
                assignment_id: assignmentId,
            }));
            dispatch(getAssignmentMaxEntryDate.init(assignmentId));
        }
    }, [assignmentId, dispatch]);
    const onClose = useCallback(() => {
        dispatch(setEndAssignmentId(null));
    }, [dispatch]);

    const [enableEndAssignmentReason] = useFeature(FeatureSwitches.enableEndAssignmentReason);
    const onSubmit = useCallback((values: IEmployeeActionFormDataModel) => {
        const { eligibleForRehire, employee_action_form_reason_text, ...rest } = values;
        // @ts-ignore
        const payloadData = mode === EndOfAssignmentSidebarMode.END_OF_ASSIGNMENT ? (enableEndAssignmentReason ? {
            ...rest,
            employee_action_form_reason_text,
            eligible_for_rehire: eligibleForRehire === Status.active,
        } as IEafAssignmentEndData : rest) : {
            employee_action_form_type: values.employee_action_form_type,
            old_end_date: assignment.end_date,
            new_end_date: values.effective_date || null,
            is_retro: false,
        } as IEafEndDateChangeData;
        dispatch(createEmployeeActionForm.init({
            assignment_id: assignmentId,
            // @ts-ignore
            employee_action_form_data: payloadData,
        }));
    }, [dispatch, mode, assignmentId, assignment?.end_date, enableEndAssignmentReason]);
    useEffect(() => {
        return function cleanup() {
            onClose();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <WorkspaceSidebar
            title={sideBarTitle}
            isOpened={Boolean(assignmentId)}
            onClose={onClose}
        >
            <Box m={3}>
                {mode === EndOfAssignmentSidebarMode.END_OF_ASSIGNMENT && (
                    <EndAssignmentForm
                        isLoading={isLoading}
                        onCancel={onClose}
                        relatedSubassignments={relatedSubassignments}
                        assignment={assignment}
                        assignmentMaxEntryDate={assignmentMaxEntryDate}
                        // @ts-ignore
                        initialValues={{
                            employee_action_form_type: EafType.AssignmentEnded,
                            employee_action_form_reason_text: '',
                            effective_date: '',
                            eligibleForRehire: null,
                            do_not_send_employee_email: false,
                        }}
                        onSubmit={onSubmit}
                    />
                )}
                {mode === EndOfAssignmentSidebarMode.EDIT_CLOSED_ASSIGNMENT_END_DATE && (
                    <AssignmentEditDateForm
                        isLoading={isLoading}
                        onCancel={onClose}
                        relatedSubassignments={relatedSubassignments}
                        assignment={assignment}
                        assignmentMaxEntryDate={assignmentMaxEntryDate}
                        relatedAssignments={relatedAssignments}
                        // @ts-ignore
                        initialValues={{
                            employee_action_form_type: EafType.EndDateChange,
                            employee_action_form_reason_text: '',
                            effective_date: '',
                            eligibleForRehire: null,
                            do_not_send_employee_email: true,
                        }}
                        onSubmit={onSubmit}
                    />
                )}
                {mode === EndOfAssignmentSidebarMode.REOPEN_ASSIGNMENT && (
                    <ReopenAssignmentForm
                        isLoading={isLoading}
                        onCancel={onClose}
                        relatedSubassignments={relatedSubassignments}
                        assignment={assignment}
                        assignmentMaxEntryDate={assignmentMaxEntryDate}
                        relatedAssignments={relatedAssignments}
                        // @ts-ignore
                        initialValues={{
                            employee_action_form_type: EafType.EndDateChange,
                            employee_action_form_reason_text: '',
                            effective_date: null,
                            eligibleForRehire: null,
                            do_not_send_employee_email: true,
                        }}
                        onSubmit={onSubmit}
                    />
                )}
            </Box>
        </WorkspaceSidebar>
    );
};
