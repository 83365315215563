/* eslint-disable react/display-name */
import { isString } from 'lodash-es';
import React, {
    ChangeEvent,
    Ref,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { useField } from 'formik';
import {
    Checkbox,
    Chip,
    CircularProgress,
    FormControl,
    FormHelperText,
    TextField,
    Tooltip,
} from '@material-ui/core';
import { inputParams } from 'shared/styles/constants';
import { IFormFieldProps } from '../formFields/models';
import { useFormHelperTextStyles } from 'shared/styles/formHelperText';

// @ts-ignore
interface ICustomAutocompleteProps<T> extends IFormFieldProps, Omit<AutocompleteProps<T>, 'renderInput'> {
    options: Array<T>;
    getKey?: (item: T) => string | null;
    getText: (item: T) => string;
    inputRef?: Ref<HTMLDivElement>;
    tooltip?: string;
    placeholder?: string;
    Label?: React.ReactNode;
    isLoading?: boolean;
    multiple?: boolean;
    limitTags?: number;
    endAdornment?: React.ReactNode;
    inputType?: React.InputHTMLAttributes<unknown>['type'];
}

export default function FormAutocomplete({
    name,
    label,
    Label,
    options,
    className,
    getKey,
    getText,
    placeholder,
    disabled = false,
    id = name,
    tooltip = '',
    isLoading = false,
    multiple = false,
    limitTags = 2,
    endAdornment,
    outerLabel,
    inputType = 'text',
    ...props
}: ICustomAutocompleteProps<any>,
) {
    const formHelperTextClasses = useFormHelperTextStyles();
    const [field, meta, helpers] = useField(name);
    const [innerValue, setInnerValue] = useState(multiple ? [] : null);
    const hasError = Boolean(meta.error && meta.touched);

    const getValue = useCallback((newValue: any) => {
        if (multiple) {
            return getKey ? options.filter(i => newValue.includes(getKey(i))) || [] : newValue;
        }
        return getKey ? options.find(i => getKey(i) === newValue) || null : newValue;
    }, [options, getKey, multiple]);

    useEffect(() => {
        const value = getValue(field.value);
        if (JSON.stringify(value) !== JSON.stringify(innerValue)) {
            setInnerValue(value);
        }
    }, [field.value, setInnerValue, getValue, innerValue]);

    const onChange = (_event: ChangeEvent<{}>, newAutocompleteValue: any) => {
        let newValue;
        if (multiple) {
            newValue = getKey && newAutocompleteValue
                ? newAutocompleteValue.map(i => getKey(i)).filter(Boolean) : newAutocompleteValue;
        } else {
            newValue = getKey && newAutocompleteValue ? getKey(newAutocompleteValue) || null : newAutocompleteValue;
            if (getKey && isString(newAutocompleteValue) && !newValue && options[0]) {
                // try to apply first available option
                newValue = getKey(options[0]) || null;
            }
            if (getKey && newAutocompleteValue && !newValue) {
                // user just press enter and not select available option
                return;
            }
        }
        helpers.setValue(newValue);
        helpers.setTouched(false);
    };

    const onBlur = () => {
        helpers.setTouched(false);
    };

    const renderOption = useMemo(() => {
        if (!multiple) {
            return undefined;
        }
        return (option, { selected }) => (
            <React.Fragment>
                <Checkbox
                    style={{ marginRight: 8 }}
                    checked={selected}
                />
                {getText(option)}
            </React.Fragment>
        );
    }, [getText, multiple]);

    const renderTags = useMemo(() => {
        if (!multiple) {
            return undefined;
        }
        return (value, getTagProps) => {
            const chipsToShow = [...value].splice(0, limitTags);
            const more = value.length - limitTags;
            return (
                <>
                    {chipsToShow.map((option, index) => (
                        <Chip
                            key={getText(option)}
                            label={getText(option)}
                            size="medium"
                            {...getTagProps({ index })}
                        />
                    ))}
                    {more > 0 && `+${more}`}
                </>
            );
        };
    }, [getText, limitTags, multiple]);

    return (
        <FormControl
            variant="outlined"
            classes={{ root: className }}
            error={hasError}
        >
            {outerLabel && (
                <label
                    htmlFor={id}
                    className={formHelperTextClasses.outerLabel}
                >
                    {outerLabel}
                </label>
            )}
            {Boolean(Label) && Label}
            <Tooltip title={tooltip}>
                <Autocomplete
                    {...props}
                    {...field}
                    options={options}
                    value={innerValue}
                    disabled={disabled}
                    id={id}
                    onChange={onChange}
                    onBlur={onBlur}
                    renderOption={renderOption}
                    renderTags={renderTags}
                    disableCloseOnSelect={multiple}
                    multiple={multiple}
                    limitTags={limitTags}
                    getOptionLabel={getText}
                    renderInput={(inputProps: any) => (
                        <TextField
                            error={hasError && meta.error}
                            {...inputProps}
                            label={label}
                            type={inputType}
                            variant="outlined"
                            placeholder={placeholder}
                            inputProps={{
                                ...inputParams,
                                ...inputProps.inputProps,
                            }}
                            InputProps={{
                                ...inputProps.InputProps,
                                endAdornment: (
                                    <>
                                        {isLoading ? <CircularProgress color="primary" size={16} /> : null}
                                        {inputProps.InputProps.endAdornment}
                                        {endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />
            </Tooltip>
            {hasError && (
                <FormHelperText classes={formHelperTextClasses}>
                    {meta.error}
                </FormHelperText>
            )}
        </FormControl>
    );
}
