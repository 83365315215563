import { selectEditSheetIdsForSubmit } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/selectors';
import React, { useCallback, useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import EditSheetAfterSubmit from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/EditSheetAfterSubmit';
import useSheetsInProgressStyles
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/SheetsInProgressStyles';
import TimeEntryTabs from 'modules/clients/header/TimeEntryTabs';
import { setHeaderContent } from 'modules/home/header/Header';
import { SheetGroup } from 'modules/timeAndExpense/store/model';
import SubmitStatus from 'shared/components/submitStatus/SubmitStatus';
import { DATE_FORMAT } from 'shared/models/Dates';
import { EntryType } from 'shared/models/sheet/Sheet';
import { useStoredSelector } from 'shared/utils/hooks/useStoredSelector';
import { useStoredValue } from 'shared/utils/hooks/useStoredValue';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { selectSheet } from 'store/entities/timesheet/selectors';

interface ISheetsRecalledViewProps {
    sheetId?: string;
    sheetGroup?: SheetGroup;
    entryType: EntryType;
    resetSheet: (sheetInfo: null) => void;
}

export default function SheetsRecalledView({ sheetId, sheetGroup, entryType, resetSheet }: ISheetsRecalledViewProps) {
    const classes = useSheetsInProgressStyles();
    const payPeriod = useStoredValue(sheetGroup?.payPeriod);
    const sheetIdsToSubmit = useStoredSelector(
        selectEditSheetIdsForSubmit(entryType, StatusNames.RECALLED, payPeriod),
    );
    const sheet = useSelector(selectSheet(sheetId));

    const endDate = sheet?.period_end || sheetGroup?.payPeriod.period_end;
    const submitStatusDue = moment(endDate).clone().add(1, 'day')
        .format(DATE_FORMAT.MM_DD_YYYY);

    useEffect(() => {
        setHeaderContent(
            <Box className={classes.headerContentWrapper}>
                <SubmitStatus
                    customClasses={classes.statusWrapper}
                    labelClass={classes.recalledSubmit}
                    dueDate={submitStatusDue}
                    status={StatusNames.RECALLED}
                />
                <TimeEntryTabs />
            </Box>,
        );

        return () => {
            setHeaderContent(null);
        };
    }, [submitStatusDue, classes]);
    const handleClose = useCallback(() => resetSheet(null), [resetSheet]);

    return (
        <EditSheetAfterSubmit
            sheetId={sheetId}
            sheetIds={sheetIdsToSubmit}
            entryType={entryType}
            onClose={handleClose}
            showAllEntriesForPeriod={payPeriod}
            statusName={StatusNames.RECALLED}
        />
    );
}
