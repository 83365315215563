import { selectTimeAndExpenseActiveStatus } from 'modules/timeAndExpense/store/selectors';
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { SyncMessage } from 'shared/components/toolbar/SyncMessage';
import { EntryType } from 'shared/models/sheet/Sheet';
import { SyncingModels } from 'store/entities/appConfig/syncing/models';
import { SheetsEntryTypes, StatusNames } from 'store/entities/timesheet/models/Status';
import { useSelector } from 'react-redux';

import useFilterAndActionControlsStyles from 'shared/components/sheetApproval/filterAndActionControls/FilterAndActionControlsStyles';
import Time from 'shared/components/sheetApproval/filterAndActionControls/buttons/TimeIcon';
import Expense from 'shared/components/sheetApproval/filterAndActionControls/buttons/ExpenseIcon';
import FiltersChips
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/FiltersChips';
import {
    selectEmployeeSubmittedSheetsFilters,
    selectEmployeeSubmittedSheetsFiltersConfiguration,
} from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsSelectors';
import { setEmployeeSubmittedSheetsFilters } from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsActions';
import SheetFilterButton from 'shared/components/filters/FilterButtonAndForm/SheetFilterButton';
import { IAvailableActionsProps } from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/sheetsData';

interface IFilterAndActionControlsProps {
    sheetType: EntryType;
    showFilters: boolean;
    title?: string;
    sheetsEntryTypes?: SheetsEntryTypes;
    availableActions?: (props: IAvailableActionsProps) => JSX.Element;
}

export default function FilterAndActionControls({
    sheetType,
    showFilters,
    title,
    sheetsEntryTypes,
    availableActions: AvailableActions,
}: IFilterAndActionControlsProps,
) {
    const classes = useFilterAndActionControlsStyles();
    const activeStatus = useSelector(selectTimeAndExpenseActiveStatus);

    return (
        <>
            <Box className={classes.rootContainer}>
                <Box className={classes.leftContainer}>
                    {sheetType === EntryType.TIME ? <Time /> : <Expense />}
                    <Box>
                        <Typography variant="h4">
                            {title ? title : (
                                <>
                                    All {(activeStatus === StatusNames.ALL) ? '' : activeStatus} {
                                        sheetType === EntryType.TIME ? 'Timesheets' : 'Expenses'
                                    }
                                </>
                            )}
                        </Typography>
                    </Box>
                </Box>
                {showFilters && (
                    <>
                        <FiltersChips
                            // @ts-ignore
                            action={setEmployeeSubmittedSheetsFilters}
                            className={classes.filtersChips}
                            selector={selectEmployeeSubmittedSheetsFilters}
                        />
                        <SyncMessage syncingKey={SyncingModels.ApprovalSheet} />
                    </>
                )}
                {showFilters && (
                    <Box className={classes.rightContainer}>
                        {AvailableActions && sheetsEntryTypes && Object.values(sheetsEntryTypes).length > 0 && (
                            <AvailableActions typedSheets={sheetsEntryTypes} />
                        )}
                        <SheetFilterButton
                            overrideClassName={classes.actionButton}
                            selectFilters={selectEmployeeSubmittedSheetsFilters}
                            selectFiltersConfig={selectEmployeeSubmittedSheetsFiltersConfiguration}
                            submitFilters={setEmployeeSubmittedSheetsFilters}
                        />
                    </Box>
                )}
            </Box>
        </>
    );
}
