import React from 'react';
import { Box } from '@material-ui/core';
import { PayrollBatchButton } from 'modules/payrollProcessorHub/components/PayrollAction/PayrollBatchButton';
import { useGroupedSheetDetailStyles } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/styles';
import { EditGroupButton } from 'modules/payrollProcessorHub/components/PayrollAction/EditGroupButton';
import { UnlockGroupButton } from 'modules/payrollProcessorHub/components/PayrollAction/UnlockAction/UnlockGroupButton';
import { ICalculationGroupSidebarActionsProps } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/components/calculationGroup/batch/ICalculationGroupSidebarActionsProps';
import { useSelector } from 'react-redux';
import {
    selectAllCustomFieldValues,
    selectCustomFields,
    selectIsLoadingCustomFields,
} from 'store/entities/customFields/selectors';
import {
    checkIfAllGroupEntriesHasJobOrders,
    checkIfNoneGroupEntriesHasJobOrders,
} from '../../../../../../store/helpers';
import { AviontePayrollDownloadBatchButton } from '../../../../../PayrollAction/AviontePayrollDownloadBatchButton';

export const CalcGroupApprovedActions = ({ calculationGroup }: ICalculationGroupSidebarActionsProps) => {
    const classes = useGroupedSheetDetailStyles();
    const hideEditOrUnlock = (
        calculationGroup.expense_calculations.length === 0
        && calculationGroup.time_calculations.every(item => item.is_holiday)
    );

    const customFields = useSelector(selectCustomFields);
    const customFieldValues = useSelector(selectAllCustomFieldValues);
    const isLoading = useSelector(selectIsLoadingCustomFields);
    const shouldShowExport = checkIfAllGroupEntriesHasJobOrders([calculationGroup], customFields, customFieldValues)
        && !isLoading;
    const shouldShowPayroll = checkIfNoneGroupEntriesHasJobOrders([calculationGroup], customFields, customFieldValues)
        && !isLoading;

    return (
        <Box className={classes.actions}>
            {!hideEditOrUnlock && (
                <>
                    <EditGroupButton calculationGroup={calculationGroup} />
                    <UnlockGroupButton calculationGroup={calculationGroup} />
                </>
            )}
            {shouldShowPayroll && (
                <PayrollBatchButton calculationGroups={[calculationGroup]} />
            )}
            {shouldShowExport && (
                <AviontePayrollDownloadBatchButton calculationGroups={[calculationGroup]} />
            )}
        </Box>
    );
};
