import { setCreateOfferLetterTemplateModalState } from 'modules/settings/submodules/offerLetters/store/action';
import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import CreateOfferLetterTemplateModal from 'modules/settings/submodules/offerLetters/components/CreateOfferLetterTemplate/CreateOfferLetterTemplateModal';
import { useDispatch } from 'react-redux';

export function CreateOfferLetterTemplateButton() {
    const dispatch = useDispatch();
    const openModal = useCallback(() => {
        dispatch(setCreateOfferLetterTemplateModalState(true));
    }, [dispatch]);
    return (
        <>
            <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={openModal}
            >
                Add new template
            </Button>
            <CreateOfferLetterTemplateModal />
        </>
    );
}
