import clsx from 'clsx';
import { EditItemButton } from 'modules/settings/common/components/EditItemButton/EditItemButton';
import { LoginAsUserButton } from 'modules/settings/common/components/LoginAsUserButton';
import { setEditEmployeeId } from 'modules/settings/submodules/employees/store/actions';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PlainText from 'shared/components/table/Cells/PlainText';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { shortDateFormat } from 'shared/models/Dates';
import { IUserInfo } from 'shared/models/User';
import { getLastFirstName } from 'shared/utils/converters/user';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';

export interface IUserRow extends IUserInfo {
    className?: string;
}

export const useUserCellDictionary = (useClientScope = false): Record<string, ICellInfo<IUserRow>> => {
    const tableClasses = useDefaultTableStyles();
    const clientId = useSelector(selectCurrentClientId);

    return useMemo(() => ({
        id: {
            key: 'id',
            title: 'ID',
            width: '2.2fr',
            render: function IdCell({ id, className }: IUserRow) {
                return (
                    <PlainText className={className} value={id} />
                );
            },
        },
        prismId: {
            key: 'prismId',
            title: 'prism id',
            render: function PrismIdCell({ prism_employee_id, className }: IUserRow) {
                return (
                    <PlainText className={className} value={prism_employee_id} />
                );
            },
        },
        avionteId: {
            key: 'avionteId',
            title: 'avionte id',
            render: function AvionteIdCell({ avionte_talent_id, className }: IUserRow) {
                return (
                    <PlainText className={className} value={avionte_talent_id} />
                );
            },
        },
        name: {
            key: 'name',
            title: 'Name',
            render: function NameCell({ className, ...user }: IUserRow){
                return (
                    <PlainText className={className} value={getLastFirstName(user)} />
                );
            },
        },
        email: {
            key: 'email',
            title: 'Email',
            render: function EmailCell({ email, className }: IUserRow){
                return (
                    <PlainText className={className} value={email} />
                );
            },
        },
        created: {
            key: 'created',
            title: 'created',
            render: function CreatedCell({ created_at, className }: IUserRow){
                return (
                    <PlainText className={className} value={moment(created_at).format(shortDateFormat)} />
                );
            },
        },
        updated: {
            key: 'updated',
            title: 'updated',
            render: function UpdatedCell({ updated_at, className }: IUserRow){
                return (
                    <PlainText className={className} value={moment(updated_at).format(shortDateFormat)} />
                );
            },
        },
        statusReadonly: {
            key: 'status',
            title: 'status',
            render: function StatusCell({ status, className }: IUserRow) {
                return (
                    <PlainText className={className} value={status} />
                );
            },
        },
        state: {
            key: 'state',
            title: 'state',
            render: function StateCell({ state, className }: IUserRow) {
                return (
                    <PlainText className={className} value={state} />
                );
            },
        },
        loginAsUser: {
            key: 'loginAsUser',
            title: '',
            width: '54px',
            render: function ActionCell({ className, id }: IUserRow) {
                return (
                    <div className={clsx(className, tableClasses.iconCell, tableClasses.detailsIcon)}>
                        <LoginAsUserButton userId={id} clientId={useClientScope ? clientId : undefined} />
                    </div>
                );
            },
        },
        actions: {
            key: 'actions',
            title: '',
            width: '74px',
            render: function ActionCell({ className, id }: IUserRow) {
                return (
                    <div className={clsx(className, tableClasses.iconCell, tableClasses.detailsIcon)}>
                        <LoginAsUserButton userId={id} clientId={useClientScope ? clientId : undefined} />
                        <EditItemButton id={id} editAction={setEditEmployeeId} />
                    </div>
                );
            },
        },
    }), [tableClasses, clientId, useClientScope]);
};
