import { getOfferLetters } from 'modules/offerLetter/store/actions';
import { offerLetterApi } from 'modules/offerLetter/store/api';
import { IUpdateOfferRequest, OfferLetterStatusSlug } from 'modules/offerLetter/store/model';
import {
    deleteEmployee,
    getEmployeeClients,
    onboardWithAvionte,
    overrideOfferLetterStatus,
    resetPassword,
    setEditEmployeeId,
    updateEmployee,
} from 'modules/settings/submodules/employees/store/actions';
import { employeesApi } from 'modules/settings/submodules/employees/store/api';
import { refreshEmployees } from 'modules/users/employees/store/actions';
import { IModalSeverity } from 'shared/components/toasts/modal';
import { getUserName } from 'shared/utils/converters/user';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { getUsers } from 'store/entities/users/actions';
import { usersApi } from 'store/entities/users/api';
import { selectUserById } from 'store/entities/users/selectors';
import { getLoadEntitiesByRequestSagaWatcher } from 'store/utils/sagas/getLoadEntitiesByRequestSagaWatcher';
import { requestSagaWatcher } from 'store/utils/sagas/requestSagaWatcher';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { api } from '../../platformUsers/store/api';

export function* overrideOfferLetterStatusSaga(
    {
        payload: { id, status },
    }: ReturnType<typeof overrideOfferLetterStatus.init>,
) {
    const params: Partial<IUpdateOfferRequest> = {
        status,
    };

    const updatedOfferLetter = yield* call(offerLetterApi.updateOfferLetter, id, params);

    yield put(getOfferLetters.success([updatedOfferLetter]));
    const actionName = status === OfferLetterStatusSlug.Accepted ? 'overridden' : 'reset';
    yield put(setGlobalToast({
        severity: IModalSeverity.Success,
        title: `Offer Letter status successfully ${actionName}`,
    }));
    yield put(overrideOfferLetterStatus.success());
}

function* overrideOfferLetterStatusWatcher() {
    yield takeLatest(
        overrideOfferLetterStatus.initType,
        withBackendErrorHandler(
            overrideOfferLetterStatusSaga,
            overrideOfferLetterStatus.error,
            'Unable to override offer letter status.',
        ),
    );
}

function* resetPasswordSaga(
    {
        payload: userId,
    }: ReturnType<typeof resetPassword.init>,
) {
    const user = yield select(selectUserById(userId));

    yield* call(usersApi.passwordResetting, {
        email: user.email,
        force_reset: true,
    });

    yield put(resetPassword.success());
    yield put(setGlobalToast({
        severity: IModalSeverity.Success,
        title: `${getUserName(user)}'s password successfully reset`,
    }));

}

function* resetPasswordWatcher() {
    yield takeLatest(
        resetPassword.initType,
        withBackendErrorHandler(
            resetPasswordSaga,
            resetPassword.error,
            'Unable to reset password.',
        ),
    );
}

function* updateEmployeeSaga({ payload }: ReturnType<typeof updateEmployee.init>) {
    const { id, data } = payload;
    // @ts-ignore
    const updatedUser = yield* call(api.updateUser, id, data);

    yield put(getUsers.success([updatedUser]));
    yield put(setGlobalToast({
        severity: IModalSeverity.Success,
        title: `User successfully updated`,
    }));
    yield put(updateEmployee.success(updatedUser));
    yield put(setEditEmployeeId(null));
}

function* updateEmployeeWatcher() {
    yield takeLatest(
        updateEmployee.initType,
        withBackendErrorHandler(
            updateEmployeeSaga,
            updateEmployee.error,
            'Unable to update user.',
        ),
    );
}

const getEmployeeClientsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getEmployeeClients,
    employeesApi.getEmployeeClients,
    'employee clients',
);

const deleteEmployeeWatcher = requestSagaWatcher(
    deleteEmployee,
    usersApi.deleteUser,
    'Unable to delete employee',
    {
        handleSuccessSaga: function* () {
            yield put(refreshEmployees());
            yield put(setEditEmployeeId(null));
            yield put(setGlobalToast({
                severity: IModalSeverity.Success,
                title: `Employee successfully deleted`,
            }));
        },
    },
);

function* onboardWithAvionteSaga({ payload: userId }: ReturnType<typeof onboardWithAvionte.init>) {
    const result = yield * call(employeesApi.onboardWithAvionte, userId);
    const user = yield select(selectUserById(userId));
    const updatedUser = {
        ...user,
        employee_profile: {
            ...user.employee_profile,
            avionte_onboarding_completed: true,
        },
    };
    yield put(getUsers.success([updatedUser]));
    yield put(onboardWithAvionte.success());
    
    if (result.has_offer_letter_errors) {
        yield put(setGlobalToast({
            severity: IModalSeverity.Warning,
            title: 'User onboarded with Avionte, but there were some errors with offer letters. Please contact support.',
        })); 
        return;
    }      
      
    yield put(setGlobalToast({
        severity: IModalSeverity.Success,
        title: `User successfully onboarded with Avionte`,
    })); 
}

function* onboardWithAvionteWatcher() {
    yield takeLatest(
        onboardWithAvionte.initType,
        withBackendErrorHandler(
            onboardWithAvionteSaga,
            onboardWithAvionte.error,
            'Unable to onboard user with Avionte.',
        ),
    );
}

export const employeesSagas = [
    overrideOfferLetterStatusWatcher,
    resetPasswordWatcher,
    updateEmployeeWatcher,
    getEmployeeClientsWatcher,
    deleteEmployeeWatcher,
    onboardWithAvionteWatcher,
];
