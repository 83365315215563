import React from 'react';
import { Formik } from 'formik';
import { defaultFormValues, ICustomFieldFormValues } from 'modules/settings/submodules/customFields/components/CustomFieldForm/model';
import { CustomFieldForm } from 'modules/settings/submodules/customFields/components/CustomFieldForm/CustomFieldForm';
import { ICustomField } from 'store/entities/customFields/model';
import { useCustomFieldValidationSchema } from 'modules/settings/submodules/customFields/components/CustomFieldForm/ValidationsSchema';

export interface ICustomFieldFormWrapperProps {
    onSubmit: (values: ICustomFieldFormValues) => void;
    submitTitle: string;
    isLoading: boolean;
    isEdit?: boolean;
    customField?: ICustomField;
}

const CustomFieldFormWrapper = ({
    onSubmit,
    submitTitle,
    isLoading,
    customField,
    isEdit = false,
}: ICustomFieldFormWrapperProps) => {
    const initialFormValues = {
        name: customField?.name || defaultFormValues.name,
        description: customField?.description || defaultFormValues.description,
        mapping: customField?.prism_field?.key || customField?.headway_connect_field?.key || defaultFormValues.mapping,
        clients: customField?.client_ids || defaultFormValues.clients,
        all_clients: customField?.all_clients || defaultFormValues.all_clients,
    };
    const customFieldValidationSchema = useCustomFieldValidationSchema(customField?.id);

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={customFieldValidationSchema}
            onSubmit={onSubmit}
            validateOnBlur={false}
        >
            {props => (
                <CustomFieldForm
                    {...props}
                    isLoading={isLoading}
                    submitTitle={submitTitle}
                    isEdit={isEdit}
                    mapped={customField?.export_field}
                />
            )}
        </Formik>
    );
};

export default CustomFieldFormWrapper;
