import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenBulkEndAssignmentModal } from 'modules/subassignmentManagement/components/BulkEndAssignment/store/actions';
import { selectCheckedAssignmentIds } from 'modules/subassignmentManagement/components/SubassignmentTable/store/selectors';
import { selectIsOpenModal } from 'modules/subassignmentManagement/components/BulkEndAssignment/store/selectors';
import { IEmployeeActionFormDataModel } from 'modules/employeeActionsForm/components/EndAssignment/EndAssignmentForm';
import { BulkEndAssignmentForm } from 'modules/subassignmentManagement/components/BulkEndAssignment/BulkEndAssignmentForm';
import { EafType } from 'modules/employeeActionsForm/store/models';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { SelectedCounterBox } from 'modules/subassignmentManagement/components/BulkOperations/SelectedCounterBox';
import { BulkEditSubassignmentSteps } from 'modules/subassignmentManagement/components/EditSubAssignment/store/model';
import { selectEditStep, selectIsLoadingEditPreInitialize } from 'modules/subassignmentManagement/components/EditSubAssignment/store/selectors';
import { bulkEditSubAssignmentPreInitializeActionV2 } from 'modules/subassignmentManagement/store/actions';
import { BulkEndPreInitializeV2Report } from 'modules/subassignmentManagement/components/BulkEndAssignment/BulkEndPreInitializeV2Report';
import { BulkEndPostInitializeV2Report } from 'modules/subassignmentManagement/components/BulkEndAssignment/BulkEndPostInitializeV2Report';
import { Status } from 'shared/models/Status';

const stepTitleMap: Partial<Record<BulkEditSubassignmentSteps, string>> = {
    [BulkEditSubassignmentSteps.Form]: '1. Bulk end assignments',
    [BulkEditSubassignmentSteps.EditVerify]: '2. Verify',
    [BulkEditSubassignmentSteps.EditPostReport]: '3. Report',
};

export const BulkEndAssignmentModal = () => {
    const dispatch = useDispatch();
    const assignmentsIds = useSelector(selectCheckedAssignmentIds);

    const step = useSelector(selectEditStep);
    const isOpened = useSelector(selectIsOpenModal);
    const isLoading = useSelector(selectIsLoadingEditPreInitialize);

    const onClose = useCallback(() => {
        dispatch(setOpenBulkEndAssignmentModal(false));
    }, [dispatch]);

    const onSubmit = useCallback((formValues: IEmployeeActionFormDataModel) => {
        const employeeActionForms = assignmentsIds.map(id => {
            const { eligibleForRehire, ...baseFormValues } = formValues;
            return {
                assignment_id: id,
                employee_action_form_data: {
                    ...baseFormValues,
                    eligible_for_rehire: eligibleForRehire === Status.active,
                },
            };
        });
        dispatch(bulkEditSubAssignmentPreInitializeActionV2.init({
            // @ts-ignore
            employee_action_forms: employeeActionForms,
            subassignments: [],
        }));
    }, [dispatch, assignmentsIds]);

    return (
        <ModalDialog
            title={stepTitleMap[step] || ''}
            isOpened={isOpened}
            onClose={onClose}
            colored
            modalProps={{
                showCloseIcon: true,
            }}
        >
            <Box>
                {step === BulkEditSubassignmentSteps.Form && (
                    <>
                        <SelectedCounterBox
                            count={assignmentsIds.length}
                            entityName="employee"
                        />
                        <BulkEndAssignmentForm
                            isLoading={isLoading}
                            onCancel={onClose}
                            // @ts-ignore
                            initialValues={{
                                employee_action_form_type: EafType.AssignmentEnded,
                                employee_action_form_reason_text: '',
                                effective_date: '',
                                eligibleForRehire: null,
                                do_not_send_employee_email: false,
                            }}
                            onSubmit={onSubmit}
                        />
                    </>
                )}
                {step === BulkEditSubassignmentSteps.EditVerify && <BulkEndPreInitializeV2Report />}
                {step === BulkEditSubassignmentSteps.EditPostReport && (
                    <BulkEndPostInitializeV2Report onClose={onClose} />
                )}
            </Box>
        </ModalDialog>
    );
};
