import { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPayRangeLoading, selectPayRanges } from 'store/entities/configuration/configurationSelectors';
import { getPayRangeByValue } from 'store/entities/configuration/configurationAction';

export function usePayRanges(
    jobOrderId: string | null,
) {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectPayRangeLoading);
    const payRanges = useSelector(selectPayRanges);
    const [lastRequestSyntheticToken, setLastRequestSyntheticToken] = useState<string | null>(null);

    const payRange = useMemo(() => {
        const found = (
            payRanges
                .find(item => item.position_value_id === jobOrderId)
        );
        return found || null;
    }, [jobOrderId, payRanges]);

    useEffect(() => {
        const requestSyntheticToken = `${jobOrderId}`;
        const shouldRequest = requestSyntheticToken !== lastRequestSyntheticToken;

        if (jobOrderId && !isLoading && shouldRequest && !payRange) {
            setLastRequestSyntheticToken(requestSyntheticToken);
            dispatch(getPayRangeByValue.init({
                job_order_id: jobOrderId,
            }));
        }
    }, [jobOrderId, lastRequestSyntheticToken, isLoading, dispatch, payRange]);

    return {
        payRange,
        isLoading,
    };
}
