import { Box } from '@material-ui/core';
import { Print } from '@material-ui/icons';
import { useFeature } from '@optimizely/react-sdk';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';
import { CreateCustomFieldValueButton } from 'modules/settings/submodules/clients/customFieldValues/components/CreateCustomFieldValue/CreateCustomFieldValueButton';
import { CustomFieldValuesTable } from 'modules/settings/submodules/clients/customFieldValues/components/CustomFieldValuesTable/CustomFieldValuesTable';
import { EditCustomFieldValueModal } from 'modules/settings/submodules/clients/customFieldValues/components/EditCustomFieldValue/EditCustomFieldValueModal';
import {
    getProjectClasses,
    setCustomFieldId,
} from 'modules/settings/submodules/clients/customFieldValues/store/actions';
import {
    selectCustomField,
    selectCustomFieldPrismField,
    selectExplicitSyncCustomFieldType,
    selectIsLoadingExportCustomFieldValues,
    selectIsReadonlyCustomFieldType,
} from 'modules/settings/submodules/clients/customFieldValues/store/selectors';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectCurrentClient, selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { exportCustomFieldValues, queryCustomFieldValues } from 'store/entities/customFields/actions';
import { FeatureSwitches } from 'utils/featureSwitches';
import ButtonWithLoader from '../../../../../../../shared/components/buttons/ButtonWithLoader';
import useActionButtonStyles from '../../../../../../clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/buttons/ActionButtonStyles';
import { useStyles } from '../../../styles';
import { SyncCustomFieldValueButton } from '../SyncCustomFieldValue/SyncCustomFieldValueButton';

export const CustomFieldValuePage = () => {
    const buttonClasses = useActionButtonStyles();
    const classes = useStyles();
    const { customFieldId } = useParams<{ customFieldId: string }>();
    const currentClientId = useSelector(selectCurrentClientId);
    const client = useSelector(selectCurrentClient);
    const customField = useSelector(selectCustomField);
    const [enableExplicitPositionsSync] = useFeature(FeatureSwitches.enableExplicitPositionsSync);
    const [enableCustomFieldsExport] = useFeature(FeatureSwitches.enableCustomFieldsExport);
    const isPrintLoading = useSelector(selectIsLoadingExportCustomFieldValues);
    const dispatch = useDispatch();
    const onPrintClick = useCallback(() => {
        dispatch(
            exportCustomFieldValues.init({
                client_id: client?.id,
                custom_field_id: customField?.id,
                filename: `${customField?.name}_${client?.name}.xlsx`,
            }),
        );
    }, [dispatch, client, customField]);

    useWithClientEffect(
        (dispatchWithClient, clientId) => {
            dispatchWithClient(setCustomFieldId(customFieldId));
            dispatchWithClient(
                queryCustomFieldValues.init({
                    client_id: clientId,
                    custom_field_id: customFieldId,
                }),
            );
            dispatchWithClient(getProjectClasses.init());
        },
        [customFieldId],
    );
    const isReadOnlyType = useSelector(selectIsReadonlyCustomFieldType);
    const isExplicitlySyncType = useSelector(selectExplicitSyncCustomFieldType);
    const prismFieldName = useSelector(selectCustomFieldPrismField);

    return (
        <>
            <Box display="flex" justifyContent="space-between"
                className={classes.controls}>
                <Box>
                    {!isReadOnlyType && (
                        <Box mb={2}>
                            <CreateCustomFieldValueButton />
                        </Box>
                    )}
                    {isExplicitlySyncType && prismFieldName && enableExplicitPositionsSync && (
                        <Box mb={2}>
                            <SyncCustomFieldValueButton
                                clientId={currentClientId}
                                fieldPrismId={prismFieldName ?? ''}
                                fieldId={customFieldId ?? ''}
                            />
                        </Box>
                    )}
                </Box>
                {enableCustomFieldsExport && (
                    <Box>
                        <ButtonWithLoader
                            classes={{ root: buttonClasses.default }}
                            onClick={() => onPrintClick()}
                            disabled={isPrintLoading}
                            isLoading={isPrintLoading}
                        >
                            <Print />
                        </ButtonWithLoader>
                    </Box>
                )}
            </Box>
            <CustomFieldValuesTable />
            <EditCustomFieldValueModal />
        </>
    );
};
