import React, { useMemo } from 'react';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { useSelector } from 'react-redux';
import PlainText from 'shared/components/table/Cells/PlainText';
import GridTable from 'shared/components/table/GridTable/GridTable';
import { useIsMobile } from 'shared/utils/hooks/media';
import {
    selectDealTypePayCodeArBuckets, selectDealTypePayCodeArBucketsAreLoading,
    selectDealTypesById,
} from 'store/entities/configuration/configurationSelectors';
import { IDealTypeArBucket } from 'shared/models/DealTypeArBucket';
import { selectPayCodesById } from '../../payCodes/store/selectors';

interface IDealTypeArBucketRow extends IDealTypeArBucket {
    className?: string;
}

export function PayCodeArBucketTable() {
    const tableClasses = useDefaultTableStyles();
    const isMobile = useIsMobile();
    const dealTypesById = useSelector(selectDealTypesById);
    const payCodesById = useSelector(selectPayCodesById);
    const dealTypesArBuckets = useSelector(selectDealTypePayCodeArBuckets);
    const isLoading = useSelector(selectDealTypePayCodeArBucketsAreLoading);

    const cells = useMemo(() => {
        return [
            {
                key: 'pay_code',
                title: 'Pay Code',
                render: function PayCode({ pay_code_id, className }: IDealTypeArBucketRow) {
                    const payCode = payCodesById[pay_code_id];
                    return (
                        <PlainText className={className} value={payCode?.description || ''} />
                    );
                },
            },
            {
                key: 'deal_type',
                title: 'DEAL TYPE',
                render: function DealType({ deal_type_id, className }: IDealTypeArBucketRow) {
                    const dealType = dealTypesById[deal_type_id];
                    return (
                        <PlainText className={ className } value={ dealType?.name } />
                    );
                },
            },
            {
                key: 'ar_bucket',
                title: 'AR Bucket',
                render: function ArBucket({ className, ar_bucket }: IDealTypeArBucketRow) {
                    return (
                        <PlainText className={ className } value={ ar_bucket } />
                    );
                },
            },
        ];
    }, [dealTypesById, payCodesById]);

    return (
        <GridTable
            rowData={dealTypesArBuckets}
            headerCellClassName={tableClasses.headCell}
            bodyCellClassName={tableClasses.bodyCell}
            className={tableClasses.tableBorder}
            getKey={deal => deal.id}
            cells={cells}
            stickyHeader
            hideHeader={isMobile}
            isLoading={isLoading}
        />
    );
}
