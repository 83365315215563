import { ClassNameMap } from '@material-ui/styles/withStyles';
import React, { useCallback } from 'react';
import { CircularProgress, TextField } from '@material-ui/core';
import { inputParams } from 'shared/styles/constants';

export const useRenderInput = ({
    error,
    isLoading,
    classes,
    inputRef,
}: {
    error?: string | boolean;
    isLoading?: boolean;
    classes?: ClassNameMap<any>;
    inputRef?: React.RefObject<HTMLInputElement>;
}) => {
    return useCallback((
        inputLabel: string,
        startAdornment?: React.ReactNode,
        endAdornment?: React.ReactNode,
        // eslint-disable-next-line react/display-name
    ) => (inputProps: any) => (
        <TextField
            error={error}
            {...inputProps}
            placeholder={inputLabel}
            variant="outlined"
            autoComplete="off"
            className={classes?.input}
            inputRef={inputRef}
            inputProps={{
                ...inputParams,
                ...inputProps.inputProps,
                autocomplete: 'off',
            }}
            InputProps={{
                ...inputProps.InputProps,
                ...inputParams,
                endAdornment: endAdornment ? endAdornment : (
                    <>
                        {isLoading ? <CircularProgress color="primary" size={16} /> : null}
                        {inputProps.InputProps.endAdornment}
                    </>
                ),
                startAdornment: (
                    <>
                        {inputProps.InputProps.startAdornment}
                        {startAdornment}
                    </>
                ),
            }}
        />
    ), [error, isLoading, inputRef, classes]);
};
