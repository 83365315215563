import { ISheetsForPostPayroll, ISheetsForPrePayroll } from '../../../store/model';
import PlainText from 'shared/components/table/Cells/PlainText';
import { getLastFirstName } from 'shared/utils/converters/user';
import React from 'react';
import { IUsersById } from 'store/entities/users/reducers';
import { ItemsById } from 'shared/models/ItemsById';
import { IClient } from 'store/entities/clients/clientsModel';
import clsx from 'clsx';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { IGridCellProps } from 'shared/components/table/GridTable/GridTableRow';

export const employeeCell = (allUsers: IUsersById) => ({
    key: 'employeeUserId',
    title: 'Employee',
    render: function EmployeeUserId({ employeeUserId, className }: IGridCellProps<ISheetsForPrePayroll>) {
        return (
            <PlainText className={className} value={getLastFirstName(allUsers[employeeUserId])} />
        );
    },
});

export const payPeriodCell = () => ({
    key: 'payPeriod',
    title: 'Pay period',
    width: '1.5fr',
    render: function PayPeriod({ payPeriod, className }: IGridCellProps<ISheetsForPrePayroll>) {
        return (
            <PlainText className={className} value={payPeriod} />
        );
    },
});

export const deductionPeriodCell = () => ({
    key: 'deductPeriod',
    title: 'deduction period',
    render: function PayPeriod({ deductPeriod, className }: IGridCellProps<ISheetsForPrePayroll>) {
        return (
            <PlainText className={className} value={deductPeriod} />
        );
    },
});

export const clientCell = (allClient: ItemsById<IClient>) => ({
    key: 'clientId',
    title: 'Client',
    render: function ClientId({ clientId, className }: IGridCellProps<ISheetsForPrePayroll>) {
        const client = allClient[clientId];
        return (
            <PlainText className={className} value={client ? client.name : ''} />
        );
    },
});

export const timeRtHoursTimeRtDollarsCell = (classes: Record<string, string>) => ({
    key: 'timeRtHoursTimeRtDollars',
    title: 'Reg hrs | pay',
    headerClassName: classes.pullRight,
    render: function TimeRtHoursTimeRtDollars({
        timeRtHours: hours,
        timeRtDollars: time,
        className,
    }: IGridCellProps<ISheetsForPrePayroll>) {
        return (
            <PlainText className={clsx(className, classes.pullRight)} value={`${hours} | ${formatDollars(time)}`} />
        );
    },
});

export const timeOtHoursTimeOtDollarsCell = (classes: Record<string, string>) => ({
    key: 'timeOtHoursTimeOtDollars',
    title: 'Ot hrs | pay',
    headerClassName: classes.pullRight,
    render: function TimeOtHoursTimeOtDollars({
        timeOtHours: hours,
        timeOtDollars: time,
        className,
    }: IGridCellProps<ISheetsForPrePayroll>) {
        return (
            <PlainText className={clsx(className, classes.pullRight)} value={`${hours} | ${formatDollars(time)}`} />
        );
    },
});

export const timeHlHoursTimeHlDollarsCell = (classes: Record<string, string>) => ({
    key: 'timeHlHoursTimeOtDollarsCell',
    title: 'holiday hrs | pay',
    headerClassName: classes.pullRight,
    render: function TimeHolidayHoursTimeHolidayDollars({
        timeHolidayHours: hours,
        timeHolidayDollars: time,
        className,
    }: IGridCellProps<ISheetsForPrePayroll>) {
        return (
            <PlainText className={clsx(className, classes.pullRight)} value={`${hours} | ${formatDollars(time)}`} />
        );
    },
});

export const timeFilesAndDollarsCell = (classes: Record<string, string>) => ({
    key: 'timeFilesAndDollars',
    title: 'files | pay',
    headerClassName: classes.pullRight,
    render: function TimeFilesAndDollars({
        timeFiles,
        timeFilesDollars,
        className,
    }: IGridCellProps<ISheetsForPrePayroll>) {
        return (
            <PlainText className={clsx(className, classes.pullRight)} value={`${timeFiles} | ${formatDollars(timeFilesDollars)}`} />
        );
    },
});

export const timeSalariedCell = (classes: Record<string, string>) => ({
    key: 'timeFilesAndDollars',
    title: 'salaried | pay',
    headerClassName: classes.pullRight,
    render: function TimeFilesAndDollars({
        timeSalariedHours,
        timeSalariedDollars,
        className,
    }: IGridCellProps<ISheetsForPrePayroll>) {
        return (
            <PlainText className={clsx(className, classes.pullRight)} value={`${timeSalariedHours} | ${formatDollars(timeSalariedDollars)}`} />
        );
    },
});

export const expenseSheetCell = (classes: Record<string, string>) => ({
    key: 'expenseSheetId',
    title: 'Expenses',
    headerClassName: classes.pullRight,
    render: function ExpenseSheetId({ expenseTotalDollars: expense, className }: IGridCellProps<ISheetsForPrePayroll>) {
        return (
            <PlainText className={clsx(className, classes.pullRight)} value={formatDollars(expense)} />
        );
    },
});

export const expenseTotalMilesCell = (classes: Record<string, string>) => ({
    key: 'expenseTotalMiles',
    title: 'Miles',
    headerClassName: classes.pullRight,
    render: function ExpenseTotalMiles({ expenseTotalMiles: miles, className }: IGridCellProps<ISheetsForPrePayroll>) {
        return (
            <PlainText className={clsx(className, classes.pullRight)} value={miles} />
        );
    },
});

export const totalDollarsCell = (classes: Record<string, string>) => ({
    key: 'totalDollars',
    title: 'Total pay',
    headerClassName: classes.pullRight,
    render: function TotalDollars({ totalDollars: total, className }: IGridCellProps<ISheetsForPrePayroll>) {
        return (
            <PlainText className={clsx(className, classes.pullRight)} value={formatDollars(total)} />
        );
    },
});

export const failedReasonCell = (classes: Record<string, string>) => ({
    key: 'failedReason',
    title: 'Failed Reason',
    headerClassName: classes.pullRight,
    width: '1.5fr',
    render: function FailedReason({ failedReason, className }: IGridCellProps<ISheetsForPostPayroll>) {
        return (
            <PlainText
                className={clsx(className, classes.pullRight)}
                // @ts-ignore
                value={failedReason}
            />
        );
    },
});
