import clsx from 'clsx';
import { settingsRoutes } from 'modules/settings/routes';
import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import PlainText from 'shared/components/table/Cells/PlainText';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';

interface IClientTableCellProps {
    value: string;
    className?: string;
    id: string;
}

export const ClientTableCell = ({ value, className, id }: IClientTableCellProps) => {
    const tableClasses = useDefaultTableStyles();
    return (
        <Link
            to={generatePath(settingsRoutes.CLIENTS.DASHBOARD, { clientId: id })}
            className={clsx(tableClasses.linkCell, className)}
        >
            <PlainText className={tableClasses.linkCellContent} value={value} />
        </Link>
    );
};
