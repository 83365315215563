import React from 'react';
import clsx from 'clsx';
import PlainText from 'shared/components/table/Cells/PlainText';
import { IPayrollSheetGroupedRow, TableClasses } from 'modules/payrollProcessorHub/components/PayrollSheetTable/model';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { max } from 'lodash-es';
import {
    usePayrollSheetGroupSubassignments,
} from 'modules/payrollProcessorHub/components/PayrollSheetTable/hooks/usePayrollSheetGroupSubassignments';

interface IApprovedLevelCellPureProps {
    className?: string;
    tableClasses: TableClasses;
    approvedCount: number;
    totalApprovers: number;
}

const ApprovedLevelCellPure = ({
    className,
    tableClasses,
    approvedCount,
    totalApprovers,
}: IApprovedLevelCellPureProps) => {
    const classes = clsx(className, tableClasses.pullRight);
    return (
        <PlainText className={classes} value={`${approvedCount}/${totalApprovers}`} />
    );
};

export function makeApprovedLevelGroupedCell(tableClasses: TableClasses) {
    return separateLogicDecorator<IPayrollSheetGroupedRow, IApprovedLevelCellPureProps>(row => {
        const subassignments = usePayrollSheetGroupSubassignments(row);
        const managersCountByAssignmentList = subassignments?.map(
            subassignment => subassignment?.managers?.length || 0,
        );
        const totalApprovers = max(managersCountByAssignmentList || []) || 1;
        return {
            ...row,
            tableClasses,
            approvedCount: row.approvedLevel,
            totalApprovers,
        };
    })(ApprovedLevelCellPure);
}
