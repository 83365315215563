import { useFeature } from '@optimizely/react-sdk';
import React, { useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { updateClientConfiguration } from 'store/entities/clients/clientsAction';
import { EafSettingsForm, IEafSettingsFormValues } from 'modules/settings/submodules/clients/clientAssignmentSetup/submodules/eafSettings/EafSettingsForm';
import { selectCurrentClientId, selectIsClientConfigurationUpdating } from 'store/entities/clients/selectors/clientsSelectors';
import { selectClientEafRetroEnabled, selectClientHideSubassignments } from 'store/entities/clients/selectors/configurationSelectors';
import { FeatureSwitches } from 'utils/featureSwitches';

export const EafSettings = () => {
    const dispatch = useDispatch();
    const clientId = useSelector(selectCurrentClientId);

    const hideSubassignments = useSelector(selectClientHideSubassignments);
    const showRetro = useSelector(selectClientEafRetroEnabled);
    const initialState = useMemo(() => ({
        hideSubassignments,
        showRetro,
    }), [hideSubassignments, showRetro]);
    const [enableEafRetro] = useFeature(FeatureSwitches.enableEafRetro);

    const isUpdating = useSelector(selectIsClientConfigurationUpdating);
    const handleUpdate = useCallback((values: IEafSettingsFormValues) => {
        dispatch(updateClientConfiguration.init({
            clientId,
            hide_subassignments: values.hideSubassignments,
            eaf_retro: enableEafRetro ? { enabled: values.showRetro } : undefined,
        }));
    }, [clientId, dispatch, enableEafRetro]);

    return (
        <Formik
            initialValues={initialState}
            onSubmit={handleUpdate}
            enableReinitialize
        >
            {props => (
                <EafSettingsForm
                    {...props}
                    isLoading={isUpdating}
                />
            )}
        </Formik>
    );
};
