import React from 'react';
import { Formik, FormikProps } from 'formik';
import { Paper, Box, Typography, FormHelperText } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
    registrationInitialValues,
    IResettingPasswordFormValues,
} from 'shared/components/auth/forms/models';
import AuthWrapper from 'shared/components/auth/AuthWrapper';

import { useStyles } from './AuthStyles';
import { passwordResetting } from '../../../store/components/resetPassword/actions';
import FormField from '../formFields/FormField';
import ButtonWithLoader from '../buttons/ButtonWithLoader';
import * as yup from 'yup';
import { ValidationMessages } from '../../models/Validation';
import {
    selectResettingPasswordIsLoading,
    selectResettingPasswordResult,
} from 'store/components/resetPassword/selectors';
import { routes } from '../../routes';
import { AuthFooter } from './components/AuthFooter';
import { selectAuthEmail } from '../../../store/components/auth/selectors';
import { Logo } from './components/Logo';
import { Maintaince } from '../alerts/Maintaince';

export const resettingPasswordValidationSchema = yup.object().shape({
    email: yup.string().required(ValidationMessages.REQUIRED).email('Please enter valid email'),
});

export default function ResetPassword() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const isResetting = useSelector(selectResettingPasswordIsLoading);
    const userEmail = useSelector(selectAuthEmail);
    const userResult = useSelector(selectResettingPasswordResult);

    const onReset = (values: IResettingPasswordFormValues) => {
        dispatch(passwordResetting.init({
            email: values.email,
        }));
    };

    return (
        <AuthWrapper>
            <Maintaince />
            <Paper className={classes.block}>
                <Box className={classes.logoContainer}>
                    <Logo />
                </Box>
                <Box mt={3}>
                    <Typography>Forgot your password?</Typography>
                </Box>
                <Formik
                    initialValues={{
                        email: userEmail || registrationInitialValues.email,
                    }}
                    validationSchema={resettingPasswordValidationSchema}
                    onSubmit={onReset}
                    validateOnBlur={false}
                >
                    {(props: FormikProps<IResettingPasswordFormValues>) => (
                        <form
                            onSubmit={props.handleSubmit}
                            className={classes.form}
                            autoComplete="off"
                        >
                            <FormField
                                name="email"
                                label="Email"
                                className={classes.input}
                            />
                            <Box
                                mt={4}
                                mb={2}
                                display="flex"
                                justifyContent="center"
                            >
                                <ButtonWithLoader
                                    isLoading={isResetting}
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    className={classes.submit}
                                >
                                    Reset password
                                </ButtonWithLoader>
                            </Box>
                            {userResult && (
                                <FormHelperText className={classes.userMessage} error={userResult.isError}>
                                    {userResult.message}
                                </FormHelperText>
                            )}
                        </form>
                    )}
                </Formik>
            </Paper>
            <AuthFooter label="Try to login again or register new account" route={routes.AUTH.RESET_PASSWORD} />
        </AuthWrapper>
    );
}
