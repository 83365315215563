import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setCreatePhysicalDemandsTemplateModalState } from 'modules/settings/submodules/clients/physicalDemands/store/action';
import CreatePhysicalDemandsModal from 'modules/settings/submodules/clients/physicalDemands/CreatePhysicalDemands/CreatePhysicalDemandsModal';
import { AddTemplateButton } from 'modules/settings/submodules/clients/components/PhysicalDemandsAndWorkingConditions/AddTemplate/AddTemplateButton';

export const CreatePhysicalDemandsButton = () => {
    const dispatch = useDispatch();

    const openModal = useCallback(() => {
        dispatch(setCreatePhysicalDemandsTemplateModalState(true));
    }, [dispatch]);
    return (
        <>
            <AddTemplateButton onClick={openModal} />
            <CreatePhysicalDemandsModal />
        </>
    );
};
