import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { BackgroundChecksTemplateTable } from 'modules/settings/submodules/clients/backgroundCheckTemplates/BackgroundCheckTable/BackgroundChecksTemplateTable';
import { CreateBackgroundCheckButton } from 'modules/settings/submodules/clients/backgroundCheckTemplates/CreateBackgroundCheck/CreateBackgroundCheckButton';
import EditBackgroundCheckModal from 'modules/settings/submodules/clients/backgroundCheckTemplates/EditBackgroundCheck/EditBackgroundCheckModal';
import { useDispatch, useSelector } from 'react-redux';
import { getBackgroundCheckTemplates } from 'store/entities/configuration/configurationAction';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';

export const BackgroundCheckPage = () => {

    const clientId = useSelector(selectCurrentClientId);
    const dispatch = useDispatch();
    useEffect(() => {
        if (clientId) {
            dispatch(getBackgroundCheckTemplates.init());
        }
    }, [dispatch, clientId]);

    return (
        <>
            <Box mb={2}>
                <CreateBackgroundCheckButton />
            </Box>
            <BackgroundChecksTemplateTable />
            <EditBackgroundCheckModal />
        </>
    );
};
