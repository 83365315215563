import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { useSelector } from 'react-redux';
import PlainText from 'shared/components/table/Cells/PlainText';
import { getLastFirstName } from 'shared/utils/converters/user';
import { selectUserById } from 'store/entities/users/selectors';

export interface IUserCellProps {
    user_id?: string;
    className?: string;
}

export const UserCell = ({ user_id, className }: IUserCellProps) => {
    const user = useSelector(selectUserById(user_id));

    if (!user) {
        return (
            <Box className={className}>
                <Skeleton
                    variant="text"
                    width="100px"
                    height="18px"
                />
            </Box>
        );
    }
    return (
        <PlainText className={className} value={getLastFirstName(user)} />
    );
};
