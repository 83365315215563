import { ConfigurationRoutes } from 'modules/settings/submodules/clients/appConfiguration/ConfigurationRoutes';
import { AssignmentsReportDataPage } from 'modules/settings/submodules/clients/assignmentsReportData/AssignmentsReportDataPage';
import { ReportRoutes } from 'modules/settings/submodules/clients/reports/ReportRoutes';
import { CommonSettingsSideMenu } from 'modules/settings/submodules/components/CommonSettingsSideMenu/CommonSettingsSideMenu';
import { BackgroundCheckPage } from 'modules/settings/submodules/clients/backgroundCheckTemplates/BackgroundCheckPage';
import React, { useEffect } from 'react';
import { Switch, useParams, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { ClientActivitiesList } from 'modules/settings/submodules/clients/activities/ClientActivitiesList';
import { ClientDetailSideMenu } from 'modules/settings/submodules/clients/ClientDetailSideMenu';
import { ClientPayCodesList } from 'modules/settings/submodules/clients/payCodes/ClientPayCodesList';
import { settingsRoutes } from 'modules/settings/routes';
import PrivateRoute from 'shared/components/auth/PrivateRoute';
import { ClientSettingsDashboard } from 'modules/settings/submodules/clients/ClientSettingsDashboard';
import { ClientEditPage } from 'modules/settings/submodules/clients/components/EditClient/ClientEditPage';
import { PhysicalDemandsPage } from 'modules/settings/submodules/clients/physicalDemands/PhysicalDemandsPage';
import MainContent from 'shared/components/common/MainContent';
import { useIsMobile } from 'shared/utils/hooks/media';
import { setClientId } from 'store/entities/clients/clientsAction';
import { selectCurrentClientHasJobNumberConfiguration } from 'store/entities/clients/selectors/timeAndPaySelectors';
import { useStyles } from './styles';
import { WorkingConditionsPage } from 'modules/settings/submodules/clients/workingConditions/WorkingConditionsPage';
import { PaySettingsPage } from 'modules/settings/submodules/clients/paySettings/PaySettingsPage';
import { ControllingOrgPage } from 'modules/settings/submodules/clients/controllingOrg/ControllingOrgPage';
import { SubmittingOrgPage } from 'modules/settings/submodules/clients/submittingOrg/SubmittingOrgPage';
import { CostCentersPage } from 'modules/settings/submodules/clients/costCenters/CostCentersPage';
import { DealsPage } from 'modules/settings/submodules/clients/deals/DealsPage';
import { JobNumberPage } from 'modules/settings/submodules/clients/jobNumber/JobNumberPage';
import { EditClientLogo } from 'modules/settings/submodules/clients/components/EditClientLogo/EditClientLogo';
import { ClientAssignmentSetupRoutes } from 'modules/settings/submodules/clients/clientAssignmentSetup/ClientAssignmentSetupRoutes';
import { PayCodeArBucketPage } from '../payCodeArBucket/PayCodeArBucketPage';
import { DealTypePage } from '../dealType/DealTypePage';
import NotFound from 'shared/components/notFound/NotFound';

export const ClientsSettingsDetailsRoutes = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { clientId } = useParams<{ clientId: string }>();

    const isDashboard = useRouteMatch({
        path: settingsRoutes.CLIENTS.DASHBOARD,
        exact: true,
    });
    const isMobile = useIsMobile();

    useEffect(() => {
        dispatch(setClientId(clientId));
        return () => {
            dispatch(setClientId(null));
        };
    }, [dispatch, clientId]);
    const hasGenworthSpecificModules = useSelector(selectCurrentClientHasJobNumberConfiguration);

    return (
        <Box
            display="flex"
            flexDirection="row"
            className={classes.container}
        >
            {!isMobile && (isDashboard ? (<CommonSettingsSideMenu />) : (<ClientDetailSideMenu />))}
            <Box className={classes.content}>
                <MainContent whiteBackground>
                    <Switch>
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.GENERAL}
                            component={ClientEditPage}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.CONTROLLING_ORG}
                            component={hasGenworthSpecificModules ? ControllingOrgPage : NotFound}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.SUBMITTING_ORG}
                            component={hasGenworthSpecificModules ? SubmittingOrgPage : NotFound}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.COST_CENTERS}
                            component={hasGenworthSpecificModules ? CostCentersPage : NotFound}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.DEALS}
                            component={hasGenworthSpecificModules ? DealsPage : NotFound}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.JOB_NUMBER}
                            component={hasGenworthSpecificModules ? JobNumberPage : NotFound}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.PAYCODE_DEAL_TYPE_AR_BUCKET}
                            component={hasGenworthSpecificModules ? PayCodeArBucketPage : NotFound}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.DEAL_TYPE}
                            component={hasGenworthSpecificModules ? DealTypePage : NotFound}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.ASSIGNMENTS_REPORT_DATA}
                            component={hasGenworthSpecificModules ? AssignmentsReportDataPage : NotFound}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.APP_CONFIGURATION.ROOT}
                            component={ConfigurationRoutes}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.PAYCODES}
                            component={ClientPayCodesList}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.PAY_SETTINGS}
                            component={PaySettingsPage}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.ACTIVITIES}
                            component={ClientActivitiesList}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.PHYSICAL_DEMANDS}
                            component={PhysicalDemandsPage}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.WORKING_CONDITIONS}
                            component={WorkingConditionsPage}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.BACKGROUND_CHECKS}
                            component={BackgroundCheckPage}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.ASSIGNMENT_SETUP.ROOT}
                            component={ClientAssignmentSetupRoutes}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.REPORTS.ROOT}
                            component={ReportRoutes}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.DASHBOARD}
                            component={ClientSettingsDashboard}
                        />
                    </Switch>
                </MainContent>
                <EditClientLogo />
            </Box>
        </Box>
    );
};
