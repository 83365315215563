import React, { useCallback } from 'react';
import InfinityGridTable from 'shared/components/table/GridTable/InfinityGridTable';
import { useTableStyles } from 'shared/styles/tableStyles';
import {
    ISubassignmentRow,
    ISubassignmentTablePureProps,
} from 'modules/subassignmentManagement/components/SubassignmentTable/model';
import { useIsMobile } from 'shared/utils/hooks/media';
import {
    SubassignmentTableGroupHeader,
} from 'modules/subassignmentManagement/components/SubassignmentTable/SubassignmentTableGroupHeader';

export const SubassignmentInfinityTablePure = ({
    selectedSubassignment,
    expandedGroupId,
    expandAll,
    ...props
}: ISubassignmentTablePureProps) => {
    const tableClasses = useTableStyles();

    const getRowClasses = useCallback((row: ISubassignmentRow) => {
        if (selectedSubassignment?.id === row.id) {
            return [tableClasses.highlightedRow];
        }
        return [];
    }, [tableClasses, selectedSubassignment?.id]);
    const isMobile = useIsMobile();

    const getExpandedGroup = useCallback(group => {
        if (expandAll) {
            return expandAll;
        }
        return group.id === expandedGroupId;
    }, [expandAll, expandedGroupId]);

    return (
        <InfinityGridTable
            {...props}
            rowData={[]}
            getKey={row => row.id}
            hideHeader={isMobile}
            renderGroupHeader={SubassignmentTableGroupHeader}
            calculateIsGroupExpanded={getExpandedGroup}
            displayDefaultGroupExpandButton={false}
            headerCellClassName={tableClasses.headerLightGrayCell}
            getRowClasses={getRowClasses}
            infiniteScrollProps={{
                scrollableTarget: props.containerId,
            }}
        />
    );
};
