import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AttachmentsPreviewSecure } from 'shared/components/attachments/AttachmentsPreviewSecure';
import { loadAttachments } from 'shared/components/attachments/store/actions';
import { IAttachment } from 'shared/models/Attachments';

export interface IAttachmentsPreviewProps {
    open: boolean;
    onClose: () => void;
    onDelete?: (file: IAttachment) => void;
    files: IAttachment[];
}

export function AttachmentsPreviewModal(props: IAttachmentsPreviewProps) {
    const dispatch = useDispatch();
    useEffect(() => {
        props.files.forEach(file => {
            dispatch(loadAttachments.init(file.id));
        });
    }, [dispatch, props.files]);
    return (<AttachmentsPreviewSecure {...props} />);
}
