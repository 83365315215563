import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { ISummaryBlockData } from 'modules/payrollProcessorHub/components/SheetSummary/SheetSummary';
import { SummaryBlock } from 'modules/payrollProcessorHub/components/SheetSummary/SummaryBlock';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import useTotalInfoTabStyles
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/totalInfoTab/TotalInfoTabStyles';

interface IMobileSheetSummaryProps {
    blocks: ISummaryBlockData[];
}

export const MobileSheetSummary = ({ blocks }: IMobileSheetSummaryProps) => {
    const classes = useTotalInfoTabStyles();

    const [page, setPage] = useState(0);
    const onChangePage = () => {
        const nextPage = page + 1 >= blocks.length ? 0 : page + 1;
        setPage(nextPage);
    };
    const block = blocks[page];

    return (
        <Box className={classes.container}>
            <Box onClick={onChangePage}>
                {block && (
                    <SummaryBlock
                        value={block.value}
                        title={block.title}
                        color={block.color}
                    />
                )}
            </Box>
            <Box className={classes.containerFiber}>
                {blocks.map((_, index) => index === page ? (
                    <FiberManualRecordRoundedIcon key={index} className={classes.fiber} />
                ) : (
                    <FiberManualRecordOutlinedIcon key={index} className={classes.fiber} />
                ))}
            </Box>
        </Box>
    );
};
