import { selectEafReasons } from 'modules/employeeActionsForm/store/selectors';
import React from 'react';
import { EafApprovalCellProps } from 'modules/eafApproval/store/model/types';
import { EafStatusSlug, IEafReason } from 'modules/employeeActionsForm/store/models';
import { useSelector } from 'react-redux';
import PlainText from 'shared/components/table/Cells/PlainText';

export const EafApprovalGridCellReason: React.FC<EafApprovalCellProps> = ({
    className,
    status,
    approval_rejection_reason,
    employee_action_form_data,
}: EafApprovalCellProps) => {
    const reasons = useSelector(selectEafReasons);

    const reason = reasons.find(
        // @ts-ignore
        (eafReason: IEafReason) => eafReason.key === employee_action_form_data.employee_action_form_reason,
    );
    const text = status.slug === EafStatusSlug.ApprovalRejected ? approval_rejection_reason : reason?.text;

    return (
        <PlainText value={text || ''} className={className} />
    );
};
