import { orderBy } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { Publish } from '@material-ui/icons';
import { AttachmentGateway } from 'shared/components/attachments/AttachmentGateway';
import EntryAttachmentsPreview from 'shared/components/attachments/EntryAttachmentsPreview';
import { useLoadSecureSheetAttachments } from 'shared/components/attachments/hooks';
import { selectExpenseEntry } from 'store/entities/timesheet/selectors';
import { useUploadStyles } from './styles';
import clsx from 'clsx';
import { Typography, Button } from '@material-ui/core';
import { useModal } from 'shared/utils/hooks/useModal';
import { useSelector } from 'react-redux';
import { IStore } from 'store/configureStore';
import { AttachmentsState } from 'store/entities/timesheet/reducers/sheetEntryAttachments';
import { IEntryAttachment, IPreviewTypes } from 'shared/models/Attachments';

interface IEntryFilesUploaderProps {
    fileTypes: string;
    maxSize: number;
    customClasses?: string;
    onAttachmentChange: (files: IPreviewTypes[], entryId?: string) => void;
    onAttachmentRemove: (file: IEntryAttachment, entryId: string) => void;
    entryId?: string;
}

export default function EntryFilesUploader(
    {
        fileTypes,
        maxSize,
        customClasses,
        onAttachmentChange,
        onAttachmentRemove,
        entryId,
    }: IEntryFilesUploaderProps,
) {
    const classes = useUploadStyles();

    const {
        isModalOpened: isPreviewOpen,
        onModalOpen: onPreviewOpen,
        onModalClose: onPreviewClose,
    } = useModal();

    const onDrop = useCallback(acceptedFiles => {
        onPreviewClose();
        onAttachmentChange(acceptedFiles, entryId);
    }, [entryId, onAttachmentChange, onPreviewClose]);

    const {
        getRootProps, getInputProps, isDragReject, rejectedFiles,
    } = useDropzone({
        accept: fileTypes,
        onDrop,
        minSize: 0,
        maxSize: maxSize,
    });

    const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles.some(file => file.size > maxSize);

    const { attachments } = useSelector<IStore, AttachmentsState>(state => state.sheetEntryAttachments);

    const entry = useSelector(selectExpenseEntry(entryId)) ?? null;

    const orderedAttachments = useMemo(() => {
        //prefer to display images
        return orderBy(
            [...attachments, ...(entry?.sheet_entry_attachments || [])],
            attachment => {
                if (attachment.mimetype !== 'application/pdf') {
                    return 0;
                }
                return 1;
            },
        );
    }, [attachments, entry]);
    useLoadSecureSheetAttachments(orderedAttachments);

    return (
        <>
            {(orderedAttachments.length > 0) && (
                <section className={classes.preview}>
                    <div className = {classes.filePreviewZone}>
                        <AttachmentGateway attachment={orderedAttachments[0]} />
                        <Button
                            className = {classes.imageButton}
                            color="inherit"
                            onClick={onPreviewOpen}>
                            See All [{orderedAttachments.length}]
                        </Button>
                    </div>
                </section>
            )}

            {entry && (orderedAttachments.length > 0) && isPreviewOpen && (
                <EntryAttachmentsPreview
                    open={isPreviewOpen}
                    onClose={onPreviewClose}
                    files={orderedAttachments}
                    onDelete={attachment => onAttachmentRemove(attachment, entry.id)}
                />
            )}

            <section className={clsx(classes.fileUpload, customClasses)}>
                <div {...getRootProps()} className={classes.fileDropzone}>
                    <div className={classes.uploadHeader}>
                        <input {...getInputProps()} />
                        <Publish fontSize="small" classes={{ root: classes.uploadIcon }} />
                        <Typography className={classes.uploadHeaderTitle}
                            color="primary"
                            variant="subtitle2"
                        >
                                Upload Receipt
                        </Typography>
                    </div>
                    <Typography color="textSecondary" variant="caption">a file up to 10MB</Typography>
                    {isDragReject && (
                        <Typography className={classes.uploadError}
                            color="primary"
                            variant="body1"
                        >
                        Please upload image or a pdf file.
                        </Typography>
                    )}
                    {isFileTooLarge && (
                        <Typography className={classes.uploadError}
                            color="primary"
                            variant="body1"
                        >
                        File is too large.
                        </Typography>
                    )}
                </div>
            </section>
        </>
    );
}
