import React from 'react';
import { Box } from '@material-ui/core';
import { useGroupedSheetDetailStyles } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/styles';
import { ApproveButtonBatch } from 'modules/payrollProcessorHub/components/SheetStatusAction/ApproveButton';
import { RejectButtonBatch } from 'modules/payrollProcessorHub/components/SheetStatusAction/RejectButton';
import { ICalculationGroupSidebarActionsProps } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/components/calculationGroup/batch/ICalculationGroupSidebarActionsProps';
import { useSelector } from 'react-redux';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';

export const CalcGroupSubmittedActions = ({ calculationGroup }: ICalculationGroupSidebarActionsProps) => {
    const classes = useGroupedSheetDetailStyles();
    const userHasPermission = useSelector(selectIsUserHasPermission(Permission.CanApprovePphSheet));
    if (!userHasPermission) {
        return null;
    }

    return (
        <Box className={classes.actions}>
            <ApproveButtonBatch calculationGroups={[calculationGroup]} />

            <RejectButtonBatch calculationGroups={[calculationGroup]} />
        </Box>
    );
};
