/* eslint-disable react/display-name */
import React from 'react';
import clsx from 'clsx';
import { ScaWithTooltip } from 'shared/components/customFieldValues/ScaWithTooltip/ScaWithTooltip';
import { calculateMiles } from 'shared/components/formSpecialFields/expenseData/utils';
import PlainText from 'shared/components/table/Cells/PlainText';
import { NoteCell, desktopCells } from 'shared/components/table/EntriesTable/cells';
import { QuickEditBreak } from 'shared/components/table/EntriesTable/cellsComponents/quickEditBreak/QuickEditBreak';
import QuickEditEntry from 'shared/components/table/EntriesTable/cellsComponents/QuickEditEntry';
import { useEntriesTableStyles } from 'shared/components/table/EntriesTable/EntriesTableStyles';
import { IEntryRow } from 'shared/components/table/EntriesTable/model';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import {
    EntryType,
    IBreakEntryDataBackend,
    IInOutBreakDataBackend,
    IInOutEntryDataBackend,
    IInOutMealBreakDataBackend,
    ITimeEntry,
    QuantityType,
} from 'shared/models/sheet/Sheet';
import { getLastFirstName } from 'shared/utils/converters/user';
import { completesByNotes } from 'shared/utils/counters/completesCounter';
import { totalDollars } from 'shared/utils/counters/dollarCounter';
import { totalBreakTime, totalFileTimePayment, totalTime } from 'shared/utils/counters/timeCounter';
import { activityHasCompletes } from 'shared/utils/formatters/activityFormatter';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { formatMinutes } from 'shared/utils/formatters/formatMinutesAndHours';
import { useFormattedPayRate } from 'shared/utils/formatters/payRate';
import { EntryColumnSlug } from 'store/entities/clients/clientsModel';
import { useCurrentClientMileageRate } from 'shared/models/Miles';
import { printDurationTimeFromInOutEntryData, transformBackendDateTimeToTime } from 'shared/models/DateTime';
import { isEntryAllowsBreak, isEntryAllowsTimeInTimeOut } from 'store/entities/timesheet/models/Entry';
import TimeInOutQuickEdit from 'shared/components/table/EntriesTable/cellsComponents/quickEdit/TimeInOutQuickEdit';
import { Box } from '@material-ui/core';
import { formatFiles } from 'shared/utils/formatters/timePaymentFormatter';

const isRowsWithoutTimeEntries = (rows: IEntryRow[]): boolean => !rows.find(({ entry }) => (
    entry.entry_type === EntryType.TIME && entry.data.entry_type !== QuantityType.FILE
));

const isRowsContainsBreakMeals = (rows: IEntryRow[]): boolean => Boolean(rows.find(({ entry }) => (
    entry.entry_type === EntryType.TIME && entry.data.entry_type === QuantityType.TIME_IN_OUT_MEAL_BREAK
)));

const getBreakCellTitle = (placeholder: string) => ({ rows }: { rows: IEntryRow[] }) => {
    if (isRowsWithoutTimeEntries(rows)) {
        return '';
    }
    if (isRowsContainsBreakMeals(rows)) {
        return 'meal break';
    }
    return placeholder;
};

const getFormattedMealBreak = (data: IInOutMealBreakDataBackend) => {
    let value = '';
    const {
        break_time_in: breakTimeIn,
        break_time_out: breakTimeOut,
    } = data;
    if (breakTimeIn && breakTimeOut) {
        const startTime = transformBackendDateTimeToTime(breakTimeIn);
        const endTime = transformBackendDateTimeToTime(breakTimeOut);
        value = `${startTime} - ${endTime}`;
    }
    return value;
};

const getRowsWithCompletedInterviews = (rows: IEntryRow[]): number => rows.filter(
    row => activityHasCompletes(row.activity) && completesByNotes(row.entry?.notes || ''),
).length;

export const entryCellDictionary: Record<EntryColumnSlug, (
    placeholder: string,
    classes: ReturnType<typeof useEntriesTableStyles>,
    additionalData?: any,
) => ICellInfo<IEntryRow>> = {
    [EntryColumnSlug.Assignment]: placeholder => ({
        ...desktopCells.assignmentCell,
        title: placeholder,
    }),
    [EntryColumnSlug.AssignmentProject]: placeholder => ({
        ...desktopCells.assignmentCell,
        title: placeholder,
    }),
    [EntryColumnSlug.Task]: placeholder => ({
        ...desktopCells.taskCell,
        title: placeholder,
    }),
    [EntryColumnSlug.Activity]: (placeholder: string) => ({
        ...desktopCells.activityCell,
        width: '15%',
        title: placeholder,
    }),
    [EntryColumnSlug.ZipCode]: (placeholder: string) => desktopCells.getScaZipCell(placeholder),
    [EntryColumnSlug.Receipt]: placeholder => ({
        ...desktopCells.receiptCell,
        title: placeholder,
    }),
    [EntryColumnSlug.Amount]: (placeholder, classes: ReturnType<typeof useEntriesTableStyles>) => ({
        key: placeholder,
        title: placeholder,
        width: '112px',
        headerClassName: classes.hoursAmountHeader,
        render: ({ entry, className, userId, jobNumber }: IEntryRow) => {
            if (isEntryAllowsTimeInTimeOut(entry)) {
                const data = entry.data as IInOutEntryDataBackend | IBreakEntryDataBackend | IInOutBreakDataBackend;
                return (
                    <PlainText
                        className={clsx(className, classes.amountBodyCell)}
                        value={printDurationTimeFromInOutEntryData(data)}
                    />
                );
            }
            if (entry.data?.entry_type === QuantityType.FILE) {
                if (jobNumber) {
                    const totalForFiles = totalFileTimePayment([entry], { [jobNumber?.id]: jobNumber });
                    if (totalForFiles) {
                        return (
                            <PlainText
                                className={clsx(className, classes.amountBodyCell)}
                                value={formatDollars(totalForFiles)}
                            />
                        );
                    }
                }
                return (
                    <PlainText className={clsx(className, classes.amountBodyCell)} />
                );
            }

            return (
                <QuickEditEntry
                    customClasses={clsx(
                        className,
                        classes.bodyCell,
                        classes.amountBodyCell,
                    )}
                    entry={entry}
                    userId={userId}
                />
            );
        },
    }),
    // @ts-ignore
    [EntryColumnSlug.Time]: (placeholder, classes: ReturnType<typeof useEntriesTableStyles>) => ({
        key: EntryColumnSlug.Time,
        renderTitle: function TimeCellTitle({ rows }) {
            return isRowsWithoutTimeEntries(rows) ? '' : placeholder;
        },
        width: '1.8fr',
        render: function TimeCell({ entry, className, error, userId }: IEntryRow) {
            if (!isEntryAllowsTimeInTimeOut(entry)) {
                return (
                    <PlainText className={className} />
                );
            }
            return (
                <Box className={clsx(className, classes.bodyCell, classes.bodyCellWithWarning, classes.pullLeft)}>
                    <TimeInOutQuickEdit
                        entry={entry as ITimeEntry}
                        value={entry.data}
                        userId={userId}
                    />
                    {Boolean(error) && (
                        <p className={classes.bodyCellWarning}>{error}</p>
                    )}
                </Box>
            );
        },
    }),
    [EntryColumnSlug.TimeReadOnly]: placeholder => ({
        key: placeholder,
        title: placeholder,
        render: function TimeReadOnlyCell({ entry, className }: IEntryRow) {
            if (entry.data?.entry_type === QuantityType.FILE && entry.data?.files) {
                return (
                    <PlainText className={className} value={formatFiles(entry.data.files)} />
                );
            }
            if (!isEntryAllowsTimeInTimeOut(entry)) {
                return (
                    <PlainText className={className} />
                );
            }
            const data = entry.data as IInOutEntryDataBackend | IBreakEntryDataBackend;
            const startTime = transformBackendDateTimeToTime(data.time_in);
            const endTime = data.time_out ? transformBackendDateTimeToTime(data.time_out) : '';
            const value = `${startTime} - ${endTime}`;

            return (
                <PlainText className={className} value={value} />
            );
        },
    }),
    [EntryColumnSlug.Break]: (placeholder, classes) => ({
        key: EntryColumnSlug.Break,
        // @ts-ignore
        renderTitle: getBreakCellTitle(placeholder),
        headerClassName: classes.hoursAmountHeader,
        render: function BreakCell({ entry, className }: IEntryRow) {
            if (entry.data.entry_type === QuantityType.TIME_IN_OUT_BREAK) {
                return (
                    <Box className={clsx(className, classes.amountBodyCell)}>
                        {/*
                        // @ts-ignore*/}
                        <QuickEditBreak entry={entry} />
                    </Box>
                );
            }
            let value;
            if (entry.data.entry_type === QuantityType.TIME_IN_OUT_MEAL_BREAK) {
                value = getFormattedMealBreak(entry.data);
            } else {
                value = QuantityType.TIME_BREAK === entry.data.entry_type
                    ? formatMinutes(totalBreakTime([entry]))
                    : '';
            }
            return (
                <PlainText
                    className={clsx(className, classes.amountBodyCell)}
                    value={value}
                />
            );
        },
    }),
    [EntryColumnSlug.BreakReadOnly]: (placeholder, classes) => ({
        key: EntryColumnSlug.Break,
        // @ts-ignore
        renderTitle: getBreakCellTitle(placeholder),
        headerClassName: classes.hoursAmountHeader,
        render: function BreakCell({ entry, className }: IEntryRow) {
            if (!isEntryAllowsBreak(entry)) {
                return (
                    <PlainText className={className} />
                );
            }
            let value;
            if (entry.data.entry_type === QuantityType.TIME_IN_OUT_MEAL_BREAK) {
                value = getFormattedMealBreak(entry.data);
            } else {
                value = formatMinutes(totalBreakTime([entry]));
            }
            return (
                <PlainText
                    className={clsx(className, classes.amountBodyCell)}
                    value={value}
                />
            );
        },
    }),
    [EntryColumnSlug.Location]: placeholder => ({
        key: placeholder,
        title: placeholder,
        render: function LocationCell({ location, className }: IEntryRow) {
            return (
                <PlainText className={className} value={location?.name} />
            );
        },
    }),
    [EntryColumnSlug.Position]: placeholder => ({
        key: placeholder,
        title: placeholder,
        render: function PositionCell({ position, className }: IEntryRow) {
            return (
                <PlainText className={className} value={position?.name} />
            );
        },
    }),
    [EntryColumnSlug.Department]: placeholder => ({
        key: placeholder,
        title: placeholder,
        render: function DepartmentCell({ department, className }: IEntryRow) {
            return (
                <PlainText className={className} value={department?.name} />
            );
        },
    }),
    [EntryColumnSlug.JobNumber]: placeholder => ({
        key: placeholder,
        title: placeholder,
        render: function JobNumberCell({ jobNumber, className }: IEntryRow) {
            return (
                <PlainText className={className} value={`${jobNumber?.job_number || ''}`} />
            );
        },
    }),
    [EntryColumnSlug.PayRate]: placeholder => ({
        key: placeholder,
        title: placeholder,
        render: function PayRateCell({ assignment, className }: IEntryRow) {
            const payRate = useFormattedPayRate(assignment);
            return (
                <PlainText className={className} value={payRate} />
            );
        },
    }),
    [EntryColumnSlug.Hours]: placeholder => ({
        key: placeholder,
        title: placeholder,
        render: function HoursCell({ entry, className }: IEntryRow) {
            return (
                <PlainText className={className} value={formatMinutes(totalTime([entry]))} />
            );
        },
    }),
    // @ts-ignore
    [EntryColumnSlug.Notes]: (placeholder, classes: ReturnType<typeof useEntriesTableStyles>) => ({
        key: EntryColumnSlug.Notes,
        renderTitle: function TimeCellTitle({ rows }) {
            const interviewingTitle = 'Case Numbers';
            const rowsWithInterviews = getRowsWithCompletedInterviews(rows);
            if (rowsWithInterviews === rows.length) {
                return interviewingTitle;
            }
            if (rowsWithInterviews > 0) {
                return `${placeholder}/${interviewingTitle}`;
            }
            return placeholder;
        },
        render: function NotesCell({ entry, className }: IEntryRow){
            return (
                <NoteCell
                    notes={ entry.notes }
                    className={ clsx(className, classes.iconCell) }
                />
            );
        },
    }),
    [EntryColumnSlug.Quantity]: placeholder => ({
        key: EntryColumnSlug.Quantity,
        title: placeholder,
        render: function QuantityCell({ entry, className }: IEntryRow){
            const getValue = (): string => {
                switch (entry.data.entry_type) {
                    case QuantityType.ODOMETER:
                        return calculateMiles(entry.data).toString();
                    case QuantityType.MILES:
                        return entry.data.miles.toString();
                    case QuantityType.MONEY:
                        return '1';
                    default:
                        return '';
                }
            };
            return (
                <PlainText className={className} value={getValue()} />
            );
        },
    }),
    [EntryColumnSlug.Rate]: placeholder => ({
        key: EntryColumnSlug.Rate,
        title: placeholder,
        render: function QuantityCell({ entry, className }: IEntryRow){
            const mileageRate = useCurrentClientMileageRate();
            const getValue = (): string => {
                switch (entry.data.entry_type) {
                    case QuantityType.ODOMETER:
                    case QuantityType.MILES:
                        return `${formatDollars(mileageRate.toNumber(), false)}/mile`;
                    case QuantityType.MONEY:
                        return formatDollars(totalDollars([entry], mileageRate));
                    default:
                        return '';
                }
            };
            return (
                <PlainText className={className} value={getValue()} />
            );
        },
    }),
    [EntryColumnSlug.AmountReadonly]: (placeholder, classes: ReturnType<typeof useEntriesTableStyles>) => ({
        key: EntryColumnSlug.Rate,
        title: placeholder,
        width: '120px',
        headerClassName: classes.hoursAmountHeader,
        render: function AmountCell({ entry, className, jobNumber }: IEntryRow) {
            const mileageRate = useCurrentClientMileageRate();
            switch (entry.data.entry_type) {
                case QuantityType.TIME:
                case QuantityType.TIME_IN_OUT:
                case QuantityType.TIME_IN_OUT_BREAK:
                case QuantityType.TIME_IN_OUT_MEAL_BREAK:
                    return (
                        <PlainText
                            className={clsx(className, classes.amountBodyCell)}
                            value={formatMinutes(totalTime([entry]))}
                        />
                    );
                case QuantityType.TIME_BREAK:
                    return (
                        <PlainText
                            className={clsx(className, classes.amountBodyCell)}
                            value={formatMinutes(totalBreakTime([entry]))}
                        />
                    );
                case QuantityType.FILE:
                    if (jobNumber) {
                        const totalForFiles = totalFileTimePayment([entry], { [jobNumber?.id]: jobNumber });
                        if (totalForFiles) {
                            return (
                                <PlainText
                                    className={clsx(className, classes.amountBodyCell)}
                                    value={formatDollars(totalForFiles)}
                                />
                            );
                        }
                    }
                    return (
                        <PlainText className={clsx(className, classes.amountBodyCell)} />
                    );
                default:
                    return (
                        <PlainText
                            className={clsx(className, classes.amountBodyCell)}
                            value={formatDollars(totalDollars([entry], mileageRate))}
                        />
                    );
            }
        },
    }),
    [EntryColumnSlug.CustomField]: (placeholder, classes, additionalData) => ({
        key: `${EntryColumnSlug.CustomField}-${additionalData?.customFieldId}`,
        title: placeholder,
        render: function CustomFieldCell({ className, displayCustomFieldValues }: IEntryRow) {
            const customFieldId = additionalData?.customFieldId || '';
            return (
                <PlainText
                    className={className}
                    value={displayCustomFieldValues?.[customFieldId]}
                />
            );
        },
    }),
    [EntryColumnSlug.SCA]: (placeholder, classes, additionalData) => ({
        key: `${EntryColumnSlug.CustomField}-${additionalData?.customFieldId}`,
        title: placeholder,
        render: function CustomFieldScaCell({ className, customFieldValuesIds }: IEntryRow) {
            const customFieldId = additionalData?.customFieldId || '';
            return (
                <Box className={className}>
                    {customFieldValuesIds?.[customFieldId]?.map(
                        id => <ScaWithTooltip key={id} customFieldValueId={id} />,
                    )}
                </Box>
            );
        },
    }),
    [EntryColumnSlug.Employee]: placeholder => ({
        key: placeholder,
        title: placeholder,
        render: function EmployeeCell({ employee, className }: IEntryRow) {
            return (
                <PlainText className={className} value={getLastFirstName(employee)} />
            );
        },
    }),
};

export const entryCellDictionaryReadOnly = {
    ...entryCellDictionary,
    [EntryColumnSlug.Time]: entryCellDictionary[EntryColumnSlug.TimeReadOnly],
    [EntryColumnSlug.Break]: entryCellDictionary[EntryColumnSlug.BreakReadOnly],
};
