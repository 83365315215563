import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { routes } from 'shared/routes';

interface IKeycloakDisabledRouteProps {
    path: string;
    component: React.FC<any>;
}

function RedirectComponent() {
    return <Redirect to={routes.HOME} />;
}

export function KeycloakDisabledRoute({ path }: IKeycloakDisabledRouteProps) {
    return (
        <Route
            path={path}
            component={RedirectComponent}
        />
    );
}
