import { IEmployeeEditFormValues } from 'modules/settings/submodules/employees/store/model';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectUserById } from 'store/entities/users/selectors';

export const useEmployeeFormInitialValues = (employeeUserId: string): IEmployeeEditFormValues => {
    const user = useSelector(selectUserById(employeeUserId));
    return useMemo(() => {
        return {
            first_name: user?.first_name || '',
            last_name: user?.last_name || '',
            phone: user?.cell_phone || '',
            email: user?.email || '',
            address: user?.address1 || '',
            address2: user?.address2 || '',
            city: user?.city || '',
            county: user?.county || '',
            state: user?.state || '',
            zip_code: user?.zip_code || '',
            prism_employee_id: user?.prism_employee_id || '',
            emailConfirmation: '',
            avionte_talent_id: user?.avionte_talent_id || '',
        };
    }, [user]);
};
