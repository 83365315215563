import * as yup from 'yup';

export const paySettingsValidationSchema = yup.object().shape({
    pay_period_type: yup.string().required(),
    pay_period_start_day: yup.string().required(),
    pay_period_end_day: yup.string().required(),
    pay_period_start_date: yup.string()
        .required()
        .test(
            'is-future-date', 
            'Start date must be in the future.', 
            value => {
                const today = new Date();
                const inputDate = new Date(value);
                today.setHours(0, 0, 0, 0);
                return inputDate > today;
            },
        ),
    pay_day: yup.string().required(),
});
