import React from 'react';
import PlainText from 'shared/components/table/Cells/PlainText';
import { eafTypeDisplayValues } from 'modules/employeeActionsForm/store/models';
import { EafApprovalCellProps } from 'modules/eafApproval/store/model/types';

export const EafApprovalGridCellChangeType = ({
    className,
    employee_action_form_data,
}: EafApprovalCellProps) => {
    const { employee_action_form_type } = employee_action_form_data;
    const value = eafTypeDisplayValues[employee_action_form_type];
    return (
        <PlainText className={className} value={value} />
    );
};
