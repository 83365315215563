import React from 'react';
import { useSelector } from 'react-redux';
import { selectIsBulkBeingCreated, selectIsDownloadingFailedCsv, selectIsLoadingCreationPdf } from 'modules/subassignmentManagement/components/CreateSubAssignment/store/selectors';
import { BulkCreationReportV2 } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/BulkReportV2/BulkCreationReportV2';
import { useBulkPreCreateOverviewItems, usePreCreateHasFailed } from 'modules/subassignmentManagement/components/CreateSubAssignment/CreationReportV2/hooks';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import { PrintButton } from 'shared/components/buttons/PrintButton';
import { GetApp } from '@material-ui/icons';

interface IPreInitializeCreationReportV2Props {
    onBack: () => void;
    onNextClick: () => void;
    onDownloadReportPdfClick: () => void;
    onDownloadFailedCsvClick: () => void;
}

export const PreInitializeCreationReportV2 = ({
    onBack,
    onNextClick,
    onDownloadReportPdfClick,
    onDownloadFailedCsvClick,
}: IPreInitializeCreationReportV2Props) => {
    const overviewItems = useBulkPreCreateOverviewItems();
    const isLoading = useSelector(selectIsBulkBeingCreated);
    const isLoadingPdf = useSelector(selectIsLoadingCreationPdf);
    const isLoadingFailedCsv = useSelector(selectIsDownloadingFailedCsv);
    const hasFailedItems = usePreCreateHasFailed();

    const additionalOverview = (
        <PrintButton
            onClick={onDownloadReportPdfClick}
            disabled={isLoadingPdf}
        />
    );

    const additionalButton = (
        <ButtonWithLoader
            variant="outlined"
            color="secondary"
            onClick={onDownloadFailedCsvClick}
            isLoading={isLoadingFailedCsv}
            disabled={!hasFailedItems}
            startIcon={<GetApp />}
        >
            Download fails to new file
        </ButtonWithLoader>
    );

    return (
        <BulkCreationReportV2
            onBack={onBack}
            handleAction={onNextClick}
            isLoading={isLoading}
            title="Pre-Initialize Roll Up"
            buttonTitle="Create"
            overviewItems={overviewItems}
            additionalOverview={additionalOverview}
            additionalButton={additionalButton}
        />
    );
};
