import { useFeature } from '@optimizely/react-sdk';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    EntryType,
    IBreakEntryDataBackend,
    IEntry,
    IInOutEntryDataBackend,
    QuantityType,
} from 'shared/models/sheet/Sheet';
import { EntriesGridCellsTitles, IEntryRow } from 'shared/components/table/EntriesTable/model';
import moment from 'moment';
import { getMomentFromBackendDateTime } from 'shared/models/DateTime';
import {
    AvailableTableConfiguration,
    EntryColumnSlug,
    IColumnConfiguration,
} from 'store/entities/clients/clientsModel';
import {
    selectIsClientHasBreaks,
    selectIsClientHasMealBreaks,
    selectIsClientHasTimeInOut,
} from 'store/entities/clients/selectors/configurationSelectors';
import { selectTableConfiguration } from 'store/entities/clients/selectors/fieldSelectors';
import { selectIsJobNumberFieldsApplied } from 'store/entities/clients/selectors/timeAndPaySelectors';
import { CustomFieldType } from 'store/entities/customFields/model';
import { selectDisplayedOnTableCustomFields } from 'store/entities/customFields/selectors';
import { FeatureSwitches } from 'utils/featureSwitches';

interface ISheetTypeDependentTitles {
    scaZipTitle: string;
    hoursAmountTitle: string;
}

export function getSheetTypeDependentTitles(entryFilter?: EntryType): ISheetTypeDependentTitles {
    const scaZip = [];
    const hoursAmount = [];

    if (entryFilter !== EntryType.EXPENSE) {
        // @ts-ignore
        scaZip.push(EntriesGridCellsTitles.ScaZone);
        // @ts-ignore
        hoursAmount.push(EntriesGridCellsTitles.Hours);
    }
    if (entryFilter !== EntryType.TIME) {
        // @ts-ignore
        scaZip.push(EntriesGridCellsTitles.ZipCode);
        // @ts-ignore
        hoursAmount.push(EntriesGridCellsTitles.Amount);
    }

    return {
        scaZipTitle: scaZip.join('/'),
        hoursAmountTitle: hoursAmount.join('/'),
    };
}

export function getEntryRowId(entryId: string) {
    return `entry.row.${entryId}`;
}

export function getEntryRowIdByRow(entryRow: IEntryRow): string {
    return getEntryRowId(entryRow.entry.id);
}

export function getEntryIdByRowId(rowId: string): string {
    return rowId.split('.').pop() as string;
}

export enum EntryErrors {
    BreakEntryIsOutsideBoundary = 'Break time is outside of activity time',
}

const getEntryTimeBreakEntryErrorsById = (entries: Array<IEntry>): Record<string, string> => {
    const breakEntries = entries.filter(entry => (
        entry.entry_type === EntryType.TIME && entry.data.entry_type === QuantityType.TIME_BREAK
    ));
    const timeInOutEntries = entries.filter(entry => (
        entry.entry_type === EntryType.TIME && entry.data.entry_type === QuantityType.TIME_IN_OUT
    )).map(entry => entry.data) as IInOutEntryDataBackend[];
    const shiftTimeIn = moment.min(
        timeInOutEntries.map(entryData => getMomentFromBackendDateTime(entryData.time_in)),
    );
    const timeOuts = timeInOutEntries.flatMap(
        entryData => entryData.time_out ? getMomentFromBackendDateTime(entryData.time_out) : [],
    );
    const shiftTimeOut = moment.max(timeOuts);
    const errorsById: Record<string, string> = {};

    if (shiftTimeOut) {
        // Find time boundaries or time_in/time_out rows.
        breakEntries.forEach(breakEntry => {
            const entryData = breakEntry.data as IBreakEntryDataBackend;
            const breakStart = getMomentFromBackendDateTime(entryData.time_in);
            const breakEnd = entryData.time_out && getMomentFromBackendDateTime(entryData.time_out);
            if (breakEnd && (breakStart.isBefore(shiftTimeIn) || breakEnd.isAfter(shiftTimeOut))) {
                errorsById[breakEntry.id] = EntryErrors.BreakEntryIsOutsideBoundary;
            }
        });
    }

    return errorsById;
};

export const getEntryErrorsById = (entryRows: Array<IEntry>): Record<string, string> => {
    const timeBreakErrors = getEntryTimeBreakEntryErrorsById(entryRows);
    return {
        ...timeBreakErrors,
    };
};

const defaultTimeInOutColumn: IColumnConfiguration<EntryColumnSlug> = {
    slug: EntryColumnSlug.TimeReadOnly,
    placeholder: 'Time',
};
const defaultBreakColumn: IColumnConfiguration<EntryColumnSlug> = {
    slug: EntryColumnSlug.BreakReadOnly,
    placeholder: 'Break',
};

const useExtendConfigurationByActivities = (
    option: AvailableTableConfiguration,
    configuration: IColumnConfiguration<EntryColumnSlug>[],
): IColumnConfiguration<EntryColumnSlug>[] => {
    const clientHasMealBreaks = useSelector(selectIsClientHasMealBreaks);
    const clientHasBreaks = useSelector(selectIsClientHasBreaks);
    const clientHasTimeInOut = useSelector(selectIsClientHasTimeInOut);
    const [enableExtendEntriesCellsByActivities] = useFeature(FeatureSwitches.enableExtendEntriesCellsByActivities);

    const extendTimeColumns = [
        AvailableTableConfiguration.Time,
        AvailableTableConfiguration.TimeDetail,
        AvailableTableConfiguration.TimeExpense,
    ].includes(option);

    return useMemo(() => {
        const extendedConfiguration = [...configuration];
        const existSlugs = new Set(configuration.map(column => column.slug));
        if (enableExtendEntriesCellsByActivities && extendTimeColumns) {
            if (
                clientHasTimeInOut
                && !(existSlugs.has(EntryColumnSlug.Time) || existSlugs.has(EntryColumnSlug.TimeReadOnly))
            ) {
                // add time column if not exist
                extendedConfiguration.splice(extendedConfiguration.length - 1, 0, defaultTimeInOutColumn);
            }
            if (
                clientHasBreaks
                && !(existSlugs.has(EntryColumnSlug.Break) || existSlugs.has(EntryColumnSlug.BreakReadOnly))
            ) {
                // add break column if not exist
                extendedConfiguration.splice(extendedConfiguration.length - 1, 0, defaultBreakColumn);
            }
            if (clientHasMealBreaks) {
                // break column should be named as meal break
                const breakColumn = configuration.find(column => column.slug === EntryColumnSlug.BreakReadOnly);
                if (breakColumn) {
                    breakColumn.placeholder = 'Meal break';
                }
            }
        }

        return extendedConfiguration;
    }, [
        configuration,
        extendTimeColumns,
        clientHasMealBreaks,
        clientHasBreaks,
        clientHasTimeInOut,
        enableExtendEntriesCellsByActivities,
    ]);
};

const useExtendConfigurationByCustomFields = (
    configuration: IColumnConfiguration<EntryColumnSlug>[],
): IColumnConfiguration<EntryColumnSlug>[] => {
    const isJobNumberFieldsApplied = useSelector(selectIsJobNumberFieldsApplied);
    const tableCustomFields = useSelector(selectDisplayedOnTableCustomFields);

    return useMemo(() => {
        if (!isJobNumberFieldsApplied) {
            const { prefixColumns, posfixColumns } = configuration.reduce((filtered, column) => {
                if (EntryColumnSlug.Activity === column.slug) {
                    filtered.prefixColumns.push(column);
                    return filtered;
                }
                filtered.posfixColumns.push(column);
                return filtered;
            }, {
                prefixColumns: new Array<IColumnConfiguration<EntryColumnSlug>>(),
                posfixColumns: new Array<IColumnConfiguration<EntryColumnSlug>>(),
            });

            return [
                ...prefixColumns,
                ...tableCustomFields.map(customField => {
                    return {
                        slug: customField.headway_connect_field?.key === CustomFieldType.SCA
                            ? EntryColumnSlug.SCA : EntryColumnSlug.CustomField,
                        placeholder: customField.name,
                        additionalData: {
                            customFieldId: customField.id,
                        },
                    };
                }),
                ...posfixColumns,
            ];
        }

        return configuration;
    }, [configuration, tableCustomFields, isJobNumberFieldsApplied]);
};

export const useExtendedConfiguration = (
    option: AvailableTableConfiguration,
): IColumnConfiguration<EntryColumnSlug>[] => {
    const baseConfiguration = useSelector(selectTableConfiguration(option)) as IColumnConfiguration<EntryColumnSlug>[];
    const extendedConfiguration = useExtendConfigurationByCustomFields(baseConfiguration);
    return useExtendConfigurationByActivities(option, extendedConfiguration);
};
