import { IDepartment } from 'modules/employmentInfo/models/Department';
import { ITravelExpense } from 'modules/travelExpenses/model';
import { IDeal } from 'shared/models/Deal';
import { IEmployeeProfile } from 'shared/models/EmployeeProfile';
import { ICreatedUpdated, IEntity } from 'shared/models/Entity';
import { ILocationBackend } from 'shared/models/Location';
import { PayRateType, PayType } from 'shared/models/PaySettings';
import { IPosition } from 'shared/models/Position';
import { EntryType, QuantityType } from 'shared/models/sheet/Sheet';
import { Status } from 'shared/models/Status';
import { IUserInfo } from 'shared/models/User';
import { Permission } from 'store/components/auth/authModels';
import { Nullable } from 'types/types';

export interface IConfigurationByAssignments {
    assignment_ids?: string | string[]; // comma separated
}

export interface IConfigurationByAssignment {
    assignment_id?: string;
}

export interface IConfigurationByPurpose {
    purpose?: Permission;
}

export interface IConfigurationByClient {
    client_id?: string;
}

export interface IConfigurationByIds {
    ids?: string[] | string;
}

export interface IAssignmentsRequest extends IConfigurationByPurpose, IConfigurationByClient, IConfigurationByIds {
    user_id?: string;
}

export interface IProjectWithAssignmentRequest extends IConfigurationByPurpose, IConfigurationByAssignments {}

export interface IProjectsRequest extends IConfigurationByAssignments, IConfigurationByAssignment,
    IConfigurationByPurpose, IConfigurationByClient, IConfigurationByIds {}

export interface IArea extends IEntity {
    state_id: string;
    description: string;
}

export interface IProject extends IEntity {
    description: string;
    sca_applies: boolean;
}

export interface IManagerInfo {
    user_id: string;
    manager_level: number;
}

export interface IManagerInfoWithUser extends IManagerInfo {
    user: IUserInfo;
}

export interface IEmployeeProfileLink extends IEntity {
    headway_onboarding_completed: boolean;
    avionte_onboarding_completed: boolean;
}

export interface IAssignmentBackend extends IEntity {
    description: string;
    sca_applies: boolean;
    user_id: string;
    client_id: string;
    managers: IManagerInfoWithUser[];
    position_id?: string;
    location_id?: string;
    department_id?: string;
    position_value_id?: string;
    location_value_id?: string;
    department_value_id?: string;
    end_date?: string | null;
    hire_date?: string;
    pay_rate_type: PayRateType;
    pay_type: PayType;
    pay_rate_value: string;
    custom_field_value_ids: string[];
    employee_profile: IEmployeeProfileLink;
    approval_levels: number;
}

export interface IAssignment extends Omit<IAssignmentBackend, 'area' | 'managers'>{
    managers: IManagerInfo[];
}

export interface IProjectWithAssignmentBackend {
    project: IProject;
    project_id: string;
    assignment: IAssignmentBackend;
    area_id: number | null;
    area?: IArea;
    description: string;
    sca_zone_id: number | null;
}

export interface IProjectWithAssignment extends IProjectWithAssignmentBackend {}

export interface IActivityRequest extends IConfigurationByClient, IConfigurationByAssignments {}

export enum ActivityTag {
    ShowZipCode = 'show_zip_code',
    NonProduction = 'non_production',
    Default='is_default',
    Travel='travel',
    Immutable='immutable',
}

export interface IActivity extends IEntity {
    description: string;
    short_description: string;
    sheet_type: EntryType;
    data_type: QuantityType;
    pay_code_id: string;
    tasks: IEntity[];
    deleted_at: string | null;
    show_zip_code: boolean;
    is_active: boolean;
    tags?: ActivityTag[];
}

export interface ITask extends IEntity {
    description: string;
    charge_code: string;
    project_id: string;
}

export function renderTaskInfo(task?: ITask): string {
    if (!task) {
        return '';
    }
    return `${task?.description} - ${task?.charge_code}`;
}

export interface ILocationRequest extends IConfigurationByAssignment, IConfigurationByClient {
    filters?: string;
}

export interface ITemplate extends IEntity {
    name: string;
    template: string;
    created_at?: string;
    updated_at?: string;
}

export interface IPhysicalDemandsTemplate extends ITemplate {
}

export interface IWorkingConditionsTemplate extends ITemplate {
}

export interface IBackgroundCheckTemplate extends ITemplate {
}

export interface IAssignmentWithLinkedRequest extends IConfigurationByAssignment,
    IConfigurationByClient, IConfigurationByIds {
    purpose?: Permission.ApproveSheets | Permission.SubmitSheets;
}

export interface IAssignmentWithLinked extends IAssignment {
    user: IUserInfo;
    employee_profile: IEmployeeProfile;
    position: IPosition;
    location: ILocationBackend;
    department: IDepartment;
    area: IArea;
    managers: IManagerInfoWithUser[];
}

export interface IJobNumberRequest extends IConfigurationByClient {
    filters?: string;
    sort?: string;
    range?: string;
    ids?: string[];
    deal_number?: number;
    username?: string;
    user_id?: string[];
    client_id?: string;
    search?: number;
}

export interface IJobNumberPostRequest extends Omit<IJobNumberRequest, 'ids' | 'user_id'> {
    filters?: string;
    sort?: string;
    range?: string;
    ids?: string; // Comma-delimited list
    username?: string;
    user_id?: string; // Comma-delimited list
}

export interface ISubassignmentBase {
    assignment_id: string;
    subassignment_group_id?: string;
    client_id: string;
    start_date: string;
    end_date: string | null;
    override_rate_value: string;
    managers?: Nullable<IManagerInfo[]>;
    custom_field_value_ids?: Nullable<string[]>;
    all_values_custom_field_ids?: Nullable<string[]>;
    is_active?: boolean;
}

export interface ISubassignment extends IEntity, ISubassignmentBase, ICreatedUpdated {
    managers?: IManagerInfo[];
    user_id: string;
}

export interface IDealRequest extends IConfigurationByClient, IConfigurationByIds {
    range?: string;
    status?: Status;
    search?: string;
}

export interface IDealResponse {
    deals: IDeal[];
    total_items: number;
}

export interface ISheetSearchRequest {
    client_id?: string,
    status_id?: string,
    user_id?: string,
    period_start?: string,
    period_end?: string,
    period_ends?: string[],
    sheet_ids?: string[],
    is_travel?: true,
    page_size?: number;
    cursor?: string;
}

export interface ITravelExpenseResponse {
    travel_expenses: ITravelExpense[];
    total_items: number;
}

export interface ISubassignmentRequest extends IConfigurationByClient,
    IConfigurationByIds, IConfigurationByAssignment {
    assignment_ids?: string[];
    approver_user_id?: string;
}

export interface IPayRange extends IEntity, ICreatedUpdated {
    client_id: string;
    position_value_id: string;
    location_value_id: string;
    min_value: number;
    max_value: number;
}

export interface IPayRangeByValueRequestPayload {
    job_order_id: string;
}

export interface IPayRangeByValuesRequest extends IPayRangeByValueRequestPayload {
    client_id?: string;
}
