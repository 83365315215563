import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import PlainText from 'shared/components/table/Cells/PlainText';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { Permission } from 'store/components/auth/authModels';
import { IClient } from 'store/entities/clients/clientsModel';
import { deleteCustomField } from 'store/entities/customFields/actions';
import { ICustomField } from 'store/entities/customFields/model';
import { selectIsLoadingCustomFields } from 'store/entities/customFields/selectors';
import { selectAllCustomFieldsRows } from 'modules/settings/submodules/customFields/components/CustomFieldsTable/selectors';
import { DeleteItemButton } from 'modules/settings/common/components/DeleteItemButton/DeleteItemButton';
import { EditItemButton } from 'modules/settings/common/components/EditItemButton/EditItemButton';
import { ISettingsTablePureProps, SettingsTablePure } from 'modules/settings/common/components/SettingsTablePure/SettingsTablePure';
import { setEditCustomFieldId } from 'modules/settings/submodules/customFields/store/actions';

export interface ICustomFieldRow extends ICustomField {
    clients: IClient[];
    className?: string;
}

export const CustomFieldsTable = (
    separateLogicDecorator<object, ISettingsTablePureProps<ICustomFieldRow>>(() => {
        const tableClasses = useDefaultTableStyles();
        const cells = [
            {
                key: 'name',
                title: 'Name',
                width: '1fr',
                render: function NameCell({ className, name }: ICustomFieldRow) {
                    return (
                        <PlainText className={className} value={name} />
                    );
                },
            },
            {
                key: 'description',
                title: 'Description',
                width: '1fr',
                render: function DescriptionCell({ className, description }: ICustomFieldRow) {
                    return (
                        <PlainText className={className} value={description} />
                    );
                },
            },
            {
                key: 'clients',
                title: 'Clients',
                width: '1fr',
                render: function ClientsCell({ className, clients, all_clients }: ICustomFieldRow) {
                    let value = 'All';
                    if (!all_clients) {
                        value = clients.map(client => client.name).join(', ');
                    }
                    return (
                        <PlainText className={className} value={value} />
                    );
                },
            },
            {
                key: 'mapping',
                title: 'Mapped field',
                width: '1fr',
                render: function MappingCell({ className,
                    export_field,
                    prism_field,
                    headway_connect_field }: ICustomFieldRow) {
                    const value = export_field?.name
                        || prism_field?.name || headway_connect_field?.name;
                    return (
                        <PlainText className={className} value={value} />
                    );
                },
            },
            {
                key: 'actions',
                width: '90px',
                render: function ActionsCell({ className, id, deletable, editable }: ICustomFieldRow) {
                    return (
                        <div className={clsx(className, tableClasses.iconCell)}>
                            {editable && (
                                <EditItemButton
                                    id={id}
                                    editAction={setEditCustomFieldId}
                                    fontSize="small"
                                />
                            )}
                            {deletable && (
                                <DeleteItemButton
                                    confirmation="Are you sure you want to delete custom field?"
                                    id={id}
                                    deleteAction={deleteCustomField.init}
                                    permission={Permission.DeleteCustomFields}
                                />
                            )}
                        </div>
                    );
                },
            },
        ];
        return {
            cells,
            isLoading: useSelector(selectIsLoadingCustomFields),
            rows: useSelector(selectAllCustomFieldsRows),
        };
    })(SettingsTablePure)
);
