import { getOfferLetters } from 'modules/offerLetter/store/actions';
import {
    deleteEmployee,
    getEmployeeClients,
    onboardWithAvionte,
    overrideOfferLetterStatus,
    resetPassword,
    setEditEmployeeId,
    updateEmployee,
} from 'modules/settings/submodules/employees/store/actions';
import { combineReducers } from 'redux';
import { isLoadingReducer, resetStateReducer, singleValueReducer } from 'store/reducerUtils';
import { extendReducer } from 'store/utils/reducers/extendReducer';

const editEmployeeId = singleValueReducer(setEditEmployeeId.action, null);
const isUpdating = isLoadingReducer(updateEmployee);
const isDeleting = isLoadingReducer(deleteEmployee);
const isStatusUpdating = isLoadingReducer(overrideOfferLetterStatus);
const isOffersLoading = isLoadingReducer(getOfferLetters);
const isPasswordResetting = isLoadingReducer(resetPassword);
const editEmployeeClients = extendReducer(
    singleValueReducer(getEmployeeClients.successType, []),
    resetStateReducer(setEditEmployeeId.action, []),
);
const isOnboardingWithAvionte = isLoadingReducer(onboardWithAvionte);

export const employees = combineReducers({
    editEmployeeId,
    isUpdating,
    isDeleting,
    isStatusUpdating,
    isOffersLoading,
    isPasswordResetting,
    editEmployeeClients,
    isOnboardingWithAvionte,
});
