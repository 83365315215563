import { IPayrollSheetRow } from 'modules/payrollProcessorHub/components/PayrollSheetTable/model';
import {
    IGroupedSheetCalculation,
    ISheetCalculationBatchBackend,
    ISheetGroupId,
    PayrollSheetTabs,
} from 'modules/payrollProcessorHub/store/model';
import {
    selectPayrollActiveTab,
    selectSheetsGroupsByIds,
} from 'modules/payrollProcessorHub/store/selectors';
import { useSelector } from 'react-redux';
import { selectAllClientsById } from 'store/entities/clients/selectors/clientsSelectors';
import { IManagerInfo } from 'store/entities/configuration/configurationModel';
import {
    selectAssignmentsById,
    selectLocationsById,
    selectPositionsById,
    selectSubassignmentsByIds,
} from 'store/entities/configuration/configurationSelectors';
import { ICalculation } from 'store/entities/timesheet/models/Calculation';
import { selectUsersById } from 'store/entities/users/selectors';
import moment from 'moment';
import { ExportFieldtype, ICustomField, ICustomFieldValue } from 'store/entities/customFields/model';

export const useActivePayrollSheetStatus = (): PayrollSheetTabs => {
    return useSelector(selectPayrollActiveTab);
};

export const generateGroupRowId = (group: IGroupedSheetCalculation) => {
    return `time-${group.time_sheet_id}_expense-${group.expense_sheet_id}`;
};

export const getSheetGroupId = (group: IGroupedSheetCalculation): ISheetGroupId => {
    return {
        timeSheetId: group.time_sheet_id,
        expenseSheetId: group.expense_sheet_id,
    };
};

export const useSheetRowsWithLinkedItems = (calculations: IGroupedSheetCalculation[]): IPayrollSheetRow[] => {
    const usersByIds = useSelector(selectUsersById);
    const positionsById = useSelector(selectPositionsById);
    const clientsById = useSelector(selectAllClientsById);
    const subassignmentsById = useSelector(selectSubassignmentsByIds);
    const assignmentsById = useSelector(selectAssignmentsById);
    const locationsById = useSelector(selectLocationsById);

    return calculations.map(calculation => {
        const assignment = assignmentsById[calculation.assignment_id || ''];
        const subassignment = subassignmentsById[calculation.assignment_id || ''];
        const managerIds = subassignment?.managers?.map((manager: IManagerInfo) => manager.user_id) || [];
        const approvedByIds = [...new Set([
            calculation.time_latest_approver_user_id,
            calculation.expense_latest_approver_user_id,
        ].filter(id => id))] as string[];
        return {
            id: generateGroupRowId(calculation),
            ...calculation,
            client: clientsById[calculation.client_id || ''],
            employee: usersByIds[calculation.user_id || ''],
            position: positionsById[calculation.position_id || ''],
            assignment,
            // @ts-ignore
            approvers: managerIds?.map(id => usersByIds[id]) || [],
            approvedBy: approvedByIds.map(id => usersByIds[id]),
            location: locationsById[assignment?.location_id || ''],
        };
    });
};

export const useGroupSheetRowsByIds = (groupsIds: ISheetGroupId[]): IPayrollSheetRow[] => {
    const groups = useSelector(selectSheetsGroupsByIds(groupsIds));
    return useSheetRowsWithLinkedItems(groups);
};

export const getSheetGroupName = (sheet: IGroupedSheetCalculation): string => {
    return generateGroupRowId(sheet);
};

export const getUniqValues = (calculations: ICalculation[], propertyName: keyof ICalculation): string[] => {
    return [...new Set(calculations.map(calculation => calculation[propertyName]))].filter(Boolean) as string[];
};

export const getLatestEditorValue = (calculations: ICalculation[]): string | null => {
    const latest = calculations.filter(c => c.updated_by).map(c => {
        return {
            id: c.sheet_id,
            updated_at: c.updated_at,
            updated_by: c.updated_by,
        };
    }).sort((a, b) => moment(b.updated_at).date() - moment(a.updated_at).date());
    if (latest && latest.length > 0) {
        const latestElement = latest[0];
        return latestElement.updated_by ?? '';
    }
    return null;
};

export const checkIfAllGroupEntriesHasJobOrders = (
    currentGroups: ISheetCalculationBatchBackend[],
    fields: ICustomField[],
    customFieldValues: ICustomFieldValue[],
): boolean => {
    if (!fields || !customFieldValues) {
        return false;
    }
    const fieldData = fields.find(f => f.export_field?.key === ExportFieldtype.AvionteJobOrderData);
    const fieldValuesIds = customFieldValues?.map(x => x.id);
    const job_order_ids = fieldData
        ? customFieldValues.filter(x => x.custom_field_id === fieldData.id)?.map(x => x.id) : [];
    const allCustomFieldsLoaded = currentGroups.every(g => g.time_calculations?.every(c =>
        c.entries.every(e => e?.custom_field_value_ids?.every(cfv => fieldValuesIds.includes(cfv))))
        && g.expense_calculations?.every(c =>
            c.entries.every(e => e?.custom_field_value_ids?.every(cfv => fieldValuesIds.includes(cfv)))));
    if (!allCustomFieldsLoaded) {
        return false;
    }
    return job_order_ids
        ? currentGroups.every(g =>
            g.time_calculations?.find(c =>
                c.entries.find(e => e?.custom_field_value_ids?.find(cfv => job_order_ids.includes(cfv))))
            || g.expense_calculations?.find(c =>
                c.entries.find(e => e?.custom_field_value_ids?.find(cfv => job_order_ids.includes(cfv)))))
        : false;
};

export const checkIfNoneGroupEntriesHasJobOrders = (
    currentGroups: ISheetCalculationBatchBackend[],
    fields: ICustomField[],
    customFieldValues: ICustomFieldValue[],
): boolean => {
    if (!fields || !customFieldValues) {
        return false;
    }
    const fieldData = fields.find(f => f.export_field?.key === ExportFieldtype.AvionteJobOrderData);
    const fieldValuesIds = customFieldValues?.map(x => x.id);
    const job_order_ids = fieldData
        ? customFieldValues.filter(x => x.custom_field_id === fieldData.id)?.map(x => x.id) : [];
    const allCustomFieldsLoaded = currentGroups.every(g => g.time_calculations?.every(c =>
        c.entries.every(e => e?.custom_field_value_ids?.every(cfv => fieldValuesIds.includes(cfv))))
        && g.expense_calculations?.every(c =>
            c.entries.every(e => e?.custom_field_value_ids?.every(cfv => fieldValuesIds.includes(cfv)))));
    if (!allCustomFieldsLoaded) {
        return false;
    }
    return job_order_ids
        ? !currentGroups.find(g =>
            g.time_calculations.find(c =>
                c.entries.find(e => e?.custom_field_value_ids?.find(cfv => job_order_ids.includes(cfv))))
            || g.expense_calculations?.find(c =>
                c.entries.find(e => e?.custom_field_value_ids?.find(cfv => job_order_ids.includes(cfv)))))
        : true;
};
