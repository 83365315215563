import React from 'react';
import FilterSVG from 'shared/components/icons/FilterSVG';
import { Box, Button, Hidden } from '@material-ui/core';

import { PayrollFilter } from 'modules/payrollProcessorHub/components/PayrollFilter/PayrollFilter';
import SheetSummary from 'modules/payrollProcessorHub/components/SheetSummary/SheetSummary';
import { FiltersTestIds } from 'shared/components/filters/FilterButtonAndForm/FilterButtonAndFormModel';
import { useFiltersSidebarStyles } from 'shared/components/filters/FilterButtonAndForm/FilterButtonAndFormStyles';
import useFilterAndActionControlsStyles
    from 'shared/components/sheetApproval/filterAndActionControls/FilterAndActionControlsStyles';
import Sidebar from 'shared/components/sidebars/Sidebar/Sidebar';
import { useModal } from 'shared/utils/hooks/useModal';

export const MobilePayrollFooter = () => {
    const classes = useFilterAndActionControlsStyles();
    const filerSidebarClasses = useFiltersSidebarStyles();
    const { isModalOpened: isOpened, onModalOpen, onModalClose: onClose } = useModal();

    return (
        <Hidden smUp>
            <Box className={classes.mobileRoot}>
                <Button
                    classes={{ root: classes.actionButton }}
                    onClick={onModalOpen}
                    data-testid={FiltersTestIds.Button}
                >
                    <FilterSVG />
                </Button>
                <SheetSummary />
            </Box>
            <Sidebar
                title="Filter"
                onClose={onClose}
                titleClasses={filerSidebarClasses}
                anchor={'bottom'}
                isOpened={isOpened}
                data-testid={FiltersTestIds.Wrapper}
            >
                <PayrollFilter />
            </Sidebar>
        </Hidden>
    );
};
