import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useFeature } from '@optimizely/react-sdk';
import { ICustomFieldRelations } from 'modules/settings/submodules/clients/customFieldValues/store/selectors';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import { CustomFieldValueMultiselectGateway } from 'shared/components/selects/CustomFieldValuesSelect/CustomFieldValueMultiselectGateway';
import { FeatureSwitches } from 'utils/featureSwitches';

interface IRelationFormSectionProps {
    relations: ICustomFieldRelations;
    fieldNamePrefix: string;
}

export const RelationFormSection = ({ relations, fieldNamePrefix }: IRelationFormSectionProps) => {
    const classes = useSettingsFormStyles();
    const [enableEditCustomFieldValueChildren] = useFeature(FeatureSwitches.enableEditCustomFieldValueChildren);

    return (
        <>
            {relations.parentFieldId && (
                <Box className={classes.formBlock}>
                    <Typography variant="subtitle2" className={classes.formBlockTitle}>Parent</Typography>
                    <CustomFieldValueMultiselectGateway
                        customFieldId={relations.parentFieldId}
                        name={`${fieldNamePrefix}ParentIds`}
                        selectAllFieldName={`${fieldNamePrefix}ParentAll`}
                        className={classes.input}
                        useIdValue
                    />
                </Box>
            )}

            {relations.siblingFieldIds.length > 0 && (
                <Box className={classes.formBlock}>
                    <Typography variant="subtitle2" className={classes.formBlockTitle}>Siblings</Typography>
                    {relations.siblingFieldIds.map(id => (
                        <CustomFieldValueMultiselectGateway
                            key={id}
                            customFieldId={id}
                            name={`${fieldNamePrefix}Siblings[${id}]`}
                            selectAllFieldName={`${fieldNamePrefix}SiblingsAllFields[${id}]`}
                            className={classes.input}
                            useIdValue
                        />
                    ))}
                </Box>
            )}

            {enableEditCustomFieldValueChildren && relations.childrenFieldIds.length > 0 && (
                <Box className={classes.formBlock}>
                    <Typography variant="subtitle2" className={classes.formBlockTitle}>Children</Typography>
                    {relations.childrenFieldIds.map(id => (
                        <CustomFieldValueMultiselectGateway
                            key={id}
                            customFieldId={id}
                            name={`${fieldNamePrefix}Children[${id}]`}
                            selectAllFieldName={`${fieldNamePrefix}ChildrenAllFields[${id}]`}
                            className={classes.input}
                            useIdValue
                        />
                    ))}
                </Box>
            )}
        </>
    );
};
