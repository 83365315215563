import React, { useEffect } from 'react';
import useTitleUpdate from 'shared/utils/hooks/useTitleUpdate';
import { TravelExpensesTable } from 'modules/travelExpenses/components/TravelExpensesTable/TravelExpensesTable';
import { TravelExpenseFilter } from 'modules/travelExpenses/components/TravelExpenseFilter/TravelExpenseFilter';
import { Box } from '@material-ui/core';
import { setHeaderContent } from 'modules/home/header/Header';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';
import {
    getControllingOrgs,
    getCostCenters, getDeals, getDealTypes,
    getJobNumbers,
    getProjectsAssignments, getSubmittingOrgs,
    loadClientAssignmentsWithLinked,
} from 'store/entities/configuration/configurationAction';
import { TravelExpensesHeader } from 'modules/travelExpenses/components/TravelExpensesHeader';

export const TravelExpenses = () => {
    useTitleUpdate('Travel Expenses', 'Travel Expenses');
    useEffect(() => {
        setHeaderContent(
            <TravelExpensesHeader />,
        );
        return () => {
            setHeaderContent(null);
        };
    }, []);
    useWithClientEffect(dispatch => {
        dispatch(getJobNumbers.init());
        dispatch(getDeals.init({}));
        dispatch(getDealTypes.init());
        dispatch(loadClientAssignmentsWithLinked.init({}));
        dispatch(getProjectsAssignments.init({}));
        dispatch(getSubmittingOrgs.init({}));
        dispatch(getControllingOrgs.init());
        dispatch(getCostCenters.init());
    });
    return (
        <Box p={4}>
            <TravelExpenseFilter />
            <TravelExpensesTable />
        </Box>
    );
};
