/* eslint-disable react/display-name */
import React from 'react';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { useSelector } from 'react-redux';
import PlainText from 'shared/components/table/Cells/PlainText';
import GridTable from 'shared/components/table/GridTable/GridTable';
import { useIsMobile } from 'shared/utils/hooks/media';
import {
    selectDealTypes,
    selectDealTypesAreLoading,
    selectDealTypesById,
} from 'store/entities/configuration/configurationSelectors';
import { DealTypeBillingTypeName, IDealType } from 'shared/models/DealType';

interface IDealTypeRow extends IDealType {
    className?: string;
}

export function DealTypeTable() {
    const tableClasses = useDefaultTableStyles();
    const isMobile = useIsMobile();
    const dealTypesById = useSelector(selectDealTypesById);
    const dealTypes = useSelector(selectDealTypes);
    const isLoading = useSelector(selectDealTypesAreLoading);

    const cells = [
        {
            key: 'deal_type',
            title: 'DEAL TYPE',
            render: function DealType({ id, className }: IDealTypeRow) {
                const dealType = dealTypesById[id];
                return (
                    <PlainText className={ className } value={ dealType?.name } />
                );
            },
        },
        {
            key: 'per_file',
            title: 'Billing type',
            render: function BillingType({ className, billing_type }: IDealTypeRow) {
                if (billing_type) {
                    const billingTypeLabel = DealTypeBillingTypeName[billing_type];
                    return (
                        <PlainText className={className} value={billingTypeLabel} />
                    );
                }
                return (
                    <PlainText className={className} value="-" />
                );
            },
        },
    ];

    return (
        <GridTable
            rowData={dealTypes}
            headerCellClassName={tableClasses.headCell}
            bodyCellClassName={tableClasses.bodyCell}
            className={tableClasses.tableBorder}
            getKey={deal => deal.id}
            cells={cells}
            stickyHeader
            hideHeader={isMobile}
            isLoading={isLoading}
        />
    );
}
