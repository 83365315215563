export enum PayType {
    Hourly = 'Hourly',
    Salaried = 'Salaried',
}

export enum PayRateType {
    PER_HOUR = 'per hour',
    PER_WEEK = 'per week',
    BI_WEEKLY = 'bi-weekly',
    SEMI_MONTHLY = 'semi-monthly',
    MONTHLY = 'monthly',
    DAILY = 'daily',
}

export enum PayPeriodType {
    Weekly = 'weekly',
    BiWeekly = 'bi_weekly',
}

//Using string values for WeekDayEnum to match backend values
export enum WeekDayEnum {
    Monday = '0',
    Tuesday = '1',
    Wednesday = '2',
    Thursday = '3',
    Friday = '4',
    Saturday = '5',
    Sunday = '6',
}
