import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { axiosInstance } from 'shared/utils/axios/axios';

export interface IApiResponse<DataModel> {
    data: DataModel;
    status: number;
}

const baseApi = {
    axiosInstance: axiosInstance,
    clientId: null as string | null,
    get: async <Response>(
        url: string,
        params?: any,
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse<Response>> =>
        axiosInstance
            .get<Response>(url, { params, ...config })
            .catch(error => {
                throw error;
            }),
    create: async <Request, Response>(
        service: string,
        entity: string,
        body: Request,
        params?: any,
    ): Promise<AxiosResponse<Response>> =>
        axiosInstance.post<Response>(`/${service}/${entity}`, body, {
            params,
        }),
    /**
     * @deprecated
     */
    updateById: async <Request, Response>(
        service: string,
        entity: string,
        id: string,
        body: Request,
    ): Promise<AxiosResponse<Response>> =>
        axiosInstance
            .put<Response>(`/${service}/${entity}/${id}`, body)
            .catch(error => {
                throw error;
            }),

    patch: async <Request, Response>(
        url: string,
        body: Request,
    ): Promise<AxiosResponse<Response>> =>
        axiosInstance.patch<Response>(url, body).catch(error => {
            throw error;
        }),

    post: async <Request, Response>(
        url: string,
        body: Request | undefined = undefined,
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse<Response>> =>
        axiosInstance.post<Response>(url, body, config),

    put: async <Request, Response>(
        url: string,
        body: Request,
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse<Response>> =>
        axiosInstance.put<Response>(url, body, config),

    delete: async <Response>(
        url: string,
        id: string,
    ): Promise<AxiosResponse<Response>> =>
        axiosInstance.delete<Response>(`${url}/${id}`).catch(error => {
            if (error.response?.status === 404 && error.config?.method === 'delete') {
                return {
                    data: { status: 'OK' } as unknown as Response,
                    status: 204,
                    statusText: 'No Content',
                    headers: error.response?.headers,
                    config: error.config,
                    request: error.request,
                };
            }
            throw error;
        }),
    deleteRaw: async <Response>(
        url: string,
    ): Promise<AxiosResponse<Response>> =>
        axiosInstance.delete<Response>(`${url}`).catch(error => {
            if (error.response?.status === 404 && error.config?.method === 'delete') {
                return {
                    data: { status: 'OK' } as unknown as Response,
                    status: 204,
                    statusText: 'No Content',
                    headers: error.response?.headers,
                    config: error.config,
                    request: error.request,
                };
            }
            throw error;
        }),
    head: async <Response>(
        url: string,
        params?: any,
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse<Response>> =>
        axiosInstance
            .head<Response>(url, { params, ...config })
            .catch(error => {
                throw error;
            }),
};

export default baseApi;
