import { IOfferLetterRowWithClient } from 'modules/offerLetter/components/OfferLetterTable/cells/employeeCells';
import { IOfferLetter, OfferLetterStatusSlug } from 'modules/offerLetter/store/model';
import {
    selectMyEmployeeProfilesByClientIds,
    selectOfferLetterPdfIsAvailable,
    selectOfferLetterPdfIsLoading,
    selectOfferLetters,
} from 'modules/offerLetter/store/selectors';
import { createSelector } from 'reselect';
import { selectAllClientsById } from 'store/entities/clients/selectors/clientsSelectors';
import { selectCustomFieldValuesByIds } from 'store/entities/customFields/selectors';
import { CustomFieldType } from '../../../../store/entities/customFields/model';

export const selectEmployeeOfferLetterRows = createSelector(
    selectOfferLetters,
    selectOfferLetterPdfIsLoading,
    selectOfferLetterPdfIsAvailable,
    selectAllClientsById,
    selectMyEmployeeProfilesByClientIds,
    selectCustomFieldValuesByIds,
    (
        offerLettersByIds,
        isPdfCheckingById,
        isPdfAvailableById,
        clientsById,
        employeeProfilesByClientIds,
        customFieldValuesByIds,
    ): IOfferLetterRowWithClient[] => {
        // @ts-ignore
        return (Object.values(offerLettersByIds) as IOfferLetter[])
            .map((offerLetter: IOfferLetter) => {
                const jobOrderId = offerLetter.custom_field_value_ids && offerLetter.custom_field_value_ids.length > 0
                    ? offerLetter.custom_field_value_ids.find(cfv_id =>
                        customFieldValuesByIds[cfv_id]?.data?.headway_connect_field === CustomFieldType.AvionteJobOrder)
                    : undefined;
                const jobOrder = customFieldValuesByIds[jobOrderId];
                const worksiteAddressRaw = jobOrder?.data?.worksite_address;
                let worksiteAddress = '';
                if (worksiteAddressRaw) {
                    const address = `${worksiteAddressRaw.address1 ?? ''} ${worksiteAddressRaw.address2 ?? ''}`.trim();
                    worksiteAddress = `${address}, ${worksiteAddressRaw.city}, ${worksiteAddressRaw.state} ${worksiteAddressRaw.zip_code}`.trim();
                }
                const positionId = offerLetter.custom_field_value_ids && offerLetter.custom_field_value_ids.length > 0
                    ? offerLetter.custom_field_value_ids.find(cfv_id =>
                        customFieldValuesByIds[cfv_id]?.data?.headway_connect_field === CustomFieldType.Position)
                    : undefined;
                const locationId = offerLetter.custom_field_value_ids && offerLetter.custom_field_value_ids.length > 0
                    ? offerLetter.custom_field_value_ids.find(cfv_id =>
                        customFieldValuesByIds[cfv_id]?.data?.headway_connect_field === CustomFieldType.Location)
                    : undefined;
                const departmentId = offerLetter.custom_field_value_ids && offerLetter.custom_field_value_ids.length > 0
                    ? offerLetter.custom_field_value_ids.find(cfv_id =>
                        customFieldValuesByIds[cfv_id]?.data?.headway_connect_field === CustomFieldType.NameCode)
                    : undefined;
                const jobOrderDisplayValue = (jobOrderId
                    && customFieldValuesByIds[jobOrderId]?.data?.name) ?? '';
                const positionDisplayValue = (customFieldValuesByIds[positionId]?.data?.name) ?? '';
                const locationDisplayValue = (locationId && customFieldValuesByIds[locationId]?.data?.name) ?? '';
                const departmentDisplayValue = departmentId && customFieldValuesByIds[departmentId]?.data?.name;
                return {
                    offerLetter: {
                        ...offerLetter,
                        isPdfLoading: isPdfCheckingById[offerLetter.id],
                        isPdfAvailable: isPdfAvailableById[offerLetter.id],
                        userCanContinueOnboarding: offerLetter?.status?.slug === OfferLetterStatusSlug.Outstanding,
                    },
                    isPdfLoading: isPdfCheckingById[offerLetter.id],
                    isPdfAvailable: isPdfAvailableById[offerLetter.id],
                    client: clientsById[offerLetter.client_id],
                    positionDisplayValue: positionDisplayValue,
                    locationDisplayValue: locationDisplayValue,
                    departmentDisplayValue: departmentDisplayValue,
                    jobOrderDisplayValue: jobOrderDisplayValue,
                    worksiteAddress: worksiteAddress,
                };
            });
    },
);
