import React from 'react';
import { EafApprovalCellProps } from 'modules/eafApproval/store/model/types';
import { useSelector } from 'react-redux';
import PlainText from 'shared/components/table/Cells/PlainText';
import { selectClientById } from 'store/entities/clients/selectors/clientsSelectors';

export const EafApprovalCellClient = ({ client_id, className }: EafApprovalCellProps) => {
    const client = useSelector(selectClientById(client_id));
    return <PlainText value={client?.name || ''} className={className} />;
};
