import { useFeature } from '@optimizely/react-sdk';
import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { selectAttachmentObjectUrlById } from 'shared/components/attachments/store/selectors';
import { HeadwayLoader } from 'shared/components/loader/HeadwayLoader';
import { IAttachmentPreviewProps } from 'shared/components/attachments/model';
import { FeatureSwitches } from 'utils/featureSwitches';
import { useUploadStyles } from './styles';
import clsx from 'clsx';

export const AttachmentPreviewImage = ({
    attachment,
    className,
}: IAttachmentPreviewProps) => {
    const classes = useUploadStyles();
    const objectUrl = useSelector(selectAttachmentObjectUrlById(attachment.id));
    const [enableSecureAttachments] = useFeature(FeatureSwitches.enableSecureAttachmentUrl);

    if (!objectUrl && enableSecureAttachments) {
        return (
            <Box mt={3}>
                <HeadwayLoader />
            </Box>
        );
    }

    return (
        <img
            className={clsx(classes.img, classes.previewImg, className)}
            src={enableSecureAttachments ? objectUrl : attachment.url}
            alt={attachment.filename}
        />
    );
};
