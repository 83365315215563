import React from 'react';

import { useSelector } from 'react-redux';

import { IPhysicalDemandsTemplate } from 'store/entities/configuration/configurationModel';
import {
    selectIsPhysicalDemandsTemplatesLoading,
    selectPhysicalDemands,
} from 'store/entities/configuration/configurationSelectors';

import {
    TemplatesTable,
    TemplateTableEntityName,
} from 'modules/settings/submodules/clients/components/PhysicalDemandsAndWorkingConditions/TemplatesTable';
import { removePhysicalDemandsTemplate } from 'store/entities/configuration/configurationAction';
import { setEditPhysicalDemandsTemplateId } from 'modules/settings/submodules/clients/physicalDemands/store/action';
import EditPhysicalDemandsModal
    from 'modules/settings/submodules/clients/physicalDemands/EditPhysicalDemands/EditPhysicalDemandsModal';

interface IPhysicalDemandsRow extends IPhysicalDemandsTemplate {
    className?: string;
}

export const PhysicalDemandsTable = () => {

    const items = useSelector(selectPhysicalDemands) as IPhysicalDemandsRow[];
    const isLoading = useSelector(selectIsPhysicalDemandsTemplatesLoading);

    return (
        <>
            <TemplatesTable
                templates={items}
                isLoading={isLoading}
                deleteAction={removePhysicalDemandsTemplate.init}
                editAction={setEditPhysicalDemandsTemplateId}
                entityName={TemplateTableEntityName.PhysicalDemands}
            />
            <EditPhysicalDemandsModal />
        </>
    );
};
