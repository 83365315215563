import React from 'react';
import { Box } from '@material-ui/core';

import { ISheetCalculationBatch } from 'modules/payrollProcessorHub/store/model';
import { PayrollStatus } from 'modules/payrollProcessorHub/components/PayrollStatus/PayrollStatus';

import { CalculationGroupSidebarActions } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/components/calculationGroup/batch/CalculationGroupSidebarActions';
import { CalculationGroupDetailByType } from './CalculationGroupDetailByType';
import { SheetGroupTotalByType } from '../common/SheetGroupTotalByType';
import { EntryTypeTabSwitcher } from '../common/EntryTypeTabSwitcher';
import { SheetEntryTable } from '../common/SheetEntryTable';
import { useEntryTypeTabs } from '../../../hooks/useEntryTypeTabs';
import { useGroupedPayrollCalculationEntries } from '../../../utils';

export interface ICalculationGroupDetailProp {
    calculationGroup: ISheetCalculationBatch;
}

export const CalculationGroupDetail = ({ calculationGroup }: ICalculationGroupDetailProp) => {
    const hasTimeCalculations = calculationGroup.time_calculations?.length > 0;
    const disableTabSwitcher = (
        !calculationGroup.time_calculations.length || !calculationGroup.expense_calculations.length
    );
    const { activeTab, switchToTimeTab, switchToExpenseTab } = useEntryTypeTabs(hasTimeCalculations);

    const groupsByDay = useGroupedPayrollCalculationEntries(calculationGroup, activeTab);

    return (
        <Box>
            <EntryTypeTabSwitcher
                activeTab={activeTab}
                onTimeClick={switchToTimeTab}
                onExpenseClick={switchToExpenseTab}
                disabled={disableTabSwitcher}
            />

            <Box component="section" p={2}>
                <Box display="flex" flexDirection="row">
                    <CalculationGroupDetailByType
                        type={activeTab}
                        calculationGroup={calculationGroup}
                    />
                    <PayrollStatus
                        status={calculationGroup.payroll_status}
                        payPeriodEnd={calculationGroup.pay_period_ends_at}
                    />
                </Box>

                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    mt={3}
                >
                    <Box
                        justifyContent="flex-start"
                        flexGrow={1}
                        mb={2}
                    >
                        <CalculationGroupSidebarActions calculationGroup={calculationGroup} />
                    </Box>
                    <Box justifyContent="flex-end" flexGrow={1}>
                        {/* TODO: adjust types */}
                        <SheetGroupTotalByType
                            // @ts-ignore
                            sheetGroup={calculationGroup}
                            type={activeTab}
                        />
                    </Box>
                </Box>
            </Box>

            <SheetEntryTable
                type={activeTab}
                entriesByDay={groupsByDay}
            />
        </Box>
    );
};
