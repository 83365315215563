import React from 'react';
import { Box } from '@material-ui/core';
import { SubmittingOrgTable } from 'modules/settings/submodules/clients/submittingOrg/components/SubmittingOrgTable/SubmittingOrgTable';
import { CreateSubmittingOrgButton } from 'modules/settings/submodules/clients/submittingOrg/components/CreateSubmittingOrg/CreateSubmittingOrgButton';
import { SubmittingOrgFilter } from 'modules/settings/submodules/clients/submittingOrg/components/Filter/SubmittingOrgFilter';

import {
    getControllingOrgs, getSubmittingOrgGenworthBranches,
    getSubmittingOrgLocations,
} from 'store/entities/configuration/configurationAction';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';
import { Dispatch } from 'redux';

export function SubmittingOrgPage() {
    useWithClientEffect((dispatch: Dispatch, clientId?: string) => {
        dispatch(getControllingOrgs.init());
        dispatch(getSubmittingOrgLocations.init({ client_id: clientId }));
        dispatch(getSubmittingOrgGenworthBranches.init());
    });
    return (
        <>
            <Box display="flex" justifyContent="space-between"
                mb={2}>
                <CreateSubmittingOrgButton />
                <SubmittingOrgFilter />
            </Box>
            <SubmittingOrgTable />
        </>
    );
}
