import * as qs from 'query-string';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, Redirect } from 'react-router-dom';
import SplashPage from 'shared/components/SplashPage/SplashPage';
import { routes } from 'shared/routes';
import { confirmEmail } from 'store/components/emailConfirmation/actions';

const EmailConfirmation = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const searchParams = qs.parse(location.search);
    const { email, secret } = searchParams;

    useEffect(() => {
        if (email && secret) {
            dispatch(confirmEmail.init({
                email: email as string,
                secret_code: secret as string,
            }));
        }
    }, [dispatch, email, secret]);

    return (
        <>
            {email && secret ? (
                <SplashPage />
            ) : (
                <Redirect to={routes.AUTH.LOGIN} />
            )}
        </>
    );
};

export default EmailConfirmation;
