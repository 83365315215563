import React, { useRef } from 'react';
import { Button, Popover } from '@material-ui/core';
import useActionButtonStyles from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/buttons/ActionButtonStyles';
import FilterSVG from 'shared/components/icons/FilterSVG';

interface IFilterButtonProps {
    children: React.ReactNode;
    icon?: React.ReactNode;
    style?: object;
    isOpened: boolean;
    onOpen: () => void;
    onClose: () => void;
}

export const ButtonWithModal = ({
    children,
    isOpened,
    onOpen,
    onClose,
    style,
    icon = <FilterSVG />,
}: IFilterButtonProps) => {
    const buttonClasses = useActionButtonStyles();
    const buttonRef = useRef(null);

    return (
        <>
            <Button
                style={style}
                className={buttonClasses.default}
                ref={buttonRef}
                onClick={onOpen}
            >
                {icon}
            </Button>
            <Popover
                open={isOpened}
                onClose={onClose}
                anchorEl={buttonRef.current}
            >
                {children}
            </Popover>
        </>
    );
};
