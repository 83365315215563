import { useFeature } from '@optimizely/react-sdk';
import { useSelector } from 'react-redux';
import { selectClientEafRetroEnabled } from 'store/entities/clients/selectors/configurationSelectors';
import { FeatureSwitches } from 'utils/featureSwitches';

export const useIsEafRetroEnabledForClient = () => {
    const [enableEafRetro] = useFeature(FeatureSwitches.enableEafRetro);
    const showRetro = useSelector(selectClientEafRetroEnabled);
    return enableEafRetro && showRetro;
};
