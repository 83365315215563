import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from 'shared/components/auth/PrivateRoute';
import { settingsRoutes } from 'modules/settings/routes';
import { EmployeeListPage } from 'modules/settings/submodules/employees/EmployeeListPage';
import { CommonSettingsSideMenu } from 'modules/settings/submodules/components/CommonSettingsSideMenu/CommonSettingsSideMenu';

export const EmployeeRoutes = () => {
    return (
        <>
            <CommonSettingsSideMenu />
            <Switch>
                <PrivateRoute
                    path={settingsRoutes.EMPLOYEES.ROOT}
                    component={EmployeeListPage}
                />
            </Switch>
        </>
    );
};
