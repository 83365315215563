/* eslint-disable react/display-name */
import { useFeature } from '@optimizely/react-sdk';
import clsx from 'clsx';
import { capitalize } from 'lodash-es';
import { DeleteItemButton } from 'modules/settings/common/components/DeleteItemButton/DeleteItemButton';
import { EditItemButton } from 'modules/settings/common/components/EditItemButton/EditItemButton';
import {
    CustomFieldValueRowType,
    ICustomFieldValueCodeNameDescriptionRow,
    ICustomFieldValueCommonRow,
    ICustomFieldValueLocationRow,
    ICustomFieldValueNameCodeRow,
    ICustomFieldValueNameValueRow,
    ICustomValueAvionteJobOrderValueRow,
} from 'modules/settings/submodules/clients/customFieldValues/components/CustomFieldValuesTable/model';
import { setEditCustomFieldValueId } from 'modules/settings/submodules/clients/customFieldValues/store/actions';
import { selectIsReadonlyCustomFieldType } from 'modules/settings/submodules/clients/customFieldValues/store/selectors';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PlainText from 'shared/components/table/Cells/PlainText';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { Status } from 'shared/models/Status';
import { Permission } from 'store/components/auth/authModels';
import { deleteCustomFieldValue } from 'store/entities/customFields/actions';
import { CustomFieldType, IAddress } from 'store/entities/customFields/model';
import { FeatureSwitches } from 'utils/featureSwitches';

enum customFieldCellKey {
    Name = 'name',
    Code = 'code',
    Description = 'description',
    Value = 'value',
    State = 'state',
    Zipcode = 'zipcode',
    LocationCode = 'locationCode',
    Actions = 'actions',
    StatusKey = 'status',
    WorksiteLocation = 'worksiteLocation',
    JobId = 'jobId',
}

const useCustomFieldValuesCellDictionary = (isReadOnlyValue: boolean) => {
    const tableClasses = useDefaultTableStyles();

    return useMemo(
        () => ({
            [customFieldCellKey.Name]: {
                key: customFieldCellKey.Name,
                title: 'Name',
                render: ({ className, name }: ICustomFieldValueCommonRow) => {
                    return <PlainText className={className} value={name} />;
                },
            },
            [customFieldCellKey.Code]: {
                key: customFieldCellKey.Code,
                title: 'Code',
                render: ({
                    className,
                    code,
                }: ICustomFieldValueNameCodeRow | ICustomFieldValueCodeNameDescriptionRow) => {
                    return <PlainText className={className} value={code} />;
                },
            },
            [customFieldCellKey.Description]: {
                key: customFieldCellKey.Description,
                title: 'Description',
                render: ({ className, description }: ICustomFieldValueCodeNameDescriptionRow) => {
                    return <PlainText className={className} value={description} />;
                },
            },
            [customFieldCellKey.Value]: {
                key: customFieldCellKey.Value,
                title: 'Value',
                render: ({ className, value }: ICustomFieldValueNameValueRow) => {
                    return <PlainText className={className} value={value} />;
                },
            },
            [customFieldCellKey.State]: {
                key: customFieldCellKey.State,
                title: 'state',
                render: ({ className, state_code }: ICustomFieldValueLocationRow) => (
                    <PlainText className={className} value={state_code} />
                ),
            },
            [customFieldCellKey.Zipcode]: {
                key: customFieldCellKey.Zipcode,
                title: 'zip code',
                render: ({ className, zip_code }: ICustomFieldValueLocationRow) => (
                    <PlainText className={className} value={zip_code} />
                ),
            },
            [customFieldCellKey.LocationCode]: {
                key: customFieldCellKey.LocationCode,
                title: 'Location code',
                render: ({ className, prismFieldId }: ICustomFieldValueLocationRow) => (
                    <PlainText className={className} value={prismFieldId} />
                ),
            },
            [customFieldCellKey.StatusKey]: {
                key: customFieldCellKey.StatusKey,
                title: 'Status',
                render: ({ className, is_active }: ICustomFieldValueCommonRow) => {
                    const status = is_active ? Status.active : Status.inactive;
                    return <PlainText className={className} value={capitalize(status)} />;
                },
            },
            [customFieldCellKey.JobId]: {
                key: customFieldCellKey.JobId,
                title: 'Avionte Job Id',
                render: ({ className, job_id }: ICustomValueAvionteJobOrderValueRow) => {
                    return <PlainText className={className} value={job_id} />;
                },
            },
            [customFieldCellKey.WorksiteLocation]: {
                key: customFieldCellKey.WorksiteLocation,
                title: 'Worksite Location',
                render: ({ className, worksite_address }: ICustomValueAvionteJobOrderValueRow) => {
                    return <PlainText className={className} value={getLocationFromAddress(worksite_address)} />;
                },
            },
            [customFieldCellKey.Actions]: {
                key: 'actions',
                width: '70px',
                render: function ActionsCell({ className, id }: ICustomFieldValueCommonRow) {
                    return (
                        <div className={clsx(className, tableClasses.iconCell, tableClasses.detailsIcon)}>
                            <EditItemButton id={id} editAction={setEditCustomFieldValueId} />
                            {!isReadOnlyValue && (
                                <DeleteItemButton
                                    confirmation="Are you sure you want to delete custom field value?"
                                    id={id}
                                    deleteAction={deleteCustomFieldValue.init}
                                    permission={Permission.deleteClientSettingsEntities}
                                />
                            )}
                        </div>
                    );
                },
            },
        }),
        [isReadOnlyValue, tableClasses],
    );
};

export const useCustomFieldValueCells = (type: CustomFieldType) => {
    const isReadOnlyValue = useSelector(selectIsReadonlyCustomFieldType);
    const cellDictionary = useCustomFieldValuesCellDictionary(isReadOnlyValue);
    const [enableCustomFieldValueStatus] = useFeature(FeatureSwitches.enableCustomFieldValueStatus);

    return useMemo(() => {
        let cells: ICellInfo<CustomFieldValueRowType>[] = [];

        switch (type) {
            case CustomFieldType.Location:
                cells = [
                    cellDictionary[customFieldCellKey.Name],
                    cellDictionary[customFieldCellKey.LocationCode],
                    cellDictionary[customFieldCellKey.State],
                    cellDictionary[customFieldCellKey.Zipcode],
                ] as ICellInfo<ICustomFieldValueLocationRow>[];
                break;
            case CustomFieldType.NameCode:
                cells = [
                    cellDictionary[customFieldCellKey.Name],
                    cellDictionary[customFieldCellKey.Code],
                ] as ICellInfo<ICustomFieldValueNameCodeRow>[];
                break;
            case CustomFieldType.NameCodeDescription:
            case CustomFieldType.NameCodeDescriptionProjectClass:
            case CustomFieldType.ShiftCode:
                cells = [
                    cellDictionary[customFieldCellKey.Name],
                    cellDictionary[customFieldCellKey.Code],
                    cellDictionary[customFieldCellKey.Description],
                ] as ICellInfo<ICustomFieldValueCodeNameDescriptionRow>[];
                break;
            case CustomFieldType.AvionteJobOrder:
                cells = [
                    cellDictionary[customFieldCellKey.Name],
                    cellDictionary[customFieldCellKey.JobId],
                    cellDictionary[customFieldCellKey.WorksiteLocation],
                ] as ICellInfo<ICustomValueAvionteJobOrderValueRow>[];
                break;
            case CustomFieldType.NameValue:
            default:
                cells = [
                    cellDictionary[customFieldCellKey.Name],
                    cellDictionary[customFieldCellKey.Value],
                ] as ICellInfo<ICustomFieldValueNameValueRow>[];
        }
        return [
            ...cells,
            ...(enableCustomFieldValueStatus ? [cellDictionary[customFieldCellKey.StatusKey]] : []),
            cellDictionary[customFieldCellKey.Actions],
        ];
    }, [type, enableCustomFieldValueStatus, cellDictionary]);
};

const getLocationFromAddress = (address: IAddress) => {
    return `${address.city}, ${address.state} ${address.zip_code}`;
};
