import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import {
    IGroupedSheetCalculation,
    ISheetGroupIdWithClient,
    PayrollSheetStatuses,
} from 'modules/payrollProcessorHub/store/model';
import { PayrollButton } from 'modules/payrollProcessorHub/components/PayrollAction/PayrollButton';
import { ApproveButton } from 'modules/payrollProcessorHub/components/SheetStatusAction/ApproveButton';
import { RejectButton } from 'modules/payrollProcessorHub/components/SheetStatusAction/RejectButton';
import { DownloadSheetButton } from 'modules/payrollProcessorHub/components/DownloadSheet/DownloadSheetButton';
import { SendReminderButton } from 'modules/payrollProcessorHub/components/SendReminder/SendReminderButton';
import { useGroupedSheetDetailStyles } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/styles';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';
import { getSheetGroupName } from 'modules/payrollProcessorHub/store/helpers';
import { EditButton } from 'modules/payrollProcessorHub/components/PayrollAction/EditButton';
import { UnlockButton } from 'modules/payrollProcessorHub/components/PayrollAction/UnlockAction/UnlockButton';
import { selectIsLoadingCustomFields } from 'store/entities/customFields/selectors';
import {
    selectAllCheckedCalculationGroupsContainJobOrder,
    selectNoneCheckedCalculationGroupsContainJobOrder,
} from '../../../../../store/selectors';
import { AviontePayrollDownloadButton } from '../../../../PayrollAction/AviontePayrollDownloadButton';

export interface ISheetGroupActionsProp {
    sheetGroup: IGroupedSheetCalculation;
}

export const SheetGroupActions = ({ sheetGroup }: ISheetGroupActionsProp) => {
    const classes = useGroupedSheetDetailStyles();
    const groupIds: ISheetGroupIdWithClient[] = [{
        timeSheetId: sheetGroup.time_sheet_id,
        expenseSheetId: sheetGroup.expense_sheet_id,
        clientId: sheetGroup.client_id,
    }];
    const userHasPayrollPermission = useSelector(selectIsUserHasPermission(Permission.payrollProcessing));
    const userHasApprovePermission = useSelector(selectIsUserHasPermission(Permission.CanApprovePphSheet));
    const isLoading = useSelector(selectIsLoadingCustomFields);
    const shouldShowExport = useSelector(selectAllCheckedCalculationGroupsContainJobOrder)
        && !isLoading;
    const shouldShowProcess = useSelector(selectNoneCheckedCalculationGroupsContainJobOrder) && !isLoading;

    switch (sheetGroup.payroll_status) {
        case PayrollSheetStatuses.APPROVED: {
            if (shouldShowProcess) {
                return (
                    <Box className={classes.actions}>
                        <EditButton sheetGroup={sheetGroup} />
                        <UnlockButton groupIds={groupIds} />
                        <span>123</span>
                        {userHasPayrollPermission && (
                            <PayrollButton groupIds={groupIds} />
                        )}
                    </Box>
                );
            }
            if (shouldShowExport) {
                return (
                    <Box className={classes.actions}>
                        <EditButton sheetGroup={sheetGroup} />
                        <UnlockButton groupIds={groupIds} />
                        {userHasPayrollPermission && (
                            <AviontePayrollDownloadButton groupIds={groupIds} />
                        )}
                    </Box>
                );
            }
            return (
                <Box className={classes.actions}>
                    <EditButton sheetGroup={sheetGroup} />
                    <UnlockButton groupIds={groupIds} />
                </Box>
            );
        }
        case PayrollSheetStatuses.SUBMITTED: {
            if (!userHasApprovePermission) {
                return null;
            }
            return (
                <Box className={classes.actions}>
                    <ApproveButton
                        groupIds={groupIds}
                    />
                    <RejectButton
                        groupIds={groupIds}
                    />
                </Box>
            );
        }
        case PayrollSheetStatuses.MISSING: {
            return <EditButton sheetGroup={sheetGroup} />;
        }
        case PayrollSheetStatuses.OVERDUE: {
            return (
                <Box className={classes.actions}>
                    <SendReminderButton
                        timeSheetId={sheetGroup.time_sheet_id}
                        expenseSheetId={sheetGroup.expense_sheet_id}
                    />
                    <DownloadSheetButton
                        timeSheetId={sheetGroup.time_sheet_id}
                        expenseSheetId={sheetGroup.expense_sheet_id}
                        fileName={getSheetGroupName(sheetGroup)}
                    />
                </Box>
            );
        }
        default:
            return null;
    }
};
