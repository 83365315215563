import { ISummaryBlockData } from '../../../payrollProcessorHub/components/SheetSummary/SheetSummary';
import { useSelector } from 'react-redux';
import { ICcpTransactionRow, selectCcpTransactionsRows } from './store/selectors';
import React, { useMemo } from 'react';
import { CcpReconciledID } from '../../models/ccpModel';
import { dollarCounter } from '../../../../shared/utils/counters/dollarCounter';
import { Box, Hidden } from '@material-ui/core';
import DottedDivider from '../../../../shared/components/divider/DottedDivider';
import { SummaryBlock } from '../../../payrollProcessorHub/components/SheetSummary/SummaryBlock';
import { MobileSheetSummary } from '../../../payrollProcessorHub/components/SheetSummary/MobileSheetSummary';
import { useSheetSummaryStyles } from '../../../payrollProcessorHub/components/SheetSummary/styles';

export function CcpReconciledSummaryBlock() {
    const rows = useSelector(selectCcpTransactionsRows) as ICcpTransactionRow[];
    const classes = useSheetSummaryStyles();

    const totalCents = useMemo(() => {
        const currentRows = rows.filter(x => !x.parent_transaction_id && x.transaction_status_id === CcpReconciledID);
        return currentRows && currentRows.length > 0
            ? currentRows.map(x => parseFloat(x.amount) * 100)
                .reduce((aggregated, current) => current + aggregated) : 0;
    }, [rows]);

    const blocks: ISummaryBlockData[] = [
        {
            value: dollarCounter(totalCents),
            title: 'Total Amount',
            color: 'green',
            visible: true,
        },
    ] as ISummaryBlockData[];
    return (
        <>
            <Hidden xsDown>
                <Box className={classes.rootShort} justifyContent={'right'}>
                    {blocks.map((block, index) => (
                        <>
                            {index > 0 && <DottedDivider key={`divider-${block.title}`} height={40} />}
                            <SummaryBlock
                                key={block.title}
                                value={block.value}
                                title={block.title}
                                color={block.color}
                            />
                        </>
                    ))}
                </Box>
            </Hidden>
            <Hidden smUp>
                <Box className={classes.root}>
                    <MobileSheetSummary blocks={blocks} />
                </Box>
            </Hidden>
        </>
    );
}
