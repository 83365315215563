import React, { useCallback } from 'react';
import { AddTemplateButton } from 'modules/settings/submodules/clients/components/PhysicalDemandsAndWorkingConditions/AddTemplate/AddTemplateButton';
import CreateWorkingConditionsModal
    from 'modules/settings/submodules/clients/workingConditions/CreateWorkingConditions/CreateWorkingConditionsModal';
import { useDispatch } from 'react-redux';

import { setCreateWorkingConditionsTemplateModalState } from 'modules/settings/submodules/clients/workingConditions/store/actions';

export const CreateWorkingConditionsButton = () => {
    const dispatch = useDispatch();

    const openModal = useCallback(() => {
        dispatch(setCreateWorkingConditionsTemplateModalState(true));
    }, [dispatch]);
    return (
        <>
            <AddTemplateButton onClick={openModal} />
            <CreateWorkingConditionsModal />
        </>
    );
};
