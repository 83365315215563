import React, { useCallback, useState } from 'react';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectCcpSubmitModalStep, selectIsCcpSubmitting,
    selectIsOpenSubmitModal, selectPostCcpSubmitReport,
} from './store/selectors';
import { setConfirmSubmitCcpModal, setOpenSubmitCcpModal, SubmitCcpTransactionsSteps } from './store/actions';
import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import ActionButton from '../../../../shared/components/buttons/ActionButton';
import clsx from 'clsx';
import { useSubmitSheetStyles } from '../../../../shared/components/modals/SubmitSheets/SubmitSheetsStyles';
import { useCcpMainStyles } from '../../styles';
import { submitCcpTransactionAction } from '../../store/actions';

const stepTitleMapping: Record<SubmitCcpTransactionsSteps, string> = {
    [SubmitCcpTransactionsSteps.Confirm]: 'Submit Completed Transactions',
    [SubmitCcpTransactionsSteps.Results]: 'Submit Completed Transactions Results',
};

export const SubmitCcpTransactionsModal = () => {
    const dispatch = useDispatch();
    const submitStyles = useSubmitSheetStyles();
    const ccpStyles = useCcpMainStyles();

    const isOpened = useSelector(selectIsOpenSubmitModal);
    const isLoading = useSelector(selectIsCcpSubmitting);
    const status = {
        submit_ccp_confirmed: false,
    };
    const step = useSelector(selectCcpSubmitModalStep) as SubmitCcpTransactionsSteps;
    const postSubmit = useSelector(selectPostCcpSubmitReport);
    const [isAgreed, setAgreed] = useState(false);

    const onClose = useCallback(() => {
        dispatch(setOpenSubmitCcpModal(false));
        dispatch(setConfirmSubmitCcpModal(false));
    }, [dispatch]);

    const onAgreedChange = useCallback(({ target }: React.ChangeEvent<HTMLInputElement>) => {
        setAgreed(target.checked);
    }, [setAgreed]);

    const onSubmit = useCallback(() => {
        dispatch(submitCcpTransactionAction.init());
    }, [dispatch]);

    return (
        <ModalDialog
            title={stepTitleMapping[step]}
            isOpened={isOpened}
            onClose={onClose}
            modalProps={{
                showCloseIcon: true,
                customClasses: { content: submitStyles.paper, title: submitStyles.title },
            }}
            sidebarProps={{ anchor: 'bottom' }}
        >
            {step === SubmitCcpTransactionsSteps.Confirm && (
                <Box>
                    <Formik
                        initialValues={status}
                        onSubmit={onSubmit}
                    >
                        <Form>
                            <Box className={submitStyles.content}>
                                <FormControlLabel
                                    control={(
                                        <Checkbox className={submitStyles.warningCheckbox} color="primary"
                                            onChange={onAgreedChange} checked={isAgreed} />
                                    )}
                                    label="I have added all the receipts for each transaction"
                                    classes={{
                                        root: submitStyles.warningWrapper,
                                        label: clsx(submitStyles.typography, submitStyles.typography),
                                    }}
                                />
                                <Typography
                                    className={clsx(submitStyles.typography, submitStyles.warning)}
                                    data-testid="modal-request-test-about-approve"
                                >
                                You are submitting your completed
                                reconciled transactions. Once submitted,
                                the <b>charges are locked and it cannot be edited.</b>
                                </Typography>

                                <Box mt={4} className={ccpStyles.buttonWrapper}>
                                    <ActionButton
                                        isLoading={isLoading}
                                        customType="success"
                                        onClick={onSubmit}
                                        disabled={!isAgreed}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                    >
                                        Submit
                                    </ActionButton>
                                    <ActionButton
                                        variant="outlined"
                                        color="primary"
                                        customType="danger"
                                        onClick={onClose}
                                        isLoading={isLoading}
                                    >
                                        Cancel
                                    </ActionButton>
                                </Box>
                            </Box>
                        </Form>
                    </Formik>
                </Box>
            )
            }
            {
                step === SubmitCcpTransactionsSteps.Results && (
                    <Box className={submitStyles.content}>
                        <Typography
                            className={clsx(submitStyles.typography, submitStyles.warning)}
                            data-testid="modal-request-test-about-approve"
                        >
                            <b>{postSubmit.length}</b> transactions were submitted
                        </Typography>
                        <Box mt={4} className={ccpStyles.buttonWrapper}>
                            <ActionButton
                                customType="success"
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={onClose}
                                isLoading={isLoading}
                            >
                                OK
                            </ActionButton>
                        </Box>
                    </Box>
                )
            }
        </ModalDialog>
    );
};
