import { useEntriesByDay } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/utils/entriesByDay';
import React, { useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
    CLOCK_TIME_FORMAT,
    compareInOutTimeValue,
    transformBackendDateTimeToFormTime,
    transformTimeToBackendDateTime,
} from 'shared/models/DateTime';
import { selectTimeEntries, selectTimeSheetById } from 'store/entities/timesheet/selectors';
import withQuickEditEntry, { IQuickEditEntryComponentProps, IQuickEditEntryProps, QuickEditActionEntry } from './withQuickEditEntry';
import IntervalDataField, { IntervalInputProps } from 'shared/components/formSpecialFields/intervalField/IntervalDataField';
import { IBreakEntryDataBackend, IInOutBreakEntryData, IInOutEntryDataBackend, ITimeEntry, QuantityType } from 'shared/models/sheet/Sheet';
import { updateTimeEntry } from 'store/entities/timesheet/actions/timeActions';
import * as yup from 'yup';
import { ValidationMessages } from 'shared/models/Validation';
import { checkNoIntersections, getTimeInTimeOutValidation } from 'shared/models/validationSchemes/timeEntry';
import TimeInOutQuickEditPicker from './TimeInOutQuickEditPicker';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core';
import { FormikValues } from 'formik';
import { incrementDateIfEntryIsNightShift } from 'store/entities/timesheet/models/Entry';
import { selectAllowTimeEntryWithoutEndTime } from 'store/entities/clients/selectors/configurationSelectors';
import { SheetStatusSlug } from 'store/entities/timesheet/models/Status';

const useInOutTimeFieldStyles = makeHighPriorityStyles((theme: Theme) => ({
    divider: {
        margin: theme.spacing(0, 1),
        lineHeight: `23px`,
    },
}));

interface ITimeInOutQuickEditProps extends IQuickEditEntryComponentProps{
}

export function TimeInOutQuickEditPure({
    disabled,
    value,
    name,
    submitForm,
}: ITimeInOutQuickEditProps) {
    const classes = useInOutTimeFieldStyles();
    const { current: inputs } = useRef([
        {
            name: 'timeIn',
            label: '',
        },
        {
            name: 'timeOut',
            label: '',
        },
    ]);

    const convert = useCallback((unconverted: string) => unconverted, []);
    const compare = useCallback(compareInOutTimeValue, []);

    const Input = useMemo(() => {
        function QuickEditInput({ ...props }: IntervalInputProps<string>) {
            return (
                <TimeInOutQuickEditPicker {...props}
                    onChange={(evt: (string | React.ChangeEvent<HTMLInputElement>)) => {
                        if (props.onChange){
                            props.onChange(evt);
                        }
                        submitForm();
                    }} />
            );
        }
        return QuickEditInput;
    }, [submitForm]);

    return (
        <IntervalDataField
            Input={Input}
            inputs={inputs}
            name={name}
            disabled={disabled}
            showTotalInput={false}
            compare={compare}
            convert={convert}
            entryType={value.entry_type}
            showDivider
            Divider={() => <p className={classes.divider}> - </p>}
        />
    );
}

export default function TimeInOutQuickEdit(props: IQuickEditEntryProps<typeof updateTimeEntry.success>) {
    const timeEntries = useSelector(selectTimeEntries);
    const filteredTimeEntries = useMemo(() => {
        return timeEntries.filter(entry => entry.id !== props.entry?.id);
    }, [timeEntries, props.entry?.id]);
    const timeEntriesByDays = useEntriesByDay(filteredTimeEntries) as Record<string, ITimeEntry[]>;
    let emptyTimeOutAllowed = useSelector(selectAllowTimeEntryWithoutEndTime);
    const sheet = useSelector(selectTimeSheetById(props.entry.sheet_id));
    if (emptyTimeOutAllowed) {
        emptyTimeOutAllowed = sheet?.status.slug !== SheetStatusSlug.APPROVED;
    }

    const schema = useMemo(() => {
        return yup.object().shape({
            data: yup.object()
                .nullable()
                .required(ValidationMessages.REQUIRED)
                .concat(getTimeInTimeOutValidation(yup.object(), emptyTimeOutAllowed))
                .test({
                    name: 'NoIntersections',
                    test: function (value: IInOutBreakEntryData | null): boolean {
                        if (!value || value.entry_type !== QuantityType.TIME_IN_OUT_BREAK) {
                            return true;
                        }
                        return checkNoIntersections(value, props.entry.entry_date, timeEntriesByDays);
                    },
                    message: ValidationMessages.NO_INTERSECTIONS,
                    exclusive: true,
                }),
        });
    }, [props.entry.entry_date, timeEntriesByDays, emptyTimeOutAllowed]);
    return withQuickEditEntry({
        action: updateTimeEntry.init,
        schema,
        getInitialValues: (entryData: IInOutEntryDataBackend | IBreakEntryDataBackend) => ({
            timeIn: transformBackendDateTimeToFormTime(entryData.time_in),
            timeOut: entryData.time_out ? transformBackendDateTimeToFormTime(entryData.time_out) : null,
            entry_type: entryData.entry_type,
        }),
        transformFormValues: (
            entryData: FormikValues,
            entry: QuickEditActionEntry<typeof updateTimeEntry.success>,
        ) => ({
            ...entry.data,
            time_in: transformTimeToBackendDateTime({
                time: entryData.timeIn,
                date: entry.entry_date,
                timeFormat: CLOCK_TIME_FORMAT,
            }),
            time_out: entryData.timeOut ? transformTimeToBackendDateTime({
                time: entryData.timeOut,
                date: incrementDateIfEntryIsNightShift(entryData.timeIn, entryData.timeOut, entry.entry_date),
                timeFormat: CLOCK_TIME_FORMAT,
            }) : null,
        }),
        extraData: (entry: QuickEditActionEntry<typeof updateTimeEntry.success>) => ({
            sca_zone: entry.sca_zone,
        }),
    })(TimeInOutQuickEditPure)(props);
}
