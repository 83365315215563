import { useFeature } from '@optimizely/react-sdk';
import { useMemo } from 'react';
import { FeatureSwitches } from 'utils/featureSwitches';
import * as yup from 'yup';
import { ValidationMessages } from 'shared/models/Validation';

export function useEmployeeValidationSchema(initialEmail: string) {
    const [enableEditEmployeePrismId] = useFeature(FeatureSwitches.enableEditEmployeePrismId);

    return useMemo(() => {
        let baseSchema = yup.object({
            first_name: yup.string().trim().required(ValidationMessages.REQUIRED),
            last_name: yup.string().trim().required(ValidationMessages.REQUIRED),
            email: yup.string().nullable()
                .email('Please enter valid email')
                .required(ValidationMessages.REQUIRED),
            phone: yup.string().nullable()
                .min(10, 'Please enter valid phone')
                .required(ValidationMessages.REQUIRED),

            address: yup.string().trim().required(ValidationMessages.REQUIRED),
            address2: yup.string().trim().matches(/^((?!#).)*$/, 'Please remove special characters'),
            city: yup.string().trim().required(ValidationMessages.REQUIRED),
            county: yup.string().trim().required(ValidationMessages.REQUIRED),
            state: yup.string().trim().required(ValidationMessages.REQUIRED),
            zip_code: yup.string().trim().required(ValidationMessages.REQUIRED),

            emailConfirmation: yup.string()
                .test({
                    name: 'confirmationShouldBeEqualToEmail',
                    test: function (value: string): boolean {
                        return initialEmail === this.parent.email || value === this.parent.email;
                    },
                    message: 'Email address does not match.',
                }),
        });
        if (enableEditEmployeePrismId) {
            baseSchema = baseSchema.concat(yup.object({
                prism_employee_id: yup.string().trim().required(ValidationMessages.REQUIRED),
            }));
        }
        return baseSchema;
    }, [initialEmail, enableEditEmployeePrismId]);
}
