import React, { useCallback, useMemo } from 'react';
import { ITableGroup } from 'shared/components/table/GridTable/GridTableGroup';
import { ITableGroupHeaderProps } from 'shared/components/table/VirtualizedGridTable/model';
import {
    IVirtualGridTableProps,
    VirtualInfinityGridTable,
} from 'shared/components/table/VirtualizedGridTable/VirtualizedGridTable';
import { VirtualizedGridTableRowPure } from 'shared/components/table/VirtualizedGridTable/VirtualizedGridTableRowPure';

export interface IVirtualGroupedGridTableProps<RowData> extends Omit<IVirtualGridTableProps<RowData>, 'rowData'>{
    groups: ITableGroup<RowData>[];
    RenderGroupHeader: (props: ITableGroupHeaderProps<RowData>) => React.ReactElement;
    onOpenGroup?: (group: ITableGroup<RowData>) => void;
    isGroupOpen: (group: ITableGroup<RowData>) => boolean;
}

export function VirtualizedGroupedGridTable<RowData>({
    groups,
    RenderGroupHeader,
    onOpenGroup,
    isGroupOpen,
    totalCount: totalCountProps,
    ...props
}: IVirtualGroupedGridTableProps<RowData>) {
    const rowData = useMemo(() => {
        const result: (RowData | ITableGroup<RowData>)[] = [];
        groups.forEach(group => {
            result.push(group);
            if (isGroupOpen(group)) {
                result.push(...group.rows);
            }
        });
        return result;
    }, [groups, isGroupOpen]);
    const totalCount = useMemo(() => {
        return groups.reduce((result, group) => {
            if (isGroupOpen(group)) {
                result += group.rows.length;
            }
            return result;
        }, totalCountProps);
    }, [groups, isGroupOpen, totalCountProps]);

    const RenderGroupedRowComponent = useCallback(headerOrRowProps => {
        if (headerOrRowProps.row.isGroup) {
            return (
                <RenderGroupHeader
                    group={headerOrRowProps.row}
                    onOpen={onOpenGroup}
                    isOpen={isGroupOpen(headerOrRowProps.row)}
                    style={headerOrRowProps.style}
                />
            );
        }
        return (
            <VirtualizedGridTableRowPure {...headerOrRowProps} />
        );
    }, [RenderGroupHeader, isGroupOpen, onOpenGroup]);

    return (
        <VirtualInfinityGridTable
            {...props}
            RenderRowComponent={RenderGroupedRowComponent}
            rowData={rowData}
            totalCount={totalCount}
        />
    );
}
