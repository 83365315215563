import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { ICustomFieldFormValues } from 'modules/settings/submodules/customFields/components/CustomFieldForm/model';
import { selectIsCreateCustomFieldModalOpened, selectIsCreatingCustomField } from 'modules/settings/submodules/customFields/components/CreateCustomField/store/selectors';
import { createCustomField, setCreateCustomFieldModalState } from 'modules/settings/submodules/customFields/store/actions';
import CustomFieldFormWrapper from 'modules/settings/submodules/customFields/components/CustomFieldForm/CustomFieldFormWrapper';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';

export const CreateCustomFieldModal = () => {
    const classes = useColoredFormModalStyles();
    const dispatch = useDispatch();

    const isOpen = useSelector(selectIsCreateCustomFieldModalOpened);
    const isLoading = useSelector(selectIsCreatingCustomField);

    const onSave = useCallback((values: ICustomFieldFormValues) => {
        dispatch(createCustomField.init({
            name: values.name,
            description: values.description,
            client_ids: values.all_clients ? [] : values.clients,
            all_clients: values.all_clients,
            export_field: values.mapping || '',
            headway_connect_field: 'name_code',
        }));
    }, [dispatch]);
    const onClose = useCallback(() => {
        dispatch(setCreateCustomFieldModalState(false));
    }, [dispatch]);

    return (
        <ModalDialog
            title="Create New Custom Field"
            isOpened={isOpen}
            onClose={onClose}
            modalProps={{
                customClasses: classes,
                showCloseIcon: true,
            }}
        >
            <Box>
                <CustomFieldFormWrapper
                    onSubmit={onSave}
                    submitTitle="Create"
                    isLoading={isLoading}
                />
            </Box>
        </ModalDialog>
    );
};
