import { groupBy, orderBy } from 'lodash-es';
import { moment } from 'utils/momentExtensions';
import { useFeature } from '@optimizely/react-sdk';
import { selectTimeAndExpenseActiveStatus } from 'modules/timeAndExpense/store/selectors';
import {
    resolvePto,
    resolvePtoMaxUseAvailable,
} from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/helpers/detailItemValueResolvers';
import { useSelector } from 'react-redux';
import { ISheetInfoItemData } from 'shared/components/sidebars/SheetDetail/SheetInfoItem';
import { formatUtcWithEstZone } from 'shared/models/Dates';
import { ISheet, ISheetLog } from 'shared/models/sheet/Sheet';
import { getUserName, getLastFirstName } from 'shared/utils/converters/user';
import { areaView } from 'shared/utils/formatters/areaFormatter';
import { formatApprovals, formatUserTimestamp } from 'shared/utils/formatters/formatApprovals';
import { logger } from 'shared/utils/logging/logger';
import {
    ApproversFromFields,
    DetailSlugs,
    IDetailConfiguration,
    SheetDetailSlug,
} from 'store/entities/clients/clientsModel';
import { selectClientTimeAndPayConfiguration } from 'store/entities/clients/selectors/timeAndPaySelectors';
import {
    selectAreaBySheetId,
    selectAssignmentById,
    selectDealById,
    selectJobNumberById,
    selectLocationById,
    selectPositionById,
    selectSubassignmentsByIds,
    selectSubmittingOrgById,
} from 'store/entities/configuration/configurationSelectors';
import { selectDepartmentById } from 'modules/employmentInfo/store/department/selectors';
import { selectSheet } from 'store/entities/timesheet/selectors';
import { useUserPto } from 'store/entities/users/hooks';
import { selectUserById, selectUsersById } from 'store/entities/users/selectors';
import { StatusNames } from 'store/entities/timesheet/models/Status';

import { rejectedReasonFormatter } from 'shared/utils/formatters/rejectedReasonFormatter';
import { IManagerInfo } from 'store/entities/configuration/configurationModel';
import { FeatureSwitches } from 'utils/featureSwitches';
import { getPayPeriodByStartEnd } from './payPeriod';
import { getCustomFieldsSheetInfoItems } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/store/helpers';
import { useSheetNonHiddenCustomFieldsWithValues } from '../hooks/customFieldsWithValuesHooks';
import { useFormattedPayRate } from './payRate';
import { pluralize } from './pluralize';
import { IUserInfo } from 'shared/models/User';

export const useDetailTitle = (sheet?: ISheet, config?: IDetailConfiguration<DetailSlugs>): string => {
    const area = useSelector(selectAreaBySheetId(sheet?.id || ''));
    const assignment = useSelector(selectAssignmentById(sheet?.assignment_id));
    const position = useSelector(selectPositionById(assignment?.position_id));
    const user = useSelector(selectUserById(sheet?.user_id));
    const jobNumber = useSelector(selectJobNumberById(sheet?.job_number_id));

    switch (config?.slug) {
        case SheetDetailSlug.Area: {
            return `${config?.placeholder} - ${areaView(area)}`;
        }
        case SheetDetailSlug.Position: {
            return position?.name || '';
        }
        case SheetDetailSlug.Employee: {
            return getLastFirstName(user);
        }
        case SheetDetailSlug.JobNumber: {
            return `Job #: ${jobNumber?.job_number || ''}`;
        }
        default:
            return '';
    }
};

export const formatEditedOn = (
    usersByIds: Record<string, IUserInfo>,
    logs: ISheetLog[],
): string => {
    const logsByUserId = groupBy(logs, item => item.actorId);
    const editedOn = Object.keys(logsByUserId).map(
        userId => {
            const userLogs = orderBy(
                logsByUserId[userId],
                [log => moment.utc(log.timestamp)],
                ['desc'],
            );
            return formatUserTimestamp(usersByIds[userId], userLogs[0].timestamp);
        },
    ).join('\n');
    return editedOn;
};

export const useSheetDetail = (
    sheetId: string,
    configuration: IDetailConfiguration<SheetDetailSlug>[] = [],
    showPTO?: boolean,
): ISheetInfoItemData[] => {
    const area = useSelector(selectAreaBySheetId(sheetId));
    const sheet = useSelector(selectSheet(sheetId));
    const payPeriod = getPayPeriodByStartEnd(sheet?.period_start || '', sheet?.period_end || '');
    const activeStatus = useSelector(selectTimeAndExpenseActiveStatus);
    const showReason = activeStatus === StatusNames.REJECTED;
    const assignment = useSelector(selectAssignmentById(sheet?.assignment_id));
    const subassignment = useSelector(selectSubassignmentsByIds)[sheet?.subassignment_id];
    const position = useSelector(selectPositionById(assignment?.position_id));
    const location = useSelector(selectLocationById(assignment?.location_id));
    const department = useSelector(selectDepartmentById(assignment?.department_id));
    const jobNumber = useSelector(selectJobNumberById(sheet?.job_number_id));
    const usersById = useSelector(selectUsersById);
    const [usePtoMaxUseAvailable] = useFeature(FeatureSwitches.usePtoMaxUseAvailable);

    const timeAndPayConfiguration = useSelector(selectClientTimeAndPayConfiguration);
    const approverFrom = timeAndPayConfiguration?.approversFrom || ApproversFromFields.FromAssignment;

    const jobNumberManagers = jobNumber?.manager_id ? [jobNumber.manager_id] : [];
    const approverIds: number[] = approverFrom === ApproversFromFields.FromAssignment
        ? subassignment?.managers?.map((manager: IManagerInfo) => manager.user_id) ?? []
        : jobNumberManagers;
    const approvers = approverIds.map(approverId => getUserName(usersById[approverId]));

    const user = usersById[sheet?.user_id || ''];
    const notes = rejectedReasonFormatter(sheet?.notes)[0];
    const batchId = sheet?.prism_batch_id;
    const deal = useSelector(selectDealById(jobNumber?.deal_id));
    const submittingOrg = useSelector(selectSubmittingOrgById(deal?.submitting_org_id));
    const usersByIds = useSelector(selectUsersById);
    const payRate = useFormattedPayRate(assignment ? {
        pay_rate_type: assignment.pay_rate_type,
        pay_rate_value: subassignment ? subassignment.override_rate_value : assignment.pay_rate_value,
    } : undefined);
    const pto = useUserPto(user?.id, sheet?.client_id);
    const [displaySheetApprovalTimestamp] = useFeature(FeatureSwitches.displaySheetApprovalTimestamp);
    const [displaySubmittedTimeForSheet] = useFeature(FeatureSwitches.displaySubmittedTimeForSheet);

    const dataViewDictionary: Record<SheetDetailSlug, () => string> = {
        [SheetDetailSlug.SheetId]: () => sheetId,
        [SheetDetailSlug.PayPeriod]: () => `${payPeriod}`,
        [SheetDetailSlug.Area]: () => areaView(area),
        [SheetDetailSlug.Position]: () => position?.name || '',
        [SheetDetailSlug.Location]: () => location?.name || '',
        [SheetDetailSlug.Department]: () => department?.name || '',
        [SheetDetailSlug.Approver]: () => displaySheetApprovalTimestamp ? '' : approvers.join(', '),
        [SheetDetailSlug.Employee]: () => getLastFirstName(user),
        [SheetDetailSlug.PayRate]: () => payRate,
        [SheetDetailSlug.Notes]: () => notes?.notes || '',
        [SheetDetailSlug.BatchId]: () => batchId || '',
        [SheetDetailSlug.DeliveryOrder]: () => '',
        [SheetDetailSlug.JobsiteLocation]: () => submittingOrg?.client_site_name,
        [SheetDetailSlug.JobNumber]: () => jobNumber?.job_number?.toString() || '',
        [SheetDetailSlug.DealNumber]: () => deal?.deal_number?.toString() || '',
    };
    const detailConfiguration = configuration.map(config => {
        let value = '';
        if (dataViewDictionary[config.slug]) {
            value = dataViewDictionary[config.slug]();
        } else {
            logger.error(new Error(`Sheet detail slug not found: ${config.slug}`));
        }
        return {
            title: config.placeholder,
            value,
        };
    });

    if (displaySheetApprovalTimestamp) {
        detailConfiguration.push({
            title: `Approving ${pluralize('Manager', approvers.length)}`,
            value: approvers.join(', '),
        });
        detailConfiguration.push({
            title: 'Approved Timestamp',
            value: formatApprovals(sheet?.approvals || [], usersByIds),
        });
    }

    showReason && detailConfiguration.push({
        title: 'Rejection Reason',
        value: dataViewDictionary[SheetDetailSlug.Notes](),
    });
    batchId && detailConfiguration.push({
        title: 'Prism BatchId',
        value: dataViewDictionary[SheetDetailSlug.BatchId](),
    });

    const sheetCustomFieldsWithValues = useSheetNonHiddenCustomFieldsWithValues(sheetId);
    const customFieldsItems = getCustomFieldsSheetInfoItems(sheetCustomFieldsWithValues);
    detailConfiguration.push(...customFieldsItems);
    showPTO && pto && detailConfiguration.push({
        title: 'Available PTO hours',
        value: usePtoMaxUseAvailable ? resolvePtoMaxUseAvailable(pto) : resolvePto(pto),
    });
    displaySubmittedTimeForSheet && sheet?.submitted_at && detailConfiguration.push({
        title: 'Submitted on',
        value: formatUtcWithEstZone(sheet.submitted_at),
    });

    return detailConfiguration;
};
