import { Box } from '@material-ui/core';
import { EafType, EmployeeActionFormData } from 'modules/employeeActionsForm/store/models';
import { SelectedCounterBox } from 'modules/subassignmentManagement/components/BulkOperations/SelectedCounterBox';
import { CompensationChangeForm } from 'modules/subassignmentManagement/components/CompensationChange/CompensationChangeForm';
import { ICompensationChangeFormValues } from 'modules/subassignmentManagement/components/CompensationChange/store/model';
import { BulkEditSubassignmentsFormStep1 } from 'modules/subassignmentManagement/components/EditSubAssignment/EditSubAssignmentForm/BulkEditSubassignmentsFormStep1';
import { AdditionalSteps } from 'modules/subassignmentManagement/components/EditSubAssignment/hooks';
import { PositionChangeForm } from 'modules/subassignmentManagement/components/PromotionDemotion/PositionChangeForm';
import { IPositionChangeFormValues } from 'modules/subassignmentManagement/components/PromotionDemotion/store/model';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { WarningAlertWithIcon } from 'shared/components/alerts/WarningAlertWithIcon';
import { PayRateType, PayType } from 'shared/models/PaySettings';
import { selectClientHideSubassignments } from 'store/entities/clients/selectors/configurationSelectors';
import { selectClientPaySettings } from 'store/entities/clients/selectors/timeAndPaySelectors';
import { selectCheckedAssignmentsApprovalLevel } from '../../SubassignmentTable/store/selectors';
import { selectEditStep } from '../store/selectors';
import { IBulkEditSubAssignmentFormValuesV2 } from './types';

export interface IBulkEditSubAssignmentFormV2CommonProps {
    setAdditionalSteps: (steps: AdditionalSteps) => void;
    onSubmit: (formValues: IBulkEditSubAssignmentFormValuesV2, additionalEafData?: EmployeeActionFormData) => void;
    payRateType: PayRateType;
    payType: PayType;
    isLoading: boolean;
    selectedCount: number;
    selectedEntityName: string;
    assignmentsOnly?: boolean;
}

export function BulkEditSubAssignmentFormV2Common({
    setAdditionalSteps,
    onSubmit,
    isLoading,
    payRateType,
    payType,
    selectedCount,
    selectedEntityName,
    assignmentsOnly,
}: IBulkEditSubAssignmentFormV2CommonProps) {
    const hideSubassignments = useSelector(selectClientHideSubassignments);
    const paySettings = useSelector(selectClientPaySettings);
    const numberOfApprovers = useSelector(selectCheckedAssignmentsApprovalLevel);
    const step = useSelector(selectEditStep);

    const [step1Values, setStep1FormValues] = useState<IBulkEditSubAssignmentFormValuesV2 | null>(null);
    const [step2Type, setStep2Type] = useState<EafType | null>(null);

    const onSubmitStep1 = useCallback((formValues: IBulkEditSubAssignmentFormValuesV2) => {
        setStep1FormValues(formValues);
        if (formValues.newPosition) {
            setStep2Type(EafType.Promotion);
            setAdditionalSteps({ [step]: 'Bulk edit - promotion/demotion' });
            return;
        }
        if (formValues.newPayRate) {
            setStep2Type(EafType.CompensationChange);
            setAdditionalSteps({ [step]: 'Bulk edit - compensation change' });
            return;
        }
        onSubmit(formValues);
    }, [onSubmit, setAdditionalSteps, step]);

    const onSubmitStep2CompensationChange = useCallback((formValues: ICompensationChangeFormValues) => {
        // @ts-ignore
        onSubmit(step1Values, {
            employee_action_form_type: EafType.CompensationChange,
            employee_action_form_reason: formValues.reason,
            effective_date: formValues.effectiveDate,
            new_base_pay_rate: formValues.newPayRate,
            is_retro: formValues.isRetro,
        });
    }, [onSubmit, step1Values]);

    const onSubmitStep2PromotionDemotion = useCallback((formValues: IPositionChangeFormValues) => {
        // @ts-ignore
        onSubmit(step1Values, {
            employee_action_form_type: formValues.type,
            effective_date: formValues.effectiveDate,
            new_base_pay_rate: formValues.newPayRate,
            new_position_custom_field_value_id: formValues.newPosition,
            is_retro: formValues.isRetro,
        });
    }, [onSubmit, step1Values]);

    const onBackStep1 = useCallback(() => {
        setStep2Type(null);
        setAdditionalSteps({ [step]: null });
    }, [setAdditionalSteps, step]);

    return (
        <Box>
            <SelectedCounterBox
                count={selectedCount}
                entityName={selectedEntityName}
            />

            <Box width="80%">
                {!step2Type && (
                    <BulkEditSubassignmentsFormStep1
                        isLoading={isLoading}
                        onSubmit={onSubmitStep1}
                        numberOfApprovers={numberOfApprovers}
                        // @ts-ignore
                        initialValuesOverride={step1Values}
                        assignmentsOnly={assignmentsOnly}
                    />
                )}
                {step2Type === EafType.CompensationChange && (
                    <CompensationChangeForm
                        isLoading={isLoading}
                        onSubmit={onSubmitStep2CompensationChange}
                        onCancel={onBackStep1}
                        // @ts-ignore
                        paySettings={paySettings}
                        initialValueOverride={{
                            newPayRate: step1Values?.newPayRate,
                        }}
                        payRateType={payRateType}
                        payType={payType}
                        readonlyPayRate
                    />
                )}
                {step2Type === EafType.Promotion && (
                    <>
                        {!hideSubassignments && (
                            <WarningAlertWithIcon>
                                This action will end the current sub-assignment and create a new one.
                            </WarningAlertWithIcon>
                        )}
                        <PositionChangeForm
                            // @ts-ignore
                            paySettings={paySettings}
                            isLoading={isLoading}
                            onSubmit={onSubmitStep2PromotionDemotion}
                            onCancel={onBackStep1}
                            initialValueOverride={{
                                newPayRate: step1Values?.newPayRate,
                                newPosition: step1Values?.newPosition,
                            }}
                            payRateType={payRateType}
                            payType={payType}
                            readonlyPayRate={Boolean(step1Values?.newPayRate)}
                            readonlyPosition
                        />
                    </>
                )}
            </Box>
        </Box>
    );
}
