import { offerCreate } from 'modules/offerLetter/components/CreateOffer/store/reducers';
import { offerEdit } from 'modules/offerLetter/components/EditOffer/store/reducers';
import { lookup } from 'modules/offerLetter/components/lookup/data/reducers';
import {
    getMyEmployeeProfile,
    getMyEmployeeProfiles,
    onboardMyEmployeeProfile,
} from 'modules/offerLetter/components/OfferLetterEmployeeDetail/store/actions';
import { employeeOfferDetail } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/store/reducers';
import { offerRescind } from 'modules/offerLetter/components/OfferRescission/store/reducers';
import { rejectOfferLetter } from 'modules/offerLetter/components/RejectOfferLetter/store/reducers';
import {
    acceptOfferLetterTerms,
    approveOfferPayRange,
    checkEndOfAssignmentPdfStatus,
    checkOfferLetterPdf,
    createOffer,
    editOffer,
    getEmployeeProfiles,
    getMoreOfferLetterAction,
    getMyOfferLetters,
    getOfferLetter,
    getOfferLetters,
    initialLoadOfferLetters,
    OfferLetterActions,
    rejectOfferPayRange,
    rescindOffer,
    setCheckedOfferLetterRow,
    setOfferLetterFilters,
    setOfferLetterTableSort,
    updateOfferLetter,
    updateOfferLetterStatus,
} from 'modules/offerLetter/store/actions';
import { IOfferLetter, IOfferLetterFilters, OfferLetterStatusSlug } from 'modules/offerLetter/store/model';
import { combineReducers } from 'redux';
import { IEmployeeProfile } from 'shared/models/EmployeeProfile';

import { ItemsById } from 'shared/models/ItemsById';
import { Order, SortingOrder } from 'shared/models/Order';

import {
    defaultInfinityScrollState,
    IInfinityScrollState,
    isLoadingReducer,
    itemsByIdReducer,
    itemsByIds,
    resetStateReducer,
    singleValueReducer,
    withMessageReducer,
} from 'store/reducerUtils';
import { ActionsReturnTypes, IActionsCreatorCommon } from 'store/utils';
import { extendReducer } from 'store/utils/reducers/extendReducer';

import moment from 'moment';
import { offerLetterTemplate } from './templates/reducer';

const initialState = {
    offerLettersById: {},
    employeeProfilesByClientId: {},
    startDates: {},
    offerLetterFilters: {
        startDate: '',
        search: '',
        statusSlug: OfferLetterStatusSlug.Outstanding,
        jobOrderId: null,
        departmentValueId: null,
        created_since: '01-01-' + String(moment().year()),
    },
    pdfCheck: {},
    pdfCheckEndOfAssignmentPdfById: {},
};

const getOfferLetterLoading = isLoadingReducer(getOfferLetter);
const myOfferLettersLoading = isLoadingReducer(getMyOfferLetters);
const isOfferLetterTermsAccepting = isLoadingReducer(acceptOfferLetterTerms);
const isOfferUpdating = isLoadingReducer(updateOfferLetter);

const offerLetterFilters = singleValueReducer<IOfferLetterFilters>(
    setOfferLetterFilters.action,
    // @ts-ignore
    initialState.offerLetterFilters,
);
const offerLetterTableSort = singleValueReducer<SortingOrder>(
    setOfferLetterTableSort.action,
    { start_date: Order.desc },
);

export function offerLetterById(
    state: ItemsById<IOfferLetter> = initialState.offerLettersById, action: OfferLetterActions,
): ItemsById<IOfferLetter> {
    switch (action.type) {
        case getMyOfferLetters.successType:
        case getOfferLetters.successType:
            return {
                ...state,
                ...itemsByIds(action.payload),
            };
        case updateOfferLetterStatus.successType:
        case updateOfferLetter.successType:
        case getOfferLetter.successType:
        case createOffer.successType:
        case editOffer.successType:
        case rescindOffer.successType:
            return {
                ...state,
                [action.payload.id]: action.payload,
            };
        default:
            return state;
    }
}

export function offerLettersTable(
    state: IInfinityScrollState<string>,
    action: OfferLetterActions,
): IInfinityScrollState<string> {
    state = state || defaultInfinityScrollState;
    switch (action.type) {
        case setOfferLetterFilters.action:
        case setOfferLetterTableSort.action:
        case initialLoadOfferLetters.action:
            return {
                ...defaultInfinityScrollState,
                isLoading: true,
            };
        case getMoreOfferLetterAction.errorType:
            return {
                ...state,
                isLoading: false,
            };
        case getMoreOfferLetterAction.successType:
            return {
                total: action.payload.total,
                items: [...state.items, ...action.payload.offerLetterIds],
                isLoading: false,
            };
        default:
            return state;
    }
}

function startDates(
    state: Record<string, string> = initialState.startDates, action: ActionsReturnTypes<typeof getOfferLetters>,
): Record<string, string> {
    switch (action.type) {
        case getOfferLetters.successType:
            // @ts-ignore
            return action.payload.reduce((acc, item) => ({
                ...acc,
                [item.start_date]: item.start_date,
            }), state);
        default:
            return state;
    }
}

// @ts-ignore
const getCheckPdfReducer = (actionCreator: IActionsCreatorCommon) => {
    const pdfCheckIsLoading = isLoadingReducer(actionCreator, false);
    const pdfCheckResult = withMessageReducer(actionCreator, null);

    const pdfCheck = combineReducers({
        isLoading: pdfCheckIsLoading,
        result: pdfCheckResult,
    });
    type PdfCheckState = ReturnType<typeof pdfCheck>;
    type PdfCheckStateById = Record<string, PdfCheckState>;

    return (
        state: PdfCheckStateById,
        action: ActionsReturnTypes<typeof actionCreator>,
    ): PdfCheckStateById => {
        const fixedState = state || initialState.pdfCheck;
        switch (action.type) {
            case actionCreator.initType:
            case actionCreator.successType:
            case actionCreator.errorType:
                return {
                    ...fixedState,
                    [action.payload.id]: {
                        ...(fixedState[action.payload.id] || {}),
                        ...pdfCheck(fixedState[action.payload.id] || {}, action),
                    },
                };
            default:
                return fixedState;
        }
    };
};

export const pdfCheckById = getCheckPdfReducer(checkOfferLetterPdf);
export const pdfCheckEndOfAssignmentPdfById = getCheckPdfReducer(checkEndOfAssignmentPdfStatus);

export function employeeProfilesByClientId(
    state: ItemsById<IEmployeeProfile> = initialState.employeeProfilesByClientId,
    action: OfferLetterActions,
): ItemsById<IEmployeeProfile> {
    switch (action.type) {
        case onboardMyEmployeeProfile.successType:
        case getMyEmployeeProfile.successType:
            return {
                ...state,
                [action.payload.client_id]: action.payload,
            };
        case getMyEmployeeProfiles.successType:
            return {
                ...state,
                // @ts-ignore
                ...action.payload.reduce((acc, item) => ({
                    ...acc,
                    [item.client_id]: item,
                }), {}),
            };
        default:
            return state;
    }
}

export const employeeProfilesByIds = itemsByIdReducer<IEmployeeProfile, IEmployeeProfile>(getEmployeeProfiles);

const checkedOfferLetterRowIds = extendReducer(
    singleValueReducer(setCheckedOfferLetterRow.action, {}),
    resetStateReducer(initialLoadOfferLetters.action, {}),
);

const isOfferPayRateApproving = isLoadingReducer(approveOfferPayRange);
const isOfferPayRateRejecting = isLoadingReducer(rejectOfferPayRange);

export const offerLetter = combineReducers({
    offerLetterFilters,
    offerLetterTableSort,
    getOfferLetterLoading,
    myOfferLettersLoading,
    offerLetterById,
    startDates,
    lookup,
    offerCreate,
    offerEdit,
    offerRescind,
    rejectOfferLetter,
    employeeOfferDetail,
    pdfCheck: pdfCheckById,
    pdfCheckEndOfAssignmentPdfById,
    template: offerLetterTemplate,
    employeeProfilesByClientId,
    offerLettersTable,
    employeeProfilesByIds,
    checkedOfferLetterRowIds,
    isOfferPayRateApproving,
    isOfferPayRateRejecting,
    isOfferLetterTermsAccepting,
    isOfferUpdating,
});
