import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
    selectExpandAllSubassignment,
} from 'modules/subassignmentManagement/components/SubassignmentTable/store/selectors';
import { setExpandAllSubassignments } from 'modules/subassignmentManagement/store/actions';
import { ExpandSvg } from 'shared/components/icons/ExpandSvg';
import useActionButtonStyles
    from 'shared/components/sheetApproval/filterAndActionControls/FilterAndActionControlsStyles';
import { withFeature } from 'shared/hoc/withFeature';
import { FeatureSwitches } from 'utils/featureSwitches';

interface IExpandAllSubassignmentButtonProps {
    className?: string;
}

export const ExpandAllSubassignmentButton = withFeature(
    FeatureSwitches.enableExpandAllAssignmentsButton,
    ({ className }: IExpandAllSubassignmentButtonProps) => {
        const classes = useActionButtonStyles();
        const dispatch = useDispatch();
        const expandAll = useSelector(selectExpandAllSubassignment);
        const handleClick = useCallback(() => {
            dispatch(setExpandAllSubassignments(!expandAll));
        }, [dispatch, expandAll]);

        return (
            <ToggleButtonGroup
                value={expandAll}
                onClick={handleClick}
                className={className}
            >
                <ToggleButton
                    value={true}
                    classes={{ root: clsx(classes.actionButton, classes.actionButtonToggle) }}
                >
                    <ExpandSvg />
                </ToggleButton>
            </ToggleButtonGroup>
        );
    },
);
