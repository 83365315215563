import React from 'react';
import { ArrowForwardIos } from '@material-ui/icons';
import { Box, Button, Collapse } from '@material-ui/core';
import { GridTableRows, IGridTableRowsProps } from 'shared/components/table/GridTable/GridTableRows';
import { ITableGroupHeaderProps } from 'shared/components/table/VirtualizedGridTable/model';

export interface ITableGroup<RowData> {
    id: string;
    rows: RowData[];
    isGroup: true;
}

export interface IGridTableGroupProps<RowData> extends IGridTableRowsProps<RowData> {
    group: ITableGroup<RowData>;
    renderGroupHeader?: (props: ITableGroupHeaderProps<RowData>) => React.ReactNode;
    isOpen: boolean;
    onOpen: () => void;
    displayExpandButton?: boolean;
}

export function GridTableGroup<RowData>({
    group,
    renderGroupHeader,
    isOpen,
    onOpen,
    displayExpandButton = true,
    ...rowsProps
}: IGridTableGroupProps<RowData>) {
    return (
        <>
            <Box className={rowsProps.classes.groupHeader}>
                <Box flexGrow={1}>
                    {renderGroupHeader && renderGroupHeader({ group, isOpen, onOpen })}
                </Box>
                {displayExpandButton && !isOpen && group.rows.length !== 0 && (
                    <Button
                        size="small"
                        color="primary"
                        onClick={onOpen}
                        data-testid="expand-table-group"
                    >
                        <ArrowForwardIos fontSize="inherit" />
                    </Button>
                )}
            </Box>
            <Collapse in={isOpen} unmountOnExit>
                <GridTableRows {...rowsProps} />
            </Collapse>
        </>
    );
}
