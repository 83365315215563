import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import Total from 'shared/components/toolbar/total/Total';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import useTotalInfoTabStyles
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/totalInfoTab/TotalInfoTabStyles';
import { ITotalInfoItem } from '../../components/TotalInfo/model';

interface ITotalInfoTabProps {
    groupedItems: ITotalInfoItem[][];
}

const TotalInfoTab = ({ groupedItems }: ITotalInfoTabProps) => {
    const [chosenTab, setChosenTab] = useState(0);
    const onChange = () => {
        let next = chosenTab + 1;
        if (next === groupedItems.length) {
            next = 0;
        } 
        setChosenTab(next);
    };
    const classes = useTotalInfoTabStyles();

    return (
        <Box className={classes.container}>
            {groupedItems.map((group, index) => (
                <React.Fragment key={index}>  
                    {index === chosenTab && (
                        <Box 
                            onClick={onChange}
                            className={classes.totalItem}
                        >
                            {group.map(totalItem => (
                                <Total
                                    key={totalItem.key}
                                    {...totalItem}
                                />
                            ))}
                        </Box>  
                    )}
                </React.Fragment>
            ))}
            {groupedItems.length > 1 && (
                <Box className={classes.containerFiber}>
                    {groupedItems.map((group, index) => (
                        <React.Fragment key={index}>  
                            {index === chosenTab ? (
                                <FiberManualRecordRoundedIcon className={classes.fiber} /> 
                            ) : (
                                <FiberManualRecordOutlinedIcon className={classes.fiber} />
                            )}
                        </React.Fragment>
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default TotalInfoTab;
