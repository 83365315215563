import { createOfferForCombinedUser, createOfferForUser } from 'modules/offerLetter/store/actions';
import React from 'react';
import { Box, Button } from '@material-ui/core';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import PlainText from 'shared/components/table/Cells/PlainText';
import GridTable from 'shared/components/table/GridTable/GridTable';
import { IUserInfo } from 'shared/models/User';
import { colors } from 'shared/styles/constants';
import { getLastFirstName } from 'shared/utils/converters/user';
import { formatPhoneNumber } from 'shared/utils/formatters/phoneNumberFormatter';
import { useIsMobile } from 'shared/utils/hooks/media';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import clsx from 'clsx';
import MobileListCell from 'shared/components/table/MobileListCell/MobileListCell';

const useStyles = makeHighPriorityStyles((theme: Theme) => ({
    tableHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1, 0, 1, 2),
        border: 'none !important',
        backgroundColor: `${colors.lightGray} !important`,
    },
    bodyCellMobile: {
        display: 'flex',
        padding: theme.spacing(1.5, 2, 0.5, 2),
        fontSize: theme.typography.pxToRem(11),
        borderBottom: `1px solid ${colors.lightGray}`,
        '& + &': {
            paddingLeft: 0,
        },
        '& button': {
            alignSelf: 'center',
            padding: theme.spacing(0.5, 1),
            fontSize: theme.typography.pxToRem(11),
        },
        '& p, & h5': {
            wordBreak: 'break-word',
        },
    },
    actionButton: {
        whiteSpace: 'nowrap',
        fontSize: theme.typography.pxToRem(11),
    },
}));

interface IEmployeeRow {
    className?: string;
    employee: IUserInfo;
}

const useCells = (isMobile: boolean, customClass: string, classes: ReturnType<typeof useStyles>) => {
    const dispatch = useDispatch();
    const actionColumn = {
        key: 'actions',
        width: '138px',
        title: '',
        render: function ActionCell({ employee, className }: IEmployeeRow) {
            return (
                <Box className={clsx(className, customClass)}>
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            if (employee.identity_id) {
                                dispatch(createOfferForUser(employee.identity_id));
                            } else {
                                dispatch(createOfferForCombinedUser(employee));
                            }
                        }}
                        className={classes.actionButton}
                    >
                        Create offer
                    </Button>
                </Box>
            );
        },
    };
    return isMobile
        ? [
            {
                key: 'employeeInfo',
                title: '',
                width: '1fr',
                render: function EmployeeInfo({ employee, className }: IEmployeeRow) {
                    const addressParts = [];
                    if (employee.state) {
                        // @ts-ignore
                        addressParts.push(`State: ${employee.state}`);
                    }
                    if (employee.zip_code) {
                        // @ts-ignore
                        addressParts.push(`Zipcode: ${employee.zip_code}`);
                    }
                    const cellItems = [`Email: ${employee.email}`];
                    if (addressParts.length) {
                        cellItems.push(addressParts.join(' | '));
                    }
                    return (
                        <MobileListCell
                            className={className}
                            title={getLastFirstName(employee)}
                            items={cellItems}
                        />
                    );
                },
            },
            actionColumn,
        ] : [
            {
                key: 'name',
                width: '2fr',
                title: 'Employee',
                render: function Employee({ employee, className }: IEmployeeRow) {
                    return (
                        <PlainText value={getLastFirstName(employee)} className={clsx(className, customClass)} />
                    );
                },
            },
            {
                key: 'email',
                width: '3fr',
                title: 'Email',
                render: function Email({ employee, className }: IEmployeeRow) {
                    return (
                        <PlainText value={employee.email} className={clsx(className, customClass)} />
                    );
                },
            },
            {
                key: 'id',
                width: '3fr',
                title: 'ID',
                render: function Id({ employee, className }: IEmployeeRow) {
                    return (
                        <PlainText value={employee.identity_id} className={clsx(className, customClass)} />
                    );
                },
            },
            {
                key: 'avionte_onboarding_completed',
                width: '3fr',
                title: 'Avionte ID',
                render: function Id({ employee, className }: IEmployeeRow) {
                    return (
                        <PlainText
                            value={employee.avionte_talent_id ?? ''}
                            className={clsx(className, customClass)}
                        />
                    );
                },
            },
            {
                key: 'cell',
                width: '2fr',
                title: 'Cell',
                render: function CellPhone({ employee, className }: IEmployeeRow) {
                    return (
                        <PlainText
                            value={formatPhoneNumber(employee.cell_phone)}
                            className={clsx(className, customClass)}
                        />
                    );
                },
            },
            {
                key: 'home',
                width: '2fr',
                title: 'Home',
                render: function Home({ employee, className }: IEmployeeRow) {
                    return (
                        <PlainText
                            value={formatPhoneNumber(employee.home_phone)}
                            className={clsx(className, customClass)}
                        />
                    );
                },
            },
            {
                key: 'state',
                width: '1fr',
                title: 'State',
                render: function State({ employee, className }: IEmployeeRow) {
                    return (
                        <PlainText value={employee.state} className={clsx(className, customClass)} />
                    );
                },
            },
            {
                key: 'zip_code',
                width: '1fr',
                title: 'Zipcode',
                render: function Zipcode({ employee, className }: IEmployeeRow) {
                    return (
                        <PlainText value={employee.zip_code} className={clsx(className, customClass)} />
                    );
                },
            },
            actionColumn,
        ];
};

export interface IEmployeeTableProps {
    employees: IUserInfo[];
}

const EmployeeTable = ({ employees }: IEmployeeTableProps) => {
    const classes = useStyles();
    const tableClasses = useDefaultTableStyles();
    const employeeRows = employees.map((employee): IEmployeeRow => ({ employee }));
    const isMobile = useIsMobile();
    const cells = useCells(
        isMobile,
        isMobile ? classes.bodyCellMobile : tableClasses.bodyCell,
        classes,
    );

    return (
        <GridTable
            rowData={employeeRows}
            headerCellClassName={classes.tableHeader}
            getKey={row => row.employee.identity_id}
            cells={cells}
            hideHeader={isMobile}
            stickyHeader
        />
    );
};

export default EmployeeTable;
