import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';
import { setOpenBulkEditSubassignmentModal } from 'modules/subassignmentManagement/components/EditSubAssignment/store/actions';
import { selectCheckedAssignmentIds, selectCheckedSubassignmentIds, subassignmentTableStateSelectors } from 'modules/subassignmentManagement/components/SubassignmentTable/store/selectors';
import { SubassignmentSelectionWarning } from 'modules/subassignmentManagement/components/SubassignmentTableActions/SubassignmentSelectionWarning';
import { useSubAssignmentTableStyles } from 'modules/subassignmentManagement/components/SubassignmentTable/styles';
import { setOpenBulkEndAssignmentModal } from 'modules/subassignmentManagement/components/BulkEndAssignment/store/actions';
import { selectClientHideSubassignments } from 'store/entities/clients/selectors/configurationSelectors';
import { BulkCreateV2Button } from 'modules/subassignmentManagement/components/SubassignmentTableActions/BulkCreateV2Button';
import {
    ExpandAllSubassignmentButton,
} from 'modules/subassignmentManagement/components/SubassignmentTableActions/ExpandAllSubassignments';

export const SubassignmentTableActions = () => {
    const classes = useSubAssignmentTableStyles();
    const dispatch = useDispatch();

    const handleEndClick = useCallback(() => {
        dispatch(setOpenBulkEndAssignmentModal(true));
    }, [dispatch]);

    const total = useSelector(subassignmentTableStateSelectors.selectTotalItems);
    const checkedAssignmentIds = useSelector(selectCheckedAssignmentIds);
    const checkedSubassignmentIds = useSelector(selectCheckedSubassignmentIds);
    const hasSelectedAssignments = Boolean(checkedAssignmentIds.length);
    const hasSelectedSubassignments = Boolean(checkedSubassignmentIds.length);
    const clientHideSubassignments = useSelector(selectClientHideSubassignments);

    const [openWarning, setIsOpenWarning] = useState(false);

    const handleEditClick = useCallback(() => {
        dispatch(setOpenBulkEditSubassignmentModal(true));
    }, [dispatch]);

    return (
        <Box display="flex" justifyContent="space-between">
            <Typography variant="h4">Search Results{total ? ` (${total})` : ''}</Typography>
            <Box
                className={classes.buttonContainer}
            >
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleEndClick}
                    disabled={!hasSelectedAssignments}
                    title={hasSelectedAssignments ? '' : 'Please choose employee'}
                >
                    Bulk End
                </Button>
                {clientHideSubassignments ? (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleEditClick}
                        disabled={!hasSelectedAssignments}
                        title={hasSelectedAssignments ? '' : 'Please choose employee'}
                    >
                        Bulk Edit
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleEditClick}
                        disabled={!hasSelectedSubassignments}
                        title={hasSelectedSubassignments ? '' : 'Please choose sub-assignments'}
                    >
                        Bulk Edit
                    </Button>
                )}
                <BulkCreateV2Button />
                {!clientHideSubassignments && (
                    <ExpandAllSubassignmentButton />
                )}
            </Box>
            <SubassignmentSelectionWarning
                open={openWarning}
                onClose={() => setIsOpenWarning(false)}
                countMultipleSubassignments={1}
            />
        </Box>
    );
};
