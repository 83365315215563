import { selectCurrentUserApprovalsLevelBySubassignmentId } from 'modules/sheetApproval/store/selectors';
import { loadAttachments } from 'shared/components/attachments/store/actions';
import { selectAttachmentObjectUrlByIds } from 'shared/components/attachments/store/selectors';
import { selectClientApproversFrom } from 'store/entities/clients/selectors/timeAndPaySelectors';
import {
    loadExpenseEntryAttachment,
    loadExpenseEntryAttachments,
} from 'store/entities/timesheet/actions/entryAttachments';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { call, put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import {
    addExpenseEntry,
    loadExpenseSheets,
    loadExpenseSheetsWithEntries,
    removeExpenseEntry, removeExpenseSheet,
    updateExpenseEntry,
    updateTemporaryExpenseEntry,
} from 'store/entities/timesheet/actions/expenseActions';
import { expenseApi } from 'store/entities/timesheet/api/expenseApi';
import { createAddEntrySaga, createDeleteEntrySaga, createUpdateEntrySaga } from 'store/entities/timesheet/sagas/utils';
import { withErrorHandler } from 'store/utils/sagas/withErrorHandler';
import { EntryType, IExpenseSheetBackend, IStatus } from 'shared/models/sheet/Sheet';
import { FeatureSwitches } from 'utils/featureSwitches';
import { optimizely } from 'utils/optimizely';
import { IUpdateSheetsStatus, IUpdateSheetStatus, StatusNames } from '../models/Status';
import {
    selectExpenseSheetStatusByName,
    selectExpensesSheetsByIds,
} from 'store/entities/timesheet/selectors';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import {
    selectApproversCountBySheets,
} from 'store/entities/configuration/configurationSelectors';
import { tryApproveSheet } from 'store/entities/timesheet/helpers';
import { selectExpenseEntryIncludingTravelsByIds } from 'store/entities/timesheet/expenseSelectors';

const addExpenseEntrySaga = createAddEntrySaga(
    EntryType.EXPENSE,
    expenseApi.createEntry,
    updateTemporaryExpenseEntry,
    addExpenseEntry.success,
    loadExpenseSheets.success,
    removeExpenseSheet,
    removeExpenseEntry.success,
);

function* addExpenseEntryWatcher() {
    yield takeEvery(
        addExpenseEntry.initType,
        withBackendErrorHandler(
            addExpenseEntrySaga,
            addExpenseEntry.error,
            'Cannot create expense entry',
        ),
    );
}

const updateExpenseEntrySaga = createUpdateEntrySaga(
    EntryType.EXPENSE,
    expenseApi.updateEntry,
    updateExpenseEntry.success,
    selectExpenseEntryIncludingTravelsByIds,
    removeExpenseSheet,
);

function* updateExpenseEntryWatcher() {
    yield* takeEvery(
        updateExpenseEntry.initType,
        withBackendErrorHandler(
            updateExpenseEntrySaga,
            updateExpenseEntry.error,
            'Cannot update expense entry',
        ),
    );
}

const removeExpenseEntrySaga = createDeleteEntrySaga(
    EntryType.EXPENSE,
    expenseApi.deleteEntry,
    removeExpenseEntry.success,
    selectExpenseEntryIncludingTravelsByIds,
    removeExpenseSheet,
);

function* removeExpenseEntryWatcher() {
    yield* takeEvery(
        removeExpenseEntry.initType,
        withBackendErrorHandler(
            removeExpenseEntrySaga,
            removeExpenseEntry.error,
            'Cannot delete expense entry',
        ),
    );
}

function* loadExpenseSheetsSaga({ payload }: ReturnType<typeof loadExpenseSheetsWithEntries.init>) {
    const { purpose, request } = payload;
    const sheets = yield* call(expenseApi.getSheetListByPurpose, purpose, request || {});
    yield* put(loadExpenseSheetsWithEntries.success(sheets));
}

function* loadExpenseSheetsWatcher() {
    yield takeEvery(loadExpenseSheetsWithEntries.initType, withErrorHandler(
        loadExpenseSheetsSaga,
        loadExpenseSheetsWithEntries.error,
        'Expense sheets were not loaded',
    ));
}

function* loadSimplifiedExpenseSheetsSaga({ payload }: ReturnType<typeof loadExpenseSheets.init>) {
    const { purpose, request } = payload;
    const sheets = yield* call(expenseApi.getSimplifiedSheetListByPurpose, purpose, request || {});
    yield* put(loadExpenseSheets.success(sheets));
}

function* loadSimplifiedExpenseSheetsWatcher() {
    yield takeLatest(loadExpenseSheets.initType, withErrorHandler(
        loadSimplifiedExpenseSheetsSaga,
        loadExpenseSheets.error,
        'Expense sheets were not loaded',
    ));
}

//the following function  is used to reject only since november 2020, approve is made with create approval api.
export function* updateExpenseSheetsStatusesSaga(sheetsIds: string[], status: IStatus, notes?: Record<string, string>) {
    const payload: IUpdateSheetsStatus = {
        sheets: sheetsIds.map(id => {
            const sheet: IUpdateSheetStatus = {
                id,
                status_id: status.id,
            };

            if (notes){
                sheet.notes = notes[id];
            }

            return sheet;
        }),
    };
    const updatedSheets = yield* call(
        expenseApi.updateSheetsStatuses,
        payload,
    );
    yield* put(loadExpenseSheetsWithEntries.success(updatedSheets));
}

export function* createExpenseSheetApprovalSaga(sheetsIds: string[]) {
    yield* call(
        expenseApi.createSheetApprovals, sheetsIds,
    );

    const sheetsById = yield* select(selectExpensesSheetsByIds);
    const clientId = yield* select(selectCurrentClientId);
    const currentUserApprovalLevelsBySubassignments = yield* select(selectCurrentUserApprovalsLevelBySubassignmentId);
    const approversFrom = yield* select(selectClientApproversFrom);
    const approversCountBySheets = yield* select(selectApproversCountBySheets(sheetsIds));
    const approvedStatus = yield* select(selectExpenseSheetStatusByName(StatusNames.APPROVED));
    const updatedSheets = sheetsIds.map(sheetId => {
        const sheet = {
            ...sheetsById[sheetId],
            entries: [],
        } as unknown as IExpenseSheetBackend;

        return tryApproveSheet(
            sheet,
            approversFrom,
            currentUserApprovalLevelsBySubassignments,
            approversCountBySheets,
            clientId,
            approvedStatus,
        );
    });
    yield* put(loadExpenseSheetsWithEntries.success(updatedSheets));
}

function* loadExpenseEntryAttachmentSaga(
    { payload: id }: ReturnType<typeof loadExpenseEntryAttachment.init>,
) {
    const attachmentBlob = yield* call(expenseApi.getEntryAttachment, id);
    const objectUrl = window.URL.createObjectURL(attachmentBlob);
    yield* put(loadAttachments.success([
        {
            id,
            objectUrl,
        },
    ]));
}

function* loadExpenseEntryAttachmentWatcher() {
    yield takeEvery(loadExpenseEntryAttachment.initType, withErrorHandler(
        loadExpenseEntryAttachmentSaga,
        loadExpenseEntryAttachment.error,
        'Expense entry attachment is not loaded',
    ));
}

function* loadExpenseEntryAttachmentsSaga({ payload: attachmentIds }: ReturnType<typeof loadExpenseEntryAttachments>) {
    const enableSecureAttachmentUrl = optimizely.isFeatureEnabled(FeatureSwitches.enableSecureAttachmentUrl);
    if (!enableSecureAttachmentUrl) {
        return;
    }

    const loadedAttachments = yield select(selectAttachmentObjectUrlByIds);
    const loadedAttachmentsIds = Object.keys(loadedAttachments);
    for (const attachmentId of attachmentIds) {
        if (!loadedAttachmentsIds.includes(attachmentId)) {
            yield put(loadExpenseEntryAttachment.init(attachmentId));
        }
    }
}

function* loadExpenseEntryAttachmentsWatcher() {
    yield takeEvery(
        loadExpenseEntryAttachments.action,
        loadExpenseEntryAttachmentsSaga,
    );
}

export default [
    addExpenseEntryWatcher,
    updateExpenseEntryWatcher,
    removeExpenseEntryWatcher,
    loadExpenseSheetsWatcher,
    loadSimplifiedExpenseSheetsWatcher,
    loadExpenseEntryAttachmentWatcher,
    loadExpenseEntryAttachmentsWatcher,
];
