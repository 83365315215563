import React from 'react';
import { useSelector } from 'react-redux';
import { CustomFieldValuesMultiselect, ICustomFieldValuesMultiselectProps } from 'shared/components/selects/CustomFieldValuesSelect/CustomFieldValuesMultiselect';
import { CustomFieldType } from 'store/entities/customFields/model';
import { selectCustomFieldById } from 'store/entities/customFields/selectors';
import { SCACustomFieldValueMultiselect } from '../SCACustomFieldValueSelect/SCACustomFieldValueMultiselect';

export const CustomFieldValueMultiselectGateway = (props: ICustomFieldValuesMultiselectProps) => {
    const customField = useSelector(selectCustomFieldById(props.customFieldId));

    if (customField?.prism_field?.key === CustomFieldType.SCA) {
        return (
            <SCACustomFieldValueMultiselect {...props} />
        );
    }

    return (
        <CustomFieldValuesMultiselect {...props} />
    );
};
