import { Box } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PlainText from 'shared/components/table/Cells/PlainText';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { ICellProps } from 'shared/components/table/GridTable/GridTableModel';
import { IEcubItem } from 'modules/settings/submodules/clients/reports/Ecub/store/models';
import { ActionCell } from 'modules/settings/submodules/clients/reports/Ecub/ActionCell';
import {
    selectEcubReportIsLoading,
    selectEcubReportItems,
} from 'modules/settings/submodules/clients/reports/Ecub/store/selectors';
import { VirtualInfinityGridTable } from 'shared/components/table/VirtualizedGridTable/VirtualizedGridTable';
import { monthTextDateFormat } from 'shared/models/Dates';
import { orderBy } from 'lodash-es';

export const EcubReportsTable = () => {
    const tableClasses = useDefaultTableStyles();
    const cells = useMemo(() => ([
        {
            key: 'name',
            title: 'name',
            width: '2fr',
            render: function NameCell({ className, name }: ICellProps<IEcubItem>){
                return (
                    <PlainText className={className} value={name.replace('.txt', '.xml')} />
                );
            },
        },
        {
            key: 'created',
            title: 'created at',
            render: function CreatedAtCell({ className, timestamp }: ICellProps<IEcubItem>){
                return (
                    <PlainText className={className} value={moment(timestamp).format(monthTextDateFormat)} />
                );
            },
        },
        {
            key: 'status',
            title: 'status',
            render: function StatusCell({ className, is_sent }: ICellProps<IEcubItem>){
                return (
                    <PlainText className={className} value={is_sent ? 'Sent' : 'Pending'} />
                );
            },
        },
        {
            key: 'actions',
            title: '',
            width: '120px',
            render: ActionCell,
        },
    ]), []);
    const isLoading = useSelector(selectEcubReportIsLoading);
    const rows = useSelector(selectEcubReportItems);
    const orderedRows = useMemo(() => orderBy(rows, 'timestamp', 'desc'), [rows]);
    const getKey = useCallback((row: IEcubItem) => row.name, []);
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const handleLoadMore = useCallback(() => {}, []);

    return (
        <Box
            display="flex"
            flexGrow={1}
            overflow="hidden"
        >
            <VirtualInfinityGridTable
                rowData={orderedRows}
                totalCount={orderedRows.length}
                getKey={getKey}
                cells={cells}
                isLoading={isLoading}
                headerCellClassName={tableClasses.headCell}
                bodyCellClassName={tableClasses.bodyCell}
                className={tableClasses.tableBorder}
                hasMore={isLoading}
                onLoadMore={handleLoadMore}
            />
        </Box>
    );
};
