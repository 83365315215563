import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { FormControl } from '@material-ui/core';
import { DatePicker, DatePickerProps, KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useField } from 'formik';
import { backendDateFormat, shortDateFormat } from 'shared/models/Dates';
import { useFormHelperTextStyles } from 'shared/styles/formHelperText';
import { useModal } from 'shared/utils/hooks/useModal';
import { IFormFieldProps } from './models';
import { v4 as uuidv4 } from 'uuid';

export interface IDayPickerFieldProps extends IFormFieldProps,
    Omit<Partial<DatePickerProps>, 'label' | 'name' | 'id'> {
    format?: string;
    disableToolbar?: boolean;
    withKeyboard?: boolean;
}

const DayPickerField = ({
    name,
    label,
    id = name,
    className,
    outerLabel,
    disabled = false,
    format = shortDateFormat,
    variant = 'inline',
    withKeyboard = false,
    inputVariant = 'outlined',
    showErrorText = true,
    ...props
}: IDayPickerFieldProps) => {
    const [field, meta, helper] = useField(name);
    const [renderKey, setRenderKey] = useState<string>(uuidv4());
    const { isModalOpened, onModalOpen, onModalClose } = useModal();
    const initialInputValue = field.value ? moment(field.value).format(format) : '';
    const [inputValue, setInputValue] = useState<string>(initialInputValue);
    const formHelperTextClasses = useFormHelperTextStyles();

    const { touched, error } = meta;
    const errorMessage = (typeof error === 'string' && touched && error) || null;
    const handleChange = useCallback((date: MaterialUiPickersDate, value?: string | null) => {
        const dateIsValid = date && date.isValid();
        // @ts-ignore
        const formattedValue = dateIsValid ? date.format(backendDateFormat) : '';
        setInputValue(value || '');
        if (dateIsValid || !date) {
            helper.setValue(formattedValue);
            helper.setTouched(true);
        }
    }, [helper]);
    const onBlur = useCallback(() => {
        if (inputValue && !moment(inputValue).isValid()) {
            helper.setValue('');
            setInputValue('');
            setRenderKey(uuidv4());
        }
    }, [helper, inputValue, setRenderKey]);
    const DatePickerComponent = withKeyboard ? KeyboardDatePicker : DatePicker;
    return (
        <FormControl
            variant="outlined"
            classes={{ root: className }}
        >
            {outerLabel && (
                <label
                    htmlFor={id}
                    className={formHelperTextClasses.outerLabel}
                >
                    {outerLabel}
                </label>
            )}
            <DatePickerComponent
                {...props}
                id={id}
                key={renderKey}
                label={label}
                name={name}
                autoOk
                open={isModalOpened}
                onClose={onModalClose}
                onOpen={onModalOpen}
                // @ts-ignore
                inputValue={inputValue}
                disabled={disabled}
                error={Boolean(errorMessage)}
                helperText={showErrorText && errorMessage}
                inputVariant={inputVariant}
                format={format}
                variant={variant}
                value={field.value ? moment(field.value) : null}
                onChange={handleChange}
                onBlur={onBlur}
            />
        </FormControl>
    );
};

export default DayPickerField;
