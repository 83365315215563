import { getEffectiveDateValidationSchema } from 'modules/subassignmentManagement/components/EafFormShared/getEffectiveDateValidationSchema';
import { ValidationMessages } from 'shared/models/Validation';
import { IClientPaySettings } from 'store/entities/clients/clientsModel';
import * as yup from 'yup';

export const getPositionChangeValidationSchema = (paySettings: IClientPaySettings) => {
    return yup.object().shape({
        type: yup.string().nullable().required(ValidationMessages.REQUIRED),
        newPosition: yup.string().nullable().required(ValidationMessages.REQUIRED),
        newPayRate: yup.number().nullable()
            .min(0, 'Pay rate should be more than 0')
            .required(ValidationMessages.REQUIRED),
        effectiveDate: getEffectiveDateValidationSchema(paySettings),
    });
};
