import { IEntity } from 'shared/models/Entity';
import {
    FeeType,
    IJobNumberBackend,
    JobNumberGroup,
    ReasonForClose,
    TimesheetSettings,
    UWSystem,
} from 'shared/models/JobNumber';

export interface IJobNumberPageResponse {
    total_items: number;
    job_numbers: IJobNumberBackend[];
}

export interface IJobNumberPagePayload {
    total_items: number;
    job_number_ids: string[];
}

export interface IJobNumberFilter {
    jobNumberSearch: number | string;
    userId?: string | null;
    dealId?: string | null;
}

export const jobNumberFilterDefaultValues: IJobNumberFilter = {
    jobNumberSearch: '',
    userId: null,
    dealId: null,
};

export interface IManageJobNumberCommonRequest {
    user_id?: string;
    fte: string;
    time_activities: IEntity[];
    is_per_diem: boolean;
    deal_id: string;
    timesheet_setting: TimesheetSettings;
    description: string;
    manager_id: string;
    job_number_group: JobNumberGroup | null;
    user_type_id: string;
    start_date: string;
    end_date: string | null;
    fee_type?: FeeType;
    per_file_rate: number | null;
    hourly_pay_rate: number | null;
    per_diem_rate: number | null;
    per_file_ot_rate?: number | null;
    per_diem_ot_rate?: number | null;
    client_id?: string;
    uw_system: UWSystem;
    mi_credit: number | null;
    billing_max: number | null;
    billing_min: number | null;
    blended_amount: number | null;
}

export interface ICreateJobNumberRequest extends IManageJobNumberCommonRequest {}
export interface IUpdateJobNumberRequest extends IManageJobNumberCommonRequest {
    reason_for_close?: ReasonForClose;
    close_on_date?: string;
}

export interface IAssignUserJobNumberRequest {
    job_number_id: string;
    user_id: string;
    client_id?: string;
    talent_id: string;
}
