import { Box, Grid } from '@material-ui/core';
import { useFeature } from '@optimizely/react-sdk';
import { useFormikContext } from 'formik';
import { selectPositionCustomFieldValueId } from 'modules/subassignmentManagement/components/PromotionDemotion/store/selectors';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import CheckboxField from 'shared/components/formFields/CheckboxField';
import { CustomFieldValuesSelect } from 'shared/components/selects/CustomFieldValuesSelect/CustomFieldValuesSelect';
import { HierarchyNodeOperation, ICustomFieldHierarchyNode } from 'store/entities/customFields/model';
import { ScopeAction } from 'store/entities/scopes/models';
import { FeatureSwitches } from 'utils/featureSwitches';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { CustomFieldValueMultiselectGateway } from 'shared/components/selects/CustomFieldValuesSelect/CustomFieldValueMultiselectGateway';

export type EditSubassignmentCustomFieldsSectionProps = {
    hierarchy: ICustomFieldHierarchyNode[];
    isBulkEdit: boolean;
    isLoading: boolean;
}

const useSectionStyles = makeHighPriorityStyles({
    input: {
        display: 'flex',
    },
});

export const EditSubassignmentCustomFieldsSection = ({
    hierarchy,
    isLoading,
    isBulkEdit,
}: EditSubassignmentCustomFieldsSectionProps) => {
    const { values } = useFormikContext();
    const showCustomFields = !isBulkEdit || values.modifyCustomFields === true;
    const classes = useSectionStyles();

    const positionCustomFieldId = useSelector(selectPositionCustomFieldValueId);
    const [enablePromotionDemotion] = useFeature(FeatureSwitches.enablePromotionDemotion);
    const showNodes = useMemo(() => {
        if (isBulkEdit) {
            return hierarchy;
        }
        const hideCustomFieldIds = [
            enablePromotionDemotion && positionCustomFieldId,
        ].filter(Boolean);
        return hierarchy.filter(node => !hideCustomFieldIds.includes(node.custom_field_id));
    }, [enablePromotionDemotion, hierarchy, isBulkEdit, positionCustomFieldId]);

    const checkBox = isBulkEdit ? (
        <CheckboxField
            disabled={isLoading}
            name="modifyCustomFields"
            label="Modify custom fields"
        />
    ) : undefined;

    const customFields = showCustomFields ? (
        <Grid container spacing={2}>
            {showNodes.map(node => (
                <Grid
                    item
                    xs={12}
                    md={6}
                    key={node.id}
                >
                    {node.operation === HierarchyNodeOperation.Actionable ? (
                        <CustomFieldValueMultiselectGateway
                            customFieldId={node.custom_field_id}
                            name={`customFieldValues[${node.custom_field_id}]`}
                            disabled={isLoading}
                            className={classes.input}
                            useIdValue
                            hierarchy={hierarchy}
                            customFieldFormValues={values.customFieldValues as Record<string, string>}
                            selectAllFieldName={`customFieldValuesAllFields[${node.custom_field_id}]`}
                            scopeActionFilter={ScopeAction.Assignment}
                            onlyActionable={false}
                        />
                    ) : (
                        <CustomFieldValuesSelect
                            customFieldId={node.custom_field_id}
                            name={`customFieldValue[${node.custom_field_id}]`}
                            disabled={isLoading}
                            className={classes.input}
                            useIdValue
                            hierarchy={hierarchy}
                            customFieldFormValues={values.customFieldValues as Record<string, string>}
                            scopeActionFilter={ScopeAction.Assignment}
                            onlyActionable={false}
                        />
                    )}
                </Grid>
            ))}
        </Grid>
    ) : undefined;

    return (
        <Box>
            {checkBox}

            {customFields}
        </Box>
    );
};
