import { OnboardingSteps } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/model';
import { getMyEmployeeProfile, getMyEmployeeProfiles, onboardMyEmployeeProfile, setOnboardingStep } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/store/actions';
import {
    OfferLetterActions,
} from 'modules/offerLetter/store/actions';
import { combineReducers } from 'redux';
import { isLoadingReducer, singleValueReducer } from 'store/reducerUtils';

export const isEmployeeProfileOnboarding = isLoadingReducer(onboardMyEmployeeProfile);
export const isEmployeeProfileLoading = isLoadingReducer(getMyEmployeeProfile);
export const isEmployeeProfilesLoading = isLoadingReducer(getMyEmployeeProfiles);

function isProfileOnboardingCompleted(
    state = false,
    action: OfferLetterActions,
): boolean {
    switch (action.type) {
        case onboardMyEmployeeProfile.successType:
            return true;
        case onboardMyEmployeeProfile.errorType:
            return false;
        default:
            return state;
    }
}

const onboardingStep = singleValueReducer(setOnboardingStep.action, OnboardingSteps.ViewPdf);

export const employeeOfferDetail = combineReducers({
    onboardingStep,
    isEmployeeProfileOnboarding,
    isProfileOnboardingCompleted,
    isEmployeeProfileLoading,
    isEmployeeProfilesLoading,
});
