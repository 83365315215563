import React from 'react';
import { Switch } from 'react-router-dom';

import { settingsRoutes } from 'modules/settings/routes';
import { CommonSettingsSideMenu } from 'modules/settings/submodules/components/CommonSettingsSideMenu/CommonSettingsSideMenu';

import PrivateRoute from 'shared/components/auth/PrivateRoute';

import OfferLetterTemplatesPage from './OfferLetterTemplatesPage';

function OfferLetterTemplatesRoutes() {
    return (
        <>
            <CommonSettingsSideMenu />
            <Switch>
                <PrivateRoute
                    path={settingsRoutes.OFFER_LETTERS.TEMPLATES}
                    component={OfferLetterTemplatesPage}
                />
            </Switch>
        </>
    );
}

export default OfferLetterTemplatesRoutes;
