import { memoize } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';
import DayPickerField, { IDayPickerFieldProps } from 'shared/components/formFields/DayPickerField';
import { backendDateFormat } from 'shared/models/Dates';
import { getPayPeriodForDate, getPayPeriodLength } from 'shared/utils/helpers/paySettingsHelpers';
import { IClientPaySettings } from 'store/entities/clients/clientsModel';
import { moment } from 'utils/momentExtensions';
import { useAssignmentFromDetails } from '../CompensationChange/useAssignmentFromDetails';

export interface IEffectiveDatePickerProps extends IDayPickerFieldProps {
    paySettings: IClientPaySettings;
    allowRetro?: boolean;
}

export const EffectiveDatePicker = ({ paySettings, allowRetro = false, ...props }: IEffectiveDatePickerProps) => {
    const { assignment, subassignment } = useAssignmentFromDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleShouldDisableDate: (day: moment.Moment) => boolean = useCallback(memoize((day: moment.Moment) => {
        const maxYearsDiffInCalendar = 5;
        const currentHireDate = subassignment?.start_date ?? assignment?.hire_date;
        if (
            (!allowRetro && day.isBefore(moment(), 'day'))
            // Avoid performance issues with pay period calculations
            || moment.duration(day.diff(moment())).get('years') > maxYearsDiffInCalendar
        ) {
            return true;
        }
        if (currentHireDate && day.isSame(moment(currentHireDate), 'day')) {
            return false;
        }
        const payPeriod = getPayPeriodForDate(paySettings, day);
        return !day.isSame(moment(payPeriod.period_start), 'day');
    }, day => day.format(backendDateFormat)), [paySettings, allowRetro, assignment, subassignment]);

    const initialFocusedDate = useMemo(() => {
        const payPeriodLength = getPayPeriodLength(paySettings);
        const payPeriod = getPayPeriodForDate(paySettings, moment().add(payPeriodLength, 'days').format(backendDateFormat));
        return payPeriod.period_start;
    }, [paySettings]);

    return (
        <DayPickerField
            disableToolbar
            initialFocusedDate={initialFocusedDate}
            withKeyboard
            shouldDisableDate={handleShouldDisableDate}
            {...props}
        />
    );
};
