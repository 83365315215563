import { AddressFormBlock } from 'modules/settings/submodules/components/AddressFormBlock/AddressFormBlock';
import React from 'react';
import { FormikProps } from 'formik';
import clsx from 'clsx';
import { Box, Button, Typography } from '@material-ui/core';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';

import { Statuses, DayPickerField, SpecialUserSelect } from './FormComponents';
import { IClientData } from 'modules/settings/submodules/clients/store/models';
import PhoneField from 'shared/components/formSpecialFields/phoneField/PhoneField';
import { shortDateFormat } from 'shared/models/Dates';
import { formBasicSpacing, useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import ClientContactTitleSelect
    from 'modules/settings/submodules/clients/components/FormFields/ClientContactTitleSelect';
import { Permission } from 'store/components/auth/authModels';
import FormField from '../../../../../../shared/components/formFields/FormField';

export interface IEditFormProps extends FormikProps<IClientData> {
    mode: string;
    isLoading?: boolean;
    isDisabled?: boolean;
    submitTitle: string;
    onCancel?: () => void;
    customClasses?: Partial<Record<'form' | 'input' | 'button' | 'formBlock' | 'formBlockTitle', string>>;
    additionalButtons?: React.ReactNode;
}

export function Form({
    handleSubmit,
    setFieldValue,
    onCancel,
    submitTitle,
    customClasses = {},
    additionalButtons,
    isLoading = false,
    isDisabled = false,
}: IEditFormProps) {
    const defaultClasses = useSettingsFormStyles();
    const classes = {
        ...defaultClasses,
        ...customClasses,
    };

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Box className={clsx(classes.formBlock)}>
                <Typography variant="subtitle2" className={clsx(classes.formBlockTitle)}>Client Details</Typography>
                <Box display="flex">
                    <Box width="60%">
                        <FormField
                            name="name"
                            label="Name"
                            className={classes.input}
                            disabled={true}
                        />
                    </Box>
                    <Box width="40%">
                        <FormField
                            name="avionte_company_id"
                            label="Avionte Company ID"
                            className={classes.input}
                            disabled={true}
                        />
                    </Box>
                </Box>

                <Box display="flex">
                    <Box width="60%">
                        <Statuses
                            name="status"
                            label="Status"
                            className={classes.input}
                            disabled={true}
                        />
                    </Box>

                    <Box width="40%">
                        <DayPickerField
                            name="status_date"
                            label="Status Date"
                            className={classes.input}
                            format={shortDateFormat}
                            placeholder={shortDateFormat}
                            variant="dialog"
                            withKeyboard
                            disabled={true}
                        />
                    </Box>
                </Box>

                <Box width="60%">
                    <FormField
                        name="service_type"
                        label="Service Type"
                        className={classes.input}
                        disabled={true}
                    />
                </Box>

                <Box display="flex">
                    <Box width="60%">
                        <SpecialUserSelect
                            feature="permission"
                            featureValue={Permission.toBeAssignedAsClientManager}
                            name="manager"
                            label="Client Manager"
                            className={classes.input}
                        />
                    </Box>
                </Box>
            </Box>

            <AddressFormBlock
                title={'Client Address'}
                setFieldValue={setFieldValue}
                customClasses={customClasses}
                disabled={true}
            />

            <Box className={clsx(classes.formBlock)}>
                <Typography variant="subtitle2" className={clsx(classes.formBlockTitle)}>Client Contact</Typography>
                <Box display="flex">
                    <Box width="60%">
                        <FormField
                            name="contact_name"
                            label="Name"
                            className={classes.input}
                            disabled={true}
                        />
                    </Box>
                    <Box width="40%">
                        <ClientContactTitleSelect
                            name="contact_title"
                            label="Title"
                            className={classes.input}
                            disabled={true}
                        />
                    </Box>
                </Box>

                <FormField
                    name="contact_email"
                    label="Email"
                    type="email"
                    className={classes.input}
                    disabled={true}
                />

                <PhoneField
                    name="contact_phone"
                    label="Phone Number"
                    className={classes.input}
                    disabled={true}
                />
            </Box>
            <Box
                display="flex"
                justifyContent="space-between"
                m={formBasicSpacing}
                mt={5}
            >
                <Box>
                    <ButtonWithLoader
                        type="submit"
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        disabled={isDisabled}
                        isLoading={isLoading}
                        data-testid="submit_update_button"
                    >
                        {submitTitle}
                    </ButtonWithLoader>
                    {onCancel && (
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.button}
                            onClick={onCancel}
                            disabled={isLoading}
                        >
                            Cancel
                        </Button>
                    )}
                </Box>
                <Box mt={4}>
                    {additionalButtons}
                </Box>
            </Box>
        </form>
    );
}
