import clsx from 'clsx';
import React, { SyntheticEvent } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { useStyles } from './styles';
import { IDashboardCardData } from '../../models/Dashboard';

interface IDashboardItemProps extends IDashboardCardData {
    isLoading: boolean;
}

const LABEL_LIMIT = 3;

export function DashboardItem({
    isLoading,
    Icon,
    color,
    backgroundColor,
    count,
    title,
    label,
    labels,
    callback,
}: IDashboardItemProps) {
    const classes = useStyles();
    const onLinkClick = (event: SyntheticEvent) => {
        event.preventDefault();
        typeof callback === 'function' && callback();
    };
    return (
        <Box
            className={clsx(classes.CurrentBox, { [classes.dashItemButton]: Boolean(callback) })}
            onClick={onLinkClick}
        >
            {Icon && (
                <Box className={classes.Icon} style={{ backgroundColor: backgroundColor }}>
                    <Icon color={color} />
                </Box>
            )}
            <Box className={classes.Length} p={2}
                style={{ color: color }}>
                {isLoading ? (
                    <CircularProgress color="inherit" />
                ) : (count ? count : `-`)}
            </Box>
            <Box className={classes.Title}>{title}</Box>
            {label && <Box className={classes.Label}>{label}</Box>}
            {labels && (
                <>
                    {labels.slice(0, LABEL_LIMIT).map(item => (
                        <Box key={item} className={classes.Label}>{item}</Box>
                    ))}
                    {labels.length > LABEL_LIMIT && (
                        <Box className={classes.Label}>
                            and more
                        </Box>
                    )}
                </>
            )}
        </Box>
    );
}
