import { Box } from '@material-ui/core';
import React, { useCallback } from 'react';
import { IClientRoleManagePayload } from 'modules/settings/submodules/platformUsers/store/models';
import {
    selectEditClientRoleId,
    selectIsClientRoleEditing,
} from 'modules/settings/submodules/platformUsers/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { selectClientRolesById } from 'store/entities/users/selectors';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';
import {
    ClientRoleForm,
} from 'modules/settings/submodules/platformUsers/components/ClientRolesAndPermissions/components/ClientRoleForm/ClientRoleForm';
import {
    DeleteClientRoleButton,
} from 'modules/settings/submodules/platformUsers/components/ClientRolesAndPermissions/components/DeleteClientRoleButton';
import {
    setEditClientRole,
    updateClientRole,
} from 'modules/settings/submodules/platformUsers/store/actions';

export function EditClientRoleModal() {
    const dispatch = useDispatch();
    const classes = useColoredFormModalStyles();

    const roleId = useSelector(selectEditClientRoleId);
    const clientRole = useSelector(selectClientRolesById)[roleId];
    const isLoading = useSelector(selectIsClientRoleEditing);

    const onClose = useCallback(() => {
        dispatch(setEditClientRole(null));
    }, [dispatch]);
    const onSave = useCallback((values: IClientRoleManagePayload) => {
        dispatch(updateClientRole.init({
            id: roleId,
            data: values,
        }));
    }, [dispatch, roleId]);

    return (
        <ModalDialog
            title="Configure Role & Permissions"
            isOpened={roleId}
            onClose={onClose}
            modalProps={{
                customClasses: classes,
                showCloseIcon: true,
            }}
        >
            <Box>
                <ClientRoleForm
                    clientRole={clientRole}
                    onSubmit={onSave}
                    isLoading={isLoading}
                    additionalActions={<DeleteClientRoleButton roleId={roleId} />}
                />
            </Box>
        </ModalDialog>
    );
}
