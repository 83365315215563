import React from 'react';
import { Typography } from '@material-ui/core';
import { useSidebarStyles } from 'shared/components/sidebars/Sidebar/styles';
import { getFormattedPayPeriod } from 'shared/models/Dates';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { formatMinutes } from 'shared/utils/formatters/formatMinutesAndHours';
import { useSheetRejectStyles } from './styles';
import { EntryType, ISheet } from 'shared/models/sheet/Sheet';
import clsx from 'clsx';
import CustomOutlinedTextField from 'shared/components/textField/CustomOutlinedTextField';
import { Control } from 'react-hook-form';

interface ISheetRejectNotesProps {
    sheetType: EntryType;
    sheet: ISheet;
    employee: string;
    control?: Control;
}

export default function SheetRejectNotes({ sheetType, sheet, employee, control }: ISheetRejectNotesProps) {
    const classes = useSheetRejectStyles();
    const sidebarClasses = useSidebarStyles();

    return (
        <section className={clsx(sidebarClasses.sidebarElement, classes.rejectionCard)}>
            <Typography variant="h6">{employee}</Typography>
            <section className={classes.sheetDisplay}>
                <Typography variant="body1" className={classes.weekendPullLeft}>{getFormattedPayPeriod(sheet)}</Typography>
                {sheetType === EntryType.TIME ? (
                    <Typography variant="subtitle1" className={classes.hoursPullRight}>{formatMinutes(sheet.total_minutes) } hrs.</Typography>
                ) : (
                    <Typography variant="subtitle1" className={classes.hoursPullRight}>{formatDollars(sheet.total_dollars) }</Typography>
                )}

            </section>
            <section className={classes.notes}>
                <CustomOutlinedTextField name={`rejectionNotes-${sheet.id}`} control={control}
                    label="Reason for rejection" customClasses={classes.notesInput} />
            </section>
        </section>
    );
}
