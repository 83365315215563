import { CcpInfinityTablePure } from '../CcpTransactionsTable/CcpInfinityTablePure';
import {
    getCsvInvoicedItem,
    getMoreCcpInvoicesAction,
} from '../../store/actions';
import React, { useCallback, useMemo } from 'react';
import { ccpInvoiceInfinityTableSelectors, ICcpInvoiceBatchRow, selectCcpInvoicesRows } from './store/selectors';
import PlainText from 'shared/components/table/Cells/PlainText';
import moment from 'moment';
import { shortDateFormat } from 'shared/models/Dates';
import { GetApp } from '@material-ui/icons';
import { Box } from '@material-ui/core';
import { ICellInfo } from '../../../../shared/components/table/GridTable/GridTableModel';
import { ICcpInvoiceBatch } from '../../models/CcpTransaction';
import { initialLoadCcpInvoicesPage } from './store/actions';
import { useCcpTableStyles } from '../../styles';
import { useDispatch, useSelector } from 'react-redux';
import { selectCcpIsLoadingInvoicesCsvReport } from '../../store/selectors';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';

export const CcpInvoicesTable = () => {
    const tableClasses = useCcpTableStyles();
    const isLoading = useSelector(selectCcpIsLoadingInvoicesCsvReport);
    const dispatch = useDispatch();

    const cells = useMemo(() => [
        {
            key: 'invoice_number',
            title: 'INVOICE NUMBER',
            render: function InvoiceNumberCell({ invoice_number, className }: ICcpInvoiceBatchRow) {
                return (
                    <PlainText className={className} value={invoice_number} />
                );
            },
        },
        {
            key: 'invoice_date',
            title: 'INVOICE DATE',
            render: function InvoiceDateCell({ invoice_date, className }: ICcpInvoiceBatchRow) {
                return (
                    <PlainText className={className} value={moment(invoice_date).format(shortDateFormat)} />
                );
            },
        },
        {
            key: 'invoice_amount',
            title: 'INVOICE AMOUNT',
            render: function InvoiceAmountCell({ invoice_amount, className }: ICcpInvoiceBatchRow) {
                return (
                    <PlainText className={className} value={`$${String(invoice_amount)}`} />
                );
            },
        },
        {
            key: 'invoice_export',
            title: 'EXPORT',
            width: '80px',
            render: function InvoiceExportCell({ id, className }: ICcpInvoiceBatchRow) {
                const onPrintClick = useCallback(() => {
                    dispatch(getCsvInvoicedItem.init(id));
                }, [id]);
                return (
                    <Box className={className}>
                        <ButtonWithLoader
                            className={tableClasses.enlargedActionsIconCellSecondaryButton}
                            onClick={onPrintClick}
                            disabled={isLoading}
                            isLoading={isLoading}
                        >
                            <GetApp />
                        </ButtonWithLoader>
                    </Box>
                );
            },
        },
    ] as ICellInfo<ICcpInvoiceBatch>[], [tableClasses, dispatch, isLoading]);
    return (
        <CcpInfinityTablePure
            infinityTableSelector={ccpInvoiceInfinityTableSelectors}
            // @ts-ignore
            rowsSelector={selectCcpInvoicesRows}
            initialLoadAction={initialLoadCcpInvoicesPage}
            getMoreAction={getMoreCcpInvoicesAction.init}
            // @ts-ignore
            cells={cells}
        />
    );
};
