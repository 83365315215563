import { selectCommonEntryFormValues } from 'modules/timeAndExpense/components/AddEntry/store/selectors';
import React, { useEffect, useState } from 'react';
import { Box, Collapse } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { withFeature } from 'shared/hoc/withFeature';
import { colors } from 'shared/styles/constants';
import { CustomFieldType } from 'store/entities/customFields/model';
import { selectCustomFieldValuesByIds } from 'store/entities/customFields/selectors';
import { FeatureSwitches } from 'utils/featureSwitches';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import {
    selectDealById,
    selectSubmittingOrgById,
} from 'store/entities/configuration/configurationSelectors';

const useStyles = makeHighPriorityStyles(theme => ({
    banner: {
        background: colors.red,
        color: colors.white,
        marginTop: theme.spacing(1),
        padding: theme.spacing(2),
        textAlign: 'center',
    },
}));

const CaliforniaStateCode = 'CA';

const CaBreakBanner = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const commonFields = useSelector(selectCommonEntryFormValues);
    const valuesByIds = useSelector(selectCustomFieldValuesByIds);
    const deal = useSelector(selectDealById(commonFields?.jobNumber?.deal_id));
    const submittingOrg = useSelector(selectSubmittingOrgById(deal?.submitting_org_id));

    useEffect(() => {
        const location = Object.values(commonFields?.customFieldValues)
            .map(valueId => valuesByIds[valueId])
            .find(value => value?.data.headway_connect_field === CustomFieldType.Location);

        if (location?.data.state_code === CaliforniaStateCode) {
            setOpen(true);
        } else if (submittingOrg?.state === CaliforniaStateCode){
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [setOpen, commonFields, valuesByIds, submittingOrg]);

    return (
        <Collapse in={open}>
            <Box className={classes.banner}>
                You are provided with a <b><u>30 minute uninterrupted and unpaid meal period</u></b>{' '}
                when you are working more than five (5) hours per day.
                You are provided with a second 30 minute uninterrupted
                and unpaid meal period when you are working more than ten (10) hours per day.
                Please record each meal period on your timesheet.
                In addition, you will receive a <b><u>10 minute paid rest break</u></b>{' '}
                during each 4 hour period you work.
                You do not need to record these rest breaks on your timesheet.
                Please contact us if you have any questions or concerns regarding your meal periods or rest breaks.
            </Box>
        </Collapse>
    );
};

export const CaliforniaBreakBanner = withFeature(
    FeatureSwitches.enableCaliforniaBreakBanner,
    CaBreakBanner,
);
