import React from 'react';
import { Box } from '@material-ui/core';
import {
    useHierarchyPageStyles,
} from 'modules/settings/submodules/components/HierarchyPage/styles';
import { useSelector } from 'react-redux';
import GridTable, { IGridTableProps } from 'shared/components/table/GridTable/GridTable';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import { useAvailableCustomFieldsStyles } from 'modules/settings/submodules/components/HierarchyPage/AvailableCustomFields/styles';
import { useHierarchyTableStyles } from 'modules/settings/submodules/components/HierarchyPage/HierarchyTable/styles';
import clsx from 'clsx';
import { ICustomFieldHierarchyRow } from 'modules/settings/submodules/customFields/store/models';
import { OperationSelect } from 'modules/settings/submodules/components/HierarchyPage/HierarchyTable/components/OperationSelect/OperationSelect';
import DisplayOnGridCheckBox
    from 'modules/settings/submodules/components/HierarchyPage/HierarchyTable/components/DisplayOnGridCheckbox/DisplayOnGridCheckbox';
import { selectCurrentClientHasAssignments } from 'store/entities/clients/selectors/clientsSelectors';
import { HierarchyNodeOperation } from 'store/entities/customFields/model';
import {
    AnchorRadio,
} from 'modules/settings/submodules/components/HierarchyPage/HierarchyTable/components/AnchorRadio';

export interface ICustomFieldHierarchyRowWithClasses extends ICustomFieldHierarchyRow {
    className?: string;
}

interface IHierarchyTableProps extends Pick<IGridTableProps<ICustomFieldHierarchyRowWithClasses>, 'BodyComponent' | 'rowData'> {}

export const HIERARCHY_LEVEL_MARGIN = 4;

export const HierarchyTable = ({
    BodyComponent,
    rowData,
}:IHierarchyTableProps) => {
    const hierarchyPageClasses = useHierarchyPageStyles();
    const customFieldClasses = useAvailableCustomFieldsStyles();
    const classes = useHierarchyTableStyles();
    const currentClientHasAssignments = useSelector(selectCurrentClientHasAssignments);
    const cells = [
        {
            key: 'CUSTOM_FIELD',
            title: 'CUSTOM FIELD',
            render: function NameCell({
                customField,
                className,
            }: ICustomFieldHierarchyRowWithClasses){
                return (
                    <Box className={className}>
                        <Box className={customFieldClasses.customFieldNameWrapper}>
                            <OpenWithIcon className={customFieldClasses.customFieldIcon} fontSize="small" />
                            <Box component="span" marginLeft={1}
                                marginRight={8}>
                                {customField?.name}
                            </Box>
                        </Box>
                    </Box>
                );
            },
        },
        {
            key: 'OPERATION',
            title: 'OPERATION',
            width: '200px',
            render: function OperationCell({ className, hierarchyNode, id }: ICustomFieldHierarchyRowWithClasses){
                return (
                    <Box className={className}>
                        <OperationSelect
                            id={id || ''}
                            operation={hierarchyNode?.operation || HierarchyNodeOperation.Actionable}
                            disabled={currentClientHasAssignments}
                        />
                    </Box>
                );
            },
        },
        {
            key: 'DISPLAY_ON_GRID',
            title: 'DISPLAY ON GRID',
            width: '200px',
            render: function DisplayOnGridCell({ className, hierarchyNode, id }: ICustomFieldHierarchyRowWithClasses){
                const operation = hierarchyNode?.operation || HierarchyNodeOperation.Actionable;
                const shouldHide = operation !== HierarchyNodeOperation.Actionable;
                return (
                    <Box className={className}>
                        {
                            !shouldHide && (
                                <DisplayOnGridCheckBox
                                    id={id || ''}
                                    isChecked={hierarchyNode?.display_on_grid || false}
                                    disabled={currentClientHasAssignments}
                                />
                            )
                        }
                    </Box>
                );
            },
        },
        {
            key: 'ANCHOR',
            title: 'Anchor',
            width: '100px',
            render: function AnchorCell({ className, hierarchyNode, id }: ICustomFieldHierarchyRowWithClasses){
                return (
                    <Box className={className}>
                        <AnchorRadio
                            id={id}
                            isChecked={Boolean(hierarchyNode.anchor)}
                        />
                    </Box>
                );
            },
        },
    ];
    const tableClasses = useDefaultTableStyles();
    return (
        <Box className={classes.hierarchyTable}>
            <Box className={hierarchyPageClasses.label}>
                <label>Selected Custom Fields</label>
            </Box>
            <GridTable
                rowData={rowData}
                getKey={row => row.hierarchyNode?.id || row.id.toString()}
                getRowId={row => row.hierarchyNode?.id || row.id.toString()}
                isPseudoRow={row => row.isSortingPseudoRow}
                cells={cells}
                isLoading={false}
                headerCellClassName={tableClasses.headCell}
                bodyCellClassName={clsx(classes.bodyCell, tableClasses.bodyCell)}
                className={tableClasses.tableBorder}
                stickyHeader
                BodyComponent={BodyComponent}
            />
        </Box>
    );
};
