import React, { useCallback } from 'react';
import { IconButton, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useMenuStyles } from 'shared/components/verticalMenu/styles';

interface IVerticalMenuSearchProps {
    isMinimized: boolean,
    onSetSidebarOpen: (value: boolean) => void,
    search: string,
    onSearchUpdate: (value: string) => void,
    subSearchPlaceholder?: string;
}

export const VerticalMenuSearch = ({
    isMinimized,
    onSetSidebarOpen,
    search,
    onSearchUpdate,
    subSearchPlaceholder,
}: IVerticalMenuSearchProps) => {
    const classes = useMenuStyles();
    const handleSearchUpdate = useCallback(event => {
        onSearchUpdate(event.target.value);
    }, [onSearchUpdate]);
    const handleSetMinimized = useCallback(() => {
        onSetSidebarOpen(true);
    }, [onSetSidebarOpen]);

    if (isMinimized) {
        return (
            <IconButton
                onClick={handleSetMinimized}
                className={classes.searchIconButton}
            >
                <SearchIcon
                    color="secondary"
                    fontSize="default"
                    className={classes.searchIcon}
                />
            </IconButton>
        );
    }
    return (
        <TextField
            variant="outlined"
            value={search}
            className={classes.searchField}
            placeholder={subSearchPlaceholder}
            onChange={handleSearchUpdate}
            autoFocus
            InputProps={{
                endAdornment: (
                    <SearchIcon color="secondary" fontSize="default" />
                ),
            }}
        />
    );
};
