import { OnboardingSteps } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/model';
import { IOnboardEmployeeProfileRequest } from 'modules/offerLetter/store/model';
import { IEmployeeProfile } from 'shared/models/EmployeeProfile';
import { createRequestActions, createSingleAction, RequestType } from 'store/utils';

export const onboardMyEmployeeProfile = createRequestActions<{
    clientId: string;
    onboardingData: IOnboardEmployeeProfileRequest;
},
IEmployeeProfile
>(
    RequestType.Create,
    'ONBOARD_MY_EMPLOYEE_PROFILE',
    'offer_letter',
);

export const getMyEmployeeProfile = createRequestActions<string, IEmployeeProfile>(
    RequestType.Get,
    'MY_EMPLOYEE_PROFILE',
    'offer_letter',
);

export const getMyEmployeeProfiles = createRequestActions<string, IEmployeeProfile[]>(
    RequestType.Get,
    'MY_EMPLOYEE_PROFILES',
    'offer_letter',
);

const SET_ONBOARDING_STEP = 'SET_ONBOARDING_STEP';
export const setOnboardingStep = createSingleAction<OnboardingSteps, typeof SET_ONBOARDING_STEP>(SET_ONBOARDING_STEP);
