import React from 'react';
import { FeatureSwitches } from 'utils/featureSwitches';
import { Box } from '@material-ui/core';

import { FilterSection } from './components/FilterSection';
import { MobilePayrollFooter } from './MobilePayrollFooter';

import { SheetTabTitle } from '../SheetTitle/SheetTabTitle';
import { PayrollSheetTable } from '../PayrollSheetTable/PayrollSheetTable';

import { useFeature } from '@optimizely/react-sdk';
import usePayrollSheetsTableStyles
    from 'modules/payrollProcessorHub/components/PayrollSheetsTablePage/styles/usePayrollSheetsTableStyles';

export const PayrollMissingSheetsTablePage = () => {
    const classes = usePayrollSheetsTableStyles();
    const [enableVirtualizationForPphTable] = useFeature(FeatureSwitches.enableVirtualizationForPphTable);

    return (
        <>
            <Box p={enableVirtualizationForPphTable ? 2 : 0}>
                <SheetTabTitle />

                <FilterSection mb={2} />
            </Box>

            <Box
                className={enableVirtualizationForPphTable ? classes.flexTableWrapper : undefined}
            >
                <PayrollSheetTable />
            </Box>

            <MobilePayrollFooter />
        </>
    );
};
