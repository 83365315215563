import { IconButton } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import clsx from 'clsx';
import React from 'react';
import { Link, generatePath } from 'react-router-dom';
import { routes } from '../../../routes';
import { useSubmittedTableStyles } from '../../sheetsSubmitted/sheetsSubmittedStyles';

interface IButtonCellProps {
    className?: string;
    sheetId: string;
    clientId: string | null;
}

export default function ButtonCell({ className, sheetId, clientId }: IButtonCellProps) {
    const classes = useSubmittedTableStyles();
    const link = clientId ? generatePath(routes.CLIENT.OFFER_LETTER.DETAILS, { client_id: clientId, id: sheetId }) : '';
    return (
        <div className={clsx(className, classes.iconCell, classes.detailsIcon)}>
            <IconButton data-testid="button-cell-link" component={Link}
                to={link}>
                <ArrowForwardIosIcon />
            </IconButton>
        </div>
    );
}
