/* eslint-disable react/display-name */
import React, { ChangeEvent, useCallback, useMemo } from 'react';
import PlainText from 'shared/components/table/Cells/PlainText';
import {
    ccpTranInfinityTableSelectors,
    ICcpTransactionRow,
    selectCcpTransactionsById,
    selectCcpTransactionsRows,
} from 'modules/ccp/components/CcpTransactionsTable/store/selectors';
import {
    changeCcpTransaction,
    getMoreCcpTransactionsAction,
    initialLoadCcpTransactionsPage,
} from 'modules/ccp/store/actions';
import { Box, FormControl, Select, Tooltip } from '@material-ui/core';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
    selectCustomFieldValuesByFieldId,
} from 'store/entities/customFields/selectors';
import { selectTransactionCodes } from '../CcpCreate/store/selectors';
import {
    allCcpSlug, CcpInvoicedID,
    CcpReconciledID,
    CcpStatusIdToNameMap,
    CcpStatusSlug,
    CcpUnreconciledID,
    ITransactionChange,
    ITransactionCode,
} from '../../models/ccpModel';
import { ICustomFieldValue } from 'store/entities/customFields/model';
import PdfSVG from 'shared/components/icons/PdfSVG';
import ReceiptSVG from 'shared/components/icons/ReceiptSVG';
import { AddBox, CallSplit } from '@material-ui/icons';
import { selectCurrentClientId } from '../../../../store/entities/clients/selectors/clientsSelectors';
import { useCellSelectStyles } from '../../../settings/submodules/components/sharedStyles/tableCellStyles';
import { setOpenNoteEditCcpSidebar, setOpenReceiptEditCcpSidebar } from '../CcpEdit/store/actions';
import { selectIsUserHasPermission } from '../../../../store/components/auth/selectors';
import { Permission } from 'store/components/auth/authModels';
import { selectClientCreditCardPortalPageCustomFieldId } from '../../../../store/entities/clients/selectors/configurationSelectors';
import { CcpInfinityTablePure } from './CcpInfinityTablePure';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { ICcpTransaction } from '../../models/CcpTransaction';
import {
    selectCustomFieldScopeRestrictionsByAction, selectScopesUserMatchesByUser,
    selectUserHasScopeRestrictionByType,
} from '../../../../store/entities/scopes/selectors';
import { ScopeAction } from '../../../../store/entities/scopes/models';
import { selectUsersById } from '../../../../store/entities/users/selectors';
import { changeSplitAmountTotal, setOpenSplitCcpSidebar } from '../CcpSplit/store/actions';
import moment from 'moment';
import { shortDateFormat } from 'shared/models/Dates';
import { useCcpTableStyles } from '../../styles';
import { selectCcpEditMode, selectCcpTransactionsActiveTab, selectIsLoadingEditModeSave } from '../../store/selectors';
import clsx from 'clsx';
import { useStatusStyles } from 'shared/components/table/Cells/StatusStyles';
import StatusCell from 'shared/components/table/Cells/StatusCell';
import { optimizely } from '../../../../utils/optimizely';
import { FeatureSwitches } from '../../../../utils/featureSwitches';

export const CcpTransactionsTable = () => {
    const scopeActionFilter = ScopeAction.Entry;
    const tableClasses = useCcpTableStyles();
    const selectClasses = useCellSelectStyles();
    const statusClasses = useStatusStyles();

    const usersById = useSelector(selectUsersById, shallowEqual);
    const customFieldId = useSelector(selectClientCreditCardPortalPageCustomFieldId,
        shallowEqual);
    const enableRegionalDirectorField = optimizely.isFeatureEnabled(FeatureSwitches.enableRegionalDirectorField);
    const hasViewAllPermission = useSelector(selectIsUserHasPermission(Permission.CanViewCcpAll),
        shallowEqual);
    const hasSubordinatePermission = useSelector(selectIsUserHasPermission(Permission.ViewCcpSubordinate),
        shallowEqual);
    const hasEditPermissionUnreconsiled = useSelector(selectIsUserHasPermission(Permission.CanEditCcpUnreconciled),
        shallowEqual);
    const hasEditPermissionReconciled = useSelector(selectIsUserHasPermission(Permission.CanEditCcpReconciled),
        shallowEqual);

    const ccpTransactionCodesById = useSelector(selectTransactionCodes, shallowEqual);
    const ccpTransactionCodes = useMemo(() => {
        return Object.values(ccpTransactionCodesById) as ITransactionCode[];
    }, [ccpTransactionCodesById]);
    const transactionsById = useSelector(selectCcpTransactionsById, shallowEqual);
    const userHasScopesByAction = useSelector(selectUserHasScopeRestrictionByType(scopeActionFilter),
        shallowEqual);
    const selectedTab = useSelector(selectCcpTransactionsActiveTab, shallowEqual);
    const scopeAllowedCustomFieldValueIdsByAction = useSelector(
        selectCustomFieldScopeRestrictionsByAction(scopeActionFilter, customFieldId ? customFieldId : ''),
        shallowEqual,
    );
    const customFieldValuesRaw = useSelector(selectCustomFieldValuesByFieldId(customFieldId ? customFieldId : ''),
        shallowEqual);
    const scopedMatchedUserIdsMapping = useSelector(selectScopesUserMatchesByUser, shallowEqual);
    const isEditMode = useSelector(selectCcpEditMode, shallowEqual);
    const isEditModeLoading = useSelector(selectIsLoadingEditModeSave, shallowEqual);

    const customFieldValues = (userHasScopesByAction && scopeAllowedCustomFieldValueIdsByAction.length > 0)
        ? customFieldValuesRaw.filter(t => scopeAllowedCustomFieldValueIdsByAction.includes(t.id))
        : customFieldValuesRaw;

    const clientId = useSelector(selectCurrentClientId, shallowEqual);
    const dispatch = useDispatch();

    const ableToEdit = (transaction_status_id: string): boolean => {
        return selectedTab !== allCcpSlug && (
            (hasEditPermissionUnreconsiled && transaction_status_id === CcpUnreconciledID)
            || (
                hasEditPermissionReconciled
                && transaction_status_id === CcpReconciledID
                && isEditMode
                && !isEditModeLoading
            ));
    };
    const hasChildren = (id: string): boolean => {
        for (const key in transactionsById) {
            const currentTransaction = transactionsById[key] as ICcpTransaction;
            if (currentTransaction.parent_transaction_id === id) {
                return true;
            }
        }
        return false;
    };
    const cellClasses = (id: string,
        departmentId: string|undefined,
        transactionCodeId: string|undefined,
        parentTransactionId: string|undefined,
        className: string|undefined): string|undefined => {
        if (selectedTab !== CcpStatusSlug.Unreconciled) {
            return className;
        }
        let allRelatedReady = true;
        let hasItemChildren = false;
        const parentToFind = parentTransactionId ? parentTransactionId : id;
        for (const key in transactionsById) {
            const currentTransaction = transactionsById[key] as ICcpTransaction;
            if (currentTransaction.parent_transaction_id === parentToFind) {
                if (!parentTransactionId && !hasItemChildren) {
                    hasItemChildren = true;
                }
                allRelatedReady = !!(currentTransaction.department_id
                    && currentTransaction.transaction_code_id
                    && allRelatedReady);
                if (!allRelatedReady) {
                    break;
                }
            }
        }
        if (hasItemChildren){
            if (allRelatedReady) {
                return clsx(className, tableClasses.readyRow);
            } else {
                return className;
            }
        }

        if (departmentId && transactionCodeId) {
            return clsx(className, tableClasses.readyRow);
        } else {
            return className;
        }
    };

    const handleChangeStub = (id: string, transaction_code_id: string|null, department_id: string|null) => {
        const changeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
            const { options } = event.target as HTMLSelectElement;
            let codeId = transaction_code_id;
            let departmentId = department_id;
            if (!transaction_code_id) {
                const codeData: ITransactionCode[] = [...options]
                    .filter(option => option.selected)
                    .map(option => ccpTransactionCodesById[option.value] as ITransactionCode);
                if (codeData.length === 1) {
                    codeId = codeData[0]?.id;
                }
            }
            if (!department_id) {
                const departmentItems: ICustomFieldValue[] = [...options]
                    .filter(option => option.selected)
                    .map(option => customFieldValues.find(cf => cf.id === option.value) as ICustomFieldValue);
                if (departmentItems.length === 1) {
                    departmentId = departmentItems[0]?.id;
                }
            }

            const current_change: ITransactionChange = {
                id: id,
                transaction_code: codeId,
                department_id: departmentId,
                client_id: clientId,
                note: undefined,
            };
            if (current_change.id) {
                dispatch(changeCcpTransaction.init(current_change));
            }
        };
        return changeHandler;
    };

    const statusToColor: Record<string, string> = useMemo(() => ({
        [CcpUnreconciledID]: statusClasses.blue,
        [CcpReconciledID]: statusClasses.lightGreen,
        [CcpInvoicedID]: statusClasses.brown,
    }), [statusClasses]);

    let cells: ICellInfo<ICcpTransaction>[];
    const directorDataCell: ICellInfo<ICcpTransaction> = {
        key: 'id',
        title: 'REGIONAL MANAGER',
        render: function employeeCell({
            id,
            user_id,
            parent_transaction_id,
            department_id,
            transaction_code_id,
            className,
        }: ICcpTransactionRow) {
            const currentMapped = scopedMatchedUserIdsMapping[user_id]?.matched_ids ?? [];
            const usersDataList = currentMapped.map(cm => usersById[cm]);
            const usersNamesList = usersDataList
                .map(userData => String([userData?.last_name, userData?.first_name].join(', ')));
            const resultString = usersNamesList.join('; ');
            if (!parent_transaction_id) {
                return (
                    <PlainText className={cellClasses(id, department_id, transaction_code_id,
                        parent_transaction_id, className)}
                    value={String(resultString)} />
                );
            } else {
                return (
                    <PlainText className={cellClasses(id, department_id, transaction_code_id,
                        parent_transaction_id, className)}
                    value={''} />
                );
            }
        },
    };

    const employeeDataCell: ICellInfo<ICcpTransaction> = {
        key: 'user_id',
        title: 'AREA MANAGER',
        render: function employeeCell({
            id,
            user_id,
            parent_transaction_id,
            department_id,
            transaction_code_id,
            className,
        }: ICcpTransactionRow) {
            const userData = usersById[user_id];
            if (!parent_transaction_id) {
                return (
                    <PlainText className={cellClasses(id, department_id, transaction_code_id,
                        parent_transaction_id, className)}
                    value={String([userData?.last_name, userData?.first_name].join(', '))} />
                );
            } else {
                return (
                    <PlainText className={cellClasses(id, department_id, transaction_code_id,
                        parent_transaction_id, className)}
                    value={''} />
                );
            }
        },
    };

    const statusCell: ICellInfo<ICcpTransaction> = {
        key: 'status',
        title: 'STATUS',
        width: '120px',
        render: function StatusCellData({
            className,
            transaction_status_id,
            parent_transaction_id,
        }: ICcpTransactionRow) {
            if (parent_transaction_id) {
                return (<PlainText className={className} value={''} />);
            }
            return (<StatusCell
                className={className}
                text={CcpStatusIdToNameMap[transaction_status_id]}
                statusClassName={statusToColor[transaction_status_id]}
            />);
        },
    };

    const defaultCells = [
        {
            key: 'purchase_date',
            title: 'PURCHASE DATE',
            render: function purchaseDataCell({
                id,
                purchase_date,
                parent_transaction_id,
                department_id,
                transaction_code_id,
                className,
            }: ICcpTransactionRow) {
                if (!parent_transaction_id) {
                    return (
                        <PlainText className={cellClasses(id, department_id, transaction_code_id,
                            parent_transaction_id, className)}
                        value={moment(purchase_date).format(shortDateFormat)} />
                    );
                } else {
                    return (
                        <PlainText className={cellClasses(id, department_id, transaction_code_id,
                            parent_transaction_id, className)}
                        value={''} />
                    );
                }
            },
        },
        {
            key: 'merchant_name',
            title: 'MERCHANT',
            render: function merchantCell({
                id,
                merchant_name,
                parent_transaction_id,
                className,
                department_id,
                transaction_code_id,
            }: ICcpTransactionRow) {
                if (!parent_transaction_id) {
                    return (
                        <PlainText className={cellClasses(id, department_id, transaction_code_id,
                            parent_transaction_id, className)}
                        value={String(merchant_name)} />
                    );
                } else {
                    return (
                        <PlainText className={cellClasses(id, department_id, transaction_code_id,
                            parent_transaction_id, className)}
                        value={''} />
                    );
                }
            },
        },
        {
            key: 'merchant_desc',
            title: 'MERCHANT DESC.',
            render: function merchantDescCell({
                id,
                merchant_desc,
                parent_transaction_id,
                className,
                department_id,
                transaction_code_id,
            }: ICcpTransactionRow) {
                if (!parent_transaction_id) {
                    return (
                        <PlainText className={cellClasses(id, department_id, transaction_code_id,
                            parent_transaction_id, className)}
                        value={String(merchant_desc)} />
                    );
                } else {
                    return (
                        <PlainText className={cellClasses(id, department_id, transaction_code_id,
                            parent_transaction_id, className)}
                        value={''} />
                    );
                }
            },
        },
        {
            key: 'transaction_type_name',
            title: 'TRANS TYPE',
            render: function transactionTypeNameCell({
                id,
                transaction_type_name,
                parent_transaction_id,
                department_id,
                transaction_code_id,
                className,
            }: ICcpTransactionRow) {
                if (!parent_transaction_id) {
                    return (
                        <PlainText className={cellClasses(id, department_id, transaction_code_id,
                            parent_transaction_id, className)}
                        value={String(transaction_type_name)} />
                    );
                } else {
                    return (
                        <PlainText className={cellClasses(id, department_id, transaction_code_id,
                            parent_transaction_id, className)}
                        value={''} />
                    );
                }
            },
        },
        {
            key: 'transaction_code',
            title: 'TRANSACTION CODE',
            render: function TransactionCodeCell({
                id,
                transaction_code_id,
                department_id,
                parent_transaction_id,
                className,
                transaction_status_id,
            }: ICcpTransactionRow) {
                if (hasChildren(id)) {
                    return (
                        <PlainText className={cellClasses(id, department_id, transaction_code_id,
                            parent_transaction_id, className)}
                        value={''} />
                    );
                }
                if (ableToEdit(transaction_status_id)) {
                    return (
                        <div className={cellClasses(id, department_id, transaction_code_id,
                            parent_transaction_id, className)}
                        >
                            <FormControl
                                variant="outlined"
                                size="small"
                                className={tableClasses.statusSelect}
                            >
                                <Select
                                    disabled={false}
                                    native
                                    inputProps={{}}
                                    classes={selectClasses}
                                    value={transaction_code_id}
                                    onChange={handleChangeStub(id, null, department_id)}
                                >
                                    <option value="">
                                        {'Select Code'}
                                    </option>
                                    {ccpTransactionCodes?.map((item, index) => (
                                        <option
                                            value={item.id}
                                            key={index}
                                        >
                                            {item.name}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    );
                } else {
                    const currentTranCode = ccpTransactionCodes.find(item => item.id === transaction_code_id);
                    const textToDisplay = currentTranCode ? currentTranCode.name : '--';
                    return (
                        <PlainText className={cellClasses(id, department_id, transaction_code_id,
                            parent_transaction_id, className)}
                        value={String(textToDisplay)} />
                    );
                }
            },
        },
        {
            key: 'department_name',
            title: 'DEPARTMENT',
            render: function DepartmentCell({
                id,
                department_id,
                transaction_code_id,
                className,
                transaction_status_id,
                parent_transaction_id,
            }: ICcpTransactionRow) {
                if (hasChildren(id)) {
                    return (
                        <PlainText className={cellClasses(id, department_id, transaction_code_id,
                            parent_transaction_id, className)}
                        value={''} />
                    );
                }
                if (ableToEdit(transaction_status_id)) {
                    return (
                        <div className={cellClasses(id, department_id, transaction_code_id,
                            parent_transaction_id, className)}
                        >
                            <FormControl
                                variant="outlined"
                                size="small"
                                className={tableClasses.statusSelect}
                            >
                                <Select
                                    disabled={false}
                                    native
                                    inputProps={{}}
                                    classes={selectClasses}
                                    value={department_id}
                                    onChange={handleChangeStub(id, transaction_code_id, null)}
                                >
                                    <option value="">
                                        {'Select Department'}
                                    </option>
                                    {customFieldValues?.filter(cfv => cfv.is_active)?.map((item, index) => (
                                        <option
                                            value={item.id}
                                            key={index}
                                        >
                                            {item.data.name}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    );
                } else {
                    const currentDepData = customFieldValues.find(item => item.id === department_id);
                    const textToDisplay = (currentDepData && currentDepData?.data) ? currentDepData.data.name : '--';
                    return (
                        <PlainText className={cellClasses(id, department_id, transaction_code_id,
                            parent_transaction_id, className)}
                        value={String(textToDisplay)} />
                    );
                }
            },
        },
        {
            key: 'amount',
            title: 'AMOUNT',
            render: function AmountCell({
                id,
                amount,
                className,
                department_id,
                parent_transaction_id,
                transaction_code_id,
            }: ICcpTransactionRow) {
                return (<PlainText className={cellClasses(id, department_id, transaction_code_id,
                    parent_transaction_id, className)}
                value={`$${String(amount)}`} />);
            },
        },
        {
            key: 'notes',
            title: 'NOTES',
            width: '100px',
            render: function ActionsCell({
                id,
                note,
                transaction_status_id,
                className,
                parent_transaction_id,
                department_id,
                transaction_code_id,
            }: ICcpTransactionRow) {
                const handleOpenNotes = () => {
                    if (ableToEdit(transaction_status_id)) {
                        dispatch(setOpenNoteEditCcpSidebar(id));
                    }
                };
                if (hasChildren(id)) {
                    return (
                        <PlainText className={cellClasses(id, department_id, transaction_code_id,
                            parent_transaction_id, className)}
                        value={''} />
                    );
                }
                if (note) {
                    return (
                        <Box className={cellClasses(id, department_id, transaction_code_id,
                            parent_transaction_id, className)}
                        >
                            <Tooltip title={note}>
                                <Box
                                    className={tableClasses.enlargedActionsIconCellSecondary}
                                    onClick={handleOpenNotes}
                                >
                                    <PdfSVG />
                                </Box>
                            </Tooltip>
                        </Box>
                    );
                } else if (ableToEdit(transaction_status_id)) {
                    return (
                        <Box className={cellClasses(id, department_id, transaction_code_id,
                            parent_transaction_id, className)}
                        >
                            <Box
                                className={tableClasses.enlargedActionsIconCellSecondary}
                                onClick={handleOpenNotes}
                            >
                                {
                                    !note && (<AddBox />)
                                }
                            </Box>
                        </Box>
                    );
                } else {
                    return (
                        <PlainText className={cellClasses(id, department_id, transaction_code_id,
                            parent_transaction_id, className)}
                        value={''} />
                    );
                }
            },
        },
        {
            key: 'receipt',
            title: 'RECEIPT',
            width: '100px',
            render: function ReceiptCell({
                id,
                attachments,
                parent_transaction_id,
                transaction_status_id,
                className,
                transaction_code_id,
                department_id,
            }: ICcpTransactionRow) {
                const showAttachments = attachments?.length > 0;
                const openReceiptsSidebar = useCallback(() => {
                    dispatch(setOpenReceiptEditCcpSidebar(id));
                }, [id]);
                if (parent_transaction_id) {
                    return (
                        <PlainText className={cellClasses(id, department_id, transaction_code_id,
                            parent_transaction_id, className)}
                        value={''} />
                    );
                }
                if (ableToEdit(transaction_status_id)) {
                    return (
                        <Box className={cellClasses(id, department_id, transaction_code_id,
                            parent_transaction_id, className)}
                        >
                            <Box className={tableClasses.enlargedActionsIconCellSecondary}
                                onClick={openReceiptsSidebar}
                            >
                                {
                                    showAttachments && (<ReceiptSVG />)
                                }
                                {
                                    !showAttachments && (<AddBox />)
                                }
                            </Box>
                        </Box>
                    );
                } else if (showAttachments) {
                    return (
                        <Box className={cellClasses(id, department_id, transaction_code_id,
                            parent_transaction_id, className)}
                        >
                            <Box className={tableClasses.enlargedActionsIconCellSecondary}
                                onClick={openReceiptsSidebar}
                            >
                                <ReceiptSVG />
                            </Box>
                        </Box>
                    );
                } else {
                    return (
                        <PlainText className={cellClasses(id, department_id, transaction_code_id,
                            parent_transaction_id, className)}
                        value={''} />
                    );
                }
            },
        },
        {
            key: 'split',
            title: 'SPLIT',
            width: '100px',
            render: function SplitRender({
                id,
                parent_transaction_id,
                transaction_status_id,
                className,
                department_id,
                transaction_code_id,
            }: ICcpTransactionRow) {
                const openSplit = useCallback(() => {
                    const transaction = transactionsById[id];
                    dispatch(changeSplitAmountTotal(transaction?.amount ?? 0));
                    dispatch(setOpenSplitCcpSidebar(id));
                }, [id]);
                if (parent_transaction_id) {
                    return (
                        <PlainText className={cellClasses(id, department_id, transaction_code_id,
                            parent_transaction_id, className)}
                        value={''} />
                    );
                }
                if (ableToEdit(transaction_status_id)) {
                    return (
                        <Box className={cellClasses(id, department_id, transaction_code_id,
                            parent_transaction_id, className)}
                        >
                            <Box className={tableClasses.enlargedActionsIconCellSecondary}
                                onClick={openSplit}>
                                <CallSplit />
                            </Box>
                        </Box>
                    );
                } else {
                    return (
                        <PlainText className={cellClasses(id, department_id, transaction_code_id,
                            parent_transaction_id, className)}
                        value={''} />
                    );
                }
            },
        },
    ] as ICellInfo<ICcpTransaction>[];
    cells = hasViewAllPermission || hasSubordinatePermission
        ? (enableRegionalDirectorField
            ? [employeeDataCell, directorDataCell, ...defaultCells]
            : [employeeDataCell, ...defaultCells])
        : defaultCells;
    if (selectedTab === allCcpSlug) {
        cells = [...cells, statusCell];
    }

    return (
        <CcpInfinityTablePure
            infinityTableSelector={ccpTranInfinityTableSelectors}
            rowsSelector={selectCcpTransactionsRows}
            initialLoadAction={initialLoadCcpTransactionsPage}
            getMoreAction={getMoreCcpTransactionsAction.init}
            cells={cells}
        />
    );
};
