import { useFeature } from '@optimizely/react-sdk';
import {
    getOfferEditBtnCell,
    getOfferLetterDateCell,
    getOfferLetterPdfCell,
    getOfferLetterStatusCell, jobOrderCell,
    locationCell,
    payRateDesktopCell,
    positionCell,
    startDateCell, worksiteCell,
} from 'modules/offerLetter/components/OfferLetterTable/cells/commonCells';
import { checkBoxCellInfo, payRangeCellInfo } from 'modules/offerLetter/components/OfferLetterTable/cells/payRangeApprovalCells';
import { setOfferLetterTableSort } from 'modules/offerLetter/store/actions';
import { isPayRateApprovalStatus } from 'modules/offerLetter/store/helpers';
import { allOfferLetterTabName, IOfferLetter, OfferLetterStatusSlug } from 'modules/offerLetter/store/model';
import { selectOfferLetterActiveTab, selectOfferLetterTableSort } from 'modules/offerLetter/store/selectors';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import PlainText from 'shared/components/table/Cells/PlainText';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { SortableTableHead } from 'shared/components/table/GridTable/SortableTableHead';
import MobileListCell from 'shared/components/table/MobileListCell/MobileListCell';
import { shortDateFormat } from 'shared/models/Dates';
import { IUserInfo } from 'shared/models/User';
import { useTableStyles } from 'shared/styles/tableStyles';
import { getLastFirstName } from 'shared/utils/converters/user';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { usePayRateType } from 'shared/utils/formatters/payRate';
import { useIsMobile } from 'shared/utils/hooks/media';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { FeatureSwitches } from 'utils/featureSwitches';

import { OfferLetterCellKey } from './OfferLetterCellKey';
import { orderCellsForPayRangeApprovalTab } from './payRangeApprovalHelpers';

export interface IOfferLetterWithPdf extends IOfferLetter {
    isPdfLoading: boolean;
    isPdfAvailable: boolean;
    userCanContinueOnboarding: boolean;
}

export interface IOfferLetterRow {
    id: string;
    className?: string;
    offerLetter: IOfferLetterWithPdf;
    isPdfLoading: boolean;
    isPdfAvailable: boolean;
    positionDisplayValue: string;
    locationDisplayValue: string;
    departmentDisplayValue: string;
    jobOrderDisplayValue: string;
    worksiteAddress: string;
    payRange?: [number, number];
}

const getFullName = (row: IOfferLetterRow) => getLastFirstName(row.offerLetter as unknown as IUserInfo);

const getSortableHead = (id: string, title: string) => function TableHeadCell() {
    return (
        <SortableTableHead
            id={id}
            tableSortingSelector={selectOfferLetterTableSort}
            setOrderAction={setOfferLetterTableSort}
        >
            {title}
        </SortableTableHead>
    );
};

export function useOfferLetterCells(
    openDetail: (offerLetter: IOfferLetterWithPdf) => void,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    offerLetterRows: IOfferLetterRow[],
): Array<ICellInfo<IOfferLetterRow>> {
    const tableClasses = useTableStyles();
    const offerLetterTab = useSelector(selectOfferLetterActiveTab);
    const clientId = useSelector(selectCurrentClientId);
    const isMobile = useIsMobile();

    const isPayApprovalTab = isPayRateApprovalStatus(offerLetterTab as OfferLetterStatusSlug);
    const userHasCheckboxActions = useSelector(selectIsUserHasPermission(Permission.ApprovePayRate));
    const isAllTab = offerLetterTab === allOfferLetterTabName;
    const [enableOfferLetterTableSorting] = useFeature(FeatureSwitches.enableOfferLetterTableSorting);

    const showStatus = isAllTab || isPayApprovalTab;
    const showOfferStartDate = !isPayApprovalTab;

    const offerDateChangeCell = getOfferLetterDateCell(offerLetterTab);

    const statusEmailCell: ICellInfo<IOfferLetterRow> = showStatus
        ? {
            ...getOfferLetterStatusCell(tableClasses),
            renderTitle: enableOfferLetterTableSorting ? getSortableHead('status', 'status') : undefined,
        }
        : {
            key: OfferLetterCellKey.Email,
            title: 'email',
            render: function EmailCell({ offerLetter, className }: IOfferLetterRow) {
                return (
                    <PlainText className={className} value={offerLetter.email} />
                );
            },
        };

    const result = isMobile ? [
        {
            key: OfferLetterCellKey.FullInfo,
            title: '',
            render: function FullInfoCell(row: IOfferLetterRow) {
                return (
                    <MobileListCell className={row.className} title={getFullName(row)}
                        items={[
                            `Position: ${row.positionDisplayValue}`,
                            `Start Date: ${moment(row.offerLetter.start_date).format(shortDateFormat)}`,
                        ]}
                    />
                );
            },
        },
        {
            key: OfferLetterCellKey.PayRate,
            title: '',
            width: '128px',
            render: function PayRateMobile({ className, offerLetter }: IOfferLetterRow) {
                const payRateType = usePayRateType(offerLetter?.pay_rate_type);
                return (
                    <MobileListCell className={className} title={formatDollars(offerLetter.pay_rate_value)}
                        items={[payRateType]}
                    />
                );
            },
        },
    ] : [
        ...(isPayApprovalTab && userHasCheckboxActions ? [checkBoxCellInfo] : []),
        {
            key: OfferLetterCellKey.Employee,
            title: 'employee',
            render: function EmployeeCell(row: IOfferLetterRow) {
                return (
                    <PlainText className={row.className} value={getFullName(row)} />
                );
            },
        },
        statusEmailCell,
        ...(showOfferStartDate ? [offerDateChangeCell] : []),
        jobOrderCell,
        ...([]),
        worksiteCell,
        ...([]),
        positionCell,
        ...([]),
        locationCell,
        ...(isPayApprovalTab ? [payRangeCellInfo] : []),
        payRateDesktopCell,
        {
            ...startDateCell,
            renderTitle: enableOfferLetterTableSorting ? getSortableHead('start_date', 'start date') : undefined,
        },
        getOfferLetterPdfCell(tableClasses, openDetail),
        getOfferEditBtnCell(clientId),
    ];

    if (isPayApprovalTab) {
        return orderCellsForPayRangeApprovalTab(result);
    }

    return result;
}
