import React, { useCallback } from 'react';
import { ISheetGroupIdWithClient } from 'modules/payrollProcessorHub/store/model';
import { Button } from '@material-ui/core';
import { useModal } from 'shared/utils/hooks/useModal';
import Modal from 'shared/components/modals/Modal';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { useDispatch } from 'react-redux';
import { loadGroupedSheets } from '../../store/actions';
import { browserHistory } from 'shared/utils/browserHistory';
import { routes } from 'shared/routes';
import PrePayrollAvionteExportModal from '../PayrollModal/PrePayrollAvionteExportModal';

export interface IAviontePayrollDownloadButtonProps {
    groupIds: ISheetGroupIdWithClient[];
}

const usePayrollModalStyles = makeHighPriorityStyles(() => ({
    modalPaper: {
        minWidth: 1000,
    },
}));

export const AviontePayrollDownloadButton = ({ groupIds }: IAviontePayrollDownloadButtonProps) => {
    const { isModalOpened, onModalClose, onModalOpen } = useModal();
    const classes = usePayrollModalStyles();
    const dispatch = useDispatch();
    const onCloseModalHandler = useCallback(() => {
        dispatch(loadGroupedSheets());
        browserHistory.push(routes.PAYROLL_PROCESSOR_HUB.SHEETS);
        onModalClose();
    }, [dispatch, onModalClose]);

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={onModalOpen}
            >
                Export batch
            </Button>
            <Modal
                title={'Export Payroll Batch'}
                isOpened={isModalOpened}
                onClose={onCloseModalHandler}
                showCloseIcon={true}
                customClasses={{
                    paper: classes.modalPaper,
                }}
            >
                <PrePayrollAvionteExportModal close={onCloseModalHandler} groupIds={groupIds} />
            </Modal>
        </>
    );
};
