import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from 'shared/components/auth/PrivateRoute';
import { Permission } from 'store/components/auth/authModels';
import { getCustomFieldsHierarchyNodes } from 'store/entities/customFields/actions';
import { HierarchyType } from 'store/entities/customFields/model';
import { FeatureSwitches } from 'utils/featureSwitches';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';
import { settingsRoutes } from 'modules/settings/routes';
import { ClientCustomFieldHierarchyRoot } from 'modules/settings/submodules/clients/clientAssignmentSetup/submodules/CustomFieldsHierarchy/ClientCustomFieldsHierarchy';
import { ClientAssignmentSetupDashboard } from 'modules/settings/submodules/clients/clientAssignmentSetup/submodules/Dashboard/ClientAssignmentSetupDashboard';
import { EafSettings } from 'modules/settings/submodules/clients/clientAssignmentSetup/submodules/eafSettings/EafSettings';
import { CustomFieldValuesRoutes } from 'modules/settings/submodules/clients/customFieldValues/CustomFieldValuesRoutes';
import { PayRangesPage } from 'modules/settings/submodules/clients/payRanges/PayRangesPage';
import { ScopesRoutes } from 'modules/settings/submodules/clients/scopes/ScopesRoutes';

export const ClientAssignmentSetupRoutes = () => {
    useWithClientEffect(dispatchWithClient => {
        dispatchWithClient(getCustomFieldsHierarchyNodes.init());
        dispatchWithClient(getCustomFieldsHierarchyNodes.init({ hierarchy_type: HierarchyType.OfferLetter }));
    });

    return (
        <Switch>
            <PrivateRoute
                path={settingsRoutes.CLIENTS.ASSIGNMENT_SETUP.HIERARCHY}
                permission={Permission.ManageCustomFields}
                component={ClientCustomFieldHierarchyRoot}
            />
            <PrivateRoute
                path={settingsRoutes.CLIENTS.ASSIGNMENT_SETUP.CUSTOM_FIELD_VALUES.ROOT}
                component={CustomFieldValuesRoutes}
                permission={Permission.modifyClientSettings}
            />
            <PrivateRoute
                path={settingsRoutes.CLIENTS.ASSIGNMENT_SETUP.SCOPES.ROOT}
                component={ScopesRoutes}
                permission={Permission.modifyClientSettings}
            />
            <PrivateRoute
                component={PayRangesPage}
                path={settingsRoutes.CLIENTS.ASSIGNMENT_SETUP.PAY_RANGES}
            />
            <PrivateRoute
                path={settingsRoutes.CLIENTS.ASSIGNMENT_SETUP.EAF_SETTINGS}
                component={EafSettings}
                permission={Permission.modifyClientSettings}
                feature={FeatureSwitches.enableEafSettings}
            />
            <PrivateRoute
                path={settingsRoutes.CLIENTS.ASSIGNMENT_SETUP.ROOT}
                component={ClientAssignmentSetupDashboard}
            />
        </Switch>
    );
};
