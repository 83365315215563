import { NotificationCenter } from 'modules/notificationCenter/components/notificationCenter/NotificationCenter';
import React, { useState } from 'react';
import {
    AppBar, Box, Hidden, Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import HideSidebarButton from 'shared/components/HideSidebarButton/HideSidebarButton';
import { useHeaderStyles } from 'shared/styles/header';
import { UserProfileMenu } from 'modules/home/header/UserProfileMenu';
import { selectHeaderTitle } from 'store/entities/appConfig/appConfigSelectors';
import { selectClientDisplayName } from 'store/entities/clients/selectors/clientsSelectors';
import { HeaderTags } from '../../../HeaderTags';

export let setHeaderContent: (content: JSX.Element | null) => void;

interface IHeaderProps {
    title?: string;
}

export default function Header({ title }: IHeaderProps) {
    const headerClasses = useHeaderStyles();
    const tenantDisplayName = useSelector(selectClientDisplayName);
    const sectionTitle = title || (process.env.REACT_APP_CLIENT_ID ? '' : tenantDisplayName);
    const pageTitle = useSelector(selectHeaderTitle);

    let headerContent: null | JSX.Element;
    [headerContent, setHeaderContent] = useState<null | JSX.Element>(null);

    return (
        <AppBar
            className={headerClasses.headerNewRoot}
            position="sticky"
            elevation={0}
        >
            <Box display="flex" className={headerClasses.leftSideWrapper}>
                <HideSidebarButton />
                <Box className={headerClasses.titleWrapper} display="flex">
                    <Hidden smDown>
                        {sectionTitle && (
                            <Typography variant="body1">
                                {sectionTitle}
                            </Typography>
                        )}
                        <Typography variant="h4" className={headerClasses.desktopPageHeader}>
                            {pageTitle.desktopHeader}
                        </Typography>
                        {pageTitle.desktopHeader && (
                            <HeaderTags title={pageTitle.tabHeader} />
                        )}
                    </Hidden>
                    <Hidden mdUp>
                        <Typography variant="h5" className={headerClasses.mobilePageHeader}>
                            {pageTitle.mobileHeader}
                        </Typography>
                        {pageTitle.mobileHeader && (
                            <HeaderTags title={pageTitle.tabHeader} />
                        )}
                        {sectionTitle && (
                            <Typography variant="body1">
                                | {sectionTitle}
                            </Typography>
                        )}
                    </Hidden>
                </Box>
            </Box>
            {headerContent}
            <Box display="flex" className={headerClasses.rightSideWrapper}>
                <NotificationCenter />
                <UserProfileMenu />
            </Box>
        </AppBar>
    );
}
