import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Box, CircularProgress } from '@material-ui/core';
import DottedDivider from 'shared/components/divider/DottedDivider';
import { useCurrentClientMileageRate } from 'shared/models/Miles';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { activityHasCompletes } from 'shared/utils/formatters/activityFormatter';
import { formatHours, formatMinutes } from 'shared/utils/formatters/formatMinutesAndHours';
import { totalDollars } from 'shared/utils/counters/dollarCounter';
import { EntryType, IEntry } from 'shared/models/sheet/Sheet';
import {
    totalBreakTime,
    totalFiles,
    totalTime,
    totalTimesByDayUnits,
} from 'shared/utils/counters/timeCounter';
import { useSelector } from 'react-redux';
import { completesByNotes } from 'shared/utils/counters/completesCounter';
import { useIsMobile } from 'shared/utils/hooks/media';
import { useModifiedTotalConfigurationBySlug } from 'store/entities/clients/hooks';
import { ISheetTotalConfigurationByTotalSlug } from 'store/entities/clients/selectors/fieldSelectors';
import { selectActivitiesById } from 'store/entities/configuration/configurationSelectors';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { ItemsById } from 'shared/models/ItemsById';
import { IActivity } from 'store/entities/configuration/configurationModel';
import { selectCalculationsByTimesheetId } from 'store/entities/timesheet/selectors';
import TotalNum from '../../../toolbar/totalNum/totalNum';
import useTotalStyles from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/EntriesGrid/total/TotalStyles';
import { AvailableTotalConfiguration, SheetTotalSlug } from 'store/entities/clients/clientsModel';
import { ITimeCalculationEntry } from 'modules/payrollProcessorHub/store/model';
import { useTimesheetIdsCalculationsToUpdate } from 'modules/timeAndExpense/components/utils/updateSheetCalculationUtils';
import { flatten, uniq, isEmpty } from 'lodash-es';

interface IEntriesTableHeaderTotalProps {
    entryFilter?: EntryType;
    entries: IEntry[];
    date: string;
    time?: boolean;
    overTime?: boolean;
    doubleTime?: boolean;
    breaks?: boolean;
    holidays?: boolean;
    expense?: boolean;
    completes?: boolean;
    trailingDoc?: boolean;
    newLoan?: boolean;
    files?: boolean;
    useTotalsLoading?: boolean;
}

interface IEntriesTableHeaderTotalStoreProps {
    activitiesById?: ItemsById<IActivity>;
    sheetsEntriesCalculations: ITimeCalculationEntry[];
    fieldsBySlug?: ISheetTotalConfigurationByTotalSlug;
    isLoading: boolean;
}

export function EntriesTableHeaderTotalPure({
    entryFilter,
    entries,
    fieldsBySlug,
    isLoading = false,
    sheetsEntriesCalculations = [],
    activitiesById = {},
}: IEntriesTableHeaderTotalProps & IEntriesTableHeaderTotalStoreProps) {
    const classes = useTotalStyles();
    const dividerClass = classes.divider;
    const isMobile = useIsMobile();

    const hasTime = Boolean(fieldsBySlug?.[SheetTotalSlug.Time]) || isMobile;
    const hasPtoTime = Boolean(fieldsBySlug?.[SheetTotalSlug.PtoTime]) || !isMobile;
    const hasRegularTime = Boolean(fieldsBySlug?.[SheetTotalSlug.RegularTime]) && !isMobile;
    const hasOverTime = Boolean(fieldsBySlug?.[SheetTotalSlug.OverTime]) && !isMobile;
    const hasDoubleTime = Boolean(fieldsBySlug?.[SheetTotalSlug.DoubleTime]) && !isMobile;
    const hasBreaks = Boolean(fieldsBySlug?.[SheetTotalSlug.Break]) && !isMobile;
    const hasExpense = Boolean(fieldsBySlug?.[SheetTotalSlug.Expense]);
    const hasCompletes = Boolean(fieldsBySlug?.[SheetTotalSlug.Completes]) && !isMobile;
    const hasFiles = Boolean(fieldsBySlug?.[SheetTotalSlug.Files]);

    const hasTimeEntries = useMemo(() => {
        return !!entries.find(entry => entry.entry_type === EntryType.TIME);
    }, [entries]);
    const mileageRate = useCurrentClientMileageRate();

    const completesAmount = useMemo(() => {
        return hasCompletes
            ? (
                entries.filter(
                    entry => activityHasCompletes(activitiesById[entry.activity_id ?? '']),
                ).reduce(
                    (acc: number, entry) => acc + completesByNotes(entry.notes || ''), 0,
                )
            )
            : 0;
    }, [hasCompletes, activitiesById, entries]);

    const formattedTime = formatMinutes(totalTime(entries));
    const formattedAmount = totalDollars(entries, mileageRate);
    const timeBreak = totalBreakTime(entries);
    const filesAmount = totalFiles(entries);
    const { regularTime, overTime, doubleTime, pto } = totalTimesByDayUnits(sheetsEntriesCalculations);

    const totalItems = useMemo<React.ReactElement[]>(() => {
        const items: React.ReactElement[] = [];
        if (entryFilter !== EntryType.EXPENSE) {
            if (hasTimeEntries || !hasFiles) {
                if (hasFiles && filesAmount > 0) {
                    items.push(
                        <TotalNum
                            key="files"
                            value={`${filesAmount}`}
                            data-testid="total-files"
                            label={fieldsBySlug?.[SheetTotalSlug.Files]?.unitLabel}
                        />,
                    );
                } else {
                    if (completesAmount > 0 || hasCompletes) {
                        items.push(
                            <TotalNum
                                key="completes"
                                value={`${completesAmount}`}
                                data-testid="total-completes"
                                label={fieldsBySlug?.[SheetTotalSlug.Completes]?.unitLabel}
                            />,
                        );
                    }
                    if (hasPtoTime && pto) {
                        items.push(
                            <TotalNum
                                key="pto-time"
                                value={formatHours(pto)}
                                data-testid="pto-time"
                                label={fieldsBySlug?.[SheetTotalSlug.PtoTime]?.unitLabel}
                            />,
                        );
                    }
                    if (hasTime) {
                        items.push(
                            <TotalNum
                                key="time"
                                value={formattedTime}
                                data-testid="total-time"
                                label={fieldsBySlug?.[SheetTotalSlug.Time]?.unitLabel}
                            />,
                        );
                    }
                    if (hasRegularTime) {
                        items.push(
                            <TotalNum
                                key="regTime"
                                value={formatHours(regularTime)}
                                data-testid="regular-time"
                                label={fieldsBySlug?.[SheetTotalSlug.RegularTime]?.unitLabel}
                            />,
                        );
                    }
                    if (hasBreaks) {
                        items.push(
                            <TotalNum
                                key="break"
                                value={formatMinutes(timeBreak)}
                                data-testid="total-time"
                                label={fieldsBySlug?.[SheetTotalSlug.Break]?.unitLabel}
                            />,
                        );
                    }
                    if (hasOverTime) {
                        items.push(
                            <TotalNum
                                key="overTime"
                                value={formatHours(overTime)}
                                data-testid="total-overtime"
                                label={fieldsBySlug?.[SheetTotalSlug.OverTime]?.unitLabel}
                            />,
                        );
                    }
                    if (hasDoubleTime) {
                        items.push(
                            <TotalNum
                                key="doubleTime"
                                value={formatHours(doubleTime)}
                                data-testid="total-doubletime"
                                label={fieldsBySlug?.[SheetTotalSlug.DoubleTime]?.unitLabel}
                            />,
                        );
                    }
                }
            }
        }
        if (entryFilter !== EntryType.TIME && hasExpense) {
            items.push(
                <TotalNum
                    key="expense"
                    value={formatDollars(formattedAmount)}
                    data-testid="total-expense"
                    label={fieldsBySlug?.[SheetTotalSlug.Expense]?.unitLabel}
                />,
            );
        }
        return items.reduce<React.ReactElement[]>((acc, el, index) => {
            acc.push(el);
            if (index < items.length - 1) {
                acc.push(
                    <DottedDivider
                        key={`divider${index}`}
                        height={25}
                        data-testid="total-divider"
                        customClasses={dividerClass}
                    />,
                );
            }
            return acc;
        }, []);
    }, [
        entryFilter,
        hasExpense,
        hasTimeEntries,
        hasFiles,
        filesAmount,
        fieldsBySlug,
        completesAmount,
        hasCompletes,
        hasTime,
        hasRegularTime,
        hasBreaks,
        hasOverTime,
        hasDoubleTime,
        formattedTime,
        regularTime,
        timeBreak,
        overTime,
        doubleTime,
        formattedAmount,
        dividerClass,
        pto,
        hasPtoTime,
    ]);

    return (
        <Box className={clsx(classes?.totalContainer, { [classes.loading]: isLoading })}>
            {isLoading && (<CircularProgress size={16} className={classes.progress} />)}
            {totalItems}
        </Box>
    );
}

export const EntriesTableHeaderTotal = separateLogicDecorator<
IEntriesTableHeaderTotalProps,
IEntriesTableHeaderTotalStoreProps
// @ts-ignore
>(({ entries, date, useTotalsLoading = false }) => {
    const totalFields = useModifiedTotalConfigurationBySlug(AvailableTotalConfiguration.DaySheetTotal);
    const sheetIds = uniq(entries.map(entry => entry.sheet_id));
    const timeSheetCalculationsById = useSelector(selectCalculationsByTimesheetId);
    const sheetsEntriesCalculations = flatten(
        sheetIds.map(id => timeSheetCalculationsById[id]?.entries || []),
    ).filter(entryCalculation => entryCalculation.entry_date === date);

    const hasCalculationDependentValues = (Object.keys(totalFields) as SheetTotalSlug[]).some(
        slug => [SheetTotalSlug.DoubleTime, SheetTotalSlug.OverTime, SheetTotalSlug.RegularTime].includes(slug),
    );
    const isLoading = useTotalsLoading && hasCalculationDependentValues
        && !isEmpty(useTimesheetIdsCalculationsToUpdate(sheetIds));
    return {
        activitiesById: useSelector(selectActivitiesById),
        sheetsEntriesCalculations,
        fieldsBySlug: totalFields,
        isLoading,
    };
})(EntriesTableHeaderTotalPure);
