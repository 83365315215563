import {
    Backdrop,
    Box,
    CircularProgress,
    Hidden,
} from '@material-ui/core';
import {
    useOfferLetterStyles,
} from 'modules/offerLetter/OfferLetterStyles';
import { OnboardingStep } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/OnboardingStep';
import { OnboardingStepper } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/OnboardingStepper';
import { ProfileStep } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/ProfileStep';
import { ViewOfferStep } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/ViewOfferStep';
import { OnboardingSteps } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/model';
import { setOnboardingStep } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/store/actions';
import {
    selectOnboardingStep,
} from 'modules/offerLetter/components/OfferLetterEmployeeDetail/store/selectors';
import {
    getOfferLetter,
    viewOfferLetter,
} from 'modules/offerLetter/store/actions';
import { OfferLetterStatusSlug } from 'modules/offerLetter/store/model';
import {
    selectGetOfferLetterLoading,
    selectIsMyEmployeeOnboardedWithAvionte,
    selectOfferLetter,
} from 'modules/offerLetter/store/selectors';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { routes } from 'shared/routes';
import { browserHistory } from 'shared/utils/browserHistory';
import { setSidebarOpenedAction } from 'store/entities/appConfig/actions';
import { getStates } from 'store/entities/scaZone/actions';
import { CommonDialog } from '../../../../shared/components/dialog/CommonDialog';

export default function OfferLetterEmployeeDetail() {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const redirectToRoot = () => browserHistory.push(routes.EMPLOYEE_OFFER_LETTER.ROOT);

    useEffect(() => {
        dispatch(getStates.init());
        dispatch(setOnboardingStep(OnboardingSteps.ViewPdf));
        dispatch(getOfferLetter.init(id));
        dispatch(setSidebarOpenedAction(false));
        return () => {
            dispatch(setSidebarOpenedAction(true)); // for opening vertical menu after hiding in offer letter page
        };
    }, [id, dispatch]);

    const classes = useOfferLetterStyles();

    const activeStep = useSelector(selectOnboardingStep);
    const isOfferLetterLoading = useSelector(selectGetOfferLetterLoading);
    const offerLetter = useSelector(selectOfferLetter(id));
    const isOnboardedWithAvionte = useSelector(selectIsMyEmployeeOnboardedWithAvionte);
    const isLoading = isOfferLetterLoading || !offerLetter;
    const [currentUnlockedStep, setUnlockedStep] = useState(0);

    useEffect(() => {
        dispatch(setSidebarOpenedAction(false));
        if (!offerLetter) {
            return;
        }

        dispatch(viewOfferLetter(offerLetter.id));

        const { status, offer_letter_terms_accepted_at } = offerLetter;
        const { slug } = status || {};

        let step = OnboardingSteps.ViewPdf;

        switch (slug) {
            case OfferLetterStatusSlug.Outstanding:
                if (offer_letter_terms_accepted_at) {
                    step = OnboardingSteps.EmployeeProfile;
                }
                break;
            case OfferLetterStatusSlug.PendingManagerApproval:
                step = OnboardingSteps.AvionteOnboarding;
                break;
            default:
                redirectToRoot();
                return;
        }

        setUnlockedStep(step);
        dispatch(setOnboardingStep(step));
    }, [dispatch, offerLetter, setUnlockedStep]);

    const handleClose = useCallback(() => {
        redirectToRoot();
    }, []);

    return (
        <>
            <Backdrop
                open={isLoading}
                className={classes.backdrop}
                onClick={handleClose}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    height="100%"
                    flexGrow={1}
                    mb={9}
                >
                    <CircularProgress />
                </Box>
            </Backdrop>
            <CommonDialog
                open={!isLoading}
                fullScreen={!isOnboardedWithAvionte}
                classes={{
                    paper: classes.paper,
                }}
            >
                <Box
                    display="flex"
                    alignItems="flex-start"
                    className={classes.offerLetterViewWrapper}
                >
                    {!isOnboardedWithAvionte && (
                        <Hidden mdDown>
                            <OnboardingStepper
                                step={activeStep}
                                unlocked={currentUnlockedStep}
                            />
                        </Hidden>
                    )}
                    {activeStep === OnboardingSteps.ViewPdf && (
                        <ViewOfferStep
                            id={id}
                            offerLetter={offerLetter}
                            isOnboardedWithAvionte={isOnboardedWithAvionte}
                        />
                    )}
                    {activeStep === OnboardingSteps.EmployeeProfile && (
                        <ProfileStep clientId={offerLetter?.client_id} offerLetterId={offerLetter?.id} />
                    )}
                    {activeStep === OnboardingSteps.AvionteOnboarding && (
                        <OnboardingStep />
                    )}
                </Box>
            </CommonDialog>
        </>
    );
}
