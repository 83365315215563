import { difference, mapValues, omit } from 'lodash-es';
import { selectIsOfferEditing } from 'modules/offerLetter/components/EditOffer/store/selectors';
import { IOfferLetter, OfferLetterStatusSlug, OfferLetterTabValue } from 'modules/offerLetter/store/model';
import { createSelector } from 'reselect';
import { IEmployeeProfile } from 'shared/models/EmployeeProfile';
import { PayPeriodType, PayRateType } from 'shared/models/PaySettings';
import { isNotEmpty } from 'shared/utils/helpers/isNotEmpty';
import { CheckedItems } from 'shared/utils/hooks/useCheckedItems';
import { logger } from 'shared/utils/logging/logger';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';
import { IStore } from 'store/configureStore';
import { selectClientPaySettings } from 'store/entities/clients/selectors/timeAndPaySelectors';
import { selectAssignmentCustomFieldMapping, selectPayRangesIds } from 'store/entities/configuration/configurationSelectors';
import { IInfinityScrollState } from 'store/reducerUtils';
import { getInfinityScrollSelectors } from 'store/utils/infinityScroll/selectors';
import { selectCustomFieldValuesByIds } from '../../../store/entities/customFields/selectors';

type PdfCheckStateById = Record<string, any>

const offerLetterState = (state: IStore) => state.modules.offerLetter;
export const selectOfferLetters = (state: IStore) => offerLetterState(state).offerLetterById;
export const selectOfferLetter = (id: string) => (state: IStore) => offerLetterState(state).offerLetterById[id];
export const selectStartDates = (state: IStore) => offerLetterState(state).startDates;
export const selectGetOfferLetterLoading = (state: IStore) => offerLetterState(state).getOfferLetterLoading;
export const selectMyOfferLettersLoading = (state: IStore) => offerLetterState(state).myOfferLettersLoading;
export const selectOfferLetterFilters = (state: IStore) => offerLetterState(state).offerLetterFilters;
export const selectOfferLetterTableSort = (state: IStore) => offerLetterState(state).offerLetterTableSort;
const selectOfferLetterPdfCheck = (state: IStore): PdfCheckStateById => offerLetterState(state).pdfCheck;
const selectEndOfAssignmentPdfCheckStatusByIds = (state: IStore): PdfCheckStateById =>
    offerLetterState(state).pdfCheckEndOfAssignmentPdfById;
export const selectEndOfAssignmentPdfCheckStatusById = (id: string) => (state: IStore): PdfCheckStateById =>
    selectEndOfAssignmentPdfCheckStatusByIds(state)[id] || {};
export const selectOfferLetterPdfIsLoading = createSelector(
    selectOfferLetterPdfCheck,
    pdfChecks => mapValues(pdfChecks, check => check.isLoading),
);
export const selectOfferLetterPdfIsAvailable = createSelector(
    selectOfferLetterPdfCheck,
    pdfChecks => mapValues(pdfChecks, check => !check.result?.isError),
);
export const selectOfferIsUpdating = (state: IStore) => offerLetterState(state).isOfferUpdating;
export const selectIsOfferLetterTermsAccepting = (state: IStore) =>
    offerLetterState(state).isOfferLetterTermsAccepting;

/**
 *  Offer letter table with pagination
 */
export const selectOfferLettersTableState = (state: IStore): IInfinityScrollState<string> =>
    offerLetterState(state).offerLettersTable;
export const offerLettersTableStateSelectors = getInfinityScrollSelectors(selectOfferLettersTableState);

export const selectOfferLettersWithPayRanges = createSelector(
    selectOfferLetters,
    (offerLettersByIds: Record<string, IOfferLetter>): IOfferLetter[] => {
        return Object.values(offerLettersByIds).filter(item => Boolean(item.pay_range_id));
    },
);

export const selectOfferLettersPayRangesIds = createSelector(
    selectOfferLettersWithPayRanges,
    (offerLetters: IOfferLetter[]): string[] => offerLetters.map(item => item.pay_range_id as string),
);

export const selectAbsentOfferLetterPayRangesIds = createSelector(
    selectPayRangesIds,
    selectOfferLettersPayRangesIds,
    (presentedIds: string[], necessaryIds: string[]): string[] => difference(necessaryIds, presentedIds),
);

export const selectAbsentOfferLetterCustomFieldIds = createSelector(
    selectCustomFieldValuesByIds,
    selectOfferLetters,
    (customFieldValuesByIds, offerLetters): string[] => {
        const offersCustomFieldValuesRaw = Object.values(offerLetters)
            .map((ol: IOfferLetter) => ol.custom_field_value_ids ?? []).flat();
        const uniqueCustomFieldValues = [...new Set(offersCustomFieldValuesRaw)];
        const absentValues = uniqueCustomFieldValues.filter(id => !customFieldValuesByIds[id]);
        return absentValues;
    },
);

/**
 * Flag is true if offer letter pdf checking is in progress
 * @param {string} id
 */
export const selectOfferLetterPdfIsCheckingById = (id: string) => (state: IStore) => (
    Boolean((selectOfferLetterPdfCheck(state)[id] || {}).isLoading)
);
/**
 * Flag is true if offer letter pdf is available
 * @param {string} id
 */
export const selectOfferLetterPdfIsAvailableById = (id: string) => (state: IStore) => (
    !((selectOfferLetterPdfCheck(state)[id] || {}).result?.isError)
);

/**
 * Select Employee profiles by client ids
 */
export const selectMyEmployeeProfilesByClientIds = (state: IStore): Record<string, IEmployeeProfile> =>
    state.modules.offerLetter.employeeProfilesByClientId;
/**
 * Select Employee profile by client id
 * @param {string} clientId
 */
export const selectMyEmployeeProfileByClientId = (clientId: string) => (state: IStore): IEmployeeProfile | null =>
    selectMyEmployeeProfilesByClientIds(state)[clientId];
/**
 * Select flag that means that employee already onboarded with Avionte and have an manager approve
 * Find any employee profile in any client that is onboarded with Avionte
 * @param {string} clientId
 */
export const selectIsMyEmployeeOnboardedWithAvionte = (state: IStore): boolean =>
    Object.values(selectMyEmployeeProfilesByClientIds(state))
        .some(profile => profile.avionte_onboarding_completed) || false;
/**
 * Select Offer Letter Active tab.
 */
export const selectOfferLetterActiveTab = (state: IStore): OfferLetterTabValue =>
    selectOfferLetterFilters(state).statusSlug;
/**
 * Select Offer Letters Has Active Filter.
 */
export const selectOfferLetterHasActiveFilter = (state: IStore): boolean =>
    isNotEmpty(omit(selectOfferLetterFilters(state), 'statusSlug'));

/**
 * Select Employee profiles by client ids
 */
export const selectEmployeeProfilesByIds = (state: IStore): Record<string, IEmployeeProfile> =>
    offerLetterState(state).employeeProfilesByIds;

export const selectEmployeeProfileById = (id = '') => (state: IStore): IEmployeeProfile | undefined =>
    selectEmployeeProfilesByIds(state)[id];

/**
 * Select position field
 */
export const selectOfferLetterFormFieldsMapping = createSelector(
    selectAssignmentCustomFieldMapping,
    assignmentMapping => {
        return {
            [assignmentMapping.department_value_id || '']: 'departmentValueId',
            [assignmentMapping.job_order_field_id || '']: 'jobOrderId',
        };
    },
);

/**
 * Select client pay rate type by pay settings
 */
export const selectPayRateTypeByPaySettings = createSelector(
    selectClientPaySettings,
    paySettings => {
        if (!paySettings) {
            return PayRateType.PER_WEEK;
        }
        switch (paySettings?.pay_period_type) {
            case PayPeriodType.BiWeekly:
                return PayRateType.BI_WEEKLY;
            case PayPeriodType.Weekly:
                return PayRateType.PER_WEEK;
            default:
                logger.error(new Error(`Unexpected pay setting pay type ${paySettings?.pay_period_type}`));
                return PayRateType.PER_WEEK;
        }
    },
);

export const selectIsUserHasRequiredActionOnPendingPayRateTab = createSelector(
    selectOfferLetters,
    selectIsUserHasPermission(Permission.ApprovePayRate),
    (offerLetterByIds, userCanApprove) => {
        const actionableState = userCanApprove ? OfferLetterStatusSlug.PendingPayRateApproval
            : OfferLetterStatusSlug.RejectedPayRate;
        // @ts-ignore
        return Object.values(offerLetterByIds).some(offer => offer.status.slug === actionableState);
    },
);

export const selectCheckedOfferLetterRowsState = createSelector(
    offerLetterState,
    (state): CheckedItems => state.checkedOfferLetterRowIds,
);
export const selectCheckedOfferLetterRowsIds = createSelector(
    selectCheckedOfferLetterRowsState,
    (state): string[] => Object.entries(state).filter(([, value]) => value).map(([key]) => key),
);

export const isOfferPayRateApproving = (state: IStore) => offerLetterState(state).isOfferPayRateApproving;
export const isOfferPayRateRejecting = (state: IStore) => offerLetterState(state).isOfferPayRateRejecting;

export const isOfferStatusOperationInProgress = createSelector(
    isOfferPayRateApproving,
    isOfferPayRateRejecting,
    selectIsOfferEditing,
    (...args) => args.some(Boolean),
);

export const selectIsOfferRescinding = createSelector(
    offerLetterState,
    state => state.offerRescind.isOfferRescinding,
);
