import React from 'react';
import clsx from 'clsx';
import { ICellProps } from 'shared/components/table/GridTable/GridTableModel';
import { DownloadSheetButtonBatch } from '../../DownloadSheet/DownloadSheetButtonBatch';
import { SendReminderButton } from '../../SendReminder/SendReminderButton';
import { IPayrollSheetGroupedRow, TableClasses } from '../model';

export function makeOverdueDetailsBatchCell(tableClasses: TableClasses) {
    return function OverdueDetailsCell({ className, ...row }: ICellProps<IPayrollSheetGroupedRow>) {
        const timeSheetIds = row.time_calculations?.map(i => i.sheet_id) || [];
        const expenseSheetIds = row.expense_calculations?.map(i => i.sheet_id) || [];

        return (
            <div className={clsx(className, tableClasses.iconCell, tableClasses.detailsIcon)}>
                <DownloadSheetButtonBatch row={row} />

                <SendReminderButton
                    timeSheetId={timeSheetIds[0] || null}
                    expenseSheetId={expenseSheetIds[0] || null}
                />
            </div>
        );
    };
}
