import React, { useEffect } from 'react';
import { FormikProps } from 'formik';
import clsx from 'clsx';
import { Box } from '@material-ui/core';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import FormField from 'shared/components/formFields/FormField';
import { formBasicSpacing, useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import { ICustomFieldFormValues } from 'modules/settings/submodules/customFields/components/CustomFieldForm/model';
import { ClientMultiselect } from 'modules/settings/common/components/ClientMultiselect';
import { MappingFieldSelector } from 'modules/settings/submodules/customFields/components/CustomFieldForm/MappingFieldSelector';
import { IPrismMappingField } from 'store/entities/customFields/model';
import { LabelWithValue } from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/components/LabelWithValue';

export interface ICustomFieldFormProps extends FormikProps<ICustomFieldFormValues> {
    isLoading?: boolean;
    isDisabled?: boolean;
    isEdit?: boolean;
    submitTitle: string;
    onCancel?: () => void;
    mapped?: IPrismMappingField | null;
}

export function CustomFieldForm({
    handleSubmit,
    submitTitle,
    mapped,
    values,
    validateForm,
    isLoading = false,
    isDisabled = false,
    isEdit = false,
}: ICustomFieldFormProps) {
    const classes = useSettingsFormStyles();

    useEffect(() => {
        validateForm();
    }, [validateForm, values.all_clients]);

    return (
        <form onSubmit={handleSubmit} className={clsx(classes.form, classes.smallPadding)}>
            <Box className={classes.formBlock}>
                <Box display="flex">
                    <Box width="60%">
                        <FormField
                            name="name"
                            outerLabel="Name"
                            className={classes.input}
                        />
                    </Box>
                </Box>
                <Box display="flex">
                    <FormField
                        name="description"
                        outerLabel="Description"
                        className={classes.input}
                    />
                </Box>
                {!isEdit && (
                    <Box display="flex">
                        <Box width="60%">
                            <MappingFieldSelector
                                name="mapping"
                                outerLabel="Mapped export field"
                                label="--"
                                className={classes.input}
                                useIdValue
                            />
                        </Box>
                    </Box>
                )}
                <Box display="flex">
                    <Box width="60%">
                        <ClientMultiselect
                            name="clients"
                            allClientsFieldName="all_clients"
                            outerLabel="Client"
                            className={classes.input}
                            useIdValue
                        />
                    </Box>
                </Box>
                {isEdit && (
                    <Box display="flex" m={2}>
                        <LabelWithValue label="Mapped export field" value={mapped?.name} />
                    </Box>
                )}
            </Box>

            <Box m={formBasicSpacing} mt={2}>
                <ButtonWithLoader
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    disabled={isDisabled}
                    isLoading={isLoading}
                    data-testid="submit_update_button"
                >
                    {submitTitle}
                </ButtonWithLoader>
            </Box>
        </form>
    );
}
