import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import { IExpenseEntryCreate } from 'store/entities/timesheet/models/Entry';
import { selectExpenseStatusIdByName, selectSheet, selectTempEntryAttachments } from 'store/entities/timesheet/selectors';
import { useExpenseSchema } from 'shared/models/validationSchemes/expenseEntry';
import { IExpenseEntryFormValues } from 'shared/components/forms/entries/ExpenseEntryModel';
import { ExpenseEntrySidebarForm } from 'shared/components/forms/entries/ExpenseEntrySidebarForm';
import { addExpenseEntry } from 'store/entities/timesheet/actions/expenseActions';
import { useEntryEditStyles } from '../EntryEdit/styles';
import { IEntryAddSidebar } from './interfaces';
import { useExpenseEntryDefaultValues, useGetCommonEntryModel } from 'shared/utils/helpers/entries';

interface IExpenseEntryProps extends IEntryAddSidebar {}

export default function AddExpenseEntry({
    supervisorId,
    userId,
    SubmitBlock,
    onClose,
    sheetId,
    statusName,
    defaultEntryDate,
    inputs,
    payPeriod,
}: IExpenseEntryProps) {
    const statusId = useSelector(selectExpenseStatusIdByName(statusName || ''));
    const classes = useEntryEditStyles();
    const dispatch = useDispatch();
    const areaId = useSelector(selectSheet(sheetId))?.area_id;
    const attachments = useSelector(selectTempEntryAttachments);
    const schema = useExpenseSchema(userId);
    const initialValues = useExpenseEntryDefaultValues(userId);
    const defaultTask = inputs.task && inputs.task.default_value;
    const getCommonEntryModel = useGetCommonEntryModel(userId, defaultTask);

    const onSubmit = useCallback((
        values: IExpenseEntryFormValues,
        formikActions: FormikHelpers<IExpenseEntryFormValues>,
    ) => {
        const baseEntryModel = getCommonEntryModel(values);
        if (baseEntryModel && values.data) {
            const entry: IExpenseEntryCreate = {
                ...baseEntryModel,
                data: values.data,
                zip_code: values.zipCode,
                sheet_entry_attachments: attachments,
                // @ts-ignore
                user_id: userId,
                // @ts-ignore
                status_id: statusId,
                period_start: payPeriod.period_start,
                // @ts-ignore
                period_end: payPeriod.period_end,
            };
            dispatch(addExpenseEntry.init(entry));
            onClose();
        }
        formikActions.setSubmitting(false);
    }, [attachments, dispatch, getCommonEntryModel, onClose, payPeriod, statusId, userId]);

    return (
        <Formik
            initialValues={{
                ...initialValues,
                entry_date: defaultEntryDate,
            }}
            validationSchema={schema}
            onSubmit={onSubmit}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {props => (
                <>
                    <ExpenseEntrySidebarForm
                        {...props}
                        supervisorId={supervisorId}
                        userId={userId}
                        // @ts-ignore
                        areaId={areaId}
                        sheetId={sheetId}
                        inputs={inputs}
                        payPeriod={payPeriod}
                    />
                    <footer className={classes.footer}>
                        <SubmitBlock onSubmit={props.submitForm} />
                    </footer>
                </>
            )}
        </Formik>
    );
}
