import React, { useCallback, useEffect } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Paper } from '@material-ui/core';

import AuthWrapper from 'shared/components/auth/AuthWrapper';
import { LoginForm } from 'shared/components/auth/forms/LoginForm';
import { loginValidationSchema } from 'shared/components/auth/forms/loginValidationSchema';
import { ILoginFormValues, loginInitialValues } from 'shared/components/auth/forms/models';
import { routes } from 'shared/routes';
import { IStore } from 'store/configureStore';
import { authByPassword } from 'store/components/auth/authActions';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { useStyles } from './AuthStyles';
import { AuthFooter } from './components/AuthFooter';
import { selectAuthEmail } from '../../../store/components/auth/selectors';
import { Logo } from './components/Logo';
import { Maintaince } from '../alerts/Maintaince';
import { useLogoutAction } from 'shared/utils/hooks/useLogoutAction';

const Login = () => {
    const classes = useStyles();
    const userEmail = useSelector(selectAuthEmail);
    const { isLoading, error } = useSelector((state: IStore) => state.auth);
    const dispatch = useDispatch();
    const logout = useLogoutAction();

    useEffect(() => {
        // This effect should be executed only on component initialization
        dispatch(logout());
        return () => {
            dispatch(setGlobalToast(null));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);
    const onSubmit = useCallback((values: ILoginFormValues) => {
        dispatch(authByPassword.init(values));
    }, [dispatch]);

    const errorMessage = error ? (error.error_description === 'Account disabled' ? 'Email Not Confirmed' : 'Invalid email or password') : '';

    return (
        <AuthWrapper>
            <Maintaince />
            <Paper className={classes.block}>
                <Box className={classes.logoContainer}>
                    <Logo />
                </Box>
                <Formik
                    initialValues={{
                        ...loginInitialValues,
                        username: userEmail,
                    }}
                    validationSchema={loginValidationSchema}
                    onSubmit={onSubmit}
                    validateOnBlur={false}
                >
                    {props => (
                        <LoginForm
                            {...props}
                            isLoading={isLoading}
                            errorMessage={errorMessage}
                        />
                    )}
                </Formik>
            </Paper>
            <AuthFooter label="Don't have an account?" route={routes.AUTH.LOGIN} />
        </AuthWrapper>
    );
};

export default Login;
