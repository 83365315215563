/// <reference types="googlemaps" />
import React, { useEffect, useRef, useState } from 'react';
import { useField } from 'formik';
import { IFormFieldProps } from 'shared/components/formFields/models';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { IAddressComponents } from 'shared/components/formSpecialFields/addressAutocompleteField/models';
import {
    getAddressComponents,
    useAddressPrediction,
} from 'shared/components/formSpecialFields/addressAutocompleteField/utils';
import { inputParams } from 'shared/styles/constants';
type AutocompletePrediction = google.maps.places.AutocompletePrediction;
type PlacesService = google.maps.places.PlacesService;
type PlaceResult = google.maps.places.PlaceResult;

export interface IAddressAutocompleteField extends IFormFieldProps {
    onAddressSelect?: (address: IAddressComponents) => void;
}

export default function AddressAutocompleteField({
    name,
    label,
    className,
    id = name,
    onAddressSelect,
    disabled,
}: IAddressAutocompleteField) {
    const [field, meta, helper] = useField(name);
    const [predictions, setPredictions] = useState<AutocompletePrediction[]>([]);
    const placeRef = useRef<HTMLDivElement | null>(null);
    const placesServiceRef = useRef<PlacesService | null>(null);
    const errorMessage = typeof meta.error === 'string' ? meta.touched && meta.error : null;

    useEffect(() => {
        if (window.google?.maps?.places && placeRef.current) {
            placesServiceRef.current = new window.google.maps.places.PlacesService(placeRef.current);
        }
    }, []);

    const makeAddressPrediction = useAddressPrediction();

    useEffect(() => {
        makeAddressPrediction(field.value, setPredictions);
    }, [field.value, makeAddressPrediction, setPredictions]);

    const onChange = (e: any, value: AutocompletePrediction | string | null) => {
        if (typeof value === 'string') {
            helper.setValue(value);
            return;
        }

        if (value) {
            helper.setValue(value.structured_formatting?.main_text);
            if (placesServiceRef.current) {
                placesServiceRef.current.getDetails(
                    {
                        placeId: value.place_id,
                        fields: ['address_component'],
                    },
                    (place: PlaceResult) => {
                        if (place?.address_components) {
                            const addressComponents = getAddressComponents(place?.address_components);
                            onAddressSelect && onAddressSelect(addressComponents);
                        }
                    },
                );
            }
        } else {
            helper.setValue('');
            const addressComponents = getAddressComponents([]);
            onAddressSelect && onAddressSelect(addressComponents);
        }
    };

    return (
        <>
            <Autocomplete
                id={id}
                options={predictions}
                freeSolo
                getOptionLabel={option => typeof option === 'string' ? option : option?.description}
                value={field.value}
                className={className}
                onChange={onChange}
                disabled={disabled}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label={label}
                        error={Boolean(errorMessage)}
                        helperText={errorMessage}
                        onChange={e => helper.setValue(e.target.value)}
                        inputProps={{
                            ...params.inputProps,
                            ...inputParams,
                        }}
                    />

                )}
            />
            <div ref={placeRef} />
        </>
    );
}
