import React from 'react';
import { Box } from '@material-ui/core';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';
import { CreateScopeButton } from 'modules/settings/submodules/clients/scopes/components/CreateScope/CreateScopeButton';
import EditScopeModal from 'modules/settings/submodules/clients/scopes/components/EditScope/EditScopeModal';
import { TypedScopesList } from 'modules/settings/submodules/clients/scopes/components/TypedScopesList/TypedScopesList';
import { useParams } from 'react-router-dom';
import { getActivities } from 'store/entities/configuration/configurationAction';
import { getScopes } from 'store/entities/scopes/actions';
import { ScopeType } from 'store/entities/scopes/models';
import { loadClientRoles } from 'store/entities/users/actions';

export const TypedScopesPage = () => {
    const { type } = useParams<{ type: ScopeType }>();

    useWithClientEffect((dispatch, clientId) => {
        dispatch(getScopes.init({
            scope_types: [type],
        }));
        if (type === ScopeType.Activity) {
            dispatch(getActivities.init());
        }
        if (type === ScopeType.SubassignmentManagement) {
            dispatch(loadClientRoles.init({
                client_id: clientId,
            }));
        }
    }, [type]);

    return (
        <>
            <Box mb={2}>
                <CreateScopeButton />
            </Box>
            <TypedScopesList type={type} />
            <EditScopeModal />
        </>
    );
};
