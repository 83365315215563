import { IOfferLetterRow } from 'modules/offerLetter/components/OfferLetterTable/cells/cells';
import { offerLettersTableStateSelectors, selectOfferLetterPdfIsAvailable, selectOfferLetterPdfIsLoading, selectOfferLetters } from 'modules/offerLetter/store/selectors';
import { createSelector } from 'reselect';
import { selectCurrentUser } from 'store/components/auth/selectors';
import { IPayRange } from 'store/entities/configuration/configurationModel';
import { selectPayRangesByIds } from 'store/entities/configuration/configurationSelectors';
import { selectCustomFieldValuesByIds, selectOrderedOfferLetterCustomFields } from 'store/entities/customFields/selectors';
import { CustomFieldType } from '../../../../store/entities/customFields/model';

export const selectOfferLetterRows = createSelector(
    offerLettersTableStateSelectors.selectItems,
    selectOfferLetters,
    selectCurrentUser,
    selectOfferLetterPdfIsLoading,
    selectOfferLetterPdfIsAvailable,
    selectOrderedOfferLetterCustomFields,
    selectCustomFieldValuesByIds,
    selectPayRangesByIds,
    (
        itemIds,
        offerLettersById,
        currentUser,
        isPdfCheckingById,
        isPdfAvailableById,
        offerCustomFields,
        customFieldValuesByIds,
        payRangesByIds: Record<string, IPayRange>,
    ): IOfferLetterRow[] => {
        return itemIds.map(id => {
            const offerLetter = offerLettersById[id];
            const jobOrderId = offerLetter.custom_field_value_ids && offerLetter.custom_field_value_ids.length > 0
                ? offerLetter.custom_field_value_ids.find(cfv_id =>
                    customFieldValuesByIds[cfv_id]?.data?.headway_connect_field === CustomFieldType.AvionteJobOrder)
                : undefined;
            const jobOrder = customFieldValuesByIds[jobOrderId];
            const worksiteAddressRaw = jobOrder?.data?.worksite_address;
            let worksiteAddress = '';
            if (worksiteAddressRaw) {
                const address = `${worksiteAddressRaw.address1 ?? ''} ${worksiteAddressRaw.address2 ?? ''}`.trim();
                worksiteAddress = `${address}, ${worksiteAddressRaw.city}, ${worksiteAddressRaw.state} ${worksiteAddressRaw.zip_code}`.trim();
            }
            const positionId = offerLetter.custom_field_value_ids && offerLetter.custom_field_value_ids.length > 0
                ? offerLetter.custom_field_value_ids.find(cfv_id =>
                    customFieldValuesByIds[cfv_id]?.data?.headway_connect_field === CustomFieldType.Position)
                : undefined;
            const locationId = offerLetter.custom_field_value_ids && offerLetter.custom_field_value_ids.length > 0
                ? offerLetter.custom_field_value_ids.find(cfv_id =>
                    customFieldValuesByIds[cfv_id]?.data?.headway_connect_field === CustomFieldType.Location)
                : undefined;
            const departmentId = offerLetter.custom_field_value_ids && offerLetter.custom_field_value_ids.length > 0
                ? offerLetter.custom_field_value_ids.find(cfv_id =>
                    customFieldValuesByIds[cfv_id]?.data?.headway_connect_field === CustomFieldType.NameCode)
                : undefined;
            const jobOrderDisplayValue = (jobOrderId
                && customFieldValuesByIds[jobOrderId]?.data?.name) ?? '';
            const positionDisplayValue = (customFieldValuesByIds[positionId]?.data?.name) ?? '';
            const locationDisplayValue = (locationId && customFieldValuesByIds[locationId]?.data?.name) ?? '';
            const departmentDisplayValue = departmentId || customFieldValuesByIds[departmentId]?.data?.name;
            let payRange: undefined | [number, number];
            const payRangeItem = payRangesByIds[offerLetter?.pay_range_id || ''];
            if (payRangeItem) {
                payRange = [payRangeItem.min_value, payRangeItem.max_value];
            }

            return {
                id,
                offerLetter,
                isPdfLoading: isPdfCheckingById[id],
                isPdfAvailable: isPdfAvailableById[id],
                jobOrderDisplayValue: jobOrderDisplayValue,
                worksiteAddress: worksiteAddress,
                positionDisplayValue: positionDisplayValue,
                locationDisplayValue: locationDisplayValue,
                departmentDisplayValue: departmentDisplayValue,
                payRange,
            } as IOfferLetterRow;
        }).filter(row => {
            return row?.offerLetter && row.offerLetter.email.toLowerCase() !== (currentUser?.email ?? '').toLowerCase();
        });
    },
);
