import { Box } from '@material-ui/core';
import { Form, FormikProvider, useFormik } from 'formik';
import { sidebarFormStyles } from 'modules/employeeActionsForm/components/EndAssignment/styles';
import { EafType, eafTypeDisplayValues } from 'modules/employeeActionsForm/store/models';
import ShowPayRateType from 'modules/offerLetter/components/OfferLetterForm/ShowPayRateType';
import { EafSubmitBlock } from 'modules/subassignmentManagement/components/EafFormShared/EafSubmitBlock';
import { EffectiveDateSection } from 'modules/subassignmentManagement/components/EafFormShared/EffectiveDateSection';
import { useEafFormStyles } from 'modules/subassignmentManagement/components/EafFormShared/useEafFormStyles';
import { IPositionChangeFormValues } from 'modules/subassignmentManagement/components/PromotionDemotion/store/model';
import { selectPositionCustomFieldValueId } from 'modules/subassignmentManagement/components/PromotionDemotion/store/selectors';
import { getPositionChangeValidationSchema } from 'modules/subassignmentManagement/components/PromotionDemotion/validationSchema';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import EnumFormSelect from 'shared/components/formFields/EnumFormSelect';
import MoneyField from 'shared/components/formFields/MoneyField';
import { CustomFieldValuesSelect } from 'shared/components/selects/CustomFieldValuesSelect/CustomFieldValuesSelect';
import { PayRateType, PayType } from 'shared/models/PaySettings';
import { IClientPaySettings } from 'store/entities/clients/clientsModel';
import { IAssignment, ISubassignment } from 'store/entities/configuration/configurationModel';
import { getCustomFieldValueGroupedByCustomFieldId } from 'store/entities/customFields/helpers';
import { selectCustomFieldValuesByIds, selectOrderedCustomFieldAssignmentNodes } from 'store/entities/customFields/selectors';
import { ScopeAction } from 'store/entities/scopes/models';

export interface IPositionChangeFormProps {
    onSubmit: (values: IPositionChangeFormValues) => void;
    onCancel: () => void;
    isLoading: boolean;
    assignment: IAssignment;
    subassignment?: ISubassignment;
    paySettings: IClientPaySettings;
    initialValueOverride?: Partial<IPositionChangeFormValues>;
    payRateType?: PayRateType;
    payType?: PayType;
    readonlyPayRate?: boolean;
    readonlyPosition?: boolean;
}

export function PositionChangeForm({
    onSubmit,
    onCancel,
    isLoading,
    assignment,
    subassignment,
    paySettings,
    initialValueOverride,
    payRateType,
    payType,
    readonlyPayRate,
    readonlyPosition,
}: IPositionChangeFormProps) {
    const classes = useEafFormStyles();
    const formClasses = sidebarFormStyles();

    const customFieldHierarchy = useSelector(selectOrderedCustomFieldAssignmentNodes);
    const positionCustomFieldId = useSelector(selectPositionCustomFieldValueId);
    const customFieldValuesByIds = useSelector(selectCustomFieldValuesByIds);

    const hierarchy = useMemo(() => {
        /**
         * For apply position filtration by subassignment custom field values we need to position on end of hierarchy
         */
        const positionNode = customFieldHierarchy.find(node => node.custom_field_id === positionCustomFieldId);
        return [
            ...customFieldHierarchy.filter(node => node.custom_field_id !== positionCustomFieldId),
            positionNode,
        ];
    }, [customFieldHierarchy, positionCustomFieldId]);

    const groupedCustomFieldValues = getCustomFieldValueGroupedByCustomFieldId(
        subassignment?.custom_field_value_ids || assignment?.custom_field_value_ids || [],
        customFieldValuesByIds,
    );
    const currentPosition = groupedCustomFieldValues[positionCustomFieldId]?.[0];

    const validationSchema = useMemo(() => getPositionChangeValidationSchema(paySettings), [paySettings]);
    const formikData = useFormik({
        initialValues: {
            currentPosition,
            newPosition: null,
            newPayRate: null,
            type: null,
            effectiveDate: '',
            isRetro: false,
            ...initialValueOverride,
        },
        validationSchema,
        onSubmit,
        validateOnBlur: false,
        validateOnChange: true,
    });

    return (
        <FormikProvider value={formikData}>
            <Form>
                {currentPosition && (
                    <Box className={classes.row}>
                        <label htmlFor="currentPosition" className={formClasses.inlineInputLabel}>Current Position:</label>
                        <Box width="250px">
                            <CustomFieldValuesSelect
                                name="currentPosition"
                                customFieldId={positionCustomFieldId}
                                className={formClasses.input}
                                useIdValue
                                useLabel
                                disabled
                            />
                        </Box>
                    </Box>
                )}

                <Box className={classes.row}>
                    <label htmlFor="newPosition" className={formClasses.inlineInputLabel}>New Position:</label>
                    <Box width="250px">
                        <CustomFieldValuesSelect
                            name="newPosition"
                            customFieldId={positionCustomFieldId}
                            className={formClasses.input}
                            useIdValue
                            useLabel
                            // @ts-ignore
                            hierarchy={hierarchy}
                            customFieldFormValues={groupedCustomFieldValues}
                            disabled={readonlyPosition}
                            scopeActionFilter={ScopeAction.PromotionDemotion}
                        />
                    </Box>
                </Box>

                <Box className={classes.row}>
                    <label htmlFor="newPayRate" className={formClasses.inlineInputLabel}>New Pay Rate:</label>
                    <Box width="130px">
                        <MoneyField
                            name="newPayRate"
                            withDollar
                            className={formClasses.input}
                            disabled={readonlyPayRate}
                        />
                    </Box>
                    <Box ml={3}>
                        <ShowPayRateType
                            type={assignment?.pay_rate_type || payRateType}
                            payType={assignment?.pay_type || payType}
                        />
                    </Box>
                </Box>

                <Box className={classes.row}>
                    <label htmlFor="type" className={formClasses.inlineInputLabel}>Reason:</label>
                    <Box width="250px">
                        <EnumFormSelect
                            name="type"
                            values={EafType}
                            displayValues={eafTypeDisplayValues}
                            allowedValues={[EafType.Promotion, EafType.Demotion]}
                            className={formClasses.input}
                        />
                    </Box>
                </Box>

                <EffectiveDateSection
                    name="effectiveDate"
                    maxDate={subassignment?.end_date || assignment?.end_date || undefined}
                    paySettings={paySettings}
                    retroFieldName="isRetro"
                />

                <EafSubmitBlock
                    isLoading={isLoading}
                    onCancel={onCancel}
                />
            </Form>
        </FormikProvider>
    );
}
