import React from 'react';
import { Box, BoxProps } from '@material-ui/core';
import Restore from '@material-ui/icons/Restore';

export const EafRetroIcon = (props: Partial<BoxProps>) => {
    return (
        <Box
            ml={1}
            title="Retro"
            display="flex"
            alignContent="baseline"
            {...props}
        >
            <Restore fontSize="small" />
        </Box>
    );
};
