import { Box } from '@material-ui/core';
import { EafApprovalTabs } from 'modules/eafApproval/components/EafApprovalTabs';
import { setHeaderContent } from 'modules/home/header/Header';
import React, { useEffect, useMemo } from 'react';

export function useEafApprovalTabs() {
    const headerContent = useMemo(() => (
        <Box
            display="flex"
            alignItems="flex-end"
            justifyContent="flex-end"
            width="100%"
        >
            <EafApprovalTabs />
        </Box>
    ), []);

    useEffect(() => {
        setHeaderContent(headerContent);
        return () => {
            setHeaderContent(null);
        };
    }, [headerContent]);
}
