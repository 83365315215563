import DateRangeFilterChip from 'modules/offerLetter/OfferLetterHeader/DateRangeFilterChip';
import React, { useCallback, useMemo } from 'react';
import { IUserInfo } from 'shared/models/User';
import { getUserName } from 'shared/utils/converters/user';
import { formatShortDate, formatYearFromEuDate } from 'shared/utils/formatters/dateFormatter';
import { useDispatch, useSelector } from 'react-redux';
import EntityFilterChip from 'shared/components/filters/EntityFilterChip';
import { Box } from '@material-ui/core';
import { selectOfferLetterFilters, selectStartDates } from 'modules/offerLetter/store/selectors';
import { setOfferLetterFilters } from 'modules/offerLetter/store/actions';
import { IOfferLetterFilters } from 'modules/offerLetter/store/model';
import { getFieldValueName } from 'store/entities/customFields/helpers';
import { selectCustomFieldValuesByIds } from 'store/entities/customFields/selectors';
import { selectUsersById } from 'store/entities/users/selectors';
import { useOfferLetterYearFilterValues } from '../hooks/useOfferLetterYearFilterValues';

export default function OfferLetterFiltersChips() {
    const filters = useSelector(selectOfferLetterFilters);
    const dispatch = useDispatch();

    const onDelete = useCallback((...keys: Array<keyof IOfferLetterFilters>) => {
        dispatch(setOfferLetterFilters({
            ...filters,
            ...keys.reduce((result, key) => {
                result[key] = '';
                return result;
            }, {}),
        }));
    }, [dispatch, filters]);

    const usersById = useSelector(selectUsersById);
    const startDates = useSelector(selectStartDates);
    const offerDatesRaw = useOfferLetterYearFilterValues();
    const offerDates = useMemo(() => {
        const offerDataArrays = offerDatesRaw.map(x => [x.value, x.text]);
        return Object.fromEntries(offerDataArrays);
    }, [offerDatesRaw]);
    const customFieldValuesByIds = useSelector(selectCustomFieldValuesByIds);

    return (
        <Box
            ml={2}
            mr={2}
            display="flex"
            justifyContent="flex-end"
        >
            <EntityFilterChip
                values={customFieldValuesByIds}
                id={filters.jobOrderId}
                name="jobOrderId"
                getText={getFieldValueName}
                onDelete={onDelete}
            />
            <EntityFilterChip
                values={customFieldValuesByIds}
                id={filters.departmentValueId}
                name="departmentValueId"
                getText={getFieldValueName}
                onDelete={onDelete}
            />
            <EntityFilterChip
                values={offerDates}
                name="created_since"
                id={filters.created_since}
                onDelete={onDelete}
                getText={formatYearFromEuDate}
            />

            <EntityFilterChip
                values={startDates}
                name="startDate"
                id={filters.startDate}
                onDelete={onDelete}
                getText={formatShortDate}
            />
            <DateRangeFilterChip
                startName="startDateGte"
                endName="startDateLte"
                startValue={filters.startDateGte}
                endValue={filters.startDateLte}
                onDelete={onDelete}
                label="Start Date"
            />

            <EntityFilterChip
                values={usersById}
                name="creatorId"
                id={filters.creatorId}
                onDelete={onDelete}
                getText={(user: IUserInfo) => `Created by ${getUserName(user)}`}
            />
            <EntityFilterChip
                values={usersById}
                name="approverId"
                id={filters.approverId}
                onDelete={onDelete}
                getText={(user: IUserInfo) => `Approver ${getUserName(user)}`}
            />
        </Box>
    );
}
