import { createSelector } from 'reselect';
import { ItemsById } from 'shared/models/ItemsById';
import { IClientRole, IGlobalRole, IPaidTimeOffResponse, IUserInfo } from 'shared/models/User';
import { Permission } from 'store/components/auth/authModels';
import { selectCurrentUser } from 'store/components/auth/selectors';
import { IUserAuthInfo, PrismUserType, UserFeature } from 'store/entities/users/model';
import { IStore } from '../../configureStore';
import { IUsersById } from './reducers';

export const selectUserById = (id?: string) => (state: IStore) => id ? state.users.usersById[id] : undefined;
export const selectUsersById = (state: IStore): IUsersById => state.users.usersById;
export const selectUsersByClientRoleId = (state: IStore): Record<string, string[]> => state.users.userIdsByClientRole;
export const selectUsers = (state: IStore): Array<IUserInfo> => Object.values(state.users.usersById);
export const selectUsersIds = (state: IStore): Array<string> => Object.keys(state.users.usersById);
export const selectPaidTimeOff = (clientId: string, userId: string) => (state: IStore): IPaidTimeOffResponse =>
    state.users.paidTimeOff[`${clientId}_${userId}`];
export const selectUsersByFeature = (feature: UserFeature, value: Permission | PrismUserType) =>
    (state: IStore) => {
        const userIds: string[] = (() => {
            switch (feature) {
                case 'purpose':
                    return state.users.userIdsByPurpose?.[value as Permission] || [];
                case 'permission':
                    return state.users.userIdsByPermission?.[value as Permission] || [];
                case 'prism_user_type':
                    return state.users.userIdsByPrismUserType?.[value as PrismUserType] || [];
            }
        })();
        return userIds.map((id: string) => selectUserById(id)(state));
    };
export const selectSearchResultUsers = (searchKey: string) => (state: IStore) => {
    return state.users.userSearchResultIds[searchKey]?.map((id: string | undefined) => selectUserById(id)(state)) || [];
};
export const selectIsUserSearching = (searchKey: string) => (state: IStore) => {
    return state.users.isSearching[searchKey] || false;
};
export const selectGlobalRoles = (state: IStore): IGlobalRole[] => {
    return Object.values(state.users.globalRolesById);
};
export const selectIsGlobalRolesLoading = (state: IStore) => state.users.globalRolesLoading;
export const selectClientRolesById = (state: IStore): ItemsById<IClientRole> => {
    return state.users.clientRolesById;
};
export const selectClientRoles = (state: IStore): IClientRole[] => {
    return Object.values(selectClientRolesById(state));
};
export const selectIsClientRolesLoading = (state: IStore) => state.users.isClientRolesLoading;

export const selectClientUserRolesByIds = (state: IStore) => state.users.clientUserRolesById;

export const selectAuthUser = createSelector(
    selectCurrentUser,
    selectUsersById,
    (currentUser, usersByIds) => {
        return currentUser?.id ? usersByIds[currentUser?.id] : null;
    },
);

export const selectUserAuthStats = (state: IStore) => {
    return state.users.authenticationUserStats as IUserAuthInfo;
};
