import React from 'react';
import { SideMenu } from 'modules/settings/submodules/components/SideMenu/SideMenu';
import { useClientSubModulesMenu } from 'modules/settings/submodules/clients/utils/useClientSubModulesMenu';

export const ClientDetailSideMenu = () => {
    const items = useClientSubModulesMenu();
    return (
        <SideMenu items={items} />
    );
};
