import React from 'react';
import { useSelector } from 'react-redux';
import { useFeature } from '@optimizely/react-sdk';
import { ISubassignmentTableProps } from 'modules/subassignmentManagement/components/SubassignmentTable/model';
import {
    selectExpandAllSubassignment,
    selectExpandedGroupId,
    selectSubassignmentsGroupedRows, subassignmentTableStateSelectors,
} from 'modules/subassignmentManagement/components/SubassignmentTable/store/selectors';
import {
    selectEditSubassignment,
} from 'modules/subassignmentManagement/components/EditSubAssignment/EditSingleSubassignmentModal/store/selectors';
import {
    SubassignmentVirtualInfinityTablePure,
} from 'modules/subassignmentManagement/components/SubassignmentTable/SubassignmentVirtualInfinityTablePure';
import { useCells } from 'modules/subassignmentManagement/components/SubassignmentTable/cells';
import { SubassignmentInfinityTablePure } from 'modules/subassignmentManagement/components/SubassignmentTable/SubassignmentInfinityTablePure';
import { FeatureSwitches } from 'utils/featureSwitches';

export const SubassignmentTable = (props: ISubassignmentTableProps) => {
    const groups = useSelector(selectSubassignmentsGroupedRows);
    const cells = useCells(groups);
    const selectedSubassignment = useSelector(selectEditSubassignment);
    const expandedGroupId = useSelector(selectExpandedGroupId);
    const expandAll = useSelector(selectExpandAllSubassignment);
    const totalCount = useSelector(subassignmentTableStateSelectors.selectTotalItems) || 0;

    const commonProps = {
        groups,
        cells,
        selectedSubassignment,
        expandedGroupId,
        expandAll,
        totalCount,
        ...props,
    };

    const [enableVirtualizationForSubassignmentsTable] = useFeature(
        FeatureSwitches.enableVirtualizationForSubassignmentsTable,
    );

    if (enableVirtualizationForSubassignmentsTable) {
        return (
            <SubassignmentVirtualInfinityTablePure
                {...commonProps}
            />
        );
    }

    return (
        <SubassignmentInfinityTablePure
            {...commonProps}
        />
    );
};
