import React, { useCallback } from 'react';
import { Create, SvgIconComponent } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';

export interface IEditButtonProps {
    id: string;
    editAction: (payload: string) => AnyAction;
    Icon?: SvgIconComponent;
    title?: string;
    fontSize?: 'inherit' | 'default' | 'small' | 'large';
    disabled?: boolean;
}

export const EditItemButton = ({
    id,
    editAction,
    title,
    Icon = Create,
    fontSize = 'default',
    disabled,
}: IEditButtonProps) => {
    const dispatch = useDispatch();
    const openModal = useCallback(() => {
        dispatch(editAction(id));
    }, [dispatch, editAction, id]);

    return (
        <IconButton
            onClick={openModal}
            title={title}
            color="primary"
            data-testid="details-button"
            disabled={disabled}
        >
            <Icon fontSize={fontSize} />
        </IconButton>
    );
};
