import { Edit } from '@material-ui/icons';
import { IInfoItemData } from 'modules/subassignmentManagement/components/AssignmentInfo/EditableInfoBlock/model';
import React from 'react';
import { Box, IconButton } from '@material-ui/core';

interface IAssignmentInfoBlockProps extends IInfoItemData {
    onEdit?: null | (() => void);
}

export const AssignmentInfoBlock = ({ label, value, onEdit }: IAssignmentInfoBlockProps) => {
    return (
        <Box display="flex">
            <Box pr={1}>
                <Box color="primary.main" fontWeight="bold">{label}:</Box>
                {value}
            </Box>
            {onEdit && (
                <Box display="flex" alignItems="center">
                    <IconButton
                        onClick={onEdit}
                        color="primary"
                        data-testid="details-button"
                    >
                        <Edit fontSize="small" />
                    </IconButton>
                </Box>
            )}
        </Box>
    );
};
