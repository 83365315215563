import React from 'react';
import PlainText from 'shared/components/table/Cells/PlainText';
import { ICellProps } from 'shared/components/table/GridTable/GridTableModel';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { IGroupedSheetCalculationCommon } from 'modules/payrollProcessorHub/store/model';

type FilesCellProps = ICellProps<IGroupedSheetCalculationCommon>;

export const FilesCell = ({ className, time_files, time_files_dollars }: FilesCellProps) => {
    let value = '';
    if (time_files) {
        value = `${time_files}`;
        if (time_files_dollars) {
            value += ` | ${formatDollars(time_files_dollars)}`;
        }
    }
    return (
        <PlainText className={className} value={value} />
    );
};
