import { Box, CircularProgress } from '@material-ui/core';
import { Formik } from 'formik';
import { PaySettingsForm } from 'modules/settings/submodules/clients/paySettings/PaySettingsForm';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { selectClientPaySettings, selectIsClientPaySettingsLoading } from 'store/entities/clients/selectors/timeAndPaySelectors';
import { upsertClientPaySettings } from './store/actions';
import { IClientPaySettingsData, IUpsertClientPaySettingsRequest } from './store/models';
import { selectIsClientPaySettingsUpdating } from './store/selectors';
import { paySettingsValidationSchema } from './validationSchema';

export const PaySettingsFormWrapper = () => {
    const clientId = useSelector(selectCurrentClientId);
    const clientPaySettings = useSelector(selectClientPaySettings);
    const isLoading = useSelector(selectIsClientPaySettingsLoading);
    const isUpdating = useSelector(selectIsClientPaySettingsUpdating);

    const initialValues = {
        pay_period_type: clientPaySettings?.pay_period_type ?? '',
        pay_period_start_day: clientPaySettings?.pay_period_start_day ?? '',
        pay_period_end_day: clientPaySettings?.pay_period_end_day ?? '',
        pay_period_start_date: clientPaySettings?.pay_period_start_date ?? '',
        pay_day: clientPaySettings?.pay_day ?? '',
    };
    const dispatch = useDispatch();
    const onSubmit = useCallback( (formValues: IClientPaySettingsData) => {        
        if (clientId) {
            const dataToSubmit = formValues as IUpsertClientPaySettingsRequest;
            dataToSubmit.week_end_on_day = dataToSubmit.pay_period_end_day;
            dispatch(upsertClientPaySettings.init({
                clientId,
                data: dataToSubmit,
            }));
        }
    }, [dispatch, clientId]);

    if (isLoading) {
        return (
            <Box ml={3}>
                <CircularProgress />
            </Box>
        );
    }

    return (

        <Formik
            key="ClientPaySettingsForm"
            initialValues={initialValues}
            onSubmit={onSubmit}
            validateOnBlur={false}
            validationSchema={paySettingsValidationSchema}
        >
            {props => (
                <PaySettingsForm   
                    isUpdating={isUpdating}                 
                    {...props}
                />
            )}
        </Formik>
    );
};
