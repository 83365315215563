import { IconButton, ListItem, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { IInfoItemData } from 'modules/subassignmentManagement/components/AssignmentInfo/EditableInfoBlock/model';
import { useEditableInfoStyles } from 'modules/subassignmentManagement/components/AssignmentInfo/EditableInfoBlock/styles';
import React from 'react';

export interface ISheetInfoItemProps {
    data: IInfoItemData;
    onEdit?: null | (() => void);
}

export const EditableInfoBlock = ({ data, onEdit }: ISheetInfoItemProps) => {
    const classes = useEditableInfoStyles();

    return (
        <ListItem className={classes.infoItem}>
            <Typography variant="subtitle2" className={classes.title}>
                {data.label}:
            </Typography>
            <Typography variant="body2">
                {data.value}
            </Typography>
            {onEdit && (
                <IconButton
                    onClick={onEdit}
                    color="primary"
                    data-testid="details-button"
                    className={classes.button}
                >
                    <Edit fontSize="small" />
                </IconButton>
            )}
        </ListItem>
    );
};
