import React from 'react';
import { useGlobalAssignmentSetupSubModules } from 'modules/settings/submodules/clients/globalAssignmentSetup/useGlobalAssignmentSetupSubModules';
import { SubmodulesDashboard } from 'modules/settings/common/components/SubmodulesDashboard';

export const GlobalAssignmentSetupDashboard = () => {
    const items = useGlobalAssignmentSetupSubModules();
    return (
        <SubmodulesDashboard items={items} />
    );
};
