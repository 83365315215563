import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { useDispatch, useSelector } from 'react-redux';
import PlainText from 'shared/components/table/Cells/PlainText';
import {
    selectActivitiesById,
    selectDealsById,
    selectDealTypesById, selectJobNumbersById,
} from 'store/entities/configuration/configurationSelectors';
import moment from 'moment';
import { shortDateFormat } from 'shared/models/Dates';
import { SettingsInfinityTablePure } from 'modules/settings/common/components/SettingsTablePure/SettingsInfinityTablePure';
import { ITravelExpenseRow } from 'modules/travelExpenses/model';
import { selectUsersById } from 'store/entities/users/selectors';
import { getUserName } from 'shared/utils/converters/user';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { Box, IconButton } from '@material-ui/core';
import { setEditTravelExpenseId } from 'modules/travelExpenses/components/Edit/store/actions';
import {
    getMoreTravelExpensesAction,
} from 'modules/travelExpenses/components/TravelExpensesTable/actions';
import {
    selectTravelExpenseRows,
    travelExpenseInfinityTableSelectors,
} from 'modules/travelExpenses/components/TravelExpensesTable/selectors';
import { EditTravelExpenseSidebar } from 'modules/travelExpenses/components/Edit/EditTravelExpenseSidebar';
import ConfirmationDialog from 'shared/components/modals/ConfirmationDialog';

import { Create } from '@material-ui/icons';
import { DeleteItemButton } from 'modules/settings/common/components/DeleteItemButton/DeleteItemButton';
import { selectTravelExpenseSheetsById } from 'modules/travelExpenses/selectors';
import { deleteTravelExpense } from 'modules/travelExpenses/actions';

export const TravelExpensesTable = () => {
    const tableClasses = useDefaultTableStyles();
    const usersById = useSelector(selectUsersById);
    const jobNumbersById = useSelector(selectJobNumbersById);
    const dealsById = useSelector(selectDealsById);
    const dealTypesById = useSelector(selectDealTypesById);
    const activitiesById = useSelector(selectActivitiesById);
    const travelExpenseSheetsById = useSelector(selectTravelExpenseSheetsById);
    const dispatch = useDispatch();
    const dispatchEdit = useCallback((travelExpenseId: string) => {
        dispatch(setEditTravelExpenseId(travelExpenseId));
    }, [dispatch]);
    const [editIdToConfirm, setEditIdToConfirm] = useState<string|null>(null);
    const onEditCancel = useCallback(() => {
        setEditIdToConfirm(null);
    }, [setEditIdToConfirm]);
    const onEditConfirm = useCallback(() => {
        if (editIdToConfirm) {
            dispatchEdit(editIdToConfirm);
            setEditIdToConfirm(null);
        }
    }, [editIdToConfirm, dispatchEdit]);
    const cells = [
        {
            key: 'employee',
            title: 'employee',
            render: function EmployeeCell({ className, sheet_id }: ITravelExpenseRow) {
                const sheet = travelExpenseSheetsById[sheet_id];
                const employee = usersById[sheet?.user_id || ''];
                return (
                    <PlainText className={ className } value={ getUserName(employee) } />
                );
            },
        },
        {
            key: 'job_number',
            title: 'job #',
            render: function JobNumberCell({ job_number_id, className }: ITravelExpenseRow) {
                const jobNumber = jobNumbersById[job_number_id || ''];
                return (
                    <PlainText className={ className } value={ jobNumber?.job_number } />
                );
            },
        },
        {
            key: 'deal_type',
            title: 'deal type',
            render: function DealTypeCell({ className, job_number_id }: ITravelExpenseRow) {
                const jobNumber = jobNumbersById[job_number_id || ''];
                const deal = dealsById[jobNumber?.deal_id || ''];
                const dealType = dealTypesById[deal?.type_id || ''];
                return (
                    <PlainText className={ className } value={ dealType?.name } />
                );
            },
        },
        {
            key: 'expense_type',
            title: 'expense type',
            render: function ExpenseTypeCell({ className, activity_id }: ITravelExpenseRow) {
                const activity = activitiesById[activity_id || ''];
                return (
                    <PlainText className={ className } value={ activity?.description } />
                );
            },
        },
        {
            key: 'travel_date',
            title: 'travel date',
            render: function TravelDateCell({ className, entry_date }: ITravelExpenseRow) {
                return (
                    <PlainText className={ className } value={ moment(entry_date).format(shortDateFormat) } />
                );
            },
        },
        {
            key: 'amount',
            title: 'amount',
            width: '100px',
            render: function AmountCell({ className, data }: ITravelExpenseRow) {
                return (
                    <PlainText className={ className } value={ formatDollars(data.dollars) } />
                );
            },
        },
        {
            key: 'actions',
            title: '',
            width: '100px',
            render: function ActionsCell({ id, processed_in_ecub_at, className }: ITravelExpenseRow) {
                const shouldShowWarning = Boolean(processed_in_ecub_at);
                const onEditClickHandler = !shouldShowWarning
                    ? () => {
                        dispatchEdit(id);
                    }
                    : () => {
                        setEditIdToConfirm(id);
                    };

                const deleteConfirmationText = shouldShowWarning
                    ? 'This Expense had been reported on the ECub. Are you sure you want to delete this Expense?'
                    : 'Are you sure you want to delete this Expense?';
                return (
                    <Box className={clsx(className, tableClasses.iconCell)}>
                        <DeleteItemButton
                            confirmation={ deleteConfirmationText }
                            id={id}
                            // @ts-ignore
                            color="secondary"
                            deleteAction={deleteTravelExpense.init}
                        />
                        <IconButton
                            onClick={onEditClickHandler}
                            color="primary"
                            data-testid="details-button"
                        >
                            <Create />
                        </IconButton>
                    </Box>
                );
            },
        },
    ];

    return (
        <>
            <SettingsInfinityTablePure
                infinityTableSelector={travelExpenseInfinityTableSelectors}
                rowsSelector={selectTravelExpenseRows}
                initialLoadAction={getMoreTravelExpensesAction.init}
                getMoreAction={getMoreTravelExpensesAction.init}
                cells={cells}
            />
            <EditTravelExpenseSidebar />
            <ConfirmationDialog
                open={Boolean(editIdToConfirm)}
                onConfirm={onEditConfirm}
                onCancel={onEditCancel}
            >
                This Expense had been reported on the ECub. Are you sure you want to edit this Expense?
            </ConfirmationDialog>
        </>
    );
};
