import React from 'react';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import { FeatureSwitches } from 'utils/featureSwitches';
import { Box } from '@material-ui/core';
import {
    getDealTypeArBuckets,
    getDealTypePayCodeArBuckets,
    getDealTypes,
} from 'store/entities/configuration/configurationAction';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';
import { PayCodeArBucketTable } from './components/PayCodeArBucketTable';
import { CreateDealTypePayCodeArBucketAssociation } from './components/CreateDealTypePayCodeArBucketAssociation';

export function PayCodeArBucketPage() {
    useWithClientEffect(dispatch => {
        dispatch(getDealTypePayCodeArBuckets.init());
        dispatch(getDealTypes.init());
        dispatch(getDealTypeArBuckets.init());
    });

    return (
        <FeatureSwitch feature={FeatureSwitches.enableArBucketSettingsModule}>
            <Box>
                <CreateDealTypePayCodeArBucketAssociation />
            </Box>
            <PayCodeArBucketTable />
        </FeatureSwitch>
    );
}
