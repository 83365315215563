import React, { useCallback, useMemo } from 'react';
import { uniqBy } from 'lodash-es';
import { Box, Button, Typography } from '@material-ui/core';
import { ISheetForPayroll, ISheetGroupIdWithClient } from 'modules/payrollProcessorHub/store/model';
import { usePayrollModalStyle } from './PayrollModalStyle';
import PrePayrollReport from './reports/PrePayrollReport';
import { RunPayrollButton } from './RunPayrollButton';
import { FieldArray, Formik, FormikProps } from 'formik';
import { initSheetGroupPayroll } from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsPayRollProcessing } from '../../store/selectors';
import DayPickerField from 'shared/components/formFields/DayPickerField';
import { shortDateFormat } from 'shared/models/Dates';
import * as yup from 'yup';
import { ValidationMessages } from 'shared/models/Validation';
import { selectAllClientsById } from 'store/entities/clients/selectors/clientsSelectors';
import { IClient } from 'store/entities/clients/clientsModel';
import CheckboxField from 'shared/components/formFields/CheckboxField';

interface IPrePayrollModalProps {
    close: () => void;
    groupIds: ISheetGroupIdWithClient[];
}

export type PayDates = Record<string, string>;

interface IPayrollInitializeForm {
    payDates: PayDates;
    separateTimeExpense: boolean;
}

/**
 * When you try to use Client.id (UID) as a key of fiels (ex. payDate.12345_123_123)
 * Formik generate wrong validation scheme and make array which consists of lots of undefined values
 * because it tries to interpretat uid as number
 * @param id
 */
const getPayrollDateFieldValueKey = (id: string) => `_${id}`;

export const payrollInitValidationSchema = (items: IClient[]) => {
    const shape = items.reduce(
        (obj, item) => ({
            ...obj,
            [getPayrollDateFieldValueKey(item.id)]: yup.string().nullable().required(ValidationMessages.REQUIRED),
        }),
        {},
    );
    return yup.object().shape({
        payDates: yup.object().shape(shape).required(ValidationMessages.REQUIRED),
        separateTimeExpense: yup.boolean(),
    });
};

export const fillPayDateInSheets = (
    groupIds: ISheetGroupIdWithClient[],
    payDates: PayDates,
): ISheetForPayroll[] => {
    return groupIds.map(groupId => ({
        ...groupId,
        payDate: payDates[getPayrollDateFieldValueKey(groupId.clientId)],
    }));
};

const PrePayrollModal = ({ close, groupIds }: IPrePayrollModalProps) => {
    const classes = usePayrollModalStyle();

    const dispatch = useDispatch();
    const isLoadingPayRoll = useSelector(selectIsPayRollProcessing);
    const allClient = useSelector(selectAllClientsById);
    const uniqClients: IClient[] = useMemo(() => {
        return uniqBy(groupIds, 'clientId')
            .map((groupId: ISheetGroupIdWithClient) => groupId.clientId)
            .map((clientId: string) => allClient[clientId]);
    }, [groupIds, allClient]);

    const schema = useMemo(
        () => payrollInitValidationSchema(uniqClients),
        [uniqClients],
    );

    const onInitPayroll = useCallback(({ payDates, separateTimeExpense }: IPayrollInitializeForm) => {
        dispatch(initSheetGroupPayroll.init({ sheets: fillPayDateInSheets(groupIds, payDates), separateTimeExpense }));
    }, [dispatch, groupIds]);

    const initialValues = {
        payDates: uniqClients.reduce((touched, client) => ({
            ...touched,
            [getPayrollDateFieldValueKey(client.id)]: null,
        }), {}),
        separateTimeExpense: false,
    };

    const isAllDatesFilled = (payDates: Record<string, string | null>) => !Object.values(payDates).includes(null);

    return (
        <Box className={classes.ContentWrapper}>
            <Box className={classes.DateLabel}>Set pay date for payroll batch.</Box>
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={onInitPayroll}
                validateOnBlur={false}
                validateOnChange={true}
            >
                {(props: FormikProps<typeof initialValues>) => (
                    <>
                        <Box className={classes.DateWrapper}>
                            <form onSubmit={props.handleSubmit}>
                                <FieldArray name="payDates">
                                    {() => (
                                        <>
                                            {uniqClients.map(client => (
                                                <Box key={client.id} className={classes.DatePickerRow}>
                                                    {uniqClients.length > 1 && (<Typography variant="h6">{client.name}</Typography>)}
                                                    <DayPickerField
                                                        key={client.id}
                                                        name={`payDates.${getPayrollDateFieldValueKey(client.id)}`}
                                                        format={shortDateFormat}
                                                        placeholder={shortDateFormat}
                                                        variant="inline"
                                                        disablePast
                                                        disabled={isLoadingPayRoll}
                                                    />
                                                </Box>
                                            ))}
                                        </>
                                    )}
                                </FieldArray>
                                <CheckboxField name="separateTimeExpense">
                                    Separate time and expense batches
                                </CheckboxField>
                            </form>
                        </Box>
                        {props.dirty && props.isValid && isAllDatesFilled(props.values.payDates) && (
                            <PrePayrollReport
                                groupIds={groupIds}
                                payDatesValue={props.values.payDates}
                                separateTimeExpense={props.values.separateTimeExpense}
                            />
                        )}
                        <Box className={classes.ButtonWrapper}>
                            <RunPayrollButton isLoading={isLoadingPayRoll} onClick={props.submitForm} />
                            <Button
                                variant="text"
                                color="secondary"
                                className={classes.SecondaryButton}
                                onClick={close}
                                disabled={isLoadingPayRoll}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </>
                )}
            </Formik>
        </Box>
    );
};

export default PrePayrollModal;
