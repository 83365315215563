import { Box, Typography } from '@material-ui/core';
import { useFeature } from '@optimizely/react-sdk';
import clsx from 'clsx';
import { getOfferLetters } from 'modules/offerLetter/store/actions';
import {
    useSettingsFormGridStyles,
} from 'modules/settings/submodules/components/sharedStyles/formStyles';
import { EditEmployeeForm } from 'modules/settings/submodules/employees/components/EditEmployee/EditEmployeeForm';
import { getUpdateEmployeePayload } from 'modules/settings/submodules/employees/components/EditEmployee/helpers';
import { ResetPasswordButton } from 'modules/settings/submodules/employees/components/EditEmployee/ResetPasswordButton';
import {
    getEmployeeClients,
    setEditEmployeeId,
    updateEmployee,
} from 'modules/settings/submodules/employees/store/actions';
import { useEmployeeFormInitialValues } from 'modules/settings/submodules/employees/store/hooks';
import { selectEditEmployeeId, selectIsEmployeeUpdating } from 'modules/settings/submodules/employees/store/selectors';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useButtonStyles } from 'shared/components/buttons/buttonStyles';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';
import { selectUserById } from 'store/entities/users/selectors';
import { FeatureSwitches } from 'utils/featureSwitches';
import { CompleteAvionteOnboardingButton } from './CompleteAvionteOnboardingButton';
import { MoreInformationSection } from './MoreInformationSection';
import { ResetOfferButton } from './ResetOfferButton';

export const EditEmployeeModal = () => {
    const dispatch = useDispatch();
    const classes = useColoredFormModalStyles();
    const formClasses = useSettingsFormGridStyles();
    const buttonClasses = useButtonStyles();

    const editEmployeeId = useSelector(selectEditEmployeeId);
    const isLoading = useSelector(selectIsEmployeeUpdating);
    const onClose = useCallback(() => {
        dispatch(setEditEmployeeId(null));
    }, [dispatch]);
    const initialValues = useEmployeeFormInitialValues(editEmployeeId);
    const handleSubmitEmployee = useCallback(formValues => {
        const updatePayload = getUpdateEmployeePayload(
            editEmployeeId,
            formValues,
            initialValues,
        );
        dispatch(updateEmployee.init(updatePayload));
    }, [dispatch, editEmployeeId, initialValues]);

    const user = useSelector(selectUserById(editEmployeeId));
    useEffect(() => {
        if (user?.email) {
            dispatch(getOfferLetters.init({
                // @ts-ignore
                email: user?.email,
            }));
        }
        if (user?.id) {
            dispatch(getEmployeeClients.init(user?.id));
            // dispatch(getUserAuthStats.init(user?.id));
        }
    }, [dispatch, user]);

    const [enableAcceptOfferLetterInSettings] = useFeature(FeatureSwitches.enableAcceptOfferLetterInSettings);
    const [enableResetOfferLetterInSettings] = useFeature(FeatureSwitches.enableResetOfferLetterInSettings);

    const additionalButton = (
        <>
            {/* temp commenting out until we figure out the business case with Avionte */}
            {/* <DeleteEmployeeButton
                className={formClasses.button}
                employeeId={editEmployeeId}
            /> */}
            <ResetPasswordButton
                className={clsx(formClasses.button, buttonClasses.link)}
                employeeId={editEmployeeId}
            />
        </>
    );

    return (
        <ModalDialog
            title="Edit Employee"
            isOpened={Boolean(editEmployeeId)}
            onClose={onClose}
            modalProps={{
                customClasses: classes,
                showCloseIcon: true,
            }}
        >
            <Box
                display="flex"
                flexDirection="row"
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    width="70%"
                    mr={2}
                >
                    <EditEmployeeForm
                        isLoading={isLoading}
                        onSubmit={handleSubmitEmployee}
                        initialValues={initialValues}
                        initialEmail={initialValues.email}
                        additionalButton={additionalButton}
                    >
                        <>
                            {(enableAcceptOfferLetterInSettings || enableResetOfferLetterInSettings) && (
                                <Box className={formClasses.formBlock}>
                                    <Typography variant="subtitle2" color="secondary">Offer Letter Status</Typography>
                                    {enableAcceptOfferLetterInSettings && (
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            m={2}
                                        >
                                            <Box marginRight={1}>
                                                <Typography variant="subtitle2">Set Avionte Onboarding Completed</Typography>
                                                <Typography variant="body2">
                                                    This will set all of their Pending-I9 offer letters to Accepted
                                                    and give them access to their timesheet.
                                                </Typography>
                                            </Box>
                                            <CompleteAvionteOnboardingButton userId={editEmployeeId} />
                                        </Box>
                                    )}
                                    {enableResetOfferLetterInSettings && (
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            m={2}
                                        >
                                            <Box>
                                                <Typography variant="subtitle2">Reset Pending I-9 Status to Outstanding in Headway Connect</Typography>
                                                <Typography variant="body2">
                                                    This will retract their Pending I-9 status and return
                                                    it back to outstanding in Headway Connect.
                                                </Typography>
                                            </Box>
                                            <ResetOfferButton userId={editEmployeeId} />
                                        </Box>
                                    )}
                                </Box>
                            )}
                        </>
                    </EditEmployeeForm>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    width="30%"
                    justifyContent="space-between"
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                    >
                        <MoreInformationSection />
                    </Box>
                </Box>
            </Box>
        </ModalDialog>
    );
};
