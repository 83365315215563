import React from 'react';
import { Box } from '@material-ui/core';
import DayPickerField, { IDayPickerFieldProps } from 'shared/components/formFields/DayPickerField';
import { useFormHelperTextStyles } from 'shared/styles/formHelperText';

export interface IDayPickerWithOuterLabelProps extends IDayPickerFieldProps {}

export const DayPickerWithOuterLabel = ({
    id,
    outerLabel,
    className,
    ...props
}: IDayPickerWithOuterLabelProps) => {
    const formHelperTextClasses = useFormHelperTextStyles();
    return (
        <Box
            display="flex"
            flexDirection="column"
            className={className}
        >
            {outerLabel && (
                <label
                    htmlFor={id}
                    className={formHelperTextClasses.outerLabel}
                >
                    {outerLabel}
                </label>
            )}
            <DayPickerField id={id} {...props} />
        </Box>
    );
};
