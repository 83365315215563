import React from 'react';
import { UserCell } from 'modules/eafApproval/components/cells/common/UserCell';
import { EafApprovalCellProps } from 'modules/eafApproval/store/model/types';

export const EafApprovalGridCellManager: React.FC<EafApprovalCellProps> = ({
    created_by: managerId,
    className,
}: EafApprovalCellProps) => {
    return <UserCell user_id={managerId} className={className} />;
};
