import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Box, Hidden } from '@material-ui/core';
import AddNewEntry from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/AddEntryControls/AddNewEntry';
import AddNewEntryDialog
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/AddEntryControlsMobile/AddNewEntryDialog';
import { TotalInfo } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/components/TotalInfo/TotalInfo';
import EntriesGrid from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/EntriesGrid/EntriesGrid';
import FilterAndActionControls
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/FilterAndActionControls';
import Footer from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/Footer';
import useSheetsInProgressStyles
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/SheetsInProgressStyles';
import { getHasEmptyEntries } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/utils/emptyEntries';
import { useEntriesByDayArray } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/utils/entriesByDay';
import { SelectorsPanel } from 'modules/timeAndExpense/components/AddEntry/components/SelectorsPanel/SelectorsPanel';
import { BackButton } from 'modules/timeAndExpense/components/EditSheet/BackButton';
import { useSheetCalculationUpdate } from 'modules/timeAndExpense/components/utils/updateSheetCalculationUtils';
import { SyncMessage } from 'shared/components/toolbar/SyncMessage';
import { isDateInPayPeriod } from 'shared/models/Dates';
import { EntryType, IEntry } from 'shared/models/sheet/Sheet';
import { useTotalTimeEntriesByDay } from 'shared/models/validationSchemes/utils/totalTimeEntriesByDay';
import { TotalMinutesByDay } from 'shared/utils/context/totalMinutesByDay';
import { useIsMobile } from 'shared/utils/hooks/media';
import { SyncingModels } from 'store/entities/appConfig/syncing/models';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { selectAllEntries, selectExpensesSheetsByIds, selectTimeSheetsByIds } from 'store/entities/timesheet/selectors';
import { PayPeriodReadonly } from './PayPeriodReadonly';

export interface IEditSheetContentProps {
    onClose: () => void;
    onSheetSubmit?: () => void;
    timeSheetIds?: string[] | null;
    expenseSheetIds?: string[] | null;
    isLoading?: boolean;
    isPayroll?: boolean;
    disableApprovedEntries?: boolean;
    showAllEntriesForPeriod?: IPayPeriod;
    statusName?: StatusNames;
    isSubmitInProgress?: boolean;
}

const useEditableEntries = (
    assignmentId: string | undefined,
    payPeriod: IPayPeriod,
    entryTypeFilter: EntryType | undefined,
    timeSheetIds?: string[] | null,
    expenseSheetIds?: string[] | null,
): IEntry[] => {
    const entries = useSelector(selectAllEntries);
    return useMemo(() => {
        return entries.filter(
            entry => {
                return entry.assignment_id === assignmentId
                && isDateInPayPeriod(payPeriod, entry.entry_date)
                && (!entryTypeFilter || entry.entry_type === entryTypeFilter)
                && (
                    (
                        entry.entry_type === EntryType.TIME
                        && (!timeSheetIds || timeSheetIds.includes(entry.sheet_id))
                    ) || (
                        entry.entry_type === EntryType.EXPENSE
                        && (!expenseSheetIds || expenseSheetIds.includes(entry.sheet_id))
                    )
                );
            },
        );
    }, [entries, assignmentId, payPeriod, entryTypeFilter, timeSheetIds, expenseSheetIds]);
};

export const EditSheetView = ({
    onClose,
    timeSheetIds,
    expenseSheetIds,
    onSheetSubmit,
    showAllEntriesForPeriod,
    statusName,
    isLoading = false,
    isPayroll = false,
    disableApprovedEntries = false,
    isSubmitInProgress = false,
}: IEditSheetContentProps) => {
    const classes = useSheetsInProgressStyles();
    const timeSheetByIds = useSelector(selectTimeSheetsByIds);
    const expenseSheetByIds = useSelector(selectExpensesSheetsByIds);
    const sheets = useMemo(() => {
        return [
            ...(timeSheetIds || []).map(id => timeSheetByIds[id]),
            ...(expenseSheetIds || []).map(id => expenseSheetByIds[id]),
        ];
    }, [timeSheetIds, timeSheetByIds, expenseSheetIds, expenseSheetByIds]);
    const payPeriod = useMemo(() => {
        return showAllEntriesForPeriod || {
            period_end: sheets[0]?.period_end,
            period_start: sheets[0]?.period_start,
        };
    }, [sheets, showAllEntriesForPeriod]);

    const userId = sheets[0]?.user_id;
    const userIdRef = useRef<string>(userId || '');
    useEffect(() => {
        if (userId) {
            userIdRef.current = userId;
        }
    }, [userId]);

    const entryTypeFilter = timeSheetIds && expenseSheetIds ? undefined
        : timeSheetIds ? EntryType.TIME : EntryType.EXPENSE;
    const entries = useEditableEntries(
        sheets[0]?.assignment_id,
        payPeriod,
        entryTypeFilter,
        showAllEntriesForPeriod ? null : timeSheetIds,
        showAllEntriesForPeriod ? null : expenseSheetIds,
    );

    const entriesByDay = useEntriesByDayArray(entries);
    const totalEntryMinutesByDay = useTotalTimeEntriesByDay(entries);

    const entryTypes = [];
    // @ts-ignore
    timeSheetIds && entryTypes.push(EntryType.TIME);
    // @ts-ignore
    expenseSheetIds && entryTypes.push(EntryType.EXPENSE);
    const hasEntries = entries.length > 0;
    const hasEmptyEntries = useMemo(() => getHasEmptyEntries(entries), [entries]);
    const hasEntriesForSubmit = hasEntries && !hasEmptyEntries;
    useSheetCalculationUpdate(payPeriod);

    const isEntryDisabled = useCallback((entry: IEntry) => {
        if (!disableApprovedEntries) {
            return false;
        }
        const sheet = entry.entry_type === EntryType.TIME
            ? timeSheetByIds[entry.sheet_id] : expenseSheetByIds[entry.sheet_id];
        return [StatusNames.APPROVED, StatusNames.APPROVED_CLOSED, StatusNames.APPROVED_CLOSED]
            .includes(sheet?.status?.name);
    }, [timeSheetByIds, expenseSheetByIds, disableApprovedEntries]);
    const isMobile = useIsMobile();

    return (
        <TotalMinutesByDay.Provider value={totalEntryMinutesByDay}>
            <Box className={classes.mainContainer}>
                <>
                    <Box className={classes.topContainer}>
                        {isMobile ? (
                            <Box
                                display="flex"
                                alignItems="center"
                                ml={2}
                            >
                                <BackButton onClick={onClose} />
                                <PayPeriodReadonly payPeriod={payPeriod} />
                            </Box>
                        ) : (
                            <>
                                <Box display="flex">
                                    <Box className={classes.backButtonWrapper}>
                                        <BackButton onClick={onClose} />
                                    </Box>
                                    <SelectorsPanel />
                                </Box>
                                <FilterAndActionControls
                                    hasEntries={false}
                                    entryTypeAllowed={[]}
                                    payPeriod={payPeriod}
                                />
                            </>
                        )}
                    </Box>
                    <Hidden xsDown>
                        <AddNewEntry
                            customClasses={{ paper: classes.addControlsPaper }}
                            userId={userIdRef.current}
                            payPeriod={payPeriod}
                            entryTypeFilter={entryTypeFilter}
                            statusName={statusName}
                            hasEntries={hasEntries}
                        />
                    </Hidden>
                    <Hidden smUp>
                        <AddNewEntryDialog
                            entries={entries}
                            userId={userIdRef.current}
                            payPeriod={payPeriod}
                            statusName={statusName}
                        />
                    </Hidden>
                </>
                <Box className={classes.aboveTable}>
                    <SyncMessage syncingKey={SyncingModels.EditableEmployeeSheet} />
                </Box>
                <EntriesGrid
                    hasEntries={hasEntries}
                    noFilteredEntries={!hasEntries}
                    filteredEntriesByDay={entriesByDay}
                    entryFilter={entryTypeFilter}
                    isLoaded={!isLoading}
                    userId={userIdRef.current}
                    payPeriod={payPeriod}
                    isEntryDisabled={isEntryDisabled}
                    statusName={statusName}
                />
                <Hidden xsDown>
                    <Box>
                        <TotalInfo
                            entries={entries}
                            entryFilter={entryTypeFilter}
                        />
                    </Box>
                </Hidden>
                <Footer
                    isLoading={isSubmitInProgress}
                    isPayroll={isPayroll}
                    entryTypes={entryTypes}
                    hasEntries={hasEntriesForSubmit}
                    submitAction={onSheetSubmit}
                    status={statusName}
                />
            </Box>
        </TotalMinutesByDay.Provider>
    );
};
