import React from 'react';
import { IPdfViewProps, PdfView } from 'shared/components/PdfViewer/PdfView';
import { Box, CircularProgress } from '@material-ui/core';

interface IPdfViewWithStatusCheckPdf extends IPdfViewProps {
    errorRender: React.ReactElement;
    isPdfChecking: boolean;
    isPdfAvailable: boolean;
}

export const PdfViewWithStatusCheck = ({
    isPdfChecking,
    isPdfAvailable,
    errorRender,
    ...props
}: IPdfViewWithStatusCheckPdf) => {
    if (isPdfChecking) {
        return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
                flexGrow={1}
            >
                <CircularProgress />
            </Box>
        );
    }

    if (isPdfAvailable) {
        return (
            <PdfView {...props} />
        );
    }

    return (
        <>{errorRender}</>
    );
};
