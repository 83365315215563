import React from 'react';
import { ISubassignmentRow } from 'modules/subassignmentManagement/components/SubassignmentTable/model';
import { useSelector } from 'react-redux';
import { useFormattedPayRate } from 'shared/utils/formatters/payRate';
import { selectAssignmentsById } from 'store/entities/configuration/configurationSelectors';
import PlainText from 'shared/components/table/Cells/PlainText';

export const SubassignmentPayRateCell = ({ className, override_rate_value, assignment_id }: ISubassignmentRow) => {
    const assignment = useSelector(selectAssignmentsById)[assignment_id];
    const payRate = useFormattedPayRate(assignment ? {
        pay_rate_type: assignment.pay_rate_type,
        pay_rate_value: override_rate_value,
    } : undefined);
    return (
        <PlainText className={className} value={payRate} />
    );
};
