import React from 'react';
import clsx from 'clsx';
import { FormikProps, FieldArray, Form } from 'formik';
import { Box, Typography, Button } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import FormField from 'shared/components/formFields/FormField';
import { FormFieldWithSeparateLabel } from 'modules/settings/common/components/FormFieldWithSeparateLabel';
import { FormSwitchField } from './FormSwitchField';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import { v4 as uuidv4 } from 'uuid';

export interface IClientLinksConfigurationFormValues {
    externalLinks: IClientConfigurationLink[]
}

export interface IClientConfigurationLink {
    id: string;
    link: string;
    name: string;
    enabled: boolean;
}

export const getLinksFormItem = ({ link = '', name = '', enabled = true }) => {
    return {
        id: uuidv4(),
        link: link,
        name: name,
        enabled: enabled,
    };
};

export const LinksConfigurationForm = ({
    isLoading, ...formikProps
}: FormikProps<IClientLinksConfigurationFormValues> & { isLoading: boolean }) => {
    const classes = useSettingsFormStyles();
    return (
        <Form>
            <Typography>
                Add a URL to add to the main menu.
            </Typography>
            <FieldArray
                name="externalLinks"
                render={ arrayHelpers => (
                    <Box className={clsx(classes.formBlock)} ml={-2}>
                        { formikProps.values.externalLinks.map((item, index) => (
                            <Box display="flex" key={item.id}>
                                <Box width="640px">
                                    <FormField
                                        placeholder="Link"
                                        name={`externalLinks.${index}.link`}
                                        className={classes.input}
                                    />
                                </Box>
                                <Box width="320px">
                                    <FormField
                                        placeholder="Text to display"
                                        name={`externalLinks.${index}.name`}
                                        className={classes.input}
                                    />
                                </Box>
                                <Box m={2}>
                                    <FormFieldWithSeparateLabel
                                        name={`externalLinks.${index}.enabled`}
                                        outerLabel="Enabled"
                                        FieldComponent={FormSwitchField}
                                    />
                                </Box>
                                <Box m={2}>
                                    <Button
                                        disabled={isLoading}
                                        onClick={() => arrayHelpers.remove(index)}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        <Delete fontSize="small" />
                                    </Button>
                                </Box>
                            </Box>
                        ))}
                        <Box display="flex">
                            <Box m={2}>
                                <ButtonWithLoader
                                    color="primary"
                                    variant="contained"
                                    isLoading={isLoading}
                                    disabled={isLoading}
                                    onClick={() => arrayHelpers.push(getLinksFormItem({}))}
                                >
                                    ADD +
                                </ButtonWithLoader>
                            </Box>
                            <Box m={2}>
                                <ButtonWithLoader
                                    isLoading={isLoading}
                                    disabled={isLoading}
                                    variant="contained"
                                    color="secondary"
                                    type="submit"
                                >
                                    Save
                                </ButtonWithLoader>
                            </Box>
                        </Box>
                    </Box>
                )}
            />
        </Form>
    );
};
