import React, { useMemo } from 'react';
import { useField } from 'formik';
import { useSelector } from 'react-redux';
import { useAutoselectSingleVariant, useFilteredCustomFieldValues, useFieldResetIfSelectedValueFiltered } from 'shared/components/selects/CustomFieldValuesSelect/hooks';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { IFormSelect } from 'shared/components/selects/model';
import { getFieldValueId, getFieldValueName } from 'store/entities/customFields/helpers';
import { HierarchyType, ICustomFieldHierarchyNode, ICustomFieldValue } from 'store/entities/customFields/model';
import {
    selectCustomFieldById,
    selectCustomFieldValuesByFieldId,
    selectIsCustomFieldValuesLoading,
} from 'store/entities/customFields/selectors';
import { ScopeAction } from 'store/entities/scopes/models';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { selectScopesIsLoading } from '../../../../store/entities/scopes/selectors';
import FormAutocomplete from '../../autocomplete/FormAutocomplete';

export interface ICustomFieldValuesSelectProps extends IFormFieldProps, IFormSelect {
    customFieldId: string;
    hierarchy?: ICustomFieldHierarchyNode[];
    valuesToShow?: ICustomFieldValue[];
    customFieldFormValues?: Record<string, string>;
    userId?: string;
    date?: string;
    useLabel?: boolean;
    useSubassignments?: boolean;
    payPeriod?: IPayPeriod;
    autoSelectSingleVariant?: boolean;
    hierarchyType?: HierarchyType;
    hidden?: boolean;
    scopeActionFilter?: ScopeAction;
    onlyActionable?: boolean;
    filterInactive?: boolean;
    useAutocomplete?: boolean;
}

export const CustomFieldValuesSelect = ({
    customFieldId,
    hierarchy,
    customFieldFormValues = {},
    userId,
    date,
    valuesToShow,
    payPeriod,
    useLabel = false,
    useSubassignments = false,
    autoSelectSingleVariant = false,
    hierarchyType = HierarchyType.Assignment,
    hidden = false,
    filterInactive = true,
    scopeActionFilter,
    onlyActionable,
    useAutocomplete = false,
    ...props
}: ICustomFieldValuesSelectProps) => {
    const customField = useSelector(selectCustomFieldById(customFieldId));

    const customFieldValues = useSelector(selectCustomFieldValuesByFieldId(customFieldId));
    const isCustomFieldValuesLoadingRaw = useSelector(selectIsCustomFieldValuesLoading);
    const isScopeDataLoading = useSelector(selectScopesIsLoading);
    const isCustomFieldValuesLoading = useMemo(() => isCustomFieldValuesLoadingRaw || isScopeDataLoading,
        [isCustomFieldValuesLoadingRaw, isScopeDataLoading]);
    const [field,, helper] = useField(props.name);

    const filteredCustomFieldValues = useFilteredCustomFieldValues({
        hierarchy,
        customFieldId,
        customFieldFormValues,
        customFieldValues,
        useSubassignments,
        valuesToShow,
        hierarchyType,
        userId,
        date,
        onlyActionable,
        scopeActionFilter,
        payPeriod,
        filterInactive,
    });

    useAutoselectSingleVariant({
        autoSelectSingleVariant: autoSelectSingleVariant && !isCustomFieldValuesLoading,
        filteredCustomFieldValues,
        helper,
        selectedValue: field.value,
        useIdValue: props.useIdValue,
        callback: props.onConfirmSelection,
    });
    useFieldResetIfSelectedValueFiltered({
        customFieldValues,
        filteredCustomFieldValues,
        helper,
        selectedValue: field.value,
        useIdValue: props.useIdValue,
        callback: props.onConfirmSelection,
    });

    return (
        <>
            {customField && !hidden && !useAutocomplete && (
                <FormSelect
                    getKey={getFieldValueId}
                    getText={getFieldValueName}
                    options={filteredCustomFieldValues}
                    outerLabel={useLabel ? undefined : customField?.name}
                    label={useLabel ? customField?.name : '--'}
                    isLoading={isCustomFieldValuesLoading}
                    {...props}
                />
            )}
            {customField && !hidden && useAutocomplete && (
                <FormAutocomplete
                    getKey={getFieldValueId}
                    getText={getFieldValueName}
                    options={filteredCustomFieldValues}
                    outerLabel={useLabel ? undefined : customField?.name}
                    label={useLabel ? customField?.name : '--'}
                    isLoading={isCustomFieldValuesLoading}
                    {...props}
                />
            )}
        </>
    );
};
