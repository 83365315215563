import { Box } from '@material-ui/core';
import React from 'react';
import { WarningAlertWithIcon } from 'shared/components/alerts/WarningAlertWithIcon';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import { FeatureSwitches } from 'utils/featureSwitches';
import { makeHighPriorityStyles } from '../../../utils/stylesWrapper';
import { colors } from '../../styles/constants';
import { useFeature } from '@optimizely/react-sdk';

const useStyles = makeHighPriorityStyles(() => ({
    wrapper: {
        background: colors.white,
    },
}));

export function Maintaince() {
    const classes = useStyles();
    const [, featureVars] = useFeature(FeatureSwitches.enableMaintainceWorkMessage);

    return (
        <FeatureSwitch feature={FeatureSwitches.enableMaintainceWorkMessage} noPlaceholder>
            <Box className={classes.wrapper}>
                <WarningAlertWithIcon>
                    <p>
                        We are currently performing system maintenance on our server.
                        Some features may be temporarily unavailable
                    </p>
                    {featureVars && featureVars.datetimeMessage && (
                        <p>{featureVars.datetimeMessage}</p>
                    )}
                    <p> We apologize for any inconvenience.</p>
                </WarningAlertWithIcon>
            </Box>
        </FeatureSwitch>
    );
}
