import React, { useCallback, useMemo } from 'react';
import { uniqBy } from 'lodash-es';
import { Box, Button } from '@material-ui/core';
import { ISheetGroupIdWithClient } from 'modules/payrollProcessorHub/store/model';
import { usePayrollModalStyle } from './PayrollModalStyle';
import PrePayrollReport from './reports/PrePayrollReport';
import { Formik, FormikProps } from 'formik';
import { initSheetGroupAviontePayrollDownload } from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsPayRollProcessing } from '../../store/selectors';
import { selectAllClientsById } from 'store/entities/clients/selectors/clientsSelectors';
import { IClient } from 'store/entities/clients/clientsModel';
import { AviontePayrollFileDownloadButton } from './AviontePayrollFileDownloadButton';

interface IPrePayrollAvionteExportProps {
    close: () => void;
    groupIds: ISheetGroupIdWithClient[];
}

/**
 * When you try to use Client.id (UID) as a key of fiels (ex. payDate.12345_123_123)
 * Formik generate wrong validation scheme and make array which consists of lots of undefined values
 * because it tries to interpretat uid as number
 * @param id
 */
const getPayrollDateFieldValueKey = (id: string) => `_${id}`;

const PrePayrollAvionteExportModal = ({ close, groupIds }: IPrePayrollAvionteExportProps) => {
    const classes = usePayrollModalStyle();

    const dispatch = useDispatch();
    const isLoadingPayRoll = useSelector(selectIsPayRollProcessing);
    const allClient = useSelector(selectAllClientsById);
    const uniqClients: IClient[] = useMemo(() => {
        return uniqBy(groupIds, 'clientId')
            .map((groupId: ISheetGroupIdWithClient) => groupId.clientId)
            .map((clientId: string) => allClient[clientId]);
    }, [groupIds, allClient]);

    const onInitPayroll = useCallback(() => {
        dispatch(initSheetGroupAviontePayrollDownload.init({ sheets: groupIds }));
    }, [dispatch, groupIds]);

    const initialValues = {
        payDates: uniqClients.reduce((touched, client) => ({
            ...touched,
            [getPayrollDateFieldValueKey(client.id)]: null,
        }), {}),
        separateTimeExpense: false,
    };

    const isAllDatesFilled = (payDates: Record<string, string | null>) => !Object.values(payDates).includes(null);

    return (
        <Box className={classes.ContentWrapper}>
            <Box
                className={classes.DateLabel}
            >
                Are you sure you want to export payroll batch for the selected sheets?
            </Box>
            <Formik
                initialValues={initialValues}
                onSubmit={onInitPayroll}
                validateOnBlur={false}
                validateOnChange={true}
            >
                {(props: FormikProps<typeof initialValues>) => (
                    <>
                        {props.dirty && props.isValid && isAllDatesFilled(props.values.payDates) && (
                            <PrePayrollReport
                                groupIds={groupIds}
                                payDatesValue={props.values.payDates}
                                separateTimeExpense={false}
                            />
                        )}
                        <Box className={classes.ButtonWrapper}>
                            <AviontePayrollFileDownloadButton isLoading={isLoadingPayRoll} onClick={props.submitForm} />
                            <Button
                                variant="text"
                                color="secondary"
                                className={classes.SecondaryButton}
                                onClick={close}
                                disabled={isLoadingPayRoll}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </>
                )}
            </Formik>
        </Box>
    );
};

export default PrePayrollAvionteExportModal;
