import React from 'react';
import { useSelector } from 'react-redux';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { IOfferLetterTemplateParam } from '../../store/models';
import { selectOfferLetterTemplateParams, selectOfferLetterTemplateParamsLoading } from '../../store/selectors';
import { Nullable } from '../../../../../../types/types';

interface ITemplateNoteStoreProps {
    templateParams?: Nullable<IOfferLetterTemplateParam[]>;
    isTemplateParamsLoading?: boolean;
}

export const TemplateNotePure = ({
    templateParams,
    isTemplateParamsLoading,
}: ITemplateNoteStoreProps) => {
    const classes = useStyles();
    if (!isTemplateParamsLoading && !templateParams) {
        return null;
    }

    if (isTemplateParamsLoading) {
        return (
            <Box className={classes.templateNoteLoader}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box className={classes.templateNote}>
            <>
                <Typography className={classes.redText}>List of mapped data points:</Typography>
                <ul className={classes.redText}>
                    {templateParams && templateParams.map(({ name }) => (
                        <li key={name}>{name}</li>
                    ))}
                </ul>
                <span>See <a href="https://helpx.adobe.com/acrobat/using/pdf-form-field-basics.html">Adobe Acrobat user guide</a> for help.</span>
            </>
        </Box>
    );
};

export const TemplateNote = separateLogicDecorator<
{},
ITemplateNoteStoreProps
>(() => ({
    templateParams: useSelector(selectOfferLetterTemplateParams),
    isTemplateParamsLoading: useSelector(selectOfferLetterTemplateParamsLoading),
}))(TemplateNotePure);
