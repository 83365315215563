/* eslint-disable react/display-name */
import React, { useMemo } from 'react';
import clsx from 'clsx';
import {
    IPayrollSheetGroupedRow,
    IPayrollSheetRow,
    StickyCellClasses,
    TableClasses,
} from 'modules/payrollProcessorHub/components/PayrollSheetTable/model';
import { useStickyCellsStyles } from 'modules/payrollProcessorHub/components/PayrollSheetTable/styles/useStickyCellsStyles';
import { setGroupedCalculationCheckedItems } from 'modules/payrollProcessorHub/store/actions';
import { PayrollSheetTabs } from 'modules/payrollProcessorHub/store/model';
import { selectCheckedCalculationGroupsState, selectGroupedCalculationsRows } from 'modules/payrollProcessorHub/store/selectors';
import { useSelector } from 'react-redux';
import {
    CheckBoxCellPure,
    CheckBoxTitlePure,
    getCheckboxCell,
    ICheckBoxCellPureProps,
    ICheckboxClasses,
    ICheckBoxTitlePureProps,
} from 'shared/components/table/Cells/checkBoxHelper';
import {
    useGroupedSheetTableStyles,
} from 'modules/payrollProcessorHub/components/PayrollSheetTable/styles/useGroupedSheetTableStyles';
import { ICellProps } from 'shared/components/table/GridTable/GridTableModel';
import { useIsMobile } from 'shared/utils/hooks/media';
import { CheckBoxCellActions, useCheckedItemsStore } from 'shared/utils/hooks/useCheckedItems';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';

const getRowId = (row: IPayrollSheetRow) => row.id;

const PayrollCheckboxHeader = separateLogicDecorator<{ classes: ICheckboxClasses }, ICheckBoxTitlePureProps>(
    ({ classes }) => {
        const rows = useSelector(selectGroupedCalculationsRows);
        const checkedItemsActions = useCheckedItemsStore<IPayrollSheetGroupedRow>(
            rows,
            selectCheckedCalculationGroupsState,
            setGroupedCalculationCheckedItems,
        );
        return {
            classes,
            checkedAll: checkedItemsActions.checkedAll,
            onCheckAll: checkedItemsActions.onCheckAll,
        };
    },
)(CheckBoxTitlePure);

const PayrollCheckbox = separateLogicDecorator<{
    classes: ICheckboxClasses,
    row: ICellProps<IPayrollSheetGroupedRow>,
    customClasses: string[],
}, ICheckBoxCellPureProps>(
    /**
     * Grouped table is sensitive for re-renders
     * To avoid render full table on change checked items we introduce component connected directly to store
     */
    ({ classes, row, customClasses }) => {
        const rows = useSelector(selectGroupedCalculationsRows);
        const checkedItemsActions = useCheckedItemsStore<IPayrollSheetGroupedRow>(
            rows,
            selectCheckedCalculationGroupsState,
            setGroupedCalculationCheckedItems,
        );
        return {
            classes,
            customClasses,
            checkedItems: checkedItemsActions.checkedItems,
            onCheck: checkedItemsActions.onCheck,
            rowClassName: row.className,
            // @ts-ignore
            rowId: getRowId(row),
        };
    },
)(CheckBoxCellPure);

/**
 * Hook for payroll checkboxes with common logic
 * @param activeTab
 */
const useCheckboxCommon = (activeTab: PayrollSheetTabs) => {
    const stickyCellsClasses: StickyCellClasses = useStickyCellsStyles();

    const userHasEditPermission: boolean = useSelector(selectIsUserHasPermission(Permission.payrollProcessing));
    const isBatchActionsTab: boolean = [PayrollSheetTabs.APPROVED, PayrollSheetTabs.SUBMITTED].includes(activeTab);
    const hasBatchActions: boolean = userHasEditPermission && isBatchActionsTab;

    const isMobile = useIsMobile();

    return useMemo(() => {
        const customHeaderClasses = stickyCellsClasses.stickyCheckboxHeader;
        const customCellClasses = stickyCellsClasses.stickyCheckbox;
        return {
            customHeaderClasses,
            customCellClasses,
            hasBatchActions,
            isMobile,
        };
    }, [stickyCellsClasses, hasBatchActions, isMobile]);
};

/**
 * Checkbox hook for grouped multiple calculations to avoid performance issues
 */
export function usePayrollSheetCheckbox(
    activeTab: PayrollSheetTabs,
    tableClasses: TableClasses,
) {
    const payrollTableClasses = useGroupedSheetTableStyles();
    const {
        customHeaderClasses,
        customCellClasses,
        hasBatchActions,
        isMobile,
    } = useCheckboxCommon(activeTab);

    return useMemo(() => {
        if (!hasBatchActions) {
            return [];
        }

        return [
            {
                key: 'checkbox',
                title: '',
                width: isMobile ? '40px' : '60px',
                headerClassName: clsx(...customHeaderClasses, tableClasses.iconCell, tableClasses.checkbox),
                renderTitle: () => <PayrollCheckboxHeader classes={tableClasses} />,
                render: function CheckboxCell(row: IPayrollSheetGroupedRow) {
                    return (
                        <PayrollCheckbox
                            classes={tableClasses}
                            customClasses={[customCellClasses, isMobile ? payrollTableClasses.mobileCheckbox : '']}
                            row={row}
                        />
                    );
                },
            },
        ];
    }, [
        hasBatchActions,
        payrollTableClasses,
        customCellClasses,
        customHeaderClasses,
        isMobile,
        tableClasses,
    ]);
}

/**
 * Checkbox hook for calculations
 */
export function usePayrollSingleSheetCheckbox(
    activeTab: PayrollSheetTabs,
    checkedItemsActions: CheckBoxCellActions,
    tableClasses: TableClasses,
) {
    const {
        customHeaderClasses,
        customCellClasses,
        hasBatchActions,
        isMobile,
    } = useCheckboxCommon(activeTab);

    if (!hasBatchActions) {
        return [];
    }

    return [
        getCheckboxCell(
            checkedItemsActions.checkedItems,
            checkedItemsActions.onCheck,
            checkedItemsActions.checkedAll,
            checkedItemsActions.onCheckAll,
            tableClasses,
            isMobile,
            // @ts-ignore
            getRowId,
            customHeaderClasses,
            customCellClasses,
        ),
    ];
}
