import React from 'react';
import clsx from 'clsx';
import { DeleteItemButton } from 'modules/settings/common/components/DeleteItemButton/DeleteItemButton';
import { IPayRangeRow } from 'modules/settings/submodules/clients/payRanges/components/payRangesTable/model';
import PlainText from 'shared/components/table/Cells/PlainText';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { ICellProps } from 'shared/components/table/GridTable/GridTableModel';
import { Permission } from 'store/components/auth/authModels';
import { deletePayRange } from 'store/entities/configuration/configurationAction';
import { getFieldValueName } from 'store/entities/customFields/helpers';
import { ILocationCustomField } from 'store/entities/customFields/model';
import { InlinePayRangeEditCell } from 'modules/settings/submodules/clients/payRanges/components/payRangesTable/InlinePayRangeEditCell';

export const usePayRangeCells = () => {
    const tableClasses = useDefaultTableStyles();
    return [
        {
            key: 'location',
            title: 'location',
            width: '2fr',
            render: function LocationCell({ className, location }: ICellProps<IPayRangeRow>) {
                return (
                    <PlainText className={className} value={location ? getFieldValueName(location) : ''} />
                );
            },
        },
        {
            key: 'state',
            title: 'state',
            render: function StateCell({ className, location }: ICellProps<IPayRangeRow>) {
                const state = (location?.data as ILocationCustomField)?.state_code || '';
                return (<PlainText className={className} value={state} />);
            },
        },
        {
            key: 'position',
            title: 'position',
            render: function PositionCell({ className, position }: ICellProps<IPayRangeRow>) {
                return (
                    <PlainText className={className} value={position ? getFieldValueName(position) : ''} />
                );
            },
        },
        {
            key: 'pay_rate',
            title: 'pay rate range (per hr.)',
            render: InlinePayRangeEditCell,
        },
        {
            key: 'actions',
            title: '',
            width: '54px',
            render: function DetailsCell({ className, id }: ICellProps<IPayRangeRow>) {
                return (
                    <div className={clsx(className, tableClasses.iconCell, tableClasses.detailsIcon)}>
                        <DeleteItemButton
                            confirmation="Are you sure you want to delete pay range?"
                            id={id}
                            deleteAction={deletePayRange.init}
                            permission={Permission.deleteClientSettingsEntities}
                        />
                    </div>
                );
            },
        },
    ];
};
