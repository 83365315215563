import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { AttachmentGateway } from 'shared/components/attachments/AttachmentGateway';
import { selectAttachmentObjectUrlById } from 'shared/components/attachments/store/selectors';
import { IAttachment } from 'shared/models/Attachments';
import { useUploadStyles } from './styles';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';

export interface IAttachmentsPreviewBaseProps {
    onDelete?: () => void;
    attachment: IAttachment;
}

export const AttachmentPreviewBase = ({
    onDelete,
    attachment,
}: IAttachmentsPreviewBaseProps) => {
    const classes = useUploadStyles();
    const objectUrl = useSelector(selectAttachmentObjectUrlById(attachment.id));

    const onDownload = () => {
        if (!objectUrl) {
            return;
        }
        const link = document.createElement('a');
        link.href = objectUrl;
        link.setAttribute('download', attachment.filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    return (
        <Box className={classes.imageContainer}>
            <AttachmentGateway attachment={attachment} className={classes.modalPreview} />
            <div className={classes.bottomBar}>
                <Typography className={classes.imageTitle}>
                    {attachment?.filename || ''}
                </Typography>
                <div className={classes.iconButton}>
                    {onDelete && (
                        <Button
                            aria-label="delete"
                            onClick={onDelete}
                            classes={{ root: classes.buttonRoot }}
                        >
                            <DeleteIcon className={classes.bottomActions} />
                        </Button>
                    )}
                    <Button
                        aria-label="download"
                        onClick={onDownload}
                        classes={{ root: classes.buttonRoot }}
                    >
                        <GetAppIcon className={classes.bottomActions} />
                    </Button>
                </div>
            </div>
        </Box>
    );
};
