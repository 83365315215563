import { Box } from '@material-ui/core';
import { useHomeStyles } from 'modules/home/HomeStyles';
import { settingsRoutes } from 'modules/settings/routes';
import { ClientsSettingsRoutes } from 'modules/settings/submodules/clients/ClientsSettingsRoutes';
import { SettingsAppBar } from 'modules/settings/submodules/components/SettingsAppBar/SettingsAppBar';
import { EmployeeRoutes } from 'modules/settings/submodules/employees/EmployeeRoutes';
import { PlatformUsersRoutes } from 'modules/settings/submodules/platformUsers/PlatformUsersRoutes';
import { SettingsDashboard } from 'modules/settings/submodules/SettingsDashboard';
import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from 'shared/components/auth/PrivateRoute';
import { Permission } from 'store/components/auth/authModels';
import { Maintaince } from 'shared/components/alerts/Maintaince';
import OfferLetterTemplatesRoutes from './submodules/offerLetters/OfferLetterTemplatesRoutes';
import { GlobalAssignmentSetupRoutes } from 'modules/settings/submodules/clients/globalAssignmentSetup/GlobalAssignmentSetupRoutes';

export const SettingsRoutes = () => {
    const classes = useHomeStyles();

    return (
        <Box
            display="flex"
            flexDirection="column"
            minHeight="100vh"
        >
            <Maintaince />
            <SettingsAppBar />
            <Box display="flex" className={classes.main}>
                <Switch>
                    <PrivateRoute
                        path={settingsRoutes.CLIENTS.ROOT}
                        component={ClientsSettingsRoutes}
                        permission={Permission.modifyClientSettings}
                    />
                    <PrivateRoute
                        path={settingsRoutes.PLATFORM_USERS.ROOT}
                        component={PlatformUsersRoutes}
                        permission={Permission.ManageManagerUsers}
                    />
                    <PrivateRoute
                        path={settingsRoutes.OFFER_LETTERS.TEMPLATES}
                        component={OfferLetterTemplatesRoutes}
                        permission={Permission.ManageOfferLetterTemplates}
                    />
                    <PrivateRoute
                        path={settingsRoutes.EMPLOYEES.ROOT}
                        component={EmployeeRoutes}
                        permission={Permission.ManageEmployeeUsers}
                    />
                    <PrivateRoute
                        path={settingsRoutes.ASSIGNMENT_SETUP.ROOT}
                        component={GlobalAssignmentSetupRoutes} />
                    <PrivateRoute
                        path={settingsRoutes.ROOT}
                        component={SettingsDashboard}
                    />
                </Switch>
            </Box>
        </Box>
    );
};
