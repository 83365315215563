import { IPayrollSheetGroupedRow } from 'modules/payrollProcessorHub/components/PayrollSheetTable/model';
import {

    getSheetCalculationBatchExpenseSheetIds,

    getSheetCalculationBatchPayPeriodEnds,
    getSheetCalculationBatchTimeSheetIds,
} from 'modules/payrollProcessorHub/helpers/sheetCalculationBatchHelpers';
import { getGroupedSheetCalculationPdf } from 'modules/payrollProcessorHub/store/actions';
import { selectPdfDownloadingIds } from 'modules/payrollProcessorHub/store/selectors';

import React, { useCallback } from 'react';
import { GetAppOutlined } from '@material-ui/icons';
import { CircularProgress, IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

export interface IDownloadSheetButtonBatchProps {
    row: IPayrollSheetGroupedRow;
}

export const DownloadSheetButtonBatch = ({ row }: IDownloadSheetButtonBatchProps) => {
    const dispatch = useDispatch();
    const pdfDownloadingIds = useSelector(selectPdfDownloadingIds);
    const isLoading = pdfDownloadingIds.includes(row.id);

    const handleClick = useCallback(() => {
        dispatch(getGroupedSheetCalculationPdf.init({
            id: row.id,
            time_sheet_ids: getSheetCalculationBatchTimeSheetIds(row),
            expense_sheet_ids: getSheetCalculationBatchExpenseSheetIds(row),
            pay_period_ends_at: getSheetCalculationBatchPayPeriodEnds(row).join(','),
            payroll_status: row.payroll_status,
        }));
    }, [dispatch, row]);

    return (
        <IconButton onClick={handleClick} disabled={isLoading}>
            {isLoading ? <CircularProgress color="inherit" size={16} /> : <GetAppOutlined />}
        </IconButton>
    );
};
