import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll, { Props as IInfiniteScrollProps } from 'react-infinite-scroll-component';
import { isEntireElementWithinViewport } from 'shared/utils/helpers/isEntireElementWithinViewport';
import GridTableSkeleton from './GridTableSkeleton';
import GridTable, { IGridTableProps } from './GridTable';

export interface IInfinityGridTable {
    onLoadMore: () => void;
    hasMore: boolean;
}

export interface IInfinityGridTableProps<RowData> extends IGridTableProps<RowData>, IInfinityGridTable {
    infiniteScrollProps?: Partial<IInfiniteScrollProps>;
}

export default function InfinityGridTable<RowData>({
    onLoadMore,
    hasMore,
    infiniteScrollProps,
    setTableRef: setTableRefOuter,
    ...gridTableData
}: IInfinityGridTableProps<RowData>) {
    const [tableRefInner, setTableRefInner] = useState<HTMLDivElement | null>(null);

    const setTableRefCb = useCallback((instance: HTMLDivElement | null) => {
        if (setTableRefOuter) {
            setTableRefOuter(instance);
        }
        setTableRefInner(instance);
    }, [setTableRefOuter]);

    useEffect(() => {
        if (hasMore && !gridTableData.isLoading) {
            // to check if there is no scrollbar
            const isTableFullyVisibleWithinScreen = isEntireElementWithinViewport(tableRefInner);
            if (isTableFullyVisibleWithinScreen) {
                onLoadMore();
            }
        }
    }, [gridTableData.isLoading, hasMore, onLoadMore, tableRefInner]);

    const dataLength = gridTableData.groups?.length || gridTableData.rowData.length || 0;

    return (
        <InfiniteScroll
            {...infiniteScrollProps}
            dataLength={dataLength}
            next={onLoadMore}
            hasMore={hasMore}
            loader={<GridTableSkeleton key="load_more_items_skeleton" />}
            style={{ overflow: 'unset' }}
        >
            <GridTable
                setTableRef={setTableRefCb}
                {...gridTableData}
                isLoading={dataLength === 0 && gridTableData.isLoading}
            />
        </InfiniteScroll>
    );
}
