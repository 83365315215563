import { FormikProps } from 'formik';
import { IOfferLetterFormValues } from 'modules/offerLetter/components/OfferLetterForm/model';
import React, { useContext, useEffect, useMemo } from 'react';
import { IEmployeeUserInfo, IUserInfo } from 'shared/models/User';
import {
    selectClientApprovalLevels,
    selectClientTimeAndPayConfiguration,
} from 'store/entities/clients/selectors/timeAndPaySelectors';
import { OfferLetterFormNextButton } from './components/OfferLetterFormNextButton';
import { MultiStepForm, MultiStepFormValues } from 'shared/components/forms/multiStep/MultiStepForm';
import { OFFER_LETTER_FORM_STEP_EMPLOYEE, OfferLetterFormStepEmployee } from './steps/OfferLetterFormStepEmployee';
import { OFFER_LETTER_FORM_STEP_OFFER, OfferLetterFormStepOffer } from './steps/OfferLetterFormStepOffer';
import { offerLetterSteppedValidationSchema } from './OfferLetterValidationsSchema';
import { OFFER_LETTER_FORM_STEP_INVITE, OfferLetterFormStepInvite } from './steps/OfferLetterFormStepInvite';
import { OFFER_LETTER_FORM_STEP_ASSIGNMENT, OfferLetterFormStepAssignment } from './steps/OfferLetterFormStepAssignment';
import { OfferLetterFormSubmitBlock } from './components/OfferLetterFormSubmitBlock';
import { useDispatch, useSelector } from 'react-redux';
import { getOfferLetterTemplates } from '../../store/templates/actions';
import { getEmployeeTypes } from 'modules/employmentInfo/store/employeeType/actions';
import { CreateOfferSidebarContext } from '../CreateOffer/CreateOfferSidebar';
import { useFeature } from '@optimizely/react-sdk';
import { FeatureSwitches } from 'utils/featureSwitches';
import { IOfferLetter } from '../../store/model';

export interface IOfferLetterSeletedEmployee extends IEmployeeUserInfo, Omit<Partial<IUserInfo>, 'first_name' | 'last_name' | 'cell_phone' | 'email'> {

}

export interface IOfferLetterMultiStepFormProps {
    isLoading?: boolean;
    onSubmit: (values: IOfferLetterFormValues) => void;
    onCancel: () => void;
    isSubmitting?: boolean;
    selectedEmployee?: IOfferLetterSeletedEmployee;
    initialValues: IOfferLetterFormValues;
    offer?: IOfferLetter;
}

export function OfferLetterMultiStepForm({
    initialValues,
    onSubmit,
    onCancel,
    isSubmitting,
    selectedEmployee,
}: IOfferLetterMultiStepFormProps) {
    const dispatch = useDispatch();
    const approversCount = useSelector(selectClientApprovalLevels);
    const [editEmployeeFeature] = useFeature(FeatureSwitches.enableEditingEmployeeNameOnOfferLetterCreation);

    useEffect(() => {
        dispatch(getOfferLetterTemplates.init());
        dispatch(getEmployeeTypes.init());
    }, [dispatch]);

    const canEditEmployee = selectedEmployee
        && editEmployeeFeature
        && selectedEmployee?.employee_profile?.avionte_onboarding_completed;
    const steps = [
        ...selectedEmployee
            ? [
                {
                    name: OFFER_LETTER_FORM_STEP_EMPLOYEE,
                    component: function StepEmployee(
                        formikProps: FormikProps<MultiStepFormValues<IOfferLetterFormValues>>,
                    ) {
                        return (
                            <OfferLetterFormStepEmployee
                                {...formikProps}
                                employee={selectedEmployee}
                                canEditEmployee={canEditEmployee}
                            />
                        );
                    },
                },
            ]
            : [
                {
                    name: OFFER_LETTER_FORM_STEP_INVITE,
                    component: OfferLetterFormStepInvite,
                },
                {
                    name: OFFER_LETTER_FORM_STEP_OFFER,
                    component: OfferLetterFormStepOffer,
                },
            ],
        {
            name: OFFER_LETTER_FORM_STEP_ASSIGNMENT,
            component: OfferLetterFormStepAssignment,
        },
    ];

    const stepField = 'step';
    const { step, setStep: onSetStep } = useContext(CreateOfferSidebarContext);
    const appSettings = useSelector(selectClientTimeAndPayConfiguration);
    const isExternalEmployeeIdEnabled = useMemo(() =>
        appSettings?.enableOfferLetterEmployeeNumber ?? false, [appSettings]);
    const validationSchema = useMemo(() => {
        return offerLetterSteppedValidationSchema(
            isExternalEmployeeIdEnabled,
            stepField,
            {
                templatePartsRequired: false,
                approversCount,
            },
        );
    }, [stepField, approversCount, isExternalEmployeeIdEnabled]);

    return (
        <MultiStepForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            NextButton={OfferLetterFormNextButton}
            validationSchema={validationSchema}
            FinalStepBlock={() => <OfferLetterFormSubmitBlock onCancel={onCancel} isLoading={isSubmitting} />}
            steps={steps}
            step={step}
            onSetStep={onSetStep}
            stepFieldName={stepField}
        />
    );
}
