import { Box } from '@material-ui/core';
import { useHomeTitle, useUserDashboardData } from 'modules/home/dashboard/utils';
import { setHeaderContent } from 'modules/home/header/Header';
import { getHomeDashboardStats } from 'modules/home/store/actions';
import { selectIsUserStatsLoading } from 'modules/home/store/selectors';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dashboard from 'shared/components/dashboard/Dashboard';
import { selectIsManager } from 'store/components/auth/selectors';
import { DashboardFilter } from './DashboardFilter/DashboardFilter';

const HomeDashboard = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getHomeDashboardStats.init());
    }, [dispatch]);

    const isLoading = useSelector(selectIsUserStatsLoading);
    const isManager = useSelector(selectIsManager);
    const data = useUserDashboardData(isManager);
    useHomeTitle();

    useEffect(() => {
        if (!process.env.REACT_APP_CLIENT_ID) {
            setHeaderContent(
                <Box
                    display="flex"
                    alignItems="flex-end"
                    justifyContent="flex-end"
                    width="100%"
                >
                    <DashboardFilter />
                </Box>,
            );
        }

        return () => {
            setHeaderContent(null);
        };
    }, []);
    return (
        <Dashboard data={data} isLoading={isLoading} />
    );
};

export default HomeDashboard;
