import React, { useMemo } from 'react';
import { entryCellDictionaryReadOnly } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/EntriesGrid/EntryCellDictionary';
import { useExtendedConfiguration } from 'shared/components/table/EntriesTable/utils';
import { EntryType } from 'shared/models/sheet/Sheet';
import { useEntriesTableStyles } from 'shared/components/table/EntriesTable/EntriesTableStyles';
import { IEntryRow } from 'shared/components/table/EntriesTable/model';
import EntryDetails from 'shared/components/table/tableEntryCells/EntryDetails';
import {
    AvailableTableConfiguration,
    EntryColumnSlug,
} from 'store/entities/clients/clientsModel';
import { getColumnsByConfiguration } from 'store/utils/tables';

export function useEntriesCells(isMobile: boolean, entryFilter?: EntryType) {
    const classes = useEntriesTableStyles();
    const option = entryFilter === EntryType.TIME
        ? AvailableTableConfiguration.TimeDetail : AvailableTableConfiguration.ExpenseDetail;

    const configuration = useExtendedConfiguration(option);

    return useMemo(() => {
        return isMobile
            ? configuration.length
                ? [
                    {
                        key: 'entryInfo',
                        title: '',
                        render: function EntryInfo(props: IEntryRow) {
                            return (
                                <EntryDetails configuration={configuration} {...props} />
                            );
                        },
                    },
                    entryCellDictionaryReadOnly[EntryColumnSlug.AmountReadonly]('', classes),
                ]
                : []
            : getColumnsByConfiguration(configuration, entryCellDictionaryReadOnly, classes);
    }, [classes, isMobile, configuration]);
}
