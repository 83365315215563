import { Box, Typography } from '@material-ui/core';
import FiltersChips
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/FiltersChips';
import { ApprovalExportCsv } from 'modules/sheetApproval/components/ApprovalExportCsv/ApprovalExportCsv';
import { selectTimeAndExpenseActiveStatus } from 'modules/timeAndExpense/store/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import SheetFilterButton from 'shared/components/filters/FilterButtonAndForm/SheetFilterButton';
import { SyncMessage } from 'shared/components/toolbar/SyncMessage';
import { EntryType, ISheet } from 'shared/models/sheet/Sheet';
import { IUserInfo } from 'shared/models/User';
import {
    setManagerSubmittedSheetsFilters,
} from 'store/components/managerSubmittedSheets/managerSubmittedSheetsActions';
import {
    selectManagerSubmittedSheetsFilters,
    selectManagerSubmittedSheetsFiltersConfiguration,
} from 'store/components/managerSubmittedSheets/managerSubmittedSheetsSelectors';
import { SyncingModels } from 'store/entities/appConfig/syncing/models';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import ApproveButton from './buttons/ApproveButton';
import Expense from './buttons/ExpenseIcon';
import RejectButton from './buttons/RejectButton';
import Time from './buttons/TimeIcon';
import useFilterAndActionControlsStyles from './FilterAndActionControlsStyles';

interface IFilterAndActionControlsProps {
    sheetType: EntryType;
    showActionControls: boolean;
    sheets: ISheet[];
    linkedUsers: IUserInfo[];
    showFilters?: boolean;
    title?: string;
    withInfinityTable?: boolean;
}

export default function FilterAndActionControls({
    sheetType,
    showActionControls,
    sheets,
    linkedUsers,
    showFilters = true,
    title,
    withInfinityTable,
}: IFilterAndActionControlsProps) {
    const classes = useFilterAndActionControlsStyles();
    const activeStatus = useSelector(selectTimeAndExpenseActiveStatus);

    return (
        <>
            <Box className={classes.rootContainer}>
                <Box className={classes.leftContainer}>
                    {sheetType === EntryType.TIME ? <><Time /></> : <><Expense /></>}
                    <Box>
                        <Typography variant="h4">
                            {title ? title : `All ${(activeStatus === StatusNames.ALL) ? '' : activeStatus} ${sheetType === EntryType.TIME ? 'Timesheets' : 'Expenses'}` }
                        </Typography>
                    </Box>
                </Box>
                <SyncMessage syncingKey={SyncingModels.ApprovalSheet} />
                {showFilters && (
                    <FiltersChips
                        className={classes.filtersChips}
                        selector={selectManagerSubmittedSheetsFilters}
                        // @ts-ignore
                        action={setManagerSubmittedSheetsFilters}
                    />
                )}
                <Box className={classes.rightContainer}>
                    {showActionControls && activeStatus === StatusNames.SUBMITTED && (
                        <>
                            <RejectButton sheets={sheets} users={linkedUsers}
                                sheetType={sheetType} />
                            <ApproveButton sheets={sheets} sheetType={sheetType} />
                        </>
                    )}
                    {showFilters && (
                        <>
                            <ApprovalExportCsv sheetType={sheetType} />
                            <SheetFilterButton
                                overrideClassName={classes.actionButton}
                                // @ts-ignore
                                submitFilters={setManagerSubmittedSheetsFilters}
                                selectFiltersConfig={selectManagerSubmittedSheetsFiltersConfiguration}
                                selectFilters={selectManagerSubmittedSheetsFilters}
                                withInfinityTable={withInfinityTable}
                            />
                        </>
                    )}
                </Box>
            </Box>
        </>
    );
}
