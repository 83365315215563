/* eslint-disable react/display-name */
import clsx from 'clsx';
import { DeleteItemButton } from 'modules/settings/common/components/DeleteItemButton/DeleteItemButton';
import { EditItemButton } from 'modules/settings/common/components/EditItemButton/EditItemButton';
import {
    ISettingsTablePureProps,
    SettingsTablePure,
} from 'modules/settings/common/components/SettingsTablePure/SettingsTablePure';
import {
    CustomFieldValueRowType,
} from 'modules/settings/submodules/clients/customFieldValues/components/CustomFieldValuesTable/model';
import { setEditScopeId } from 'modules/settings/submodules/clients/scopes/store/actions';
import { selectScopeIsDeleting } from 'modules/settings/submodules/clients/scopes/store/selector';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PlainText from 'shared/components/table/Cells/PlainText';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { ICellProps } from 'shared/components/table/GridTable/GridTableModel';
import { shortDateFormat } from 'shared/models/Dates';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { Permission } from 'store/components/auth/authModels';
import { deleteScopes } from 'store/entities/scopes/actions';
import { IScope, ScopeType } from 'store/entities/scopes/models';
import { selectScopesByType, selectScopesIsLoading } from 'store/entities/scopes/selectors';

interface ITypedScopesListProps {
    type: ScopeType;
}

type ScopeCellProps = ICellProps<IScope>;

export const TypedScopesList = (
    separateLogicDecorator<ITypedScopesListProps, ISettingsTablePureProps<CustomFieldValueRowType>>(({ type }) => {
        const rows = useSelector(selectScopesByType(type));
        const tableClasses = useDefaultTableStyles();
        const isDeleting = useSelector(selectScopeIsDeleting);

        const cells = useMemo(() => {
            return [
                {
                    key: 'name',
                    title: 'Name',
                    render: ({ className, name }: ScopeCellProps) => {
                        return (
                            <PlainText className={className} value={name} />
                        );
                    },
                },
                {
                    key: 'updated_at',
                    title: 'Last edited',
                    render: ({ className, updated_at }: ScopeCellProps) => (
                        <PlainText className={className} value={moment(updated_at).format(shortDateFormat)} />
                    ),
                },
                {
                    key: 'actions',
                    title: '',
                    width: '100px',
                    render: ({ className, id }: ScopeCellProps) => {
                        return (
                            <div className={clsx(className, tableClasses.iconCell)}>
                                <EditItemButton
                                    id={id}
                                    editAction={setEditScopeId}
                                    fontSize="small"
                                />
                                <DeleteItemButton
                                    confirmation="Are you sure you want to delete scope?"
                                    id={id}
                                    deleteAction={deleteScopes.init}
                                    disabled={isDeleting}
                                    permission={Permission.deleteClientSettingsEntities}
                                />
                            </div>
                        );
                    },
                },
            ];
        }, [tableClasses, isDeleting]);

        return {
            cells,
            rows,
            isLoading: useSelector(selectScopesIsLoading),
        };
    })(SettingsTablePure)
);
