import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useField } from 'formik';
import { useEffectWithSkipInitialChange } from 'shared/components/forms/utils';
import { BreakCaRule } from 'shared/components/formSpecialFields/TimeEntryField/BreakCaRule';
import InOutTimeField, {
    IInOutTimeFieldProps,
} from 'shared/components/formSpecialFields/TimeEntryField/InOutTimeField';
import PopOver from 'shared/components/popover/PopOver';
import { calculateDurationFromStartEndValue } from 'shared/models/DateTime';
import { moment } from 'utils/momentExtensions';
import { useSelector } from 'react-redux';
import { selectCommonEntryFormValues } from 'modules/timeAndExpense/components/AddEntry/store/selectors';
import { selectCustomFieldValuesByIds } from 'store/entities/customFields/selectors';
import { CustomFieldType } from 'store/entities/customFields/model';

export interface IBreakInOutTimeFieldProps extends IInOutTimeFieldProps {}

const startKey = 'breakTimeIn';
const endKey = 'breakTimeOut';
const startKeyMain = 'timeIn';
const endKeyMain = 'timeOut';
const CaliforniaStateCode = 'CA';

export const BreakInOutTimeField = (props: IBreakInOutTimeFieldProps) => {
    const [field,, helper] = useField(props.name);
    const [disabled, setDisabled] = useState(false);
    const [isShowPopup, showPopup] = useState(false);
    const commonFields = useSelector(selectCommonEntryFormValues);
    const valuesByIds = useSelector(selectCustomFieldValuesByIds);
    const totalRef = useRef(null);
    const closePopup = useCallback(() => showPopup(false), [showPopup]);

    const fieldValue = useMemo(() => {
        return field.value ? field.value : {};
    }, [field.value]);
    const timeDuration = useMemo(() => {
        return calculateDurationFromStartEndValue(fieldValue[startKeyMain], fieldValue[endKeyMain]);
    }, [fieldValue]);
    const breakDuration = useMemo(() => {
        return calculateDurationFromStartEndValue(fieldValue[startKey], fieldValue[endKey]);
    }, [fieldValue]);

    useEffect(() => {
        // Disable break inputs if time less than 5 hours
        const timeDurationMilliseconds = timeDuration.asMilliseconds() || 0;
        if (timeDurationMilliseconds < moment.duration(5, 'hours').asMilliseconds()) {
            if (fieldValue[startKey] || fieldValue[endKey]) {
                helper.setValue({
                    ...fieldValue,
                    [startKey]: null,
                    [endKey]: null,
                });
            }
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    // eslint-disable-next-line
    }, [timeDuration, setDisabled, helper]);

    useEffectWithSkipInitialChange(() => {
        const location = Object.values(commonFields?.customFieldValues)
            .map(valueId => valuesByIds[valueId])
            .find(value => value?.data.headway_connect_field === CustomFieldType.Location);
        if (location?.data.state_code === CaliforniaStateCode) {
            showPopup(breakDuration.asMinutes() < moment.duration(30, 'minutes').asMinutes());
        }
    }, [breakDuration, showPopup, commonFields, valuesByIds]);

    return (
        <>
            <InOutTimeField
                {...props}
                disabled={disabled}
                fieldLabels={{
                    startKey,
                    startLabel: 'Meal Break Start',
                    endKey,
                    endLabel: 'Meal Break End',
                }}
                hideCommonError
                totalRef={totalRef}
            />
            <PopOver
                isOpened={isShowPopup}
                onClose={closePopup}
                anchorEl={totalRef.current}
            >
                <BreakCaRule />
            </PopOver>
        </>
    );
};
