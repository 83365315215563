import { IOfferLetterRow, IOfferLetterWithPdf } from 'modules/offerLetter/components/OfferLetterTable/cells/cells';
import {
    departmentCell,
    getActionCell,
    getOfferLetterPdfCell,
    getOfferLetterStatusCell,
    getStatusPayRateMobileCell,
    locationEmployeeCell,
    offerDateCell,
    positionEmployeeCell,
    startDateCell,
} from 'modules/offerLetter/components/OfferLetterTable/cells/commonCells';
import { OfferLetterCellKey } from 'modules/offerLetter/components/OfferLetterTable/cells/OfferLetterCellKey';
import { useOfferLetterEmployeeStyles } from 'modules/offerLetter/OfferLetterEmployee/OfferLetterEmployeeStyles';
import { OfferLetterStatusSlug } from 'modules/offerLetter/store/model';
import { getRejectReasonTitle, getRescissionReasonTitle } from 'modules/offerLetter/store/utils';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PlainText from 'shared/components/table/Cells/PlainText';
import MobileListCell from 'shared/components/table/MobileListCell/MobileListCell';
import { useTableStyles } from 'shared/styles/tableStyles';
import { formatShortDate } from 'shared/utils/formatters/dateFormatter';
import { formatPayRate } from 'shared/utils/formatters/payRate';
import { useIsMobile } from 'shared/utils/hooks/media';
import { IClient } from 'store/entities/clients/clientsModel';
import { selectPaySettingsByClientIds } from 'store/entities/clients/selectors/timeAndPaySelectors';

export interface IOfferLetterRowWithClient extends IOfferLetterRow {
    client?: IClient;
}

export function useEmployeeOfferCells(openDetail: (offerLetter: IOfferLetterWithPdf) => void) {
    const tableClasses = useTableStyles();
    const classes = useOfferLetterEmployeeStyles();
    const isMobile = useIsMobile();

    return isMobile ? [
        {
            key: OfferLetterCellKey.FullInfo,
            title: '',
            render: function FullInfoCell({
                className,
                offerLetter,
                positionDisplayValue,
                client,
            }: IOfferLetterRowWithClient) {
                const items = [
                    client?.name || '',
                    `Offer Date: ${formatShortDate(offerLetter.offer_date)}`,
                    `Start Date: ${formatShortDate(offerLetter.start_date)}`,
                ];

                if (
                    ([OfferLetterStatusSlug.Declined, OfferLetterStatusSlug.Rescinded] as Array<string>)
                        .includes(offerLetter.status.slug)
                ) {
                    items.push(`Reason: ${getRejectReasonTitle(offerLetter.rejection_reason)}`);
                } else if (OfferLetterStatusSlug.Expired === offerLetter.status.slug) {
                    items.push(`Expiration Date: ${formatShortDate(offerLetter.start_date)}`);
                }

                const mobileAction = useCallback(() => {
                    openDetail(offerLetter);
                }, [offerLetter]);

                return (
                    <MobileListCell
                        className={className}
                        listClassName={classes.mobileListWrapper}
                        title={positionDisplayValue}
                        items={items}
                        action={mobileAction}
                    />
                );
            },
        },
        getStatusPayRateMobileCell(classes, openDetail),
    ] : [
        positionEmployeeCell,
        {
            ...locationEmployeeCell,
            title: 'work location',
        },
        departmentCell,
        getOfferLetterStatusCell(tableClasses),
        {
            key: OfferLetterCellKey.Client,
            title: 'client',
            render: function ClientCell({ className, client }: IOfferLetterRowWithClient) {
                return (
                    <PlainText className={className} value={client?.name} />
                );
            },
        },
        offerDateCell,
        startDateCell,
        {
            key: OfferLetterCellKey.PayRate,
            title: 'pay rate',
            render: function PayRate({ className, offerLetter }: IOfferLetterRow) {
                const paySettingsByClientId = useSelector(selectPaySettingsByClientIds);
                const payRate = formatPayRate(
                    offerLetter,
                    paySettingsByClientId[offerLetter.client_id]?.pay_period_type,
                );
                return (
                    <PlainText className={className} value={payRate} />
                );
            },
        },
        getOfferLetterPdfCell(tableClasses, openDetail),
        {
            key: OfferLetterCellKey.Reason,
            title: 'reason',
            render: function LocationCell({ offerLetter, className }: IOfferLetterRow) {
                const rowLabel = (() => {
                    switch (offerLetter.status.slug) {
                        case OfferLetterStatusSlug.Rescinded:
                            return getRescissionReasonTitle(offerLetter);
                        case OfferLetterStatusSlug.Declined:
                            return getRejectReasonTitle(offerLetter.rejection_reason);
                        default:
                            return '';
                    }
                })();
                return (
                    <PlainText className={className} value={rowLabel} />
                );
            },
        },
        getActionCell(tableClasses),
    ];
}
