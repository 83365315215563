import { useGroupedSheetDetailStyles } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { ApproveOfferButton } from 'modules/offerLetter/components/ApproveOffer/ApproveOfferButton';
import { RejectOfferButton } from 'modules/offerLetter/components/RejectPayRangeOffer/RejectOfferButton';
import { isPayRateApprovalStatus } from 'modules/offerLetter/store/helpers';
import { selectCheckedOfferLetterRowsIds, selectOfferLetterActiveTab } from 'modules/offerLetter/store/selectors';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';

export const OfferLetterTableActions = () => {
    const selectedOfferIds = useSelector(selectCheckedOfferLetterRowsIds);
    const activeTab = useSelector(selectOfferLetterActiveTab);
    // @ts-ignore
    const isPayApproval = isPayRateApprovalStatus(activeTab);
    const userCanApprovePayRate = useSelector(selectIsUserHasPermission(Permission.ApprovePayRate));
    const classes = useGroupedSheetDetailStyles();

    if (isPayApproval && userCanApprovePayRate && selectedOfferIds.length) {
        return (
            <Box className={classes.actions} mr={2}>
                <ApproveOfferButton offerLetterIds={selectedOfferIds} />
                <RejectOfferButton offerLetterIds={selectedOfferIds} />
            </Box>
        );
    }
    return null;
};
