import { Box, Grid, GridProps } from '@material-ui/core';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import React from 'react';
import FormField from 'shared/components/formFields/FormField';
import { IAvionteJobOrderCustomField } from 'store/entities/customFields/model';
import { PositionToOfferLetterDataSection } from './PositionToOfferLetterDataSection';

const makePathTemplate = (path: string) => (prop: keyof IAvionteJobOrderCustomField) => `${path}${prop}`;

const gridItemProps: Partial<GridProps> = {
    sm: 6,
    xs: 12,
    item: true,
};

export const AvionteJobOrdderFieldValueDataSection: React.FC<{ path?: string }> = ({ path = '' }) => {
    const classes = useSettingsFormStyles();
    const withPath = makePathTemplate(path);

    return (
        <Box className={classes.formBlock}>

            <Grid container>

                <Grid {...gridItemProps}>
                    <FormField
                        name={withPath('title')}
                        label="Position"
                        className={classes.input}
                        disabled
                    />
                </Grid>

                <Grid {...gridItemProps}>
                    <FormField
                        name={withPath('job_id')}
                        label="Avionte Job ID"
                        className={classes.input}
                        disabled
                    />
                </Grid>

                <Grid {...gridItemProps}>
                    <FormField
                        name={withPath('company_name')}
                        label="Client Name"
                        className={classes.input}
                        disabled
                    />
                </Grid>

                <Grid {...gridItemProps}>
                    <FormField
                        name={withPath('company_id')}
                        label="Client ID"
                        className={classes.input}
                        disabled
                    />
                </Grid>

                <Grid {...gridItemProps} sm={12}>
                    <FormField
                        name={withPath('worksite_address')}
                        label="Worksite Address (Location)"
                        valueFormatter={parseWorksiteAddress}
                        className={classes.input}
                        disabled
                    />
                </Grid>
                <Grid {...gridItemProps} sm={12}>
                    <FormField
                        name={withPath('worksite_location_name')}
                        label="Worksite Location Name"
                        className={classes.input}
                    />
                </Grid>
                <PositionToOfferLetterDataSection path={ path } />
            </Grid>

        </Box>
    );
};

const parseWorksiteAddress = (address: IAvionteJobOrderCustomField['worksite_address']) => {
    if (!address) {
        return '';
    }

    return `${address.address1}${address.address2 ? `, ${address.address2}` : ''}, ${address.city}, ${address.county ? `${address.county}, ` : ''}${address.state} ${address.zip_code}`;
};
