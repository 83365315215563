import baseApi from 'shared/utils/baseApi';
import { clientConfigurationsService } from 'store/entities/configuration/configurationApi';
import {
    ICustomField,
    ICustomFieldValue,
    ICustomFieldValuesQueryRequest,
    ICustomFieldHierarchyWithLinkedResponse, IHierarchyRequest, ICustomFieldValuesExportRequest, IExportMappingField,
} from 'store/entities/customFields/model';

const mappingFieldsRoute = 'prism_field_types';
const customFieldsRoute = 'custom_fields';

export const customFieldsApi = {
    async getCustomFields(): Promise<ICustomField[]> {
        const { data } = await baseApi.get<{ custom_fields: ICustomField[] }>(
            `/${clientConfigurationsService}/${customFieldsRoute}`,
        );
        return data.custom_fields;
    },
    async getCustomFieldMappings(): Promise<IExportMappingField[]> {
        const { data } = await baseApi.get<{ avionte_export_types: IExportMappingField[] }>(
            `/${clientConfigurationsService}/${mappingFieldsRoute}`,
        );
        return data.avionte_export_types;
    },
    async getGlobalCustomFieldsHierarchyNodes(
        request?: IHierarchyRequest,
    ): Promise<ICustomFieldHierarchyWithLinkedResponse> {
        const { data } = await baseApi.get<ICustomFieldHierarchyWithLinkedResponse>(
            `/${clientConfigurationsService}/custom_fields_hierarchy/global`,
            request,
        );
        return data;
    },
    async getClientCustomFieldsHierarchyNodesWithLinked(
        clientId: string,
        request?: IHierarchyRequest,
    ): Promise<ICustomFieldHierarchyWithLinkedResponse> {
        const { data } = await baseApi.get<ICustomFieldHierarchyWithLinkedResponse>(
            `/${clientConfigurationsService}/clients/${clientId}/custom_fields_hierarchy`,
            request,
        );
        return data;
    },

    async queryCustomFieldValues(request: ICustomFieldValuesQueryRequest): Promise<ICustomFieldValue[]> {
        const { data } = await baseApi.post<
        ICustomFieldValuesQueryRequest, { custom_field_values: ICustomFieldValue[] }
        >(
            `/${clientConfigurationsService}/custom_field_values/query`,
            request,
        );
        return data.custom_field_values;
    },
    async exportCustomFieldValues(request: ICustomFieldValuesExportRequest): Promise<BlobPart> {
        const { data } = await baseApi.get<BlobPart>(
            `/${clientConfigurationsService}/custom_field_values/export`,
            request,
            { responseType: 'blob' },
        );
        return data;
    },
};
