import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import {
    getScopes,
    getUserCustomFieldRestrictions,
    getUserCustomFieldRestrictionsV2,
    getUsersMatchingScopes,
} from 'store/entities/scopes/actions';
import { scopesApi } from 'store/entities/scopes/api';
import {
    CustomFieldScopeRestrictionsByAction,
    IGroupedScopeCustomFieldValues,
    IUserMatchItemResult,
    ScopeType,
} from 'store/entities/scopes/models';
import { getLoadEntitiesByRequestSagaWatcher } from 'store/utils/sagas/getLoadEntitiesByRequestSagaWatcher';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { call, put, select, takeEvery } from 'typed-redux-saga';
import { FeatureSwitches } from 'utils/featureSwitches';
import { optimizely } from 'utils/optimizely';
import { uniq } from 'lodash-es';
import { usersApi } from '../users/api';
import { selectClientCreditCardPortalPageRegionalDirectorRoleId } from '../clients/selectors/configurationSelectors';
import { getUsers, getUsersWithClientRole } from '../users/actions';

const getScopesWatcher = getLoadEntitiesByRequestSagaWatcher(
    getScopes,
    scopesApi.getScopes,
    'scopes',
);

function normalizeGroupedFieldValuesByAction(
    clientId: string,
    groups: IGroupedScopeCustomFieldValues[],
): CustomFieldScopeRestrictionsByAction {
    return groups.filter(group => group.group.client_id === clientId).reduce(
        (mem, group) => {
            return {
                ...mem,
                [group.group.action]: {
                    ...mem[group.group.action],
                    [group.group.custom_field_id]: group.custom_field_value_ids,
                },
            };
        },
        {} as CustomFieldScopeRestrictionsByAction,
    );
}

function* getUserCustomFieldRestrictionsSaga({ payload }: ReturnType<typeof getUserCustomFieldRestrictions.init>) {
    const enableScopeActions = optimizely.isFeatureEnabled(FeatureSwitches.enableScopeActions);
    if (enableScopeActions) {
        const clientId = yield select(selectCurrentClientId);
        const groups = yield* call(scopesApi.getUserCustomFieldRestrictionsV2, {
            client_ids: [clientId],
            scope_types: payload.scope_types || [ScopeType.SubassignmentManagement],
        });
        yield put(getUserCustomFieldRestrictionsV2.success(normalizeGroupedFieldValuesByAction(clientId, groups)));
        yield put(getUserCustomFieldRestrictions.success({}));
    } else {
        const fieldScopes = yield* call(scopesApi.getUserCustomFieldRestrictions, payload || {});
        yield put(getUserCustomFieldRestrictions.success(fieldScopes));
    }
}

export function* getUserCustomFieldRestrictionsWatcher() {
    yield takeEvery(
        getUserCustomFieldRestrictions.initType,
        withBackendErrorHandler(
            getUserCustomFieldRestrictionsSaga,
            getUserCustomFieldRestrictions.error,
            `Unable to fetch user field scopes`,
        ),
    );
}

function* getUsersMatchingScopesSaga({ payload }: ReturnType<typeof getUsersMatchingScopes.init>) {
    const enableRegionalDirectorField = optimizely.isFeatureEnabled(FeatureSwitches.enableRegionalDirectorField);
    if (enableRegionalDirectorField) {
        const data = yield* call(scopesApi.getUsersMatchingUsersScopes, payload);
        const regionalDirectorRoleId = yield select(selectClientCreditCardPortalPageRegionalDirectorRoleId);
        const matchedLists = data
            .map(x => x.matched_ids ?? [])
            .filter(x => x.length > 0);
        const matchedUserIds = matchedLists?.length > 0 ? uniq(matchedLists
            .reduce((resultState, x) => {
                return [...(x ?? []), ...(resultState ?? [])];
            })) : [];
        if (matchedUserIds.length) {
            const users = yield* call(usersApi.getUsersWithoutPagination, {
                ids: matchedUserIds.join(','),
                client_role_id: regionalDirectorRoleId,
                client_id: payload.client_id,
            });
            const filteredUserIds = users.map(x => x.id);
            const matchesWithRequestedRoles = data.map(x => {
                return {
                    id: x.user_id,
                    user_id: x.user_id,
                    matched_ids: (x.matched_ids ?? []).filter(y => (filteredUserIds ?? []).includes(y)),
                } as IUserMatchItemResult;
            });
            yield put(getUsersWithClientRole.success({
                client_role_id: regionalDirectorRoleId ?? '',
                user_ids: filteredUserIds,
            }));
            yield put(getUsers.success(users));
            yield put(getUsersMatchingScopes.success(matchesWithRequestedRoles));
        } else {
            yield put(getUsersMatchingScopes.success([]));
        }
    } else {
        yield put(getUsersMatchingScopes.success([]));
    }
}

export function* getUsersMatchingScopesWatcher() {
    yield takeEvery(
        getUsersMatchingScopes.initType,
        withBackendErrorHandler(
            getUsersMatchingScopesSaga,
            getUsersMatchingScopes.error,
            `Unable to fetch matching user pairs`,
        ),
    );
}

export default [
    getScopesWatcher,
    getUserCustomFieldRestrictionsWatcher,
    getUsersMatchingScopesWatcher,
];
