import React, { useCallback, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { Box } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { FormikProvider, useFormik } from 'formik';
import { isEqual } from 'lodash-es';
import { useInlineFormStyles } from 'modules/settings/submodules/components/sharedStyles/inlineFormStyles';
import {
    IClientRoleFilter,
} from 'modules/settings/submodules/platformUsers/store/models';
import { useSelector, useDispatch } from 'react-redux';
import {
    setClientRolesFilter,
} from 'modules/settings/submodules/platformUsers/store/actions';
import {
    selectClientRoleFilter,
} from 'modules/settings/submodules/platformUsers/store/selectors';
import { useTextFilterStyles } from 'shared/components/filters/TextFilterStyles';
import FormField from 'shared/components/formFields/FormField';
import ClientSelect from 'shared/components/selects/ClientSelect';
import { debounce } from 'ts-debounce';
import { textInputDebounceTimeout } from 'utils/constants';

export function ClientRolesFilter() {
    const classes = useInlineFormStyles();
    const textFilterClasses = useTextFilterStyles();
    const dispatch = useDispatch();

    const filter = useSelector(selectClientRoleFilter);
    const formikData = useFormik<IClientRoleFilter>({
        initialValues: filter,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onSubmit: () => {},
    });

    const handleFilterApply = useCallback((values: IClientRoleFilter) => {
        dispatch(setClientRolesFilter(values));
    }, [dispatch]);
    const onChangeDebounced = useMemo(() => {
        return debounce(handleFilterApply, textInputDebounceTimeout);
    }, [handleFilterApply]);

    const { values } = formikData;
    useEffect(() => {
        if (!isEqual(values, filter)) {
            onChangeDebounced(values);
        }
    }, [filter, values, onChangeDebounced]);

    return (
        <Box
            display="flex"
            justifyContent="flex-end"
        >
            <FormikProvider value={formikData}>
                <form className={clsx(classes.form, classes.flexEnd)}>
                    <FormField
                        name="search"
                        placeholder="Search"
                        className={clsx(classes.input, textFilterClasses.filter)}
                        inputProps={{
                            placeholder: 'Search',
                            style: { cursor: 'pointer' },
                        }}
                        endAdornment={<Search />}
                    />
                    <ClientSelect
                        name="client_id"
                        label="Client"
                        className={classes.input}
                        useIdValue
                    />
                </form>
            </FormikProvider>
        </Box>
    );
}
