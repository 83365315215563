import React from 'react';

import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { useIsMobile } from 'shared/utils/hooks/media';
import {
    PayrollSheetVirtualTable,
} from 'modules/payrollProcessorHub/components/PayrollSheetTable/PayrollSheetVirtualTable';
import { IPayrollSheetGroupedRow } from 'modules/payrollProcessorHub/components/PayrollSheetTable/model';
import InfinityGridTable, { IInfinityGridTableProps } from 'shared/components/table/GridTable/InfinityGridTable';
import { FeatureSwitches } from 'utils/featureSwitches';
import { useFeature } from '@optimizely/react-sdk';

export interface IPayrollSheetTableProps<RowData extends IPayrollSheetGroupedRow>
    extends Omit<IInfinityGridTableProps<RowData>, 'getKey'> {
    totalCount?: number;
}

export function PayrollSheetTablePure<RowData extends IPayrollSheetGroupedRow>(
    props: IPayrollSheetTableProps<RowData>,
) {
    const isMobile = useIsMobile();
    const tableClasses = useDefaultTableStyles();
    const isFullTableLoading = props.isLoading && props.rowData.length === 0;

    const [enableVirtualizationForPphTable] = useFeature(FeatureSwitches.enableVirtualizationForPphTable);

    if (enableVirtualizationForPphTable) {
        return (
            <PayrollSheetVirtualTable
                {...props}
            />
        );
    }

    return (
        <InfinityGridTable
            {...props}
            getKey={row => row.id}
            isLoading={isFullTableLoading}
            headerCellClassName={tableClasses.headCell}
            bodyCellClassName={tableClasses.bodyCell}
            hideHeader={isMobile}
            stickyHeader
        />
    );
}
