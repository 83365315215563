import { IDeal } from 'shared/models/Deal';
import { IEntity, INamedEntity } from 'shared/models/Entity';

export enum UWSystem {
    Enact = 'enact',
    ContractClient = 'contract_client',
}

export enum TimesheetSettings {
    Hourly = 'hourly',
    PerFile = 'per_file',
}

export enum FeeType {
    Daily = 'daily',
    Blended = 'blended',
}

export enum JobNumberGroup {
    Headway = 'headway',
    Genworth = 'genworth',
    Enact = 'enact',
}

export const JobNumberGroupDisplayValues: Record<JobNumberGroup, string> = {
    [JobNumberGroup.Headway]: 'Headway',
    [JobNumberGroup.Genworth]: 'Genworth',
    [JobNumberGroup.Enact]: 'Enact',
};

export enum ReasonForClose {
    AcceptedPermPositionInIndustry = 'accepted_perm_position_in_industry',
    AcceptedPermPositionOutsideIndustry = 'accepted_perm_position_outside_industry',
    AcceptedPositionUndisclosed = 'accepted_position_undisclosed',
    AssignmentTermComplete = 'assignment_term_completed',
    JobPerformanceQualityIssues = 'job_performance_quality_issues',
    JobPerformanceAttendance = 'job_performance_attendance',
    JobPerformanceProductivity = 'job_performance_productivity',
    JobPerformanceMiscellaneous = 'job_performance_miscellaneous',
    ChangeInApprovingMgr = 'change_in_approving_mgr',
    ChangeInBilling = 'change_in_billing',
    ChangeInPayRate = 'change_in_pay_rate',
    ChangeInOrgNumber = 'change_in_org_number',
    ChangeInCostCenter = 'change_in_cost_center',
    ChangeInHourlyToPerFile = 'change_in_hourly_to_per_file',
    ClientsPreEmploymentScreeningFailed = 'clients_pre_employment_screening_failed',
    ClientRequestGenworthRelationshipEnded = 'client_request_genworth_relationship_ended',
    ClientRequestProductivity = 'client_request_productivity',
    ClientRequestBusinessDecision = 'client_request_business_decision',
    ClientClosedOffice = 'client_closed_office',
    ConflictOfInterest = 'conflict_of_interest',
    DeclinedOffer = 'declined_offer',
    DuplicateJob = 'duplicate_job',
    FailedBackgroundCheck = 'failed_background_check',
    GenworthOfferRescinded = 'genworth_offer_rescinded',
    HiredByGenworth = 'hired_by_genworth',
    HiredByGenworthClient = 'hired_by_genworth_client',
    HiredByHeadway = 'hired_by_headway',
    JobAbandonment = 'job_abandonment',
    MedialLeave = 'medial_leave',
    NeverStarted = 'never_started',
    NotQualifiedExperience = 'not_qualified_experience',
    PassedAway = 'passed_away',
    Retiring = 'retiring',
    Resigned = 'resigned',
    TestingForIT = 'testing_for_it',
    TransferToOtherDept = 'transfer_to_other_dept',
    VolumeReduction = 'volume_reduction',
    IncorrectSetup = 'incorrect_setup',
}

export interface ITimeActivity extends IEntity {
    activity_id: string;
    job_number_id: string;
}

export interface IJobNumberUserType extends INamedEntity {
    client_id: string;
    avionte_job_id?: string;
}

export interface IJobNumber extends IEntity {
    job_number: string;
    description: string;
    client_id: string;
    job_number_group: JobNumberGroup | null;
    deal_id: string;
    start_date: string;
    end_date?: string;
    manager_id: string;
    user_type_id: string;
    fte: string;
    uw_system: UWSystem;
    timesheet_setting: TimesheetSettings;
    close_on_date: string;
    reason_for_close?: ReasonForClose;
    time_activities?: ITimeActivity[];

    fee_type?: FeeType;
    billing_min: string | null;
    billing_max: string | null;
    mi_credit: string | null;
    blended_amount: string | null;

    is_per_diem: boolean;

    per_file_rate?: string | null;
    hourly_pay_rate?: string | null;
    per_diem_rate?: string | null;

    per_file_ot_rate?: string | null;
    per_diem_ot_rate?: string | null;

    user_id: string;
}

export interface IJobNumberBackend extends IJobNumber {
    deal: IDeal;
}

export const UWSystemDisplayValues: Record<UWSystem, string> = {
    [UWSystem.Enact]: 'Enact',
    [UWSystem.ContractClient]: 'Contract Client',
};

export const TimesheetSettingsDisplayValues: Record<TimesheetSettings, string> = {
    [TimesheetSettings.Hourly]: 'Hourly',
    [TimesheetSettings.PerFile]: 'Per File',
};

export const ReasonForCloseDisplayValues: Record<ReasonForClose, string> = {
    [ReasonForClose.AcceptedPermPositionInIndustry]: 'Accepted Perm Position in Industry',
    [ReasonForClose.AcceptedPermPositionOutsideIndustry]: 'Accepted Perm Position outside industry',
    [ReasonForClose.AcceptedPositionUndisclosed]: 'Accepted Position (Undisclosed)',
    [ReasonForClose.AssignmentTermComplete]: 'Assignment Term Complete',
    [ReasonForClose.JobPerformanceQualityIssues]: 'Job Performance (Quality Issues)',
    [ReasonForClose.JobPerformanceAttendance]: 'Job Performance (Attendance)',
    [ReasonForClose.JobPerformanceProductivity]: 'Job Performance (Productivity)',
    [ReasonForClose.JobPerformanceMiscellaneous]: 'Job Performance (Miscellaneous)',
    [ReasonForClose.ChangeInApprovingMgr]: 'Change in Approving Mgr.',
    [ReasonForClose.ChangeInBilling]: 'Change in Billing',
    [ReasonForClose.ChangeInPayRate]: 'Change in Pay Rate',
    [ReasonForClose.ChangeInOrgNumber]: 'Change in Org Number',
    [ReasonForClose.ChangeInCostCenter]: 'Change in Cost Center',
    [ReasonForClose.ChangeInHourlyToPerFile]: 'Change in Hourly to Per File',
    [ReasonForClose.ClientsPreEmploymentScreeningFailed]: 'Client’s Pre Employment Screening failed',
    [ReasonForClose.ClientRequestGenworthRelationshipEnded]: 'Client Request – Genworth Relationship ended',
    [ReasonForClose.ClientRequestProductivity]: 'Client Request, Productivity',
    [ReasonForClose.ClientRequestBusinessDecision]: 'Client Request - Business Decision',
    [ReasonForClose.ClientClosedOffice]: 'Client Closed Office',
    [ReasonForClose.ConflictOfInterest]: 'Conflict of Interest',
    [ReasonForClose.DeclinedOffer]: 'Declined Offer',
    [ReasonForClose.DuplicateJob]: 'Duplicate Job',
    [ReasonForClose.FailedBackgroundCheck]: 'Failed Background Check',
    [ReasonForClose.GenworthOfferRescinded]: 'Genworth – Offer Rescinded',
    [ReasonForClose.HiredByGenworth]: 'Hired by Genworth',
    [ReasonForClose.HiredByGenworthClient]: 'Hired by Genworth Client',
    [ReasonForClose.HiredByHeadway]: 'Hired by Headway',
    [ReasonForClose.JobAbandonment]: 'Job Abandonment',
    [ReasonForClose.MedialLeave]: 'Medial Leave',
    [ReasonForClose.NeverStarted]: 'Never Started',
    [ReasonForClose.NotQualifiedExperience]: 'Not Qualified Experience',
    [ReasonForClose.PassedAway]: 'Passed Away',
    [ReasonForClose.Retiring]: 'Retiring',
    [ReasonForClose.Resigned]: 'Resigned',
    [ReasonForClose.TestingForIT]: 'Testing for IT',
    [ReasonForClose.TransferToOtherDept]: 'Transfer to Other Dept.',
    [ReasonForClose.VolumeReduction]: 'Volume Reduction',
    [ReasonForClose.IncorrectSetup]: 'Incorrect Setup',
};

export const FeeTypeDisplayValues: Record<FeeType, string> = {
    [FeeType.Daily]: 'Daily',
    [FeeType.Blended]: 'Blended Fee',
};
