import React, { useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { FormikProps } from 'formik';
import { Box, ThemeProvider, Typography } from '@material-ui/core';
import { useEntryEditStyles } from 'shared/components/sidebars/EntryEdit/styles';
import FormField from 'shared/components/formFields/FormField';
import { sidebarDarkSectionTheme as darkTheme } from 'shared/components/sidebars/EntryEdit/sidebarDarkSectionTheme';
import { IWithInputFields } from 'shared/components/forms/utils';
import { UserAutocompleteWithSearch } from 'shared/components/autocomplete/UserAutocompleteWithSearch/UserAutocompleteWithSearch';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { JobNumbers } from 'shared/components/selects/JobNumbers';
import { FormFieldWithSeparateLabel } from 'modules/settings/common/components/FormFieldWithSeparateLabel';
import { TravelExpenseTypeSelect } from 'modules/travelExpenses/components/TravelExpenseFilter/TravelExpenseTypeSelect';
import { EntryType, IAmountEntryData } from 'shared/models/sheet/Sheet';
import MoneyDataField from 'shared/components/formSpecialFields/expenseData/MoneyDataField';
import { UserType } from 'store/entities/users/model';
import { WeekEndingField } from 'modules/travelExpenses/components/SidebarForm/WeekEndingField';
import { getSheetsPayPeriod } from 'store/entities/timesheet/actions/sheets';
import { selectUserById } from 'store/entities/users/selectors';
import { getUserName } from 'shared/utils/converters/user';
import { useJobNumberDetails, useTravelDatePayPeriod } from 'modules/travelExpenses/components/SidebarForm/hooks';
import { TravelDateField } from 'modules/travelExpenses/components/SidebarForm/TravelDateField';
import {
    selectDealsById,
    selectDealTypesById,
    selectSubmittingOrgsById,
} from 'store/entities/configuration/configurationSelectors';
import { IJobNumber } from 'shared/models/JobNumber';

export interface ITravelExpenseSidebarFormValues {
    employeeId: string,
    jobNumberId: string,
    expenseTypeId: string,
    travelDate: string,
    weekEnding: string,
    notes: string,
    data: IAmountEntryData | null,
}

export interface ITravelExpenseSidebarFormProps
    extends FormikProps<ITravelExpenseSidebarFormValues>, IWithInputFields {
    entryId?: string;
    isEdit?: boolean;
}

export function TravelExpenseSidebarForm({
    handleSubmit,
    values,
    setFieldValue,
    isEdit = false,
}: ITravelExpenseSidebarFormProps) {

    const classes = useEntryEditStyles();
    const currentClientId = useSelector(selectCurrentClientId);

    const { employeeId, jobNumberId, travelDate } = values;
    const employee = useSelector(selectUserById(employeeId));
    const jobNumberDetails = useJobNumberDetails(jobNumberId);
    const dispatch = useDispatch();
    useEffect(() => {
        if (employeeId){
            dispatch(getSheetsPayPeriod.init({ employeeId }));
        }
    }, [dispatch, employeeId]);

    const travelDatePayPeriod = useTravelDatePayPeriod(travelDate);
    useEffect(() => {
        setFieldValue('weekEnding', travelDatePayPeriod?.period_end || '');
    }, [travelDatePayPeriod, setFieldValue]);

    const dealsById = useSelector(selectDealsById);
    const submittingOrgsById = useSelector(selectSubmittingOrgsById);

    const jobNumbersGetText = useCallback(({ job_number, deal_id }) => {
        const deal = dealsById && dealsById[deal_id || ''];
        const submittingOrg = submittingOrgsById && submittingOrgsById[deal?.submitting_org_id || ''];
        return `${job_number} - ${submittingOrg?.client_site_name}`;
    }, [dealsById, submittingOrgsById]);

    const dealTypesById = useSelector(selectDealTypesById);

    const jobNumbersFilter = useCallback((jobNumber: IJobNumber) => {
        const deal = dealsById[jobNumber?.deal_id || ''];
        const dealType = dealTypesById[deal?.type_id || ''];
        return Boolean(dealType?.billing_type);
    }, [dealTypesById, dealsById]);

    return (
        <form
            onSubmit={handleSubmit}
            className={classes.form}
            autoComplete="off"
        >
            <Box p={2}>
                <Box p={2}>
                    {
                        isEdit
                            ? (
                                <FormField
                                    name="employeeName"
                                    label="Employee"
                                    defaultValue={getUserName(employee)}
                                    disabled
                                    className={classes.formInput}
                                />
                            )
                            : (
                                <UserAutocompleteWithSearch
                                    additionalFilter={{
                                        client_id: currentClientId || undefined,
                                        user_type: UserType.Employee,
                                    }}
                                    name="employeeId"
                                    label="Search Employee"
                                    useIdValue
                                    className={classes.formInput}
                                />
                            )
                    }
                </Box>
                <Box p={2}>
                    <FormFieldWithSeparateLabel
                        name="jobNumberId"
                        outerLabel="Job Number:"
                        label="--"
                        FieldComponent={JobNumbers}
                        userId={values.employeeId}
                        useIdValue
                        getText={jobNumbersGetText}
                        filter={jobNumbersFilter}
                        className={classes.formInputWithLabel}
                    />
                </Box>
                <Box p={2}>
                    <FormFieldWithSeparateLabel
                        name="expenseTypeId"
                        outerLabel="Expense Type:"
                        label="--"
                        FieldComponent={TravelExpenseTypeSelect}
                        className={classes.formInputWithLabel}
                        useIdValue
                    />
                </Box>
                <Box p={2}>
                    <TravelDateField jobNumberId={jobNumberId} />
                </Box>
                <Box p={2}>
                    <WeekEndingField />
                </Box>
                <Box p={2}>
                    <FormField
                        name="notes"
                        label="Travel Description"
                        className={classes.formInput}
                    />
                </Box>
            </Box>
            <section className={clsx(classes.entryDataEdit, classes.entityEdit)}>
                <ThemeProvider theme={darkTheme}>
                    <Typography color="primary" variant="h6">
                        Expense Amount
                    </Typography>
                    <MoneyDataField
                        name="data"
                        key={EntryType.EXPENSE}
                        className={classes.expenseDataEdit}
                        label="$"
                    />
                </ThemeProvider>
            </section>
            {
                jobNumberId && (
                    <Box pl={2}>
                        <Box p={1}>
                            <label>Deal Number:</label>
                            <Box component={'span'} ml={2}>{ jobNumberDetails.dealNumber }</Box>
                        </Box>
                        <Box p={1}>
                            <label>Deal Type:</label>
                            <Box component={'span'} ml={2}>{ jobNumberDetails.dealTypeName }</Box>
                        </Box>
                        <Box p={1}>
                            <label>Submitting Org:</label>
                            <Box component={'span'} ml={2}>{ jobNumberDetails.submittingOrgName }</Box>
                        </Box>
                        <Box p={1}>
                            <label>Invoice Recipient:</label>
                            <Box component={'span'} ml={2}>{ jobNumberDetails.invoiceRecipientName }</Box>
                        </Box>
                        <Box p={1}>
                            <label>Cost Center:</label>
                            <Box component={'span'} ml={2}>{ jobNumberDetails.costCenterName }</Box>
                        </Box>
                    </Box>
                )
            }
        </form>
    );
}
