import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useFeature } from '@optimizely/react-sdk';
import { OpenModalButton } from 'modules/settings/common/components/OpenModalButton/OpenModalButton';
import { setCreateClientRoleModalState } from 'modules/settings/submodules/platformUsers/store/actions';
import { useDispatch } from 'react-redux';
import MainContent from 'shared/components/common/MainContent';
import { loadClientRoles } from 'store/entities/users/actions';
import { CreateUserModal } from './components/CreateClientRole/CreateUserModal';
import { EditClientRoleModal } from './components/EditClientRole/EditClientRole';
import { ClientRolesTable } from './components/ClientRoleTable/ClientRolesTable';
import { ClientRolesFilter } from './components/ClientRolesFilter';
import { FeatureSwitches } from 'utils/featureSwitches';

export const ClientRolesAndPermissionsPage = () => {
    const dispatch = useDispatch();
    const [enableClientRoleManagement] = useFeature(FeatureSwitches.enableClientRoleManagement);
    const [enableClientRoleFilter] = useFeature(FeatureSwitches.enableClientRoleFilter);

    useEffect(() => {
        dispatch(loadClientRoles.init());
    }, [dispatch]);

    return (
        <MainContent whiteBackground>
            <Box
                mb={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                {enableClientRoleManagement && (
                    <OpenModalButton openAction={setCreateClientRoleModalState} />
                )}
                {enableClientRoleFilter && (
                    <ClientRolesFilter />
                )}
            </Box>
            <ClientRolesTable />
            <CreateUserModal />
            <EditClientRoleModal />
        </MainContent>
    );
};
