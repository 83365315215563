import React from 'react';
import MainContent from 'shared/components/common/MainContent';
import { ManagersTable } from 'modules/users/managers/components/UserRolesTable/ManagersTable';
import useTitleUpdate from 'shared/utils/hooks/useTitleUpdate';

export const ManagersListPage = () => {
    useTitleUpdate('Managers');
    return (
        <MainContent>
            <ManagersTable />
        </MainContent>
    );
};
