import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box } from '@material-ui/core';
import { EcubStatuses } from 'modules/settings/submodules/clients/reports/Ecub/EcubStatuses';
import { loadEcubReportItems } from 'modules/settings/submodules/clients/reports/Ecub/store/actions';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';
import { ALL_STATUS_SLUG } from 'modules/settings/submodules/clients/reports/Ecub/store/models';
import { selectEcubReportActiveTab } from 'modules/settings/submodules/clients/reports/Ecub/store/selectors';
import { EcubReportsTable } from 'modules/settings/submodules/clients/reports/Ecub/EcubReportsTable';
import { WarningAlertWithIcon } from 'shared/components/alerts/WarningAlertWithIcon';

export const EcubReport = () => {
    const dispatch = useDispatch();
    const activeStatus = useSelector(selectEcubReportActiveTab);
    useWithClientEffect(() => {
        dispatch(loadEcubReportItems.init({
            status: activeStatus !== ALL_STATUS_SLUG ? activeStatus : undefined,
        }));
    }, [dispatch, activeStatus]);

    return (
        <Box
            display="flex"
            flexGrow={1}
            flexDirection="column"
        >
            <Typography variant="h3">eCub report</Typography>
            <Box mb={1} mt={1}>
                <WarningAlertWithIcon>
                    The latest report will automatically send at 2pm EST every Friday unless paused.
                    If it stays paused past 2pm, you must manually send it out below.
                </WarningAlertWithIcon>
            </Box>
            <EcubStatuses />
            <EcubReportsTable />
        </Box>
    );
};
