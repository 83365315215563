import { Box } from '@material-ui/core';
import { IUserRow, useUserCellDictionary } from 'modules/settings/submodules/components/SharedTableRows/UsersRows';
import { EditEmployeeModal } from 'modules/settings/submodules/employees/components/EditEmployee/EditEmployeeModal';
import { EmployeeTable } from 'modules/users/employees/components/UserListPage/EmployeeTable/EmployeeTable';
import EmployeeFilter from 'modules/users/employees/components/UserListPage/Filter/EmployeeFilter';
import { EmployeeNameFilter } from 'modules/users/employees/components/UserListPage/Filter/EmployeeNameFilter';
import EmployeeChips from 'modules/users/employees/components/UserListPage/FilterChips/EmployeeChips';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import MainContent from 'shared/components/common/MainContent';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { loadGlobalRoles } from 'store/entities/users/actions';

export const EmployeeListPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadGlobalRoles.init());
    }, [dispatch]);
    const userCellsDictionary = useUserCellDictionary();
    const userCells: ICellInfo<IUserRow>[] = useMemo(() => [
        userCellsDictionary.name,
        userCellsDictionary.prismId,
        userCellsDictionary.avionteId,
        userCellsDictionary.email,
        userCellsDictionary.created,
        userCellsDictionary.updated,
        userCellsDictionary.statusReadonly,
        userCellsDictionary.state,
        userCellsDictionary.actions,
    ], [userCellsDictionary]);

    return (
        <>
            <MainContent whiteBackground>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    mb={2}
                >
                    <Box display="flex">
                        <EmployeeNameFilter />
                        <EmployeeFilter />
                    </Box>
                </Box>
                <EmployeeChips />
                <EmployeeTable cells={userCells} />
                <EditEmployeeModal />
            </MainContent>
        </>
    );
};
