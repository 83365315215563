/* eslint-disable react/display-name */
import React from 'react';
import clsx from 'clsx';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { useSelector } from 'react-redux';
import PlainText from 'shared/components/table/Cells/PlainText';

import {
    selectControllingOrgsById,
    selectDealTypesById,
    selectSubmittingOrgsById,
} from 'store/entities/configuration/configurationSelectors';

import { Box } from '@material-ui/core';
import { IInvoiceRecipient, RecipientType } from 'shared/models/Deal';

import { UpdateDealStatusSelect } from 'modules/settings/submodules/clients/deals/components/UpdateStatusSelect/UpdateDealStatusSelect';
import moment from 'moment';
import { shortDateFormat } from 'shared/models/Dates';
import { ItemsById } from 'shared/models/ItemsById';
import { IControllingOrg } from 'shared/models/ControllingOrg';
import { ISubmittingOrg } from 'shared/models/SubmittingOrg';
import { formatBNumber } from 'shared/utils/formatters/bNumber';
import { EditItemButton } from 'modules/settings/common/components/EditItemButton/EditItemButton';
import { setEditDealId } from 'modules/settings/submodules/clients/deals/components/EditDeal/store/actions';
import { SettingsInfinityTablePure } from 'modules/settings/common/components/SettingsTablePure/SettingsInfinityTablePure';
import { dealsInfinityTableSelectors, IDealRow, selectDealsRows } from 'modules/settings/submodules/clients/deals/components/DealsTable/store/selectors';
import { getMoreDealsAction, initialLoadDealsPage } from 'modules/settings/submodules/clients/deals/store/actions';

export const DealsTable = () => {
    const tableClasses = useDefaultTableStyles();

    const submittingOrgsById = useSelector(selectSubmittingOrgsById);
    const controllingOrgsById = useSelector(selectControllingOrgsById);
    const dealTypesById = useSelector(selectDealTypesById);

    const cells = [
        {
            key: 'deal_number',
            title: 'DEAL #',
            render: ({ deal_number, className }: IDealRow) => (
                <PlainText className={className} value={String(deal_number)} />
            ),
        },
        {
            key: 'deal_type',
            title: 'DEAL TYPE',
            render: ({ type_id, className }: IDealRow) => {
                const dealType = dealTypesById[type_id];
                return (
                    <PlainText className={ className } value={ dealType?.name } />
                );
            },
        },
        {
            key: 'controlling_org_name',
            title: 'CTRL ORG',
            render: ({ className, controlling_org_id }: IDealRow) => {
                const controllingOrg = controllingOrgsById[controlling_org_id];
                return (
                    <PlainText className={ className } value={ controllingOrg?.name } />
                );
            },
        },
        {
            key: 'controllingOrg_b_number',
            title: 'CTRL ORG #',
            render: ({ className, controlling_org_id }: IDealRow) => {
                const controllingOrg = controllingOrgsById[controlling_org_id];
                return (
                    <PlainText className={ className } value={ formatBNumber(controllingOrg?.b_number) } />
                );
            },
        },
        {
            key: 'submittingOrg_name',
            title: 'SUB ORG',
            render: ({ className, submitting_org_id }: IDealRow) => {
                const submittingOrg = submittingOrgsById[submitting_org_id];
                return (
                    <PlainText className={ className } value={ submittingOrg?.client_site_name } />
                );
            },
        },
        {
            key: 'submittingOrg_number',
            title: 'SUB ORG #',
            render: ({ className, submitting_org_id }: IDealRow) => {
                const submittingOrg = submittingOrgsById[submitting_org_id];
                return (
                    <PlainText className={ className } value={ formatBNumber(submittingOrg?.submitting_org_number) } />
                );
            },
        },
        {
            key: 'invoiceOrg_number',
            title: 'INVOICE ORG #',
            render: ({ className, recipient }: IDealRow) => {
                const recipientNumber = getRecipientNumber(recipient, controllingOrgsById, submittingOrgsById);
                return (
                    <PlainText className={ className } value={recipientNumber} />
                );
            },
        },
        {
            key: 'CITY',
            title: 'CITY',
            render: ({ className, submitting_org_id }: IDealRow) => {
                const submittingOrg = submittingOrgsById[submitting_org_id];
                return (
                    <PlainText className={ className } value={ submittingOrg?.city } />
                );
            },
        },
        {
            key: 'STATE',
            title: 'STATE',
            render: ({ className, submitting_org_id }: IDealRow) => {
                const submittingOrg = submittingOrgsById[submitting_org_id];
                return (
                    <PlainText className={ className } value={ submittingOrg?.state } />
                );
            },
        },
        {
            key: 'CREATED',
            title: 'CREATED',
            render: ({ className, created_at }: IDealRow) => (
                <PlainText className={className} value={moment(created_at).format(shortDateFormat)} />
            ),
        },
        {
            key: 'status',
            title: 'status',
            render: function StatusCell({ id, status, className }: IDealRow) {
                return (
                    <Box className={className}>
                        <UpdateDealStatusSelect id={id} status={status}
                            className={tableClasses.statusSelect} />
                    </Box>
                );
            },
        },
        {
            key: 'actions',
            title: '',
            width: '100px',
            render: function ActionsCell({ id, className }: IDealRow) {
                return (
                    <div className={clsx(className, tableClasses.iconCell, tableClasses.detailsIcon)}>
                        <EditItemButton id={id} editAction={setEditDealId} />
                    </div>
                );
            },
        },
    ];

    return (
        <SettingsInfinityTablePure
            infinityTableSelector={dealsInfinityTableSelectors}
            rowsSelector={selectDealsRows}
            initialLoadAction={initialLoadDealsPage}
            getMoreAction={getMoreDealsAction.init}
            cells={cells}
        />
    );
};

function getRecipientNumber(
    recipient: IInvoiceRecipient,
    controllingOrgsById: ItemsById<IControllingOrg>,
    submittingOrgsById: ItemsById<ISubmittingOrg>){
    if (recipient.recipient_type === RecipientType.ControllingOrg){
        return formatBNumber(controllingOrgsById[recipient.recipient_id]?.b_number) || '';
    }
    if (recipient.recipient_type === RecipientType.SubmittingOrg){
        return formatBNumber(submittingOrgsById[recipient.recipient_id]?.submitting_org_number) || '';
    }
    return '';
}
