import { useFeature } from '@optimizely/react-sdk';
import { EditPayrollGroupedSheetPage } from 'modules/payrollProcessorHub/components/EditSheet/EditPayrollGroupedSheetPage';
import { selectEditCalculationGroupId } from 'modules/payrollProcessorHub/store/selectors';
import React, { useEffect, useMemo } from 'react';
import { Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MainContainer from 'shared/components/common/MainContainer';
import { routes } from 'shared/routes';
import { Box } from '@material-ui/core';
import { PayrollMissingSheetsTablePage } from 'modules/payrollProcessorHub/components/PayrollSheetsTablePage/PayrollMissingSheetsTablePage';
import { PayrollOverdueSheetsTablePage } from 'modules/payrollProcessorHub/components/PayrollSheetsTablePage/PayrollOverdueSheetsTablePage';
import { loadGroupedSheets } from 'modules/payrollProcessorHub/store/actions';
import { useActivePayrollSheetStatus } from 'modules/payrollProcessorHub/store/helpers';
import { PayrollSheetTabs } from 'modules/payrollProcessorHub/store/model';
import { setHeaderContent } from 'modules/home/header/Header';
import MainContent from 'shared/components/common/MainContent';
import useTitleUpdate from 'shared/utils/hooks/useTitleUpdate';
import PayrollSheetStatusTabs from 'modules/payrollProcessorHub/components/PayrollSheetStatusTabs/PayrollSheetStatusTabs';
import { PayrollSheetsTablePage } from 'modules/payrollProcessorHub/components/PayrollSheetsTablePage/PayrollSheetsTablePage';
import PayrollSheetDetailSidebar
    from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/PayrollSheetDetailSidebar';
import { PayrollCalculationDetailGroupedSidebar } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/PayrollCalculationDetailGroupedSidebar';
import { FeatureSwitches } from 'utils/featureSwitches';

export const PayrollSheets = () => {
    const editGroupId = useSelector(selectEditCalculationGroupId);
    useTitleUpdate('Time and Expense Sheets');
    useEffect(() => {
        setHeaderContent(
            <Box display="flex" alignItems="flex-end">
                <PayrollSheetStatusTabs />
            </Box>,
        );

        return () => {
            setHeaderContent(null);
        };
    }, [editGroupId]);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadGroupedSheets());
    }, [dispatch]);

    const activeStatus = useActivePayrollSheetStatus();
    const page = useMemo(() => {
        switch (activeStatus) {
            case PayrollSheetTabs.MISSING:
                return <PayrollMissingSheetsTablePage />;
            case PayrollSheetTabs.OVERDUE:
                return <PayrollOverdueSheetsTablePage />;
            default:
                return <PayrollSheetsTablePage />;
        }
    }, [activeStatus]);

    const [enableVirtualizationForPphTable] = useFeature(FeatureSwitches.enableVirtualizationForPphTable);
    const Wrapper = enableVirtualizationForPphTable ? MainContainer : MainContent;

    if (editGroupId) {
        return (
            <MainContainer>
                <EditPayrollGroupedSheetPage />
            </MainContainer>
        );
    }

    return (
        <Wrapper>
            {page}
            <PayrollCalculationDetailGroupedSidebar />
            <Route
                path={routes.PAYROLL_PROCESSOR_HUB.SHEET_DETAIL}
                component={PayrollSheetDetailSidebar}
            />
        </Wrapper>
    );
};
