import React from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import { ICellProps } from 'shared/components/table/GridTable/GridTableModel';
import { setPayrollDetailCalculationGroupId, setPayrollEditCalculationGroupId } from 'modules/payrollProcessorHub/store/actions';
import { EditItemButton } from 'modules/settings/common/components/EditItemButton/EditItemButton';

import { IPayrollSheetGroupedRow, TableClasses } from '../model';
import { PayrollSheetStatuses } from 'modules/payrollProcessorHub/store/model';

export function makeDetailsGroupCell(tableClasses: TableClasses, userHasEditPermission: boolean) {
    const DetailsCell = ({
        className,
        payroll_status,
        id,
    }: ICellProps<IPayrollSheetGroupedRow>) => {
        const dispatch = useDispatch();
        const openDetail = () => dispatch(setPayrollDetailCalculationGroupId(id));
        if (payroll_status === PayrollSheetStatuses.MISSING) {
            return (
                <div className={clsx(className, tableClasses.iconCell, tableClasses.detailsIcon)}>
                    {userHasEditPermission && (
                        <EditItemButton id={id} editAction={setPayrollEditCalculationGroupId} />
                    )}
                </div>
            );
        }
        return (
            <div className={clsx(className, tableClasses.iconCell, tableClasses.detailsIcon)}>
                <IconButton data-testid="details-button" onClick={openDetail}>
                    <ArrowForwardIos />
                </IconButton>
            </div>
        );
    };

    return DetailsCell;
}
