import React from 'react';
import MainContent from 'shared/components/common/MainContent';

import OfferLetterTemplatesPageContent from './OfferLetterTemplatesPageContent';

function OfferLetterTemplatesPage() {
    return (
        <MainContent whiteBackground>
            <OfferLetterTemplatesPageContent />
        </MainContent>
    );
}

export default OfferLetterTemplatesPage;
