import React from 'react';
import { Box, BoxProps, Hidden } from '@material-ui/core';
import { PayrollFilter } from 'modules/payrollProcessorHub/components/PayrollFilter/PayrollFilter';

export const FilterSection = (props: BoxProps) => (
    <Hidden xsDown>
        <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            {...props}
        >
            <PayrollFilter />

            {props.children}
        </Box>
    </Hidden>
);
