import React, { useCallback } from 'react';
import { useFeature } from '@optimizely/react-sdk';
import { TerminationButton } from 'modules/employeeActionsForm/components/Termination/TerminationButton';
import { AssignmentStartDateChange } from 'modules/subassignmentManagement/components/AssignmentStartDateChange';
import { setDisplayAssignmentStartDateChangeDialog } from 'modules/subassignmentManagement/components/AssignmentStartDateChange/store/actions';
import { EafHistory } from 'modules/subassignmentManagement/components/EafHistory/EafHistory';
import { PromotionDemotion } from 'modules/subassignmentManagement/components/PromotionDemotion/PromotionDemotion';
import { setOpenPromotionDemotion } from 'modules/subassignmentManagement/components/PromotionDemotion/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from '@material-ui/lab';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { EndAssignmentButton } from 'modules/employeeActionsForm/components/EndAssignment/EndAssignmentButton';
import {
    selectAssignmentInfo,
    selectDetailAssignmentId,
    selectDetailAssignmentIsStillActive,
    selectIsLoadingDetail,
} from 'modules/subassignmentManagement/components/AssignmentInfo/store/selectors';
import { setDetailAssignmentId } from 'modules/subassignmentManagement/store/actions';
import { EditableInfoBlock } from 'modules/subassignmentManagement/components/AssignmentInfo/EditableInfoBlock/EditableInfoBlock';
import { IInfoItemData } from 'modules/subassignmentManagement/components/AssignmentInfo/EditableInfoBlock/model';
import { AssignmentInfoTitles } from 'modules/subassignmentManagement/components/AssignmentInfo/store/model';
import { CompensationChange } from 'modules/subassignmentManagement/components/CompensationChange/CompensationChange';
import { setOpenCompensationChange } from 'modules/subassignmentManagement/components/CompensationChange/store/actions';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import { WorkspaceSidebar } from 'shared/components/workspaces/WorkspaceSidebar/WorkspaceSidebar';
import { CustomFieldType } from 'store/entities/customFields/model';
import { FeatureSwitches } from 'utils/featureSwitches';
import {
    AssignmentEditEndDateButtons,
} from '../../../employeeActionsForm/components/AssignmentEditEndDate/AssignmentEditEndDateButton';
import { selectIsUserHasPermission } from '../../../../store/components/auth/selectors';
import { Permission } from '../../../../store/components/auth/authModels';

interface IAssignmentInfoSidebarProps {
    children?: React.ReactNode;
}

export const AssignmentInfoSidebar = ({ children }: IAssignmentInfoSidebarProps) => {
    const dispatch = useDispatch();
    const assignmentId = useSelector(selectDetailAssignmentId);
    const assignmentInfo = useSelector(selectAssignmentInfo);
    const isLoading = useSelector(selectIsLoadingDetail);

    const [enableCompensationChange] = useFeature(FeatureSwitches.enableCompensationChange);
    const [enableChangeStartDate] = useFeature(FeatureSwitches.enableAssignmentStartDateChanging);
    const [enablePromotionDemotionForAssignment] = useFeature(FeatureSwitches.enablePromotionDemotionForAssignment);
    const isAssignmentActive = useSelector(selectDetailAssignmentIsStillActive);
    const superAdmin = useSelector(selectIsUserHasPermission(Permission.ManageEverything));

    const onClose = useCallback(() => {
        dispatch(setDetailAssignmentId(null));
    }, [dispatch]);

    const getOnEdit = useCallback((info: IInfoItemData) => {
        if (!isAssignmentActive) {
            return null;
        }
        switch (info.label) {
            case AssignmentInfoTitles.BaseRate:
                if (!enableCompensationChange || !isAssignmentActive) {
                    return null;
                }
                return () => {
                    dispatch(setOpenCompensationChange(true));
                };
            case AssignmentInfoTitles.StartDate:
                if (!enableChangeStartDate) {
                    return null;
                }
                return () => {
                    dispatch(setDisplayAssignmentStartDateChangeDialog(true));
                };
            default: {
                if (enablePromotionDemotionForAssignment
                    && info.additionalData?.customFieldType === CustomFieldType.Position) {
                    return () => {
                        dispatch(setOpenPromotionDemotion(true));
                    };
                }
                return null;
            }
        }
    }, [dispatch, enableChangeStartDate, enableCompensationChange, isAssignmentActive,
        enablePromotionDemotionForAssignment]);

    return (
        <WorkspaceSidebar
            title={assignmentInfo.employeeName || 'Main Assignment Details'}
            isOpened={Boolean(assignmentId)}
            onClose={onClose}
            key={assignmentId}
        >
            <Box m={3}>
                {/*
                // @ts-ignore */}
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                >
                    {isAssignmentActive && (
                        <Grid item>
                            <EndAssignmentButton />
                        </Grid>
                    )}
                    {isAssignmentActive && (
                        <Grid item>
                            <TerminationButton />
                        </Grid>
                    )}
                    {!isAssignmentActive && superAdmin && (
                        <AssignmentEditEndDateButtons />
                    )}
                </Grid>

                <Box mt={2}>
                    <Typography
                        variant="body1"
                        color="primary"
                    >
                        <strong>Employment Details:</strong>
                    </Typography>
                </Box>

                <Box my={2}>
                    {assignmentInfo.baseInfo.map(infoBlock => (
                        <EditableInfoBlock
                            key={`assignments_info_${infoBlock.label}`}
                            data={infoBlock}
                        />
                    ))}
                </Box>
                <Box mr={-2}>
                    <Grid container>
                        {assignmentInfo.additionalInfo.map(infoBlock => (
                            <Grid
                                item
                                md={6}
                                key={`additional_info_${infoBlock.label}`}
                            >
                                <EditableInfoBlock
                                    data={infoBlock}
                                    onEdit={getOnEdit(infoBlock)}
                                />
                            </Grid>
                        ))}
                        {isLoading && (Array.from(new Array(5)).map((_, i) => (
                            <Grid
                                item
                                md={6}
                                key={`skeleton_${i}`}
                            >
                                <Box pr={3} mb={1.5}>
                                    <Skeleton />
                                </Box>
                            </Grid>
                        )))}
                    </Grid>
                    <Box>
                        {children}
                    </Box>
                </Box>

                <FeatureSwitch feature={FeatureSwitches.enableAssignmentHistory} noPlaceholder>
                    <Box>
                        <Box mt={2} mb={2}>
                            <Divider light />
                        </Box>
                        <Typography
                            variant="body1"
                            color="primary"
                        >
                            <strong>Audit:</strong>
                        </Typography>
                        <Box
                            mr={-3}
                            ml={-3}
                            mt={2}
                        >
                            <EafHistory assignmentId={assignmentId} />
                        </Box>
                    </Box>
                </FeatureSwitch>
            </Box>
            <CompensationChange />
            <AssignmentStartDateChange />
            <PromotionDemotion />
        </WorkspaceSidebar>
    );
};
