import { settingsRoutes } from 'modules/settings/routes';
import { setEditLogoClientId, updateClientLogo } from 'modules/settings/submodules/clients/components/EditClientLogo/store/actions';
import { payRangesSagas } from 'modules/settings/submodules/clients/payRanges/store/sagas';
import { reportsSagas } from 'modules/settings/submodules/clients/reports/store/sagas';
import { scopeSagas } from 'modules/settings/submodules/clients/scopes/store/sagas';
import {
    IUpdateClientRequest,
} from 'modules/settings/submodules/clients/store/models';
import { generatePath } from 'react-router-dom';
import { autoHideDefaultDuration, IModalSeverity } from 'shared/components/toasts/modal';
import { browserHistory } from 'shared/utils/browserHistory';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { getClientConfigurationTheme, setClientsInfo } from 'store/entities/clients/clientsAction';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';

import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import workingConditionsSagas from 'modules/settings/submodules/clients/workingConditions/store/sagas';
import physicalDemandsSagas from 'modules/settings/submodules/clients/physicalDemands/store/sagas';
import backgroundChecksTemplatesSagas from 'modules/settings/submodules/clients/backgroundCheckTemplates/store/sagas';
import customFieldValuesSagas from 'modules/settings/submodules/clients/customFieldValues/store/sagas';
import { costCenterSagas } from 'modules/settings/submodules/clients/costCenters/store/sagas';
import {
    updateClient,
    setClientStatuses,
    getPositionWorkLocationAssociation,
    createPositionWorkLocationAssociation,
    deletePositionWorkLocationAssociation,
    getClientPayCodes, deleteClient,
} from 'modules/settings/submodules/clients/store/actions';
import { api } from 'modules/settings/submodules/clients/store/api';
import appSettingsSagas from 'modules/settings/submodules/clients/appConfiguration/store/sagas';
import { controllingOrgSagas } from '../controllingOrg/store/sagas';
import { submittingOrgSagas } from 'modules/settings/submodules/clients/submittingOrg/store/sagas';
import { jobNumbersSagas } from 'modules/settings/submodules/clients/jobNumber/store/sagas';
import { dealSagas } from 'modules/settings/submodules/clients/deals/store/sagas';
import { paySettingsSagas } from 'modules/settings/submodules/clients/paySettings/store/sagas';
import { activitySagas } from 'modules/settings/submodules/clients/activities/store/sagas';
import { associateDealTypePayCodeArBucketSagas } from '../payCodeArBucket/store/sagas';
import { assignmentsReportDataSagas } from '../assignmentsReportData/store/sagas';
import { HeadwayError } from '../../../../../utils/errors';
import { requestSagaWatcher } from '../../../../../store/utils/sagas/requestSagaWatcher';
import { clientsApi } from '../../../../../store/entities/clients/clientsApi';
import { selectIsUserHasPermission } from '../../../../../store/components/auth/selectors';
import { Permission } from '../../../../../store/components/auth/authModels';

function* updateClientSaga(action: ReturnType<typeof updateClient.init>) {
    const clientId = action.payload.client_id as string;
    const data = {
        manager_user_id: action.payload.manager?.id || '',
    } as IUpdateClientRequest;

    const result = yield* call(api.updateClient, clientId, data);
    yield put(updateClient.success(result));
    yield put(setClientsInfo([result]));
    browserHistory.push(generatePath(settingsRoutes.CLIENTS.DASHBOARD, { clientId }));
}

function* getClientStatusesSaga() {
    const result = yield* call(api.getClientStatuses);
    yield put(setClientStatuses.success(result));
}

function* getClientStatusesWatcher() {
    yield* takeLatest(setClientStatuses.initType, withBackendErrorHandler(getClientStatusesSaga, setClientStatuses.error, 'Unable to fetch client statuses'));
}

function* updateClientWatcher() {
    yield* takeLatest(updateClient.initType, withBackendErrorHandler(updateClientSaga, updateClient.error, 'Unable to update client'));
}

function* getPositionLocationAssociationsSaga() {
    const result = yield* call(api.getPositionLocationAssociations);
    yield put(getPositionWorkLocationAssociation.success(result));
}

function* getPositionLocationAssociationsWatcher() {
    yield* takeLatest(
        getPositionWorkLocationAssociation.initType,
        withBackendErrorHandler(getPositionLocationAssociationsSaga, getPositionWorkLocationAssociation.error, 'Unable to fetch associations'),
    );
}

function* createPositionLocationAssociationsSaga(
    action: ReturnType<typeof createPositionWorkLocationAssociation.init>,
) {
    const result = yield* call(api.createPositionLocationAssociation, action.payload);
    yield put(createPositionWorkLocationAssociation.success(result));
}

function* createPositionLocationAssociationsWatcher() {
    yield* takeLatest(
        createPositionWorkLocationAssociation.initType,
        withBackendErrorHandler(
            createPositionLocationAssociationsSaga,
            createPositionWorkLocationAssociation.error,
            'Unable to create association',
        ),
    );
}

function* deletePositionLocationAssociationsSaga(
    action: ReturnType<typeof deletePositionWorkLocationAssociation.init>,
) {
    yield* call(api.deletePositionLocationAssociation, action.payload);
    yield put(deletePositionWorkLocationAssociation.success(action.payload));
}

function* deletePositionLocationAssociationsWatcher() {
    yield* takeLatest(
        deletePositionWorkLocationAssociation.initType,
        withBackendErrorHandler(
            deletePositionLocationAssociationsSaga,
            deletePositionWorkLocationAssociation.error,
            'Unable to delete association',
        ),
    );
}

export function* getClientPayCodesSaga(
    action: ReturnType<typeof getClientPayCodes.init>,
) {
    const clientId = yield select(selectCurrentClientId);
    if (clientId || action.payload) {
        const payCodes = yield* call(api.getPayCodes, action.payload || {});
        yield put(getClientPayCodes.success(payCodes));
    }
}

function* getClientPayCodesWatcher() {
    yield* takeLatest(
        getClientPayCodes.initType,
        withBackendErrorHandler(
            getClientPayCodesSaga,
            getClientPayCodes.error,
            'Unable to fetch client pay codes.',
        ),
    );
}

export function* updateClientLogoSaga(
    { payload }: ReturnType<typeof updateClientLogo.init>,
) {
    const { clientId, data } = payload;
    const response = yield* call(api.updateClientLogo, clientId, data);
    yield put(updateClientLogo.success({
        clientId,
        data: response,
    }));
    yield put(setEditLogoClientId(null));
    yield put(getClientConfigurationTheme.init());
    yield put(setGlobalToast({
        severity: IModalSeverity.Success,
        title: 'Client logo successfully updated',
        autoHideDuration: autoHideDefaultDuration,
    }));
}

function* updateLogoWatcher() {
    yield* takeLatest(
        updateClientLogo.initType,
        withBackendErrorHandler(
            updateClientLogoSaga,
            updateClientLogo.error,
            'Unable to update client logo.',
        ),
    );
}

const deleteClientWatcher = requestSagaWatcher(
    deleteClient,
    clientsApi.deleteClient,
    'Unable to delete client',
    {
        preconditionSaga: function* () {
            const userHasPermission = yield select(selectIsUserHasPermission(Permission.ManageEverything));
            if (!userHasPermission) {
                throw new HeadwayError(`You don't have appropriate permission`);
            }
        },
        handleSuccessSaga: function* () {
            browserHistory.push(settingsRoutes.CLIENTS.ROOT);
            yield put(setGlobalToast({
                severity: IModalSeverity.Success,
                title: `Client successfully deleted`,
            }));
        },
    },
);

export default [
    updateClientWatcher,
    updateLogoWatcher,
    getClientStatusesWatcher,
    getPositionLocationAssociationsWatcher,
    createPositionLocationAssociationsWatcher,
    deletePositionLocationAssociationsWatcher,
    getClientPayCodesWatcher,
    deleteClientWatcher,
    ...activitySagas,
    ...controllingOrgSagas,
    ...submittingOrgSagas,
    ...workingConditionsSagas,
    ...physicalDemandsSagas,
    ...backgroundChecksTemplatesSagas,
    ...appSettingsSagas,
    ...costCenterSagas,
    ...dealSagas,
    ...jobNumbersSagas,
    ...paySettingsSagas,
    ...customFieldValuesSagas,
    ...associateDealTypePayCodeArBucketSagas,
    ...assignmentsReportDataSagas,
    ...scopeSagas,
    ...payRangesSagas,
    ...reportsSagas,
];
