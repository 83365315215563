import React, { useEffect } from 'react';
import { FormikProps } from 'formik';
import { useStyles } from 'shared/components/auth/AuthStyles';
import { Box, Button, CircularProgress, FormHelperText } from '@material-ui/core';
import { ILoginFormValues } from 'shared/components/auth/forms/models';
import FormField from 'shared/components/formFields/FormField';
import PasswordField from 'shared/components/formSpecialFields/password/PasswordField';
import { Link } from 'react-router-dom';
import { routes } from 'shared/routes';
import { useDispatch } from 'react-redux';
import { cacheAuthEmail } from '../../../../store/components/auth/authActions';

export interface ILoginFormProps extends FormikProps<ILoginFormValues> {
    isLoading?: boolean;
    errorMessage?: string;
}

export function LoginForm({ isLoading, errorMessage, handleSubmit, values }: ILoginFormProps) {
    const classes = useStyles();

    const dispatch = useDispatch();
    useEffect(() => {
        if (values.username) {
            dispatch(cacheAuthEmail(values.username));
        }
    }, [dispatch, values.username]);

    return (
        <form
            onSubmit={handleSubmit}
            className={classes.form}
            autoComplete="off"
        >
            <FormField
                name="username"
                label="Username"
                className={classes.input}
                autoFocus
                defaultInputProps={false}
            />

            <PasswordField
                name="password"
                label="Password"
                className={classes.input}
            />
            <Link to={routes.AUTH.RESET_PASSWORD} className={classes.link}>
                Forgot Password?
            </Link>

            <Box
                mt={2}
                mb={2}
                display="flex"
                justifyContent="center"
            >
                <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={isLoading}
                    className={classes.submit}
                >
                    Log in
                    {isLoading && (
                        <Box ml={1} mb={-0.5}>
                            <CircularProgress color="inherit" size={16} />
                        </Box>
                    )}
                </Button>
            </Box>
            {errorMessage && (
                <FormHelperText className={classes.userMessage} error>{errorMessage}</FormHelperText>
            )}
        </form>
    );
}
