import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import { EafApprovalCellProps } from 'modules/eafApproval/store/model/types';
import { AttachmentsPreviewModal } from 'shared/components/attachments/AttachmentsPreviewModal';
import ReceiptSVG from 'shared/components/icons/ReceiptSVG';
import { useModal } from 'shared/utils/hooks/useModal';

export const EafAttachmentsCell = ({
    className,
    attachments,
}: EafApprovalCellProps) => {
    const {
        isModalOpened: isPreviewOpen,
        onModalOpen: onPreviewOpen,
        onModalClose: onPreviewClose,
    } = useModal();
    return (
        <Box className={className}>
            {attachments.length > 0 && (
                <>
                    <IconButton onClick={onPreviewOpen}>
                        <ReceiptSVG />
                    </IconButton>
                    {isPreviewOpen && (
                        <AttachmentsPreviewModal
                            open={isPreviewOpen}
                            onClose={onPreviewClose}
                            files={attachments}
                        />
                    )}
                </>
            )}
        </Box>
    );
};
