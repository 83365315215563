import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { FormikProps } from 'formik';
import { OfferLetterPayRanges } from 'modules/offerLetter/components/OfferLetterForm/components/OfferLetterPayRanges/OfferLetterPayRanges';
import {
    selectOfferLetterFormFieldsMapping,
    selectPayRateTypeByPaySettings,
} from 'modules/offerLetter/store/selectors';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DayPickerField from 'shared/components/formFields/DayPickerField';
import FormField from 'shared/components/formFields/FormField';
import { MultiStepFormValues } from 'shared/components/forms/multiStep/MultiStepForm';
import { CustomFieldValuesSelect } from 'shared/components/selects/CustomFieldValuesSelect/CustomFieldValuesSelect';
import { EmployeeType } from 'shared/components/selects/EmployeeType';
import { monthTextDateFormat } from 'shared/models/Dates';
import { PayRateType, PayType } from 'shared/models/PaySettings';
import { useIsPayRangesAppliedForClient } from 'store/entities/clients/hooks';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import {
    selectClientTimeAndPayConfiguration,
} from 'store/entities/clients/selectors/timeAndPaySelectors';
import { HierarchyType } from 'store/entities/customFields/model';
import {
    selectCustomFieldValuesByIds,
    selectOfferLetterHierarchy,
} from 'store/entities/customFields/selectors';
import { getUserCustomFieldRestrictions } from 'store/entities/scopes/actions';
import { ScopeAction, ScopeType } from 'store/entities/scopes/models';
import { OfferLetterTemplateSelect } from '../../CreateOffer/components/OfferLetterTemplateSelect';
import { OfferLetterPayRateField } from '../components/OfferLetterPayRateField';
import { defaultOfferLetterValues, IOfferLetterFormValues } from '../model';
import { useStyles } from '../styles';
import moment from 'moment';

export function OfferLetterOfferForm({
    values,
    setFieldValue,
}: FormikProps<MultiStepFormValues<IOfferLetterFormValues>>) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const clientId = useSelector(selectCurrentClientId);
    const { jobOrderId, payRate, payType, startDate } = values;
    const customFieldValuesByIds = useSelector(selectCustomFieldValuesByIds);
    const jobOrder = useMemo(() => {
        return jobOrderId ? customFieldValuesByIds[jobOrderId] : undefined;
    }, [jobOrderId, customFieldValuesByIds]);
    const worksiteAddressString = useMemo(() => {
        const worksiteAddress = jobOrder?.data?.worksite_address;
        if (worksiteAddress) {
            const address = `${worksiteAddress.address1 ?? ''} ${worksiteAddress.address2 ?? ''}`.trim();
            return `${address}, ${worksiteAddress.city}, ${worksiteAddress.state} ${worksiteAddress.zip_code}`.trim();
        } else {
            return '';
        }
    }, [jobOrder]);
    const hierarchy = useSelector(selectOfferLetterHierarchy);
    const clientPayRateType = useSelector(selectPayRateTypeByPaySettings);
    const customFieldMapping = useSelector(selectOfferLetterFormFieldsMapping);
    const customFieldValues = Object.keys(customFieldMapping).reduce((mem, key) => ({
        ...mem,
        [key]: values[customFieldMapping[key]],
    }), {});
    const appSettings = useSelector(selectClientTimeAndPayConfiguration);
    const isExternalEmployeeIdEnabled = useMemo(() =>
        appSettings?.enableOfferLetterEmployeeNumber ?? false, [appSettings]);

    const payRangesEnabled = useIsPayRangesAppliedForClient();

    useEffect(() => {
        if (clientId) {
            const scopesRequestData = {
                client_ids: [clientId],
                scope_types: [ScopeType.SubassignmentManagement],
            };
            dispatch(getUserCustomFieldRestrictions.init(scopesRequestData));
        }
    }, [clientId, dispatch]);

    useEffect(() => {
        if (jobOrder) {
            setFieldValue('payRate', payRate || defaultOfferLetterValues.payRate);
            const moment_start = moment(jobOrder.data?.start_date);
            if (moment(startDate).isBefore(moment_start)) {
                setFieldValue('startDate', null);
            }
            if (jobOrder.data?.pay_type === PayType.Hourly) {
                setFieldValue('payRateType', PayRateType.PER_HOUR);
                setFieldValue('payType', PayType.Hourly);
            } else if (jobOrder?.data?.pay_type === PayType.Salaried) {
                setFieldValue('payRateType', clientPayRateType);
                setFieldValue('payType', PayType.Salaried);
            } else {
                setFieldValue('payRateType', PayRateType.PER_HOUR);
                setFieldValue('payType', PayType.Hourly);
            }
        }

    }, [jobOrder, setFieldValue, payRate, startDate, payType, clientPayRateType]);

    return (
        <>
            {isExternalEmployeeIdEnabled && (
                <FormField
                    name="employee_number"
                    label="External Employee ID"
                    className={classes.field}
                />
            )}
            {hierarchy.map(node => (
                <CustomFieldValuesSelect
                    key={node.custom_field_id}
                    customFieldId={node.custom_field_id}
                    name={customFieldMapping[node.custom_field_id]}
                    className={classes.field}
                    useIdValue
                    useLabel
                    hierarchy={hierarchy}
                    customFieldFormValues={customFieldValues}
                    hierarchyType={HierarchyType.Assignment}
                    scopeActionFilter={ScopeAction.OfferLetters}
                    useAutocomplete={true}
                />
            ))}
            <Box className={clsx(classes.field)} mt={3}>
                <Typography variant="body2"><b>Worksite Location:</b> {worksiteAddressString}</Typography>
                <Typography variant="body2"><b>Pay Type:</b> {payType}</Typography>
                <Typography variant="body2"><b>Job Type:</b> {jobOrder?.data?.order_type}</Typography>
            </Box>

            <Box display="flex">
                <EmployeeType
                    name="employeeType"
                    label="Employment Type"
                    className={clsx(classes.field, classes.firstInputBlock)}
                    useIdValue
                />
                <FormField
                    name="hours"
                    label="Range Of Hours"
                    inputProps={{ maxLength: 10 }}
                    className={clsx(classes.field, classes.secondInputBlock)}
                />
            </Box>

            <DayPickerField
                name="startDate"
                label="Start Date"
                className={classes.field}
                format={monthTextDateFormat}
                disableToolbar
                minDate={jobOrder?.data?.start_date}
            />

            <OfferLetterTemplateSelect
                name="template"
                label="Offer Letter Template"
                className={classes.field}
                useIdValue
            />

            <OfferLetterPayRateField values={values} />

            <OfferLetterPayRanges enabled={payRangesEnabled} />
        </>
    );
}
