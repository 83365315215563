import { deleteClient } from 'modules/settings/submodules/clients/store/actions';
import { combineReducers } from 'redux';
import {
    ClientAction,
    getClientConfigurationTheme,
    getClientFieldsConfiguration,
    updateClientConfiguration,
    getClientPaySettings,
    SET_CLIENTS,
    SET_CLIENTS_INFO,
    setClientId,
    setPayrollProcessing,
} from 'store/entities/clients/clientsAction';
import { deleteItemByIdReducer, isLoadingReducer, itemsByIds } from 'store/reducerUtils';
import {
    ApproversFromFields,
    IClient,
    IClientInfo,
    IClientConfiguration,
    IClientPaySettings,
    IThemeConfigurationWrapper,
    ITimeAndPayClientConfiguration,
} from 'store/entities/clients/clientsModel';
import { processFieldsConfiguration } from 'store/entities/clients/selectors/utils';
import { ItemsById } from 'shared/models/ItemsById';
import { omit } from 'lodash-es';
import { extendReducer } from 'store/utils/reducers/extendReducer';

const initialState = {
    fieldsConfigurationByClientId: {},
    configurationThemeByClientId: {},
    timeAndPayConfigurationByClientId: {},
    paySettings: {},
    clientsById: {},
    tenantInfo: null,
    clientId: null,
    isPayrollProcessing: false,
    scaEnabledByClientId: {},
};

function isPayrollProcessing(state: boolean = initialState.isPayrollProcessing, action: ClientAction): boolean {
    switch (action.type) {
        case setPayrollProcessing.initType:
            return true;
        case setPayrollProcessing.errorType:
        case setPayrollProcessing.successType:
            return false;
        default:
            return state;
    }
}

/**
 * Reducer for all clients.
 * For example clients that not related to the current user can be displayed at the Offer Letter page.
 */
const allClientsById = extendReducer(
    (
        state: ItemsById<IClient> = initialState.clientsById,
        action: ClientAction,
    ): ItemsById<IClient> => {
        switch (action.type) {
            case SET_CLIENTS_INFO:
            case SET_CLIENTS:
                return {
                    ...state,
                    ...itemsByIds(action.payload),
                };
            default:
                return state;
        }
    },
    deleteItemByIdReducer(deleteClient),
);

/**
 * Reducer for all related to current user clients.
 * Usually it is a basic source for client data.
 */
const clientsById = extendReducer(
    (
        state: ItemsById<IClientInfo> = initialState.clientsById,
        action: ClientAction,
    ): ItemsById<IClientInfo> => {
        switch (action.type) {
            case SET_CLIENTS_INFO:
                return {
                    ...state,
                    ...itemsByIds(action.payload),
                };
            default:
                return state;
        }
    },
    deleteItemByIdReducer(deleteClient),
);

function fieldsConfigurationByClientId(
    state: Record<string, IClientConfiguration> = initialState.fieldsConfigurationByClientId,
    action: ClientAction,
): Record<string, IClientConfiguration> {
    switch (action.type) {
        case updateClientConfiguration.successType:
        case getClientFieldsConfiguration.successType: {
            const { client_id, configuration } = action.payload;
            return {
                ...state,
                [client_id]: processFieldsConfiguration(configuration, client_id),
            };
        }
        default:
            return state;
    }
}

function configurationByClientId(
    state: Record<string, IClientConfiguration> = initialState.fieldsConfigurationByClientId,
    action: ClientAction,
): Record<string, IClientConfiguration> {
    switch (action.type) {
        case updateClientConfiguration.successType:
        case getClientFieldsConfiguration.successType: {
            const { client_id, configuration: initialConfiguration } = action.payload;
            const fieldsConfigurationKeys = ['inputs', 'tables', 'detail', 'filters', 'totals'];
            const configuration = omit(initialConfiguration, fieldsConfigurationKeys);
            return {
                ...state,
                [client_id]: configuration,
            };
        }
        default:
            return state;
    }
}

const isLoadingFieldsConfiguration = isLoadingReducer(getClientFieldsConfiguration);

function timeAndPayConfigurationByClientId(
    state: ItemsById<ITimeAndPayClientConfiguration> = initialState.timeAndPayConfigurationByClientId,
    action: ClientAction,
): ItemsById<ITimeAndPayClientConfiguration> {
    if (action.type === getClientFieldsConfiguration.successType) {
        const { payload } = action;
        const clientConfiguration: ITimeAndPayClientConfiguration = {
            id: payload.client_id,
            timeTracking: payload.configuration.time_tracking,
            approversFrom: payload.configuration.approvers_from || ApproversFromFields.FromAssignment,
            approvalLevels: payload.configuration.approval_levels,
            isGovernmentContract: payload.configuration.is_government_contract,
            hasJobNumberConfiguration: payload.configuration?.sheet_entry_group_key?.includes('job_number_id') || false,
            enableOfferLetterEmployeeNumber: payload.configuration?.enable_offer_letter_employee_number || false,
            scaEnabled: payload.configuration?.sca_enabled || false,
            allowFutureDayEntry: payload.configuration?.allow_future_day_entry || false,
            allowTimeEntryWithoutEndTime: payload.configuration?.allow_time_entry_without_end_time || false,
            expenseReceiptRequiredDollars: payload.configuration?.expense_receipt_required_dollars || 0,
            mileageRate: payload.configuration?.mileage_rate || '0',
            allowTimeSheetGrouping: payload.configuration?.ui_sheet_grouping?.enabled || false,
        };
        return {
            ...state,
            ...itemsByIds([clientConfiguration]),
        };
    }

    return state;

}

function paySettingsByClientId(
    state: ItemsById<IClientPaySettings> = initialState.paySettings,
    action: ClientAction,
): ItemsById<IClientPaySettings> {
    if (action.type === getClientPaySettings.successType) {
        const { payload } = action;
        return {
            ...state,
            [payload.client_id]: payload,
        };
    }

    return state;
}

const isClientPaySettingsLoading = isLoadingReducer(getClientPaySettings);

function configurationThemeByClientId(
    state: Record<string, IThemeConfigurationWrapper> = initialState.configurationThemeByClientId,
    action: ClientAction,
): Record<string, IThemeConfigurationWrapper> {
    switch (action.type) {
        case getClientConfigurationTheme.successType:
            return {
                ...state,
                ...action.payload.reduce((acc: any, item: { client_id: any; }) => ({
                    ...acc,
                    [item.client_id]: item,
                }), {}),
            };
        default:
            return state;
    }
}

function clientId(
    state: string | null = initialState.clientId,
    action: ClientAction,
): string | null {
    switch (action.type) {
        case setClientId.action:
            return action.payload;
        default:
            return state;
    }
}

const isClientConfigurationUpdating = isLoadingReducer(updateClientConfiguration);

export const clients = combineReducers({
    fieldsConfigurationByClientId,
    configurationByClientId,
    configurationThemeByClientId,
    timeAndPayConfigurationByClientId,
    isLoadingFieldsConfiguration,
    isPayrollProcessing,
    allClientsById,
    clientsById,
    clientId,
    paySettingsByClientId,
    isClientPaySettingsLoading,
    isClientConfigurationUpdating,
});
