import clsx from 'clsx';
import React from 'react';
import { EafRetroIcon } from 'modules/eafApproval/components/EafRetroIcon';
import { EafApprovalCellTitleMap } from 'modules/eafApproval/store/model/data';
import { setEafApprovalSortOrder } from 'modules/eafApproval/store/actions';
import { selectEafApprovalGridOrder } from 'modules/eafApproval/store/selectors';
import { EafApprovalCellProps, EafApprovalGridCellKey } from 'modules/eafApproval/store/model/types';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { SortableTableHead } from 'shared/components/table/GridTable/SortableTableHead';
import { Box } from '@material-ui/core';
import { formatShortDate } from 'shared/utils/formatters/dateFormatter';

export const EafApprovalGridCellEffectiveDate = ({
    className,
    employee_action_form_data,
    onClick,
}: EafApprovalCellProps) => {
    const tableClasses = useDefaultTableStyles();
    // @ts-ignore
    const { effective_date, is_retro } = employee_action_form_data;
    const date = formatShortDate(effective_date);
    return (
        <Box
            className={clsx(className, { [tableClasses.clickable]: Boolean(onClick) })}
            onClick={onClick}
        >
            {date}
            {is_retro && <EafRetroIcon />}
        </Box>
    );
};

export const getEffectiveDateHead = () => function EffectiveDateTableHead() {
    return (
        <SortableTableHead
            id="effective_date"
            tableSortingSelector={selectEafApprovalGridOrder}
            setOrderAction={setEafApprovalSortOrder}
        >
            {EafApprovalCellTitleMap[EafApprovalGridCellKey.EffectiveDate]}
        </SortableTableHead>
    );
};
