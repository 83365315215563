import clsx from 'clsx';
import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

const useStyles = makeHighPriorityStyles({
    loaderContainer: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    fullScreen: {
        height: '100vh',
        width: '100vw',
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 1,
    },
});

interface IHeadwayLoaderProps {
    fullScreen?: boolean;
}

export const HeadwayLoader = ({ fullScreen }: IHeadwayLoaderProps) => {
    const classes = useStyles();
    return (
        <Box className={clsx(classes.loaderContainer, { [classes.fullScreen]: fullScreen })}>
            <CircularProgress />
        </Box>
    );
};
