import {
    ICreateEmployeeOfferLetterBackend,
    ICreateOfferLetterBackend, IEmployeeProfilesRequest, IGetOfferLetterParams,
    IOfferLetter, IOfferLetterWithLinked,
    IOnboardEmployeeProfileRequest,
    IUpdateOfferRequest,
} from 'modules/offerLetter/store/model';
import { IEmployeeProfile } from 'shared/models/EmployeeProfile';
import baseApi from 'shared/utils/baseApi';
import { withClientId } from 'store/utils/withClientId';

export const offerLetterApi = {
    // This function is for requests that should get data from all the tenants
    async getAllOfferLetters(params: IGetOfferLetterParams = {}): Promise<IOfferLetterWithLinked> {
        const { data } = await baseApi.get<IOfferLetterWithLinked>(
            '/client-users/offer_letters', params,
        );

        return data;
    },
    async getOfferLetter(params: IGetOfferLetterParams = {}): Promise<IOfferLetterWithLinked> {
        const { data } = await baseApi.get<IOfferLetterWithLinked>(
            '/client-users/offer_letters', withClientId(params),
        );

        return data;
    },
    async getOfferLetterById(id: string): Promise<IOfferLetter> {
        const { data } = await baseApi.get<IOfferLetter>(
            `/client-users/offer_letters/${id}`, withClientId({}),
        );

        return data;
    },
    async createOffer(offerLetterCreateRequest: ICreateOfferLetterBackend): Promise<IOfferLetter> {
        const { data } = await baseApi.create<ICreateOfferLetterBackend, IOfferLetter>(
            'client-users', 'offer_letters', withClientId(offerLetterCreateRequest),
        );

        return data;
    },
    async createEmployeeOffer(offerLetterCreateRequest: ICreateEmployeeOfferLetterBackend): Promise<IOfferLetter> {
        const { data } = await baseApi.create<ICreateEmployeeOfferLetterBackend, IOfferLetter>(
            'client-users', 'offer_letters/employee', withClientId(offerLetterCreateRequest),
        );

        return data;
    },
    async updateOfferAndResend(id: string, request: ICreateOfferLetterBackend): Promise<IOfferLetter> {
        const { data } = await baseApi.create<ICreateOfferLetterBackend, IOfferLetter>(
            'client-users', `offer_letters/${id}/edit_and_resend`, request,
        );

        return data;
    },
    async updateOfferLetter(id: string, request: Partial<IUpdateOfferRequest>) {
        const params = {
            ...request,
            status: request?.status?.toLowerCase(),
        };

        const { data } = await baseApi.patch<Partial<IUpdateOfferRequest>, IOfferLetter>(
            `client-users/offer_letters/${id}`, params,
        );

        return data;
    },
    async acceptOfferLetterTerms(id: string): Promise<IOfferLetter> {
        const { data } = await baseApi.post<undefined, IOfferLetter>(
            `client-users/offer_letters/${id}/accept_terms`,
        );
        return data;
    },
    async onboardMyEmployeeProfile(clientId: string, request: IOnboardEmployeeProfileRequest) {
        const { data } = await baseApi.create<IOnboardEmployeeProfileRequest, IEmployeeProfile>(
            'client-users', `clients/${clientId}/onboard_employee_profiles/mine`, request,
        );

        return data;
    },
    async getMyEmployeeProfile(clientId: string): Promise<IEmployeeProfile> {
        const { data } = await baseApi.get<IEmployeeProfile>(
            `/client-users/clients/${clientId}/employee_profiles/mine`,
        );

        return data;
    },
    async getMyEmployeeProfiles(): Promise<IEmployeeProfile[]> {
        const { data } = await baseApi.get<{ employee_profiles: IEmployeeProfile[]}>(
            `/client-users/employee_profiles/mine`,
        );

        return data.employee_profiles;
    },
    async checkOfferLetterPdf(id: string): Promise<string> {
        const { data } = await baseApi.head<string>(
            `/documents/offer_letters/${id}`,
        );
        return data;
    },
    async checkEndOfAssignmentPdf(id: string): Promise<string> {
        const { data } = await baseApi.head<string>(
            `/documents/end_of_assignment/${id}`,
        );
        return data;
    },
    async getEmployeeProfiles(request: IEmployeeProfilesRequest): Promise<IEmployeeProfile[]> {
        const clientId = baseApi.clientId || request.client_id;
        if (!clientId) {
            throw new Error('Client not specified');
        }
        const { data } = await baseApi.get<{ employee_profiles: IEmployeeProfile[]}>(
            `/client-users/clients/${clientId}/employee_profiles`,
            request,
        );

        return data.employee_profiles;
    },
};
