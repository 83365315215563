import moment from 'moment';
import { backendDateFormat, isDateInPayPeriod } from 'shared/models/Dates';
import { PayPeriodType, WeekDayEnum } from 'shared/models/PaySettings';
import { IClientPaySettings } from 'store/entities/clients/clientsModel';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { HeadwayError } from 'utils/errors';

export const getPayPeriodLength = (paySettings: IClientPaySettings) => {
    switch (paySettings.pay_period_type) {
        case PayPeriodType.Weekly:
            return 7;
        case PayPeriodType.BiWeekly:
            return 14;
        default:
            throw new HeadwayError('Not implemented');
    }
};

export const getPayPeriodForDate = (paySettings: IClientPaySettings, date?: moment.MomentInput): IPayPeriod => {
    const periodLength = getPayPeriodLength(paySettings);
    let periodStart = paySettings.pay_period_start_date;
    if (paySettings.pay_period_type === PayPeriodType.Weekly) {
        // Use near date to avoid performance issues
        periodStart = moment(date)
            .isoWeekday(getDayOfWeekName(paySettings.pay_period_start_day))
            .format(backendDateFormat);
    }
    if (!periodStart) {
        throw new HeadwayError('No pay_period_start_date for non weekly pay period');
    }
    const payPeriod: IPayPeriod = {
        period_start: periodStart,
        period_end: moment(periodStart).add(periodLength - 1, 'days').format(backendDateFormat),
    };

    const offsetVector = moment(date).isSameOrAfter(moment(payPeriod.period_start), 'day') ? 1 : -1;

    while (!isDateInPayPeriod(payPeriod, date)) {
        payPeriod.period_start = moment(payPeriod.period_start)
            .add(offsetVector * periodLength, 'days')
            .format(backendDateFormat);
        payPeriod.period_end = moment(payPeriod.period_end)
            .add(offsetVector * periodLength, 'days')
            .format(backendDateFormat);
    }

    return payPeriod;
};

export const getDayOfWeekName = (value: string): string => {
    return reverseWeekDayEnum[value];
};

const reverseWeekDayEnum = Object.fromEntries(
    Object.entries(WeekDayEnum).map(([key, value]) => [value, key]),
) as Record<string, string>;
