import React, { useCallback } from 'react';
import { Create } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setEditPlatformUser } from 'modules/settings/submodules/platformUsers/store/actions';

interface IEditUserButton {
    userId: string;
}

export const EditUserButton = ({ userId }: IEditUserButton) => {
    const dispatch = useDispatch();
    const openModal = useCallback(() => {
        dispatch(setEditPlatformUser(userId));
    }, [dispatch, userId]);

    return (
        <IconButton
            onClick={openModal}
            color="primary"
            data-testid="details-button"
        >
            <Create />
        </IconButton>
    );
};
