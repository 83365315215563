import { useFeature } from '@optimizely/react-sdk';
import { getDisplayCustomFieldsValues } from 'modules/subassignmentManagement/components/SubassignmentTable/store/selectors';
import React, { useMemo } from 'react';
import { List } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { SheetInfoItem } from 'shared/components/sidebars/SheetDetail/SheetInfoItem';
import { getUserName } from 'shared/utils/converters/user';
import { formatShortDate } from 'shared/utils/formatters/dateFormatter';
import { formatPhoneNumber } from 'shared/utils/formatters/phoneNumberFormatter';
import { selectCustomFieldValuesByIds, selectOrderedOfferLetterCustomFields } from 'store/entities/customFields/selectors';
import { selectClientRolesById, selectClientUserRolesByIds, selectUsersById } from 'store/entities/users/selectors';
import { FeatureSwitches } from 'utils/featureSwitches';
import { IOfferLetter, OfferLetterStatusSlug } from '../../store/model';
import { useSheetDetailStyles } from 'shared/components/sidebars/SheetDetail/styles';
import { getRescissionReasonTitle } from 'modules/offerLetter/store/utils';

interface IOfferLetterInfoProps {
    offerLetter: IOfferLetter;
    isPayApproval?: boolean;
}

export default function OfferLetterInfo({ offerLetter, isPayApproval }: IOfferLetterInfoProps) {
    const classes = useSheetDetailStyles(); // TODO: We need to use real shared classes & components
    const offerLetterFields = useSelector(selectOrderedOfferLetterCustomFields);
    const customFieldValuesByIds = useSelector(selectCustomFieldValuesByIds);
    const displayCustomFieldValues = getDisplayCustomFieldsValues(
        offerLetterFields,
        customFieldValuesByIds,
        offerLetter.custom_field_value_ids ?? [],
    );
    const clientRolesByIds = useSelector(selectClientRolesById);
    const clientUserRolesByIds = useSelector(selectClientUserRolesByIds);
    const usersByIds = useSelector(selectUsersById);
    // @ts-ignore
    const creator = usersByIds[offerLetter.creator_id];
    const creatorRole = useMemo(() => {
        const userRoleAssociation = Object.values(clientUserRolesByIds)
            // @ts-ignore
            .find(userRole => userRole.user_id === offerLetter.creator_id);
        const role = Object.values(clientRolesByIds)
            // @ts-ignore
            .find(clientRole => clientRole.id === userRoleAssociation?.client_role_id);
        return role?.name || 'Manager';
    }, [clientRolesByIds, clientUserRolesByIds, offerLetter.creator_id]);
    const [enableFilterOfferLetterByApprover] = useFeature(FeatureSwitches.enableFilterOfferLetterByApprover);
    const approvers = offerLetter.managers.map(manager => usersByIds[manager.user_id]).filter(Boolean);

    const data = [
        ...isPayApproval ? [
            {
                title: 'Name',
                value: getUserName(offerLetter),
            },
        ] : [],
        {
            title: 'Email',
            value: offerLetter.email,
        },
        {
            title: 'Offer Created',
            value: formatShortDate(offerLetter.offer_date),
        },
        ...enableFilterOfferLetterByApprover ? [
            {
                title: approvers.length > 1 ? 'Approvers' : 'Approver',
                value: approvers.map(approver => getUserName(approver)).join(', '),
            },
        ] : [],
        ...isPayApproval ? [
            {
                title: 'Phone',
                value: formatPhoneNumber(offerLetter.cell_phone),
            },
            {
                title: creatorRole,
                value: getUserName(creator),
            },
        ] : [
            ...(offerLetterFields.map(field => ({
                title: field.name,
                value: displayCustomFieldValues[field.id],
            }))),
            {
                title: 'Start Date',
                value: offerLetter.start_date,
            },
            ...offerLetter.status.slug === OfferLetterStatusSlug.Rescinded ? [
                {
                    title: 'Reason',
                    value: (
                        offerLetter.rescission_reason_custom_text
                        || getRescissionReasonTitle(offerLetter)
                    ),
                },
                ...offerLetter.rescinded_by ? [{
                    title: 'Rescinder',
                    value: getUserName(usersByIds[offerLetter.rescinded_by]),
                }] : [],
            ] : [],
        ],
    ];
    return (
        <List className={classes.listWrapper}>
            {data.map(item => (
                <>
                    {item.value && (
                        <SheetInfoItem
                            key={item.title}
                            // @ts-ignore
                            data={item}
                        />
                    )}
                </>
            ))}
        </List>
    );
}
