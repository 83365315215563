import baseApi from 'shared/utils/baseApi';
import { IClientPaySettings } from 'store/entities/clients/clientsModel';
import { IUpsertClientPaySettingsRequest } from './models';

const servicePath = 'client-configurations';
const modulePath = 'clients';

export const api = {
    async upsertClientPaySettings(
        clientId: string,
        request: IUpsertClientPaySettingsRequest,
    ): Promise<IClientPaySettings> {
        const { data } = await baseApi.put<IUpsertClientPaySettingsRequest, IClientPaySettings>(
            `${servicePath}/${modulePath}/${clientId}/pay_settings`, request,
        );
        return data;
    },
};
