import { IStore } from 'store/configureStore';

export const selectEmployeeDetailState = (state: IStore) => state.modules.offerLetter.employeeOfferDetail;
export const selectOnboardingStep = (state: IStore) => selectEmployeeDetailState(state).onboardingStep;
export const selectProfileOnboardingCompleted = (state: IStore) => 
    selectEmployeeDetailState(state).isProfileOnboardingCompleted;
export const selectMyEmployeeProfileOnboarding = (state: IStore) => 
    selectEmployeeDetailState(state).isEmployeeProfileOnboarding;
export const selectIsMyEmployeeProfileLoading = (state: IStore): boolean =>
    selectEmployeeDetailState(state).isEmployeeProfileLoading;
export const selectIsMyEmployeeProfilesLoading = (state: IStore): boolean =>
    selectEmployeeDetailState(state).isEmployeeProfilesLoading;
