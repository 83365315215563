import { Box } from '@material-ui/core';
import { useFeature } from '@optimizely/react-sdk';
import {
    CustomFieldValueFormWrapper,
} from 'modules/settings/submodules/clients/customFieldValues/components/CustomFieldValueForm/CustomFieldValueFormWrapper';
import {
    ICustomFieldValueFormValues,
} from 'modules/settings/submodules/clients/customFieldValues/components/CustomFieldValueForm/model';
import {
    getHierarchyRelationsByFormValues,
} from 'modules/settings/submodules/clients/customFieldValues/components/CustomFieldValueForm/utils';
import {
    selectEditCustomFieldValue,
    selectIsCustomFieldValueUpdating,
} from 'modules/settings/submodules/clients/customFieldValues/components/EditCustomFieldValue/store/selectors';
import {
    setEditCustomFieldValueId,
    updateCustomFieldValue,
} from 'modules/settings/submodules/clients/customFieldValues/store/actions';
import {
    selectClientHasCompatibleHierarchy,
    selectCustomField,
    selectCustomFieldRelations,
} from 'modules/settings/submodules/clients/customFieldValues/store/selectors';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';
import { FormMode } from 'shared/models/FormMode';
import { Status } from 'shared/models/Status';
import { FeatureSwitches } from 'utils/featureSwitches';

export const EditCustomFieldValueModal = () => {
    const classes = useColoredFormModalStyles();
    const dispatch = useDispatch();
    const customField = useSelector(selectCustomField);
    const customFieldValue = useSelector(selectEditCustomFieldValue);
    const isLoading = useSelector(selectIsCustomFieldValueUpdating);
    const isHierarchiesAreCompatible = useSelector(selectClientHasCompatibleHierarchy);
    const relations = useSelector(selectCustomFieldRelations);
    const [enableEditCustomFieldValueChildren] = useFeature(FeatureSwitches.enableEditCustomFieldValueChildren);
    const [enableCustomFieldValueStatus] = useFeature(FeatureSwitches.enableCustomFieldValueStatus);

    const onClose = useCallback(() => {
        dispatch(setEditCustomFieldValueId(null));
    }, [dispatch]);
    const onSave = useCallback((values: ICustomFieldValueFormValues) => {
        dispatch(updateCustomFieldValue.init({
            id: customFieldValue.id,
            // @ts-ignore
            data: {
                ...getHierarchyRelationsByFormValues(
                    values,
                    relations,
                    isHierarchiesAreCompatible,
                    enableEditCustomFieldValueChildren,
                ),
                data: values.data,
                is_active: enableCustomFieldValueStatus ? values.status === Status.active : undefined,
            },
        }));
    }, [
        dispatch,
        customFieldValue,
        relations,
        isHierarchiesAreCompatible,
        enableEditCustomFieldValueChildren,
        enableCustomFieldValueStatus,
    ]);

    return (
        <ModalDialog
            title={`Edit ${customField?.name || 'Custom Field'} Value`}
            isOpened={Boolean(customFieldValue)}
            onClose={onClose}
            modalProps={{
                customClasses: classes,
                showCloseIcon: true,
            }}
        >
            <Box m={-3}>
                <CustomFieldValueFormWrapper
                    onSubmit={onSave}
                    submitTitle="Save"
                    isLoading={isLoading}
                    mode={FormMode.Edit}
                    customFieldValue={customFieldValue}
                    customFieldId={customFieldValue?.custom_field_id}
                />
            </Box>
        </ModalDialog>
    );
};
