import {
    Box, Button,
    Hidden,
    Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';
import {
    useOfferLetterStyles,
} from 'modules/offerLetter/OfferLetterStyles';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { routes } from 'shared/routes';
import { useIsMobile } from 'shared/utils/hooks/media';

export interface IOnboardingHeaderProps {
    title: string;
    icon?: React.ReactNode;
    className?: string;
    navButtonText?: string;
}

export const OnboardingHeader = ({
    title,
    icon,
    className,
    navButtonText = 'Continue Later',
}: IOnboardingHeaderProps) => {
    const classes = useOfferLetterStyles();
    const isMobile = useIsMobile();

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            className={clsx(classes.headerWrapper, className)}
        >
            <Box display="flex" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                    <Hidden xsDown>
                        {icon}
                    </Hidden>
                    <Typography className={classes.headerText}>
                        {title}
                    </Typography>
                </Box>
                {isMobile && (
                    <NavLink className={classes.link} to={routes.EMPLOYEE_OFFER_LETTER.ROOT}>
                        <Close fontSize="small" classes={{ root: classes.closeIcon }} />
                    </NavLink>
                )}
            </Box>
            {!isMobile && (
                <Box className={classes.headerButtonsContainer}>
                    {HeaderNavButton({ navButtonText })}
                </Box>
            )}
        </Box>
    );
};

const HeaderNavButton = ({ navButtonText }: { navButtonText: string }) => (
    <NavLink
        to={routes.EMPLOYEE_OFFER_LETTER.ROOT}
        component={({ navigate, ...props }) => (
            <Button
                {...props}
                color="primary"
                variant="outlined"
            />
        )}
    >
        {navButtonText}
    </NavLink>
);
