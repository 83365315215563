import { Box, CircularProgress, IconButton } from '@material-ui/core';
import { Update } from '@material-ui/icons';
import clsx from 'clsx';
import { IOfferLetterRow, IOfferLetterWithPdf } from 'modules/offerLetter/components/OfferLetterTable/cells/cells';
import OfferLetterStatus from 'modules/offerLetter/components/OfferLetterTable/OfferLetterStatus';
import { useOfferManagePermission } from 'modules/offerLetter/hooks/useOfferManagePermission';
import { useOfferLetterEmployeeStyles } from 'modules/offerLetter/OfferLetterEmployee/OfferLetterEmployeeStyles';
import { OfferLetterStatusSlug, OfferLetterTabValue } from 'modules/offerLetter/store/model';
import moment from 'moment';
import React from 'react';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import PdfSVG from 'shared/components/icons/PdfSVG';
import { useSubmittedTableStyles } from 'shared/components/sheetsSubmitted/sheetsSubmittedStyles';
import ButtonCell from 'shared/components/table/Cells/ButtonCell';
import PlainText from 'shared/components/table/Cells/PlainText';
import MobileListCell from 'shared/components/table/MobileListCell/MobileListCell';
import { shortDateFormat } from 'shared/models/Dates';
import { routes } from 'shared/routes';
import { useTableStyles } from 'shared/styles/tableStyles';
import { formatShortDate } from 'shared/utils/formatters/dateFormatter';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { useFormattedPayRate, usePayRateType } from 'shared/utils/formatters/payRate';
import { OfferLetterCellKey } from './OfferLetterCellKey';

type TableClassesType = ReturnType<typeof useTableStyles>;

export const offerDateCell = {
    key: OfferLetterCellKey.OfferDate,
    title: 'offer date',
    render: function OfferDateCell({ offerLetter, className }: IOfferLetterRow) {
        return (
            <PlainText className={className}
                value={formatShortDate(offerLetter.offer_date)}
            />
        );
    },
};

export const startDateCell = {
    key: OfferLetterCellKey.StartDate,
    title: 'start date',
    render: function StartDateCell({ offerLetter, className }: IOfferLetterRow) {
        return (
            <PlainText className={className} value={formatShortDate(offerLetter.start_date)} />
        );
    },
};

export const payRateDesktopCell = {
    key: OfferLetterCellKey.PayRate,
    title: 'pay rate',
    width: '90px',
    render: function PayRate({ className, offerLetter }: IOfferLetterRow) {
        const payRate = useFormattedPayRate(offerLetter);
        return (
            <PlainText className={className} value={payRate} />
        );
    },
};

export const getOfferLetterPdfCell = (
    tableClasses: TableClassesType,
    action: (offerLetter: IOfferLetterWithPdf) => void,
) => ({
    key: OfferLetterCellKey.OfferLetterPDF,
    title: 'offer letter',
    width: '96px',
    headerClassName: tableClasses.cellJustifyCenter,
    render: function OfferLetterCell({ className, offerLetter }: IOfferLetterRow) {
        return (
            <div className={clsx(className, tableClasses.iconCell, tableClasses.cellJustifyCenter)}>
                <IconButton
                    onClick={action ? () => action(offerLetter) : undefined}
                    disabled={offerLetter.isPdfLoading}
                >
                    {offerLetter.isPdfLoading ? <CircularProgress color="inherit" size={16} /> : <PdfSVG />}
                </IconButton>
            </div>
        );
    },
});

export const positionCell = {
    key: OfferLetterCellKey.Position,
    title: 'position',
    render: function PositionCell({ positionDisplayValue, className }: IOfferLetterRow) {
        return (
            <PlainText className={className} value={positionDisplayValue} />
        );
    },
};

export const positionEmployeeCell = {
    key: OfferLetterCellKey.Position,
    title: 'position',
    render: function PositionCell({ positionDisplayValue, jobOrderDisplayValue, className }: IOfferLetterRow) {
        return (
            <PlainText
                className={className}
                value={jobOrderDisplayValue ? jobOrderDisplayValue : positionDisplayValue} />
        );
    },
};

export const jobOrderCell = {
    key: OfferLetterCellKey.JobOrder,
    title: 'Job Order',
    render: function PositionCell({ jobOrderDisplayValue, className }: IOfferLetterRow) {
        return (
            <PlainText className={className} value={jobOrderDisplayValue} />
        );
    },
};

export const worksiteCell = {
    key: OfferLetterCellKey.Worksite,
    title: 'Worksite location',
    render: function PositionCell({ worksiteAddress, className }: IOfferLetterRow) {
        return (
            <PlainText className={className} value={worksiteAddress} />
        );
    },
};

export const departmentCell = {
    key: OfferLetterCellKey.Department,
    title: 'department',
    render: function DepartmentCell({ departmentDisplayValue, className }: IOfferLetterRow) {
        return (
            <PlainText className={className} value={departmentDisplayValue} />
        );
    },
};

export const locationCell = {
    key: OfferLetterCellKey.Location,
    title: 'location',
    render: function LocationCell({ locationDisplayValue, className }: IOfferLetterRow) {
        return (
            <PlainText className={className} value={locationDisplayValue} />
        );
    },
};

export const locationEmployeeCell = {
    key: OfferLetterCellKey.Location,
    title: 'location',
    render: function LocationCell({ locationDisplayValue, worksiteAddress, className }: IOfferLetterRow) {
        return (
            <PlainText className={className} value={worksiteAddress ? worksiteAddress : locationDisplayValue} />
        );
    },
};

export const getOfferLetterStatusCell = (tableClasses: TableClassesType) => ({
    key: OfferLetterCellKey.Status,
    title: 'status',
    width: '112px',
    headerClassName: tableClasses.cellJustifyCenter,
    render: function StatusCell({ className, offerLetter }: IOfferLetterRow) {
        return (
            <OfferLetterStatus offerLetter={offerLetter} className={className} />
        );
    },
});

interface IContinueOnboardingButtonProps {
    className?: string;
    offerLetter: IOfferLetterWithPdf;
}

export const ContinueOnboardingButton = ({ className, offerLetter }: IContinueOnboardingButtonProps) => {
    const classes = useSubmittedTableStyles();
    return (
        <>
            {offerLetter.userCanContinueOnboarding && (
                <IconButton
                    className={clsx(className, classes.iconCell, classes.continueOnboarding)}
                    component={Link}
                    to={generatePath(routes.EMPLOYEE_OFFER_LETTER.DETAIL, { id: offerLetter.id })}
                    size="small"
                    title="Continue Onboarding"
                >
                    <Update />
                </IconButton>
            )}
        </>
    );
};

export const getActionCell = (tableClasses: TableClassesType) => ({
    key: OfferLetterCellKey.Action,
    title: '',
    width: '56px',
    headerClassName: tableClasses.cellJustifyCenter,
    render: function ActionCell({ className, offerLetter }: IOfferLetterRow) {
        return (
            <Box className={className}>
                <ContinueOnboardingButton offerLetter={offerLetter} />
            </Box>
        );
    },
});

export const getStatusPayRateMobileCell = (
    classes: ReturnType<typeof useOfferLetterEmployeeStyles>,
    action?: (offerLetter: IOfferLetterWithPdf) => void,
) => ({
    key: OfferLetterCellKey.StatusPayRate,
    title: '',
    width: '128px',
    render: function PayRateMobile({ className, offerLetter }: IOfferLetterRow) {
        const payRateType = usePayRateType(offerLetter.pay_rate_type);
        return (
            <Box
                className={className}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                onClick={action ? () => action(offerLetter) : undefined}
            >
                <OfferLetterStatus className={classes.statusMobile} offerLetter={offerLetter} />
                <MobileListCell listClassName={clsx(classes.mobileListWrapper, classes.listItemsAlignEnd)}
                    title={formatDollars(offerLetter.pay_rate_value)}
                    items={[payRateType]}
                />
                <ContinueOnboardingButton className={className} offerLetter={offerLetter} />
            </Box>
        );
    },
});

export const getOfferEditBtnCell = (clientId: string | null) => ({
    key: OfferLetterCellKey.OfferEdit,
    title: '',
    width: '75px',
    render: function EditBtnCell({ offerLetter, className }: IOfferLetterRow) {
        const userHasPermission = useOfferManagePermission(offerLetter);
        if (!userHasPermission) {
            return <PlainText className={className} />;
        }
        return (
            <ButtonCell
                className={className}
                sheetId={offerLetter.id}
                clientId={clientId}
            />
        );
    },
});

export const getOfferLetterDateCell = (offerLetterTab: OfferLetterTabValue) => {
    switch (offerLetterTab) {
        // TODO change data source for accepted and declined
        case OfferLetterStatusSlug.Accepted:
            return {
                key: OfferLetterCellKey.AcceptedDate,
                title: 'accepted date',
                render: function OfferDateCell({ offerLetter, className }: IOfferLetterRow) {
                    return (
                        <PlainText
                            className={className}
                            value={moment(offerLetter.accepted_at).format(shortDateFormat)}
                        />
                    );
                },
            };
        case OfferLetterStatusSlug.Declined:
            return {
                key: OfferLetterCellKey.DeclinedDate,
                title: 'declined date',
                render: function OfferDateCell({ offerLetter, className }: IOfferLetterRow) {
                    return (
                        <PlainText
                            className={className}
                            value={moment(offerLetter.rejected_at).format(shortDateFormat)}
                        />
                    );
                },
            };
        default:
            return offerDateCell;
    }
};
