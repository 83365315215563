import { TemplateNote } from 'modules/settings/submodules/offerLetters/components/OfferLetterTemplateForm/TemplateNote';
import React from 'react';
import { ButtonWithModal } from 'shared/components/filters/FilterButtonAndForm/ButtonWithModal';
import { useModal } from 'shared/utils/hooks/useModal';
import HelpIcon from '@material-ui/icons/Help';

export function FileUploadInfo() {
    const { isModalOpened, onModalClose, onModalOpen } = useModal();

    return (
        <ButtonWithModal
            isOpened={isModalOpened}
            onClose={onModalClose}
            onOpen={onModalOpen}
            icon={<HelpIcon />}
            style={{
                position: 'absolute',
                right: '-2px',
            }}
        >
            <TemplateNote />
        </ButtonWithModal>
    );
}
