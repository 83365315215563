import React from 'react';
import { useSelector } from 'react-redux';
import {
    CustomFieldValuesSelect,
    ICustomFieldValuesSelectProps,
} from 'shared/components/selects/CustomFieldValuesSelect/CustomFieldValuesSelect';
import { SCACustomFieldValueSelect } from 'shared/components/selects/SCACustomFieldValueSelect/SCACustomFieldValueSelect';
import { selectCustomFieldById } from 'store/entities/customFields/selectors';
import { CustomFieldType } from 'store/entities/customFields/model';

export interface ICustomFieldsValuesSelectGatewayProps extends ICustomFieldValuesSelectProps {}

export const CustomFieldsValuesSelectGateway = (props: ICustomFieldsValuesSelectGatewayProps) => {
    const customField = useSelector(selectCustomFieldById(props.customFieldId));

    if (customField?.prism_field?.key === CustomFieldType.SCA) {
        return (
            <SCACustomFieldValueSelect {...props} />
        );
    }

    return (
        <CustomFieldValuesSelect {...props} />
    );
};
