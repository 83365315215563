import React from 'react';
import { Box } from '@material-ui/core';
import { AccessTime, AttachMoney } from '@material-ui/icons';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { CellClasses, IPayrollSheetGroupedRow } from '../model';

interface ITypeCellPureProps {
    cellClasses: CellClasses;
    className?: string;
    hasTime: boolean;
    hasExpense: boolean;
}
const TypeCellPure = ({ className, cellClasses, hasTime, hasExpense }: ITypeCellPureProps) => (
    <Box className={className}>
        {hasTime && (
            <AccessTime className={cellClasses.timeIcon} />
        )}
        {hasExpense && (
            <AttachMoney className={cellClasses.expenseIcon} />
        )}
    </Box>
);

export function makeGroupedTypeCell(cellClasses: CellClasses) {
    return separateLogicDecorator<IPayrollSheetGroupedRow, ITypeCellPureProps>(({
        time_calculations,
        expense_calculations,
        ...props
    }) => {
        return {
            ...props,
            hasTime: Boolean(time_calculations?.length),
            hasExpense: Boolean(expense_calculations?.length),
            cellClasses,
        };
    })(TypeCellPure);
}
