import { Box } from '@material-ui/core';
import { PersonOutlined } from '@material-ui/icons';
import { useOfferLetterStyles } from 'modules/offerLetter/OfferLetterStyles';
import { OnboardingHeader } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/OnboardingHeader';
import EmployeeProfileFormWrapper from 'modules/offerLetter/components/OfferLetterEmployeeDetail/form/EmployeeProfileFormWrapper';
import React from 'react';

export interface IProfileStep {
    clientId: string;
    offerLetterId: string;
}

export const ProfileStep = ({ clientId, offerLetterId }: IProfileStep) => {
    const classes = useOfferLetterStyles();
    return (
        <Box className={classes.onboardingContainer}>
            <OnboardingHeader
                icon={<PersonOutlined fontSize="small" className={classes.headerIcon} />}
                title="2. Create Connect Profile"
            />
            <EmployeeProfileFormWrapper
                clientId={clientId}
                offerLetterId={offerLetterId}
            />
        </Box>
    );
};
