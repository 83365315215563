import { Box, Grid, Typography } from '@material-ui/core';
import { useFeature } from '@optimizely/react-sdk';
import clsx from 'clsx';
import { FormikProvider, useFormik } from 'formik';
import { selectClientAvailableCustomFields } from 'modules/settings/submodules/clients/customFieldValues/store/selectors';
import { IScopeModifyRequest } from 'modules/settings/submodules/clients/scopes/store/models';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import ClientRoleSelect from 'modules/settings/submodules/platformUsers/components/CreateUserClientAssociation/ClientRoleSelect';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { WarningAlertWithIcon } from 'shared/components/alerts/WarningAlertWithIcon';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import EnumFormSelect from 'shared/components/formFields/EnumFormSelect';
import FormField from 'shared/components/formFields/FormField';
import { ActivitySelect } from 'shared/components/selects/ActivitySelect/ActivitySelect';
import { CustomFieldValueMultiselectGateway } from 'shared/components/selects/CustomFieldValuesSelect/CustomFieldValueMultiselectGateway';
import FormMultiselect from 'shared/components/selects/FormMultiselect';
import UserSelect from 'shared/components/selects/UserSelect';
import { ValidationMessages } from 'shared/models/Validation';
import { QuantityTypeLabelMap } from 'shared/models/sheet/Sheet';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { getCustomFieldValueGroupedByCustomFieldId, getFieldValueName } from 'store/entities/customFields/helpers';
import { CustomFieldType, ICustomFieldValue, ILocationCustomField } from 'store/entities/customFields/model';
import { selectCustomFieldValuesByIds } from 'store/entities/customFields/selectors';
import { IScope, ScopeAction, ScopeActionDisplayNames, ScopeType } from 'store/entities/scopes/models';
import { UserType } from 'store/entities/users/model';
import { FeatureSwitches } from 'utils/featureSwitches';
import * as yup from 'yup';

interface IScopeFormProps {
    onSubmit: (values: IScopeModifyRequest) => void;
    type: ScopeType;
    isLoading: boolean;
    scope?: IScope;
    buttonText?: string,
}

export interface IScopeFormValues {
    name: string,
    customFieldValues: Record<string, string[]>,
    userIds: string[],
    activityIds: string[],
    roleIds: string[],
    actions?: ScopeAction[],
}

function getLocationFieldName(customValue: ICustomFieldValue): string {
    const { state_code } = customValue?.data as ILocationCustomField;
    return `${state_code} - ${getFieldValueName(customValue)}`;
}

function getFieldName(customValue: ICustomFieldValue): string {
    if (customValue?.data.headway_connect_field === CustomFieldType.Location) {
        return getLocationFieldName(customValue);
    }
    return getFieldValueName(customValue);
}

export function ScopeForm({
    onSubmit: onSubmitProps,
    type,
    isLoading,
    scope,
    buttonText = 'Create',
}: IScopeFormProps) {
    const classes = useSettingsFormStyles();
    const customFields = useSelector(selectClientAvailableCustomFields);
    const clientId = useSelector(selectCurrentClientId);
    const customFieldValuesByIds = useSelector(selectCustomFieldValuesByIds);
    const [enableScopeActions] = useFeature(FeatureSwitches.enableScopeActions);

    const onSubmit = useCallback((values: IScopeFormValues) => {
        onSubmitProps({
            client_id: clientId,
            name: values.name,
            activity_ids: type === ScopeType.Activity ? values.activityIds : undefined,
            user_ids: type === ScopeType.Activity ? undefined : values.userIds,
            custom_field_value_ids: Object.values(values.customFieldValues || {}).flat().filter(Boolean),
            type: type,
            actions: enableScopeActions ? values.actions : undefined,
            client_role_ids: enableScopeActions && type === ScopeType.SubassignmentManagement
                ? values.roleIds : undefined,
        });
    }, [onSubmitProps, type, clientId, enableScopeActions]);

    const getActivityText = activity => {
        const data_type = QuantityTypeLabelMap[activity.data_type];
        return `${activity.description} (${data_type})`;
    };

    const validationSchema = yup.object().shape({
        name: yup.string().required(ValidationMessages.REQUIRED),
    });
    const formikData = useFormik<IScopeFormValues>({
        initialValues: {
            name: scope?.name || '',
            customFieldValues: getCustomFieldValueGroupedByCustomFieldId(
                scope?.custom_field_value_ids || [],
                customFieldValuesByIds,
            ),
            userIds: scope?.user_ids || [],
            activityIds: scope?.activity_ids || [],
            actions: scope ? scope?.actions || [] : Object.values(ScopeAction),
            roleIds: scope?.client_role_ids || [],
        },
        validationSchema,
        onSubmit,
        validateOnBlur: false,
    });
    const { handleSubmit } = formikData;

    const userFilter = useMemo(() => ({
        client_id: clientId || undefined,
        user_type: UserType.Manager,
    }), [clientId]);

    return (
        <FormikProvider value={formikData}>
            <form onSubmit={handleSubmit}>
                <Box m={2}>
                    <WarningAlertWithIcon>
                        Please fill only values that you want to restrict in scope.
                        If you select {`"All"`}, the new values of this field will not be automatically added to the scope.
                    </WarningAlertWithIcon>
                </Box>

                <Grid container spacing={2}>
                    <Grid item sm={6}>
                        <FormField
                            name="name"
                            outerLabel="Name"
                            className={classes.input}
                        />
                    </Grid>
                </Grid>

                <Box>
                    <Box mt={3} ml={1}>
                        <Typography variant="subtitle2" className={clsx(classes.formBlockTitle)}>
                            Values
                        </Typography>
                    </Box>

                    <Grid container spacing={2}>
                        {customFields.map(field => (
                            <Grid
                                item
                                sm={6}
                                key={field.id}
                            >
                                <CustomFieldValueMultiselectGateway
                                    customFieldId={field.id}
                                    name={`customFieldValues[${field.id}]`}
                                    getText={getFieldName}
                                    className={classes.input}
                                    useIdValue
                                    selectAllFieldName={`customFieldValuesAllFields[${field.id}]`}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>

                <Box>
                    <Box mt={3} ml={1}>
                        <Typography variant="subtitle2" className={clsx(classes.formBlockTitle)}>
                            Applies to
                        </Typography>
                    </Box>

                    {type === ScopeType.SubassignmentManagement && (
                        <UserSelect
                            additionalFilter={userFilter}
                            name="userIds"
                            outerLabel="Search User"
                            className={classes.input}
                            useIdValue
                            multiple
                        />
                    )}
                    {type === ScopeType.Activity && (
                        <ActivitySelect
                            getText={getActivityText}
                            name="activityIds"
                            outerLabel="Activities"
                            className={classes.input}
                            useIdValue
                            multiple
                        />
                    )}
                </Box>

                {enableScopeActions && type === ScopeType.SubassignmentManagement && (
                    <>
                        <Box>
                            <EnumFormSelect
                                name="actions"
                                values={ScopeAction}
                                className={classes.input}
                                outerLabel="Scope actions"
                                displayValues={ScopeActionDisplayNames}
                                InputComponent={FormMultiselect}
                                multiple
                                // @ts-ignore
                                useSelectAll
                            />
                        </Box>
                        <Box>
                            <ClientRoleSelect
                                name="roleIds"
                                outerLabel="Roles"
                                className={classes.input}
                                clientId={clientId}
                                useIdValue
                                multiple
                            />
                        </Box>
                    </>
                )}

                <Box mt={7}>
                    <ButtonWithLoader
                        type="submit"
                        variant="contained"
                        color="secondary"
                        disabled={isLoading}
                        isLoading={isLoading}
                    >
                        {buttonText}
                    </ButtonWithLoader>
                </Box>
            </form>
        </FormikProvider>
    );
}
