import React from 'react';
import useTitleUpdate from 'shared/utils/hooks/useTitleUpdate';
import { Hidden } from '@material-ui/core';
import UserProfileDesktopView from './UserProfileDesktopView';
import UserProfileMobileView from './UserProfileMobileView';

const UserProfile: React.FC = () => {
    useTitleUpdate('User Profile Settings');

    return (
        <>
            <Hidden xsDown>
                <UserProfileDesktopView />
            </Hidden>
            <Hidden smUp>
                <UserProfileMobileView />
            </Hidden>
        </>
    );
};

export default UserProfile;
